import React, { Component } from 'react'
import { noopTemplate as css } from 'lib/utils'

// import { sentry } from 'services/analytics'
import { BORDER_RADIUS } from 'styles/constants'

type State = {
  hasError: boolean
}

type Props = {}
export default class PageReloadBoundary extends Component<Props, State> {
  state = { hasError: false }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, info: any) {
    // sentry.captureException(error)
  }

  render() {
    return this.state.hasError ? <VanillaErrorPage /> : this.props.children
  }
}

const VanillaErrorPage = () => {
  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        text-align: center;
      `}
    >
      <div
        css={css`
          max-width: 550px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            color: rgba(0, 0, 0, 0.87);
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.33;
            letter-spacing: 0em;
            margin-bottom: 0.35em;
          `}
        >
          We apologise for the delay you are experiencing.
        </div>

        <div
          css={css`
            color: rgba(0, 0, 0, 0.54);
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.6;
            letter-spacing: 0.0075em;
            margin-bottom: 0.35em;
          `}
        >
          We are currently updating the website and expect this page to be back
          shortly.
        </div>

        <button
          css={css`
            padding: 6px 16px;
            margin: 8px 8px 0 0;
            color: #3f51b5;
            font-size: 0.9375rem;
            line-height: 1.75;
            font-weight: 500;
            border-radius: ${BORDER_RADIUS}px;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64px;
            box-sizing: border-box;
            border: none;
            cursor: pointer;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            &:hover {
              background-color: rgb(240, 241, 249);
            }
            outline: none;
          `}
          onClick={() => {
            location.reload(true)
          }}
        >
          Try again now
        </button>
      </div>
    </div>
  )
}
