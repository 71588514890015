import React, { useState } from 'react'
import { noopTemplate as css } from 'lib/utils'
import { FormattedMessage } from 'react-intl'
import { theme } from 'theme'
import { captureEvent } from 'services/analytics'
import AppLogo from 'assets/AppLogo.png'
import ExitIcon from 'images/grey-close.svg'
import {
  EVENT_CATEGORY_DOWNLOAD_APP_CTA,
  EVENT_USER_DISMISSED_APP_BANNER,
  EVENT_USER_CLICKED_THROUGH_APP_BANNER
} from 'tools/constants'
import { Text, Button } from 'lib/components'
import cookies from 'tools/cookies'
import LazyLoad from 'react-lazyload'
const DAYS_UNTIL_EXPIRES = 30

const AppBanner = () => {
  const flag = cookies.get('_DimissAppBanner') || false
  const [hideBanner, setHideBanner] = useState(flag)

  const handleButtonClick = (actionLabel: string) => {
    // Save the cookie to the browser
    const expiration = new Date()
    expiration.setDate(expiration.getDate() + DAYS_UNTIL_EXPIRES)
    cookies.set('_DimissAppBanner', true, expiration)

    // Hide the component
    setHideBanner(true)

    // Capture event in analytics
    captureUserEvent(actionLabel)
  }

  const captureUserEvent = (actionLabel: string) => {
    const event = {
      category: EVENT_CATEGORY_DOWNLOAD_APP_CTA,
      action: actionLabel,
      label: document.location.href
    }

    captureEvent(event)
  }

  return !hideBanner ? (
    <div
      css={css`
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        box-shadow: -3px 0 12px rgba(0, 0, 0, 0.2);
        width: 100vw;
        background: #fff;
        padding: 0.5em 0;
      `}
    >
      <ExitIcon
        onClick={() => handleButtonClick(EVENT_USER_DISMISSED_APP_BANNER)}
      />
      <LazyLoad height={24} once>
        <img
          src={AppLogo}
          css={css`
            object-fit: contain;
            object-position: center;
            height: 32px;
            width: 32px;
          `}
        />
      </LazyLoad>

      <div
        css={css`
          flex-flow: column;
          display: flex;
        `}
      >
        <Text variant="h7">
          RateBeer <FormattedMessage id="banner.officialApp" />
        </Text>
        <FormattedMessage id="banner.free" />
      </div>

      <Button
        href="https://play.google.com/store/apps/details?id=com.ratebeer&utm_source=RateBeer&utm_medium=banner"
        onClick={() => captureUserEvent(EVENT_USER_CLICKED_THROUGH_APP_BANNER)}
        color="yellow"
        variant="contained"
      >
        <Text className="bold" color="textPrimary">
          Get It
        </Text>
      </Button>
    </div>
  ) : null
}

export default AppBanner
