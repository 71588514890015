import React from 'react'
import { noopTemplate as css } from 'lib/utils'
import { Button } from 'lib/components'

import { useUser, useSignin, useSignup } from 'services/auth'
import { intl } from 'services/intl'

import AccountMenuAvatar from './AccountMenuAvatar'

type AccountLinksProps = Stylable

const AccountLinks = ({ className, style }: AccountLinksProps) => {
  const user = useUser()
  const signin = useSignin()
  const signup = useSignup()

  if (user) {
    return <AccountMenuAvatar user={user} />
  }

  return (
    <div className={className} style={style}>
      <Button
        variant="outlined"
        color="white"
        onClick={() => signin()}
        style={{ textTransform: 'none' }}
        size="small"
      >
        {intl.formatMessage('Log In')}
      </Button>

      <Button
        css={css`
          @media (max-width: 1300px) {
            display: none;
          }
        `}
        className="ml-4"
        variant="contained"
        color="white"
        onClick={() => signup()}
        style={{ textTransform: 'none' }}
        size="small"
      >
        <span
          css={css`
            color: black;
          `}
        >
          {intl.formatMessage('Sign Up')}
        </span>
      </Button>
    </div>
  )
}

export default AccountLinks
