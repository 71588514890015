import { updateQueryString, queryString } from 'lib/utils'

import { TabName } from 'features/search/Page'

type SearchPageUrlInput = {
  searchTerm: string
  tab?: TabName
}

export const searchPageUrl = ({ searchTerm, tab }: SearchPageUrlInput) => {
  const q = updateQueryString(queryString(location.href), {
    allowedParameters: ['q', 'beername', 'tab'],
    parameters: {
      q: searchTerm,
      tab: tab
    }
  })

  console.log(q)

  const base = '/search'

  return q ? `${base}?${q}` : base
}
