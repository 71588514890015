import React from 'react'
import { Provider as IntlProvider } from 'services/intl'
import { ApolloProvider } from '@apollo/react-common'
import { client as apolloClient } from 'services/graphql'
import { BrowserRouter } from 'react-router-dom'
import { StateContainer as GeoLocationProvider } from 'services/geo-location'
import { StateContainer as AuthStateProvider } from 'services/auth'
import { Provider as RouterHooksProvider } from 'services/router'
import { Provider as SnackbarProvider } from 'services/snackbar'
import { AnalyticsProvider } from 'services/analytics'
import { Provider as ErrorPageProvider } from 'services/error-page'
import HotApp from './HotApp'

import { StylesProvider } from '@material-ui/core/styles'

import { compose } from 'ramda'

import { ThemeProvider } from 'theme'
import { Provider as DialogDialog } from 'services/dialog'
import { HeadMetaProvider } from 'services/head-meta'
import { PageReloadBoundary } from 'components'
import { WindowSizeProvider } from 'services/window-size'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

export type Wrapper = (
  node: React.ComponentType<{ children?: React.ReactNode }>
) => React.ComponentType<{ children?: React.ReactNode }>

export const applyThemeProvider: Wrapper = Comp => {
  return ({ children }) => (
    <ThemeProvider>
      <Comp>{children}</Comp>
    </ThemeProvider>
  )
}

export const applySnackbarProvider: Wrapper = Comp => {
  return ({ children }) => (
    <SnackbarProvider>
      <Comp>{children}</Comp>
    </SnackbarProvider>
  )
}

export const applyGlobalDialogProvider: Wrapper = Comp => {
  return ({ children }) => (
    <DialogDialog>
      <Comp>{children}</Comp>
    </DialogDialog>
  )
}

export const applyMaterialUIProvider: Wrapper = Comp => {
  return ({ children }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StylesProvider injectFirst>
        <Comp>{children}</Comp>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  )
}

const applyApolloProvider: Wrapper = Comp => {
  return ({ children }) => (
    <ApolloProvider client={apolloClient}>
      <Comp>{children}</Comp>
    </ApolloProvider>
  )
}

export const applyIntlProvider: Wrapper = Comp => {
  return ({ children }) => (
    <IntlProvider>
      <Comp>{children}</Comp>
    </IntlProvider>
  )
}

export const applyRouterProvider: Wrapper = Comp => {
  return ({ children }) => (
    <BrowserRouter>
      <RouterHooksProvider>
        <Comp>{children}</Comp>
      </RouterHooksProvider>
    </BrowserRouter>
  )
}

export const applyGeoLocationProvider: Wrapper = Comp => {
  return ({ children }) => (
    <GeoLocationProvider>
      <Comp>{children}</Comp>
    </GeoLocationProvider>
  )
}

export const applyAuthStateProvider: Wrapper = Comp => {
  return ({ children }) => (
    <AuthStateProvider>
      <Comp>{children}</Comp>
    </AuthStateProvider>
  )
}
export const applyHeadMetaProvider: Wrapper = Comp => {
  return ({ children }) => (
    <HeadMetaProvider>
      <Comp>{children} </Comp>
    </HeadMetaProvider>
  )
}
export const applyWindowSizeProvider: Wrapper = Comp => {
  return ({ children }) => (
    <WindowSizeProvider>
      <Comp>{children}</Comp>
    </WindowSizeProvider>
  )
}
export const applyAnalyticsProvider: Wrapper = Comp => {
  return ({ children }) => (
    <AnalyticsProvider>
      <Comp>{children}</Comp>
    </AnalyticsProvider>
  )
}
export const applyErrorPageProvider: Wrapper = Comp => {
  return ({ children }) => (
    <ErrorPageProvider>
      <Comp>{children}</Comp>
    </ErrorPageProvider>
  )
}

const wrapper = compose(
  // Independent Providers
  compose(
    applyRouterProvider,
    applyApolloProvider
  ),
  compose(
    applyIntlProvider,
    applyGeoLocationProvider,
    applyAuthStateProvider
  ),
  applyHeadMetaProvider,
  compose(
    applyMaterialUIProvider,
    applyErrorPageProvider,
    applyThemeProvider,
    applySnackbarProvider,
    applyGlobalDialogProvider,
    applyWindowSizeProvider
  ),
  applyAnalyticsProvider
)

const Root = wrapper(HotApp)

export default () => (
  <PageReloadBoundary>
    <Root />
  </PageReloadBoundary>
)
