export default {
  "Account Settings": "Configuración de cuenta",
  "Add description": "Agregar descripción",
  "Add location": "Agregar ubicación",
  "Appearance": "Aspecto",
  "Apply to sell with us": "Solicite vender con nosotros",
  "Are you sure you want to delete your review?": "¿Estás seguro que deseas eliminar tu reseña?",
  "Aroma": "Aroma",
  "Associated Brewer": "Fabricante de cerveza asociado",
  "Available here": "Disponible aquí",
  "Available to buy from {count} trusted retail partners": "Disponible para comprar en {count} socios minoristas de confianza",
  "Average": "Promedio",
  "Beer name": "Nombre de cerveza",
  "Beer styles": "Estilos de cerveza",
  "Beers Spotted Here": "Cervezas descubiertas aquí",
  "Bottles {bottlesCount}": "Botellas {bottlesCount}",
  "Buy from": "Comprar de",
  "Cancel": "Cancelar",
  "Community": "Comunidad",
  "Copied to clipboard": "Copiado al portapapeles",
  "Copy to clipboard": "Copiar al portapapeles",
  "Country of origin": "País de origen",
  "Events": "Eventos",
  "Filter": "Filtro",
  "Find a beer or brewer": "Encontrar una cerveza o un fabricante de cerveza",
  "Find a country": "Encontrar un país",
  "Find a style": "Encontrar un estilo",
  "Follow on Social Media": "Seguir en las redes sociales",
  "Forums": "Foros",
  "From {price} per {serve}": "Desde {price} por {serve}",
  "Latest Activity": "Actividad más reciente",
  "Log In": "Iniciar sesión",
  "Log Out": "Cerrar sesión",
  "Manage Breweries Followed": "Gestionar cervecerías seguidas",
  "Manage People Followed": "Gestionar personas seguidas",
  "Mouthfeel": "Sensación en la boca",
  "My Beer Cellar": "Mi bodega de cervezas",
  "My Beer Ratings": "Mis calificaciones de cervezas",
  "My Beer Styles": "Mis estilos de cerveza",
  "My Breweries": "Mis cervecerías",
  "My Countries & States": "Mis países y estados",
  "My Favorites": "Mis favoritos",
  "My Place Ratings": "Mis calificaciones de lugares",
  "My Profile": "Mi perfil",
  "New Releases": "Nuevos productos",
  "No results found for": "No se encontraron resultados para {query}",
  "Overall": "General",
  "Places": "Lugares",
  "Rate this beer on these attributes": "Calificar esta cerveza sobre la base de estos atributos",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "RateBeer trabaja con los minoristas más grandes para ofrecer a los amantes de la cerveza una inmejorable variedad de cervezas a los mejores precios.",
  "Ratings": "Calificaciones",
  "Read more": "leer más",
  "Retailers": "tiendas",
  "Review this place": "Escribir una reseña sobre este lugar",
  "Save": "Guardar",
  "Search beers spotted here": "Buscar cervezas descubiertas aquí",
  "Search for Beers, Breweries, or Bars...": "Buscar cervezas, cervecerías o bares…",
  "Seasonal beer": "Cerveza de temporada",
  "Shop now": "Compra ahora",
  "Sign Up": "Registrarse",
  "Similar beers": "Cervezas similares",
  "Taps {tapsCount}": "Grifos {tapsCount}",
  "Taste": "Sabor",
  "The most comprehensive ratings and reviews of beers from around the world": "Las calificaciones y reseñas más integrales de cervezas de todo el mundo",
  "This score could not be updated": "No fue posible actualizar este puntaje",
  "This score has been updated": "Este puntaje no ha sido actualizado",
  "Total score": "PUNTUACIÓN TOTAL",
  "View beer menu": "Ver menú de cervezas",
  "View food menu": "Ver menú de comidas",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Ver ofertas de {count} {count, plural, one {retailer} other {retailers}}",
  "We apologise for the delay you are experiencing.": "Nos disculpamos por la demora que está experimentando.",
  "We are currently updating the website and expect this page to be back shortly.": "Actualmente estamos actualizando el sitio web y esperamos que esta página esté disponible dentro de poco.",
  "Your review has been updated": "Tu reseña ha sido actualizada",
  "add.abv": "% de ABV",
  "add.abvPlaceholder": "(por ej. 11%)",
  "add.addBeer": "Agregar nueva cerveza",
  "add.addBrewery": "Agregar nueva cervecería",
  "add.autumn": "Otoño",
  "add.beerGuidelines": "Pautas",
  "add.beerGuidelines1": "Las cervezas deben existir comercialmente y deben estar en producción.",
  "add.beerGuidelines2": "Las recetas deben ser únicas. No deben ser recetas sin barrica, nitro, etc. o variantes sin receta.",
  "add.beerGuidelines3": "Las sidras con frutas deben tener más de 50% de manzana fermentable y deben tener más de 2% de ABV.",
  "add.beerGuidelines4": "Incluye los nombres de los productores al frente del nombre de la cerveza, por ej. {correctExample} no {incorrectExample}.",
  "add.beerImage": "Agregar una imagen",
  "add.beerName": "Nombre de cerveza",
  "add.beerNameExplanation": "La convención de nomenclatura de RateBeer debe incluir el nombre de la cervecería + el nombre de la cerveza.",
  "add.beerNameInfo": "(incluir al fabricante de cerveza)",
  "add.beerNamePlaceholder": "(por ej. Elysian Space Dust)",
  "add.breweryName": "Nombre de la cervecería",
  "add.breweryNamePlaceholder": "(ej. Elysian)",
  "add.contractBrewer": "Fabricada por",
  "add.contractBrewerPlaceholder": "Buscar cervecerías...",
  "add.contractBrewerPrompt": "Esta es una cerveza de contrato",
  "add.description": "Descripción comercial",
  "add.descriptionPlaceholder": "Lo que se lee en la etiqueta del producto",
  "add.duplicateFound": "Duplicate Found",
  "add.duplicateMessage": "Is this the beer you're trying to add?",
  "add.ibuPlaceholder": "(por ej. 57)",
  "add.imageInstructions": "* Use imágenes de latas o botellas",
  "add.invalid": "No válido",
  "add.newBeer": "Agregar una nueva cerveza",
  "add.noBrewery": "¿No puedes encontrar tu cervecería?",
  "add.optional": "Opcional",
  "add.removeBeerImage": "Eliminar imagen",
  "add.seasonalPlaceholder": "¿Es una cerveza de temporada?",
  "add.series": "Serie",
  "add.signInToAddBeer": "Inicia sesión para agregar una cerveza",
  "add.special": "Producto especial",
  "add.spring": "Primavera",
  "add.stylePlaceholder": "Seleccionar estilo",
  "add.summer": "Verano",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Números de código de barras)",
  "add.upcInstructions": "Incluye los 8, 12, 13 o 14 números completos",
  "add.upcPlaceholder": "Todos los números en el código de barras",
  "add.view": "View",
  "add.winter": "Invierno",
  "all.adblock": "Al parecer hay un bloqueador de ventanas emergentes instalado. Considera agregar a RateBeer.com como excepción. ¡Salud!",
  "all.ago": "{date} atrás",
  "all.back": "Back",
  "all.beer": "Cerveza",
  "all.beers": "Cervezas",
  "all.cider": "Sidra",
  "all.ciders": "Sidras",
  "all.cookies": "Utilizamos cookies y otros",
  "all.error": "Lo sentimos, se produjo un error de red",
  "all.joinBody": "Aprovecha todo lo que RateBeer tiene que ofrecer. Lee los artículos completos, ve estadísticas detalladas y entérate de lo que la comunidad tiene que decir.",
  "all.joinTitle": "¡Únete a nuestra comunidad!",
  "all.learnMore": "Obtén más información",
  "all.loading": "Cargando",
  "all.mead": "Aguamiel",
  "all.meads": "Aguamieles",
  "all.noSuchBeer": "No existe esa cerveza en la base de datos. Intenta buscar otra cerveza.",
  "all.ok": "Aceptar",
  "all.sake": "Sake",
  "all.sakes": "Sakes",
  "banner.free": "Gratis – Google Play",
  "banner.getIt": "Obtenla",
  "banner.officialApp": "App oficial",
  "beer.aboutBeer": "Acerca de esta Cerveza",
  "beer.aboutCider": "Acerca de esta Sidra",
  "beer.aboutMead": "Acerca de esto Aguamiel",
  "beer.aboutSake": "Acerca de esta Sake",
  "beer.abv": "ABV",
  "beer.aka": "También se le conoce como",
  "beer.aliasBeer": "Cerveza Alias",
  "beer.aliasCopy": "El fabricante comercializa esta misma cerveza o un producto muy parecido con más de un nombre. Esto se puede deber a que el fabricante distribuye esta cerveza bajo diferentes nombres en diferentes países o simplemente que el fabricante cambió el nombre, pero no la receta, en diferentes momentos.",
  "beer.allStyles": "Agregar estilos de cerveza para ",
  "beer.appearance": "Aspecto",
  "beer.applyToSellWithUs": "Postula para vender con nosotros",
  "beer.aroma": "Aroma",
  "beer.autumn": "Otoño",
  "beer.availability": "Disponibilidad",
  "beer.availableAt": "Disponible en",
  "beer.averageRatings": "Reseñas promedio",
  "beer.bottled": "Embotellado",
  "beer.brewedBy": "Fabricada por ",
  "beer.brewedFor": "Fabricada para ",
  "beer.buyFrom": "Comprar de",
  "beer.calories": "CAL. EST.",
  "beer.clearRating": "Borrar",
  "beer.copy": "Copiar",
  "beer.delete": "Eliminar cerveza/calificaciones",
  "beer.deleteAlias": "Eliminar alias",
  "beer.deleteConfirm": "Sí, eliminar",
  "beer.deleteOption": "Eliminar",
  "beer.deleteRating": "¿Deseas eliminar la calificación?",
  "beer.distribution": "Distribución",
  "beer.edit": "Editar",
  "beer.editAlias": "Agregar/editar alias",
  "beer.editBarcodes": "Agregar/editar códigos de barra",
  "beer.editBeer": "Editar esta cerveza",
  "beer.editPicture": "Agregar/editar foto",
  "beer.editTags": "Agregar/Editar Tags",
  "beer.findFriendsText": "Encuentra amigos para compartir lo que estás bebiendo y entérate de lo que han probado",
  "beer.findFriendsTitle": "Encontrar amigos",
  "beer.findIt": "Encuéntrala",
  "beer.findItNearYou": "Encuéntrala cerca de ti",
  "beer.fix": "Fijar puntuación",
  "beer.flavor": "Sabor",
  "beer.from": "de ",
  "beer.highestScore": "Mejor puntuación",
  "beer.howItWorks": "Cómo funciona",
  "beer.howItWorksContent": "Trabajamos duro para encontrarte los mejores minoristas, con la mayor variedad y con excelentes precios para que puedas comprar la cerveza que te encanta",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Ubicar vendedores",
  "beer.locations": "{count} {count, plural, one {Ubicación} other {Ubicaciones}}",
  "beer.locationsNearby": "{count} {count, plural, one {Ubicación cercana} other {Ubicaciones cercanas}}",
  "beer.mostLiked": "Más gustadas",
  "beer.mostRecent": "Más reciente",
  "beer.mouthfeel": "Paladar",
  "beer.moveRating": "Trasladar calificación",
  "beer.myFriends": "Mis amigos",
  "beer.myReview": "Mi reseña",
  "beer.noDescription": "No hay descripción disponible",
  "beer.noRating": "Sin calificación",
  "beer.noReviewsText": "Cuéntale al mundo lo que piensas. ¡Sé el primero en calificar y escribir una reseña!",
  "beer.noReviewsTitle": "¿Probaste esta cerveza?",
  "beer.onTap": "De grifo",
  "beer.outOfProd": "SIN PRODUCCIÓN",
  "beer.overall": "General",
  "beer.per": "por",
  "beer.privacyNotice": "Al hacer clic en el / los enlace (s) de la tienda arriba, se lo remitirá al sitio web del minorista y, a partir de ahí, se aplicará su Política de privacidad.",
  "beer.private": "PRIVADO",
  "beer.quickRate": "Calificación rápida",
  "beer.quickRateSaved": "Calificación rápida guardada",
  "beer.quickRated": "Con calificación rápida: ",
  "beer.rateAndReview": "Calificar y escribir reseña",
  "beer.rateIt": "¿La probaste? ¡Califícala!",
  "beer.ratedBy": "Calificación de",
  "beer.ratingPrivate": "Calificación privada",
  "beer.ratingPublic": "Calificación pública",
  "beer.readMore": "leer más",
  "beer.reviews": "Reseñas",
  "beer.reviewsRatings": "Reseñas y calificaciones",
  "beer.saveRating": "¡Guarda esa calificación!",
  "beer.saveRatingText": "Crea una cuenta para guardar calificaciones, leer reseñas de expertos, ver calificaciones en detalle, revisar estadísticas y mucho más.",
  "beer.seasonal": "Estacional",
  "beer.seasonalLabel": "ESTACIONAL",
  "beer.seeAll": "Ver todas",
  "beer.seeAllXReviews": "Ver las {count} reseñas",
  "beer.sendCorrections": "Enviar correcciones",
  "beer.series": "Serie",
  "beer.serveIn": "Servir en",
  "beer.share": "Compartir",
  "beer.shareTutorial": "Comparte, envía correcciones, agrega imágenes",
  "beer.shelfTags": "Ver etiqueta del estante",
  "beer.shopNow": "Compra ahora",
  "beer.showLess": "mostrar menos",
  "beer.signInToFavorite": "Inicia sesión para agregar esta cerveza a tus favoritos",
  "beer.signInToFind": "Inicia sesión para encontrar esta cerveza.",
  "beer.signInToLikeReview": "Inicia sesión para marcar con Me gusta esta reseña",
  "beer.similarBeer": "Cervezas Similares",
  "beer.similarCider": "Sidras Similares",
  "beer.similarMead": "Aguamieles Similares",
  "beer.similarSake": "Sakes Similares",
  "beer.special": "Especial",
  "beer.spring": "Primavera",
  "beer.style": "Estilo ",
  "beer.summer": "Verano",
  "beer.tags": "Etiquetas",
  "beer.top50": "Mejores 50",
  "beer.topRaters": "Mejores calificadores",
  "beer.totalRetailers": "Esta cerveza es vendida por {count} terceros vendedores verificados",
  "beer.undoRating": "Deshacer calificación",
  "beer.unrateable": "Esta cerveza no puede ser calificada",
  "beer.verified": "VERIFICADO",
  "beer.winter": "Invierno",
  "beer.writeAReview": "Escribir una reseña",
  "beer.writeFullReview": "Escribir una reseña completa",
  "beer.yourReview": "Tu reseña",
  "error.refresh": "Actualizar",
  "favorites.instructions": "Cuando encuentres una cerveza que te guste, pulsa el botón Guardar para guardarla para después. Ve tus cervezas guardadas en un solo lugar en todos tus dispositivos.",
  "favorites.noSavedBeers": "No has guardado ninguna cerveza",
  "favorites.remove": "Eliminar",
  "favorites.removeConfirmation": "Sí, eliminar",
  "favorites.removePrompt": "¿Eliminar de Favoritos?",
  "favorites.saved": "Guardada",
  "favorites.savedConfirmation": "Guardada en Favoritos",
  "favorites.signInToViewFavorites": "Inicia sesión para agregar esta cerveza a tus favoritos",
  "favorites.viewAll": "Ver todas",
  "feedback.android": "App móvil de Android",
  "feedback.androidReproduceInfo": "(por ejemplo, iniciar sesión, buscar por escaneo de código de barra, tocar resultado de cerveza, expandir panel de calificación, enviar calificación de experto)",
  "feedback.bug": "Reportar un error",
  "feedback.bugMessageInfo": "(¿Qué resultados esperabas? ¿Qué resultados obtuviste?)",
  "feedback.bugMessagePrompt": "Resumen del error",
  "feedback.buildInfo": "(por ejemplo, versión de app 1.7.0. Para encontrar, pulsa ? en la esquina superior derecha de la pantalla de inicio)",
  "feedback.buildPrompt": "Versión de app",
  "feedback.categoryPrompt": "¿Qué te gustaría hacer hoy?",
  "feedback.correction": "Enviar una corrección",
  "feedback.correctionMessageInfo": "(¿Cómo esperabas que apareciera la información? ¿Aparece información incorrecta actualmente?)",
  "feedback.correctionMessagePrompt": "Resumen de corrección",
  "feedback.email": "Correo electrónico",
  "feedback.header": "Formulario de comentarios",
  "feedback.iOS": "App móvil de iOS",
  "feedback.iOSReproduceInfo": "(por ejemplo, iniciar sesión, buscar por escaneo de código de barra, tocar resultado de cerveza, expandir panel de calificación, enviar calificación de experto)",
  "feedback.mediumPrompt": "¿Dónde tuviste este problema?",
  "feedback.reproducePrompt": "¿Qué pasos podemos seguir para reproducir este error?",
  "feedback.submit": "Enviar",
  "feedback.submitted": "¡Gracias por enviar tus comentarios!",
  "feedback.suggestion": "Hacer una sugerencia",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "¿Qué te gustaría ver en RateBeer?",
  "feedback.url": "URL",
  "feedback.username": "Nombre de usuario",
  "feedback.web": "Sitio Web",
  "feedback.webReproduceInfo": "(Por ejemplo, hacer inicio de sesión, visitar ratebeer.com/community, hacer clic en barra lateral)",
  "footer.FAQs": "Preguntas frecuentes",
  "footer.RBBest": "Lo mejor de RateBeer",
  "footer.TOS": "Términos de servicio",
  "footer.aboutRateBeer": "Acerca de RateBeer",
  "footer.aboutUs": "Acerca de nosotros",
  "footer.account": "Cuenta",
  "footer.addBeer": "Agregar esta cerveza",
  "footer.addBrewer": "Agregar este fabricante",
  "footer.addEvent": "Agregar un evento",
  "footer.addPlace": "Agregar un lugar",
  "footer.addPremium": "Agregar Premium",
  "footer.addToRB": "Agregar a RateBeer",
  "footer.contactUs": "Comuníquese con nosotros",
  "footer.copyright": "Todos los derechos reservados.",
  "footer.developers": "Desarrolladores",
  "footer.editAccount": "Editar cuenta",
  "footer.privacy": "Política de privacidad",
  "footer.theBest": "Las mejores",
  "footer.top50Beers": "Las 50 mejores cervezas",
  "footer.topReviewers": "Mejores críticos",
  "glasscopy1": "Tall, thin and footed with a short stem. Flutes are delicate and show off a beer’s body. These vessels are ideal for light and sparkling beers.",
  "glasscopy10": "Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.",
  "glasscopy11": "A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.",
  "glasscopy12": "This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!",
  "glasscopy13": "Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.",
  "glasscopy14": "A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.",
  "glasscopy15": "Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.",
  "glasscopy16": "A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.",
  "glasscopy17": "Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.",
  "glasscopy18": "A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.",
  "glasscopy19": "A small cup, often fluted at the edge, and larger than ochoko.",
  "glasscopy2": "Short glasses, holds around 12oz. of beer. Slightly wider at the mouth than the foot, with gradual, evenly sloping sides. Great basic glass, well-suited for pale lagers.",
  "glasscopy20": "A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.",
  "glasscopy21": "Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.",
  "glasscopy3": "Designed to be drained in a few gulps. These glasses allow for billowing heads to form. Neither are ideal for developing aroma or discerning subtleties.",
  "glasscopy4": "The American microbrewer’s standard. Gently sloped 16oz. glass made for session-type beers. Typically used to serve Ambers and English & American pales.",
  "glasscopy5": "Similar to a shaker but often straight-sided, usually with an additional flair or bulb to retain aroma. These glasses are often marked to measure an imperial pint.",
  "glasscopy6": "A classic in North America, the dimpled mug is large and has a handle. It is convex, with the mouth larger than the base. Dimples make appreciating the appearance of the beer difficult, but the wide mouth releases the aroma nicely. Commonly used for raunchy lagers.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "A small, almost straight-sided glass sits on an inch-long stem and foot. The basic footed pilsner has a slightly bulbous bottom and narrower mouth, which makes it better for drinking than for smelling, and places the most emphasis on the appearance. It has a bit more style than some other glasses, so it is best used for pilsners, and decent cream or golden ales than for the lowliest lagers.",
  "glasscopy9": "Often a favorite of top tasters. The Duvel glass is a well-known variant of the tulip style, and the tasting glass in the RateBeer logo is an almost perfect example.",
  "header.my2017": "Mi año en la cerveza",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Las mejores cervezas por estilo",
  "home.Top Rated Beers Nearby": "Las cervezas mejor calificadas cercanas",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Descargar la app",
  "home.downloadBlurb": "Lleva a RateBeer contigo ¡donde quiera que estés! Te ofrecemos excelentes funciones para ayudarte a encontrar nuevas cervezas que te encantarán.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "¡Mensaje enviado!",
  "home.porter": "Porter",
  "home.retailers": "tiendas",
  "home.saison": "Saison",
  "home.searchPrompt": "Encontrar cervezas, cervecerías o bares…",
  "home.shopOnline": "Compra en línea",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Enlace de texto",
  "home.top50Beers": "50 mejores cervezas",
  "home.worldOfBeer": "Tu guía al mundo de la cerveza",
  "landing.TOS": "Términos de servicio",
  "landing.feedback": "Este es nuestro primer intento con una app de RateBeer{br}¡Ayúdanos a mejorarla!",
  "landing.h1": "El sitio Web de calificación de cervezas nro. 1 del mundo,{br}¡ahora en una app!",
  "landing.h2": "Ahora puedes calificar cervezas{br}desde la palma de tu mano",
  "landing.label": "calificaciones",
  "landing.li1": "Busca en nuestra base de datos de más de 500.000 cervezas",
  "landing.li2": "Califica, escribe reseñas y comparte",
  "landing.li3": "Haz un registro de todas las cervezas que has probado",
  "landing.privacy": "POLÍTICA DE PRIVACIDAD",
  "landing.review": "RateBeer es la fuente de información de cervezas y fabricantes más confiable de Internet.",
  "landing.rightsReserved": "Todos los derechos reservados.",
  "landing.submit": "Enviar comentarios",
  "landing.visit": "Visita ratebeer.com",
  "place.addBeerIds": "Agregar ID's de cervezas",
  "place.addBeers": "Agregar cervezas",
  "place.addEvent": "Agregar evento",
  "place.ambiance": "Ambiente",
  "place.available": "Disponible",
  "place.beerMenu": "Menú de cervezas",
  "place.close": "Cerrar",
  "place.customizeShelftags": "Personalizar etiquetas de estante",
  "place.editBeers": "Editar cervezas",
  "place.editPlace": "Editar lugar",
  "place.flights": "Vuelos",
  "place.food": "Comida",
  "place.foodMenu": "Menú de comida",
  "place.getDirection": "Obtener indicaciones",
  "place.hours": "Horas",
  "place.lessInfo": "Menos información",
  "place.map": "Mapa",
  "place.moreInfo": "Más información",
  "place.overall": "General",
  "place.phone": "Número de teléfono",
  "place.printShelftags": "Imprimir etiquetas de estante",
  "place.selection": "Selección",
  "place.sendToPhone": "Enviar al teléfono",
  "place.service": "Servicio",
  "place.shelftags": "Etiquetas de estante",
  "place.showBreakdown": "Mostrar detalle del puntaje",
  "place.value": "Correlación precio/calidad",
  "place.wifi": "Wi-Fi gratis",
  "rate.BOTTLE": "Botella",
  "rate.CAN": "Lata",
  "rate.CASK": "Barril",
  "rate.TAP": "Grifo",
  "rate.commentPlaceholder": "Agrega notas de cata, descripciones, etc.",
  "rate.infoSubtitle": "Para asegurar la calidad de nuestras puntuaciones de cervezas, solo incluimos calificaciones detalladas",
  "rate.locationPlaceholder": "Agregar ubicación",
  "rate.nearbyPlaces": "Ubicaciones cercanas",
  "rate.ourRatingSystem": "Nuestro sistema de calificaciones",
  "rate.private1": "0 - 74 caracteres",
  "rate.private2": "Guardado para uso personal",
  "rate.private3": "No contribuye a la puntuación general",
  "rate.privateLabel": "PRIVADA",
  "rate.public1": "Más de 75 caracteres",
  "rate.public2": "Contribuye a la puntuación general de cervezas",
  "rate.public3": "Visible para la comunidad",
  "rate.publicLabel": "PÚBLICA",
  "rate.save": "Guardar",
  "rate.searchResults": "Resultados",
  "rate.servedIn": "Servido en",
  "rate.totalScore": "PUNTUACIÓN TOTAL",
  "search.advanced": "Búsqueda avanzada",
  "search.beerCaps": "CERVEZAS",
  "search.beerHeader": "Cervezas",
  "search.beerSearchFor": "Escribe en la barra de búsqueda para encontrar cervezas",
  "search.brewersCaps": "FABRICANTES DE CERVEZA",
  "search.brewersHeader": "Fabricantes de cerveza",
  "search.brewersSearchFor": "Escribe en la barra de búsqueda para encontrar fabricantes",
  "search.closestMatch": "Coincidencia más cercana",
  "search.for": "Buscar",
  "search.in": "en",
  "search.mostRatings": "Mayoría de calificaciones",
  "search.myRating": "Mi calificación:",
  "search.noResults": "No hay resultados para ",
  "search.pCaps": "LUGARES",
  "search.pHeader": "Lugares",
  "search.pSearchFor": "Escribe en la barra de búsqueda para encontrar lugares",
  "search.resultFor": "resultado para",
  "search.resultLabelStr": "Calificaciones",
  "search.resultsFor": "resultados para",
  "search.sortBy": "Ordenar por",
  "search.sortByCaps": "ORDENAR POR",
  "search.topRated": "Mejores calificadas",
  "search.userHeader": "Usuarios",
  "search.userSearchFor": "Escribe en la barra de búsqueda para encontrar usuarios"
}
