import React from 'react'

import MIconButton, {
  IconButtonProps as MIconButtonProps
} from '@material-ui/core/IconButton'

export type IconButtonProps<P> = FlexibleRootComponent<MIconButtonProps, P>

export const IconButton = <P extends any>(props: IconButtonProps<P>) => {
  return <MIconButton {...props as any} />
}
