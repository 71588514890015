export const retry = <T extends any>(
  attempts: number,
  promiseFactory: () => Promise<T>,
  waitPromise: (retries: number) => Promise<void> = () => wait(3000)
): Promise<T> => {
  let tries = 0

  const run = (): Promise<T> => {
    if (tries < attempts) {
      tries += 1
      return promiseFactory().catch(() => waitPromise(tries).then(run))
    }

    throw Error(`Failed after ${attempts} attempts`)
  }

  return run()
}

export const wait = (ms: number): Promise<void> =>
  new Promise(res => setTimeout(res, ms))
