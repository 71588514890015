import React from 'react'
import { theme } from 'theme'

import { colorized } from 'lib/components/tools'
import { ICON_SIZES } from 'styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Size = 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | number

export const getIconSize = (size?: Size): number => {
  if (typeof size === 'number') {
    return size
  }

  switch (size) {
    case 'x-small': {
      return ICON_SIZES.XS
    }
    case 'small': {
      return ICON_SIZES.SM
    }
    case 'medium': {
      return ICON_SIZES.MD
    }
    case 'large': {
      return ICON_SIZES.LG
    }
    case 'x-large': {
      return ICON_SIZES.XL
    }
  }

  return ICON_SIZES.MD
}

export type IconProps = Stylable &
  React.HTMLAttributes<HTMLElement> & {
    size?: number | 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
  }

type IconFromFontAwesomeInput = {
  icon: IconProp
  defaultColor?: string
}

export const iconFromFontAwesome = ({
  defaultColor,
  icon
}: IconFromFontAwesomeInput) => {
  const Icon: React.FunctionComponent<IconProps> = ({
    size = 'medium',
    className,
    style
  }) => {
    const iconSize = typeof size === 'number' ? size : getIconSize(size)

    return (
      <FontAwesomeIcon
        style={{ height: iconSize, width: iconSize, ...style }}
        className={className}
        icon={icon}
      />
    )
  }

  return colorized({ defaultColor })(Icon)
}

type IconFromMaterialUIInput = {
  MaterialIcon: React.ReactType
  defaultColor?: string
}

export const iconFromMaterialUI = ({
  MaterialIcon,
  defaultColor = theme.colors.textSecondary
}: IconFromMaterialUIInput) => {
  const MUIIcon: React.FunctionComponent<IconProps> = ({
    size = 'medium',
    className,
    style,
    color,
    ...props
  }) => {
    const iconSize = typeof size === 'number' ? size : getIconSize(size)
    return (
      <MaterialIcon
        className={className}
        style={{ height: iconSize, width: iconSize, ...style }}
        {...props}
      />
    )
  }

  return colorized({ defaultColor })(MUIIcon)
}

type IconFromSvgInput = {
  SvgIcon: React.ReactType
  defaultColor?: string
}

export const iconFromSvg = ({
  SvgIcon,
  defaultColor = theme.colors.textSecondary
}: IconFromSvgInput) => {
  const SVGIcon: React.FunctionComponent<IconProps> = ({
    size = 'medium',
    className,
    style
  }) => {
    const iconSize = typeof size === 'number' ? size : getIconSize(size)

    return (
      <SvgIcon
        className={className}
        style={{ height: iconSize, width: iconSize, ...style }}
      />
    )
  }

  return colorized({ defaultColor })(SVGIcon)
}

type IconFromDataUrlInput = {
  dataUrl: string
}

// Sadly images cant have the color edited unlike svgs... don't use this too much, prefer to replace the image icons with svgs.
export const iconFromDataUrl = ({ dataUrl }: IconFromDataUrlInput) => {
  type Props = Omit<
    IconProps &
      React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
      >,
    'color'
  >

  const DataUrlIcon: React.FunctionComponent<Props> = ({
    size = 'medium',
    className,
    style,
    ...rest
  }) => {
    const iconSize = typeof size === 'number' ? size : getIconSize(size)
    return (
      <img
        className={className}
        style={{
          height: iconSize,
          width: iconSize,
          objectFit: 'contain',
          objectPosition: 'center',
          ...style
        }}
        src={dataUrl}
        {...rest}
      />
    )
  }

  return DataUrlIcon
}
