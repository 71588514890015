import React, { useEffect } from 'react'
import { createStore } from 'lib/state-container'
import { throttle } from 'lib/utils'
import { mapObjIndexed } from 'ramda'
type RangeConfig = { start: number; end?: number }
type BreakPointsConfig = Record<string, RangeConfig>
const defaultBreakPoints = {
  mobile: { start: 0, end: 1000 },
  desktop: { start: 1001 }
}

type Match<BreakPointsConfig> = {
  [K in keyof BreakPointsConfig]: boolean | undefined
}
export type WindowSize = { height: number; width: number }
const { Provider: StoreProvider, useStore } = createStore<WindowSize>()
const THROTTLE_MS = 250
const getCurrentwindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight
})
const initialState = getCurrentwindowSize()
export const useWindowSize = (): WindowSize => {
  const [state] = useStore(state => state)
  return state
}
export const useMediaQuery = <T extends BreakPointsConfig>(
  config: T
): Match<T> => {
  const [{ width }] = useStore(state => state)
  const ret = mapObjIndexed(
    ({ start, end = Infinity }) => width >= start && width <= end,
    config
  ) as Match<T>
  return ret
}
export const useDefaultMediaQuery = () => useMediaQuery(defaultBreakPoints)

export const GlobalWindowSize: React.FunctionComponent = () => {
  const [setSize] = useStore()
  const handle = throttle(() => {
    setSize(() => ({
      width: window.innerWidth,
      height: window.innerHeight
    }))
  }, THROTTLE_MS)

  useEffect(() => {
    window.addEventListener('resize', handle, true)
    return () => {
      window.removeEventListener('resize', handle, true)
    }
  }, [])
  return null
}
export { StoreProvider as WindowSizeStoreProvider }

export const WindowSizeProvider: React.FunctionComponent = ({ children }) => {
  return (
    <StoreProvider initialState={initialState}>
      {children}
      <GlobalWindowSize />
    </StoreProvider>
  )
}
