import React from 'react'
import styled from 'styled-components'
import { theme } from 'theme'

type ColorName = keyof typeof theme['colors'] | 'inherit' | 'default'

const getColor = (colorName: Exclude<ColorName, 'default'>): string => {
  if (colorName === 'inherit') {
    return 'inherit'
  }

  return theme.colors[colorName]
}

export type ColorizedProps<P> = P extends { color?: any }
  ? Omit<P, 'color'> & { color?: ColorName }
  : P & { color?: ColorName }

type ColorizedOptions = {
  defaultColor?: string
  defaultColorProp?: ColorName
}

export const colorized = ({
  defaultColor,
  defaultColorProp = 'default'
}: ColorizedOptions = {}) => <P extends any>(Comp: React.ComponentType<P>) => {
  type NewProps = Omit<P, 'color'> & { color?: ColorName }

  const WrappedComponent = styled(Comp)`
    color: ${({ _color }: any) => _color};
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  ` as any

  return ({ color = defaultColorProp, ...rest }: NewProps) => {
    return (
      <WrappedComponent
        _color={
          color === 'default'
            ? defaultColor
            : color
            ? getColor(color)
            : undefined
        }
        color={color}
        {...rest}
      />
    )
  }
}
