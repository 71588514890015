export default {
  "Account Settings": "Accountinstellingen",
  "Add description": "Voeg beschrijving toe",
  "Add location": "Voeg locatie toe",
  "Appearance": "Uiterlijk",
  "Apply to sell with us": "Aanmelden om via ons te verkopen",
  "Are you sure you want to delete your review?": "Weet je zeker dat je je recensie wilt verwijderen?",
  "Aroma": "Aroma",
  "Associated Brewer": "Bijbehorende brouwer",
  "Available here": "Hier beschikbaar",
  "Available to buy from {count} trusted retail partners": "Te koop via {count} vertrouwde winkelpartners",
  "Average": "Gemiddeld",
  "Beer name": "Naam bier",
  "Beer styles": "Bierstijlen",
  "Beers Spotted Here": "Bieren die hier gespot zijn",
  "Bottles {bottlesCount}": "Flessen {bottlesCount}",
  "Buy from": "Koop bij",
  "Cancel": "Annuleren",
  "Community": "Community",
  "Copied to clipboard": "Gekopieerd naar klembord",
  "Copy to clipboard": "Kopiëren naar klembord",
  "Country of origin": "Land van herkomst",
  "Events": "Evenementen",
  "Filter": "Filteren",
  "Find a beer or brewer": "Zoek een bier of een brouwer",
  "Find a country": "Zoek een land",
  "Find a style": "Zoek een stijl",
  "Follow on Social Media": "Volgen op sociale media",
  "Forums": "Forums",
  "From {price} per {serve}": "Vanaf {price} per {serve}",
  "Latest Activity": "Laatste activiteit",
  "Log In": "Inloggen",
  "Log Out": "Uitloggen",
  "Manage Breweries Followed": "Gevolgde brouwerijen beheren",
  "Manage People Followed": "Gevolgde mensen beheren",
  "Mouthfeel": "Mondgevoel",
  "My Beer Cellar": "Mijn bierkelder",
  "My Beer Ratings": "Mijn beoordelingen van bieren",
  "My Beer Styles": "Mijn bierstijlen",
  "My Breweries": "Mijn brouwerijen",
  "My Countries & States": "Mijn landen en staten",
  "My Favorites": "Mijn favorieten",
  "My Place Ratings": "Mijn beoordelingen van locaties",
  "My Profile": "Mijn profiel",
  "New Releases": "Nieuwe uitgaven",
  "No results found for": "Geen resultaten gevonden voor {query}",
  "Overall": "Totaal",
  "Places": "Locaties",
  "Rate this beer on these attributes": "Beoordeel dit bier op deze eigenschappen",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "RateBeer werkt samen met de grootste winkels om bierliefhebbers een onovertroffen bierassortiment tegen de beste prijzen te bieden",
  "Ratings": "Beoordelingen",
  "Read more": "meer informatie",
  "Retailers": "winkels",
  "Review this place": "Recenseer deze locatie",
  "Save": "Opslaan",
  "Search beers spotted here": "Zoek bieren die hier gespot zijn",
  "Search for Beers, Breweries, or Bars...": "Zoek een bier, een brouwer of een bar…",
  "Seasonal beer": "Seizoensgebonden bier",
  "Shop now": "Nu winkelen",
  "Sign Up": "Aanmelden",
  "Similar beers": "Vergelijkbare bieren",
  "Taps {tapsCount}": "Op tap {tapsCount}",
  "Taste": "Smaak",
  "The most comprehensive ratings and reviews of beers from around the world": "De meeste beoordelingen en recensies van bieren uit de hele wereld",
  "This score could not be updated": "Deze score kan niet worden bijgewerkt",
  "This score has been updated": "Deze score is bijgewerkt",
  "Total score": "TOTALE SCORE",
  "View beer menu": "Biermenu bekijken",
  "View food menu": "Menukaart bekijken",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Bekijk aanbiedingen van {count} {count, plural, one {retailer} other {retailers}}",
  "We apologise for the delay you are experiencing.": "Onze excuses voor de vertraging die je ondervindt.",
  "We are currently updating the website and expect this page to be back shortly.": "We zijn de website momenteel aan het updaten en verwachten dat deze pagina snel weer beschikbaar komt.",
  "Your review has been updated": "Je recensie is bijgewerkt",
  "add.abv": "Alcoholpercentage",
  "add.abvPlaceholder": "(bijv. 11%)",
  "add.addBeer": "Nieuw bier toevoegen",
  "add.addBrewery": "Nieuwe brouwerij toevoegen",
  "add.autumn": "Herfst",
  "add.beerGuidelines": "Richtlijnen",
  "add.beerGuidelines1": "Bier moet commercieel verkrijgbaar en in productie zijn.",
  "add.beerGuidelines2": "Receptuur moet uniek zijn. Geen vat-, nitro- en andere varianten van een receptuur.",
  "add.beerGuidelines3": "Cider met fruit moet 50% of meer appelfermenteerbaar zijn en een alcoholpercentage van meer dan 2% hebben.",
  "add.beerGuidelines4": "Plaats de naam van de fabrikant voor de naam van het bier, bijv. {correctExample} niet {incorrectExample}.",
  "add.beerImage": "Afbeelding toevoegen",
  "add.beerName": "Naam bier",
  "add.beerNameExplanation": "De naamgevingsconventie van RateBeer is om Naam brouwerij + Naam bier op te nemen.",
  "add.beerNameInfo": "(inclusief brouwer)",
  "add.beerNamePlaceholder": "(bijv. Elysian Space Dust)",
  "add.breweryName": "Naam brouwerij",
  "add.breweryNamePlaceholder": "(bijv. Elysian)",
  "add.contractBrewer": "Gebrouwen door",
  "add.contractBrewerPlaceholder": "Zoek brouwerijen…",
  "add.contractBrewerPrompt": "Dit is een contractbier",
  "add.description": "Commerciële omschrijving",
  "add.descriptionPlaceholder": "Wat er op het productetiket staat",
  "add.duplicateFound": "Duplicate Found",
  "add.duplicateMessage": "Is dit het bier dat je wilt toevoegen?",
  "add.ibuPlaceholder": "(bijv. 57)",
  "add.imageInstructions": "*Gebruik afbeeldingen van blikjes of flessen",
  "add.invalid": "Ongeldig",
  "add.newBeer": "Een nieuw bier toevoegen",
  "add.noBrewery": "Kun je jouw brouwerij niet vinden?",
  "add.optional": "Optioneel",
  "add.removeBeerImage": "Afbeelding verwijderen",
  "add.seasonalPlaceholder": "Is dit een seizoensgebonden bier?",
  "add.series": "Serie",
  "add.signInToAddBeer": "Log in om een bier toe te voegen",
  "add.special": "Speciale uitgave",
  "add.spring": "Lente",
  "add.stylePlaceholder": "Kies stijl",
  "add.summer": "Zomer",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(cijfers streepjescode)",
  "add.upcInstructions": "Vermeld alle 8, 12, 13 of 14 cijfers",
  "add.upcPlaceholder": "Alle cijfers van de streepjescode",
  "add.view": "Bekijken",
  "add.winter": "Winter",
  "all.adblock": "Het lijkt erop dat er een adblocker is geïnstalleerd. Overweeg RateBeer.com toe te voegen als uitzondering. Proost!",
  "all.ago": "{date} geleden",
  "all.back": "Back",
  "all.beer": "Bier",
  "all.beers": "Bieren",
  "all.cider": "Cider",
  "all.ciders": "Ciders",
  "all.cookies": "We gebruiken cookies enzo",
  "all.error": "Er is een netwerkfout opgetreden",
  "all.joinBody": "Profiteer van alles wat RateBeer te bieden heeft. Lees volledige recensies, bekijk gedetailleerde statistieken en zie wat onze community te zeggen heeft.",
  "all.joinTitle": "Word lid van onze community!",
  "all.learnMore": "Meer informatie",
  "all.loading": "Bezig met laden",
  "all.mead": "Mede",
  "all.meads": "Medes",
  "all.noSuchBeer": "Geen dergelijk bier in de database. Probeer een ander bier te zoeken.",
  "all.ok": "OK",
  "all.sake": "Sake",
  "all.sakes": "Sakes",
  "banner.free": "Gratis – Google Play",
  "banner.getIt": "Downloaden",
  "banner.officialApp": "Officiële app",
  "beer.aboutBeer": "Over dit Bier",
  "beer.aboutCider": "Over deze Cider",
  "beer.aboutMead": "Over deze Mede",
  "beer.aboutSake": "Over deze Sake",
  "beer.abv": "Alcoholpercentage",
  "beer.aka": "Ook bekend als",
  "beer.aliasBeer": "Aliasbier",
  "beer.aliasCopy": "De brouwer verkoopt hetzelfde of bijna hetzelfde product onder verschillende namen. Dat kan zijn omdat de brouwer dit bier in verschillende landen onder verschillende namen op de markt brengt of omdat de brouwer op een bepaald moment de naam maar niet de receptuur heeft veranderd.",
  "beer.allStyles": "Alle bierstijlen voor ",
  "beer.appearance": "Uiterlijk",
  "beer.applyToSellWithUs": "Meld je aan om via ons te verkopen",
  "beer.aroma": "Aroma",
  "beer.autumn": "Herfst",
  "beer.availability": "Beschikbaarheid",
  "beer.availableAt": "Beschikbaar op",
  "beer.averageRatings": "Gemiddelde beoordelingen",
  "beer.bottled": "Gebotteld",
  "beer.brewedBy": "Gebrouwen door ",
  "beer.brewedFor": "Gebrouwen voor ",
  "beer.buyFrom": "Koop bij",
  "beer.calories": "GESCH. CAL.",
  "beer.clearRating": "Wissen",
  "beer.copy": "Kopiëren",
  "beer.delete": "Bier/beoordelingen verwijderen",
  "beer.deleteAlias": "Alias verwijderen",
  "beer.deleteConfirm": "Ja, verwijderen",
  "beer.deleteOption": "Verwijderen",
  "beer.deleteRating": "Beoordeling verwijderen?",
  "beer.distribution": "Distributie",
  "beer.edit": "Bewerken",
  "beer.editAlias": "Alias toevoegen/bewerken",
  "beer.editBarcodes": "Streepjescodes toevoegen/bewerken",
  "beer.editBeer": "Dit bier bewerken",
  "beer.editPicture": "Afbeelding toevoegen/bewerken",
  "beer.editTags": "Toevoegen/Bewerken Tags",
  "beer.findFriendsText": "Zoek vrienden om te delen wat je drinkt en te zien wat zij hebben geprobeerd",
  "beer.findFriendsTitle": "Vrienden zoeken",
  "beer.findIt": "Vind",
  "beer.findItNearYou": "Vind dit bij jou in de buurt",
  "beer.fix": "Score bepalen",
  "beer.flavor": "Smaak",
  "beer.from": "van ",
  "beer.highestScore": "Hoogste score",
  "beer.howItWorks": "Zo werkt het",
  "beer.howItWorksContent": "We werken er hard aan om de beste winkels met het grootste aanbod en de beste prijzen voor je te vinden, zodat je het bier kunt kopen waar je van houdt",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Zoek verkopers",
  "beer.locations": "{count} {count, plural, one {locatie} other {locaties}}",
  "beer.locationsNearby": "{count} {count, plural, one {locatie in de buurt} other {locaties in de buurt}}",
  "beer.mostLiked": "Meest geliked",
  "beer.mostRecent": "Meest recente",
  "beer.mouthfeel": "Mondgevoel",
  "beer.moveRating": "Beoordeling verplaatsen",
  "beer.myFriends": "Mijn vrienden",
  "beer.myReview": "Mijn recensie",
  "beer.noDescription": "Geen beschrijving beschikbaar",
  "beer.noRating": "Geen beoordeling",
  "beer.noReviewsText": "Laat de wereld weten wat je ervan vindt. Beoordeel en recenseer het als eerste!",
  "beer.noReviewsTitle": "Heb je dit bier geprobeerd?",
  "beer.onTap": "Van de tap",
  "beer.outOfProd": "UIT PRODUCTIE",
  "beer.overall": "Totaal",
  "beer.per": "per",
  "beer.privacyNotice": "Door op de bovenstaande winkellink (s) te klikken, wordt u doorgestuurd naar de website van de verkoper en van daaruit is hun privacybeleid van toepassing.",
  "beer.private": "PRIVÉ",
  "beer.quickRate": "Snel beoordelen",
  "beer.quickRateSaved": "Snelle beoordeling opgeslagen",
  "beer.quickRated": "Snel beoordeeld: ",
  "beer.rateAndReview": "Beoordelen en recenseren",
  "beer.rateIt": "Heb je het geprobeerd? Beoordeel het dan!",
  "beer.ratedBy": "Beoordeeld door",
  "beer.ratingPrivate": "Privébeoordeling",
  "beer.ratingPublic": "Openbare beoordeling",
  "beer.readMore": "meer informatie",
  "beer.reviews": "Recensies",
  "beer.reviewsRatings": "Recensies en beoordelingen",
  "beer.saveRating": "Bewaar die beoordeling!",
  "beer.saveRatingText": "Maak een account aan om beoordelingen te bewaren, expertrecensies te lezen, gedetailleerde statistieken van beoordelingen en recensies te bekijken, en nog veel meer.",
  "beer.seasonal": "Seizoensgebonden",
  "beer.seasonalLabel": "SEIZOENSGEBONDEN",
  "beer.seeAll": "Alles bekijken",
  "beer.seeAllXReviews": "Alle {count} recensies bekijken",
  "beer.sendCorrections": "Correcties verzenden",
  "beer.series": "Serie",
  "beer.serveIn": "Serveren in",
  "beer.share": "Delen",
  "beer.shareTutorial": "Delen, correcties verzenden, afbeeldingen toevoegen",
  "beer.shelfTags": "Schapetiket bekijken",
  "beer.shopNow": "Nu kopen",
  "beer.showLess": "minder weergeven",
  "beer.signInToFavorite": "Log in om dit bier op te slaan als favoriet",
  "beer.signInToFind": "Log in om dit bier te zoeken.",
  "beer.signInToLikeReview": "Log in om deze recensie leuk te vinden",
  "beer.similarBeer": "Soortgelijke Bieren",
  "beer.similarCider": "Soortgelijke Ciders",
  "beer.similarMead": "Soortgelijke Mede",
  "beer.similarSake": "Soortgelijke Sakes",
  "beer.special": "Speciaal",
  "beer.spring": "Lente",
  "beer.style": "Stijl ",
  "beer.summer": "Zomer",
  "beer.tags": "Tags",
  "beer.top50": "Top 50",
  "beer.topRaters": "Top beoordelaars",
  "beer.totalRetailers": "Dit bier wordt verkocht door {count} geverifieerde externe verkopers",
  "beer.undoRating": "Beoordeling ongedaan maken",
  "beer.unrateable": "Dit bier kan niet worden beoordeeld",
  "beer.verified": "GEVERIFIEERD",
  "beer.winter": "Winter",
  "beer.writeAReview": "Een recensie schrijven",
  "beer.writeFullReview": "Volledige recensie schrijven",
  "beer.yourReview": "Je recensie",
  "error.refresh": "Verversen",
  "favorites.instructions": "Als je een bier hebt dat je lekker vindt, tik je op de knop Opslaan om het te bewaren voor later. Bekijk je opgeslagen bier op een enkele plek op al je apparaten.",
  "favorites.noSavedBeers": "Je hebt nog geen bier opgeslagen",
  "favorites.remove": "Verwijderen",
  "favorites.removeConfirmation": "Ja, verwijderen",
  "favorites.removePrompt": "Verwijder uit favorieten?",
  "favorites.saved": "Opgeslagen",
  "favorites.savedConfirmation": "Opgeslagen in favorieten",
  "favorites.signInToViewFavorites": "Log in om dit bier op te slaan als favoriet",
  "favorites.viewAll": "Alles bekijken",
  "feedback.android": "Mobiele Android-app",
  "feedback.androidReproduceInfo": "(bijv. inloggen, zoeken door streepjescode te scannen, op bierresultaat tikken, paneel expertbeoordeling uitklappen, expertbeoordeling verzenden)",
  "feedback.bug": "Een bug melden",
  "feedback.bugMessageInfo": "(Welke resultaten verwachtte je? Welke resultaten kreeg je?)",
  "feedback.bugMessagePrompt": "Samenvatting van bug",
  "feedback.buildInfo": "(bijv. app-versie 1.7.0. Je vindt dit nummer door op het ? rechtsboven in het startscherm te tikken)",
  "feedback.buildPrompt": "App-versie",
  "feedback.categoryPrompt": "Wat wil je doen?",
  "feedback.correction": "Een correcties versturen",
  "feedback.correctionMessageInfo": "(Hoe had je verwacht dat de informatie zou verschijnen? Wordt er nu onjuiste informatie weergegeven?)",
  "feedback.correctionMessagePrompt": "Samenvatting van correctie",
  "feedback.email": "E-mailadres",
  "feedback.header": "Feedbackformulier",
  "feedback.iOS": "Mobiele iOS-app",
  "feedback.iOSReproduceInfo": "(bijv. inloggen, zoeken door streepjescode te scannen, op bierresultaat tikken, paneel expertbeoordeling uitklappen, expertbeoordeling verzenden)",
  "feedback.mediumPrompt": "Waar heb je dit probleem ervaren?",
  "feedback.reproducePrompt": "Welke stappen kunnen we volgen om dit probleem aan onze kant te reproduceren?",
  "feedback.submit": "Versturen",
  "feedback.submitted": "Bedankt voor het versturen van je feedback!",
  "feedback.suggestion": "Een suggestie doen",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Wat zou je graag willen zien van RateBeer?",
  "feedback.url": "URL",
  "feedback.username": "Gebruikersnaam",
  "feedback.web": "Website",
  "feedback.webReproduceInfo": "(bijv. inloggen, naar ratebeer.com/community gaan, op link in zijbalk klikken)",
  "footer.FAQs": "Veelgestelde vragen",
  "footer.RBBest": "Beste van RateBeer",
  "footer.TOS": "Servicevoorwaarden",
  "footer.aboutRateBeer": "Over RateBeer",
  "footer.aboutUs": "Over ons",
  "footer.account": "Account",
  "footer.addBeer": "Een bier toevoegen",
  "footer.addBrewer": "Een brouwer toevoegen",
  "footer.addEvent": "Een evenement toevoegen",
  "footer.addPlace": "Een locatie toevoegen",
  "footer.addPremium": "Premium toevoegen",
  "footer.addToRB": "Toevoegen Aan RateBeer",
  "footer.contactUs": "Neem contact met ons op",
  "footer.copyright": "Alle rechten voorbehouden.",
  "footer.developers": "Ontwikkelaars",
  "footer.editAccount": "Account bewerken",
  "footer.privacy": "Privacybeleid",
  "footer.theBest": "Het beste",
  "footer.top50Beers": "De beste 50 bieren",
  "footer.topReviewers": "Toprecensenten",
  "glasscopy1": "Lang, dun en boven de voet een dunne stam. Fluitjes zijn delicaat en laten de body van het bier zien. Deze glazen zijn ideaal voor lichte, sprankelende bieren.",
  "glasscopy10": "Een ietwat obscuur glas, dit glas met korte voet wordt door de Belgen wel eens gebruikt voor Scotch ales. De distel vorm is onderin knolvormig en bol, met daarboven wijd uitlopende, rechte zijdes die naar boven steeds breder uitlopen. De wijde mond kan net als bij een Martini glas voor wat problemen zorgen -- druppelen of knoeien – en houdt weinig aroma vast.",
  "glasscopy11": "Een langgerekt, 90 centimeter lang, krom lopend glas, vaak knol- of bolvormig aan de bovenkant en kogelvormig aan de onderkant. Het glas heeft een houten standaard nodig om rechtop te blijven. Het best bekende voorbeeld is het Belgische Pauwel Kwak glas.",
  "glasscopy12": "Dit klassiek Duitse glas, krommend vanaf een wat groter, bolvormige bovenkant naar een smaller gebied met daaronder weer een iets bredere basis. Deze vorm moedigt een wat grotere schuimkraag aan en zorgt ervoor dat er wat meer licht door het wolkachtige bier kan passeren, wat een gloeiend effect bewerkstelligt. Voert de frisheid wat sneller af. Doordrinken dus!",
  "glasscopy13": "Normaal een wat strakkere, meer gestyleerde versie van de minder elegante, meer utilitaristische shaker. Hoegaarden is het meest voorkomende voorbeeld.",
  "glasscopy14": "Een wat in elkaar gedoken, cylinder vormig met platte bodem, met recht of iets taps toelopende zijkanten, wat vooral zorgt voor een brede ruimte om een omvangrijke, ruwe schuimkraag te behouden. Exemplaren op een voetje hebben soms een sferische bodem and lijken qua vorm een beetje op een kelk.",
  "glasscopy15": "Ook wel bekend als de kelk. Dit prachtige glazen voertuig met voet is gevormd als een kom met vaak een rechte of iets taps toelopende rand net boven de kom. Deze vorm geeft de ruwe schuimkragen van de Belgische stijl bieren mooi weer en zorgt voor een grote oppervlakte, waardoor aroma's goed kunnen worden afgegeven.",
  "glasscopy16": "Een cylindrisch, toelopende vorm glas, bijvoorbeeld bekend als merkglas van Celebrator en Harvey Porter.",
  "glasscopy17": "Met stam en voet, sferisch en potvormig, dat bovenaan iets smaller wordt. Volgens vele de beste vorm voor ongeëvenaard behoud van aroma. Deze worden ook vaak gebruik in miniatuurvorm voor het schenken van kleinere hoeveelheden.",
  "glasscopy18": "Een klein kommetje, veel voorkomend in diverse vormen, kleuren en soorten. Vaak lopen deze aan de bovenkant iets uit, zodat de geur van de saké zich iets makkelijker losmaakt uit het glas.",
  "glasscopy19": "Een klein kopje met vaak een geribbelde rand en iets groter dan de ochoko.",
  "glasscopy2": "Korte glazen met een inhoud van ongeveer 350 milliliter. Bovenin iets breder dan onderin en loopt gelijkmatig uit. Geweldig basisglas, zeer geschikt voor pale lagers.",
  "glasscopy20": "Een vierkante, cederhouten kistje met een inhoud van ongeveer 180 ml, van origine bedoeld als meetinstrument voor rijst. Niet langer populair, want de houten aroma's van de masu overstemmen de delicate geur en smaak van moderne, premium sake.",
  "glasscopy21": "Meestal gebruikt om malt liquor en andere pauperbieren te verbergen. Papieren zakken worden heel af en toe gebruikt om bieren te marketen of te verpakken.",
  "glasscopy3": "Ontworpen om in een paar teugen leeg te drinken. Deze glazen helpen bij het ontstaan van golvend schuim. Minder geschikt voor het waarnemen van subtiele geuren en andere details.",
  "glasscopy4": "De standaard van de Amerikaanse craft brouwer. Een voorzichtig taps toelopend glas met een inhoud van ongeveer 480 ml, geschikt voor 'session' achtige bieren. Wordt meestal gebruikt voor Amerikaanse en Engelse Pale Ales.",
  "glasscopy5": "Lijkt een beetje op de shaker, maar dan zonder het tapse verloop, met meestal nog een kleine bolling bovenin het glas. Vaak staat er een markering op die een 'imperial pint' aangeeft.",
  "glasscopy6": "Een klassieker in Noord-Amerika, de gedeukte mok is groot en heeft een handvat. Het is convex, waarbij de bovenkant groter is dan de onderkant. De deuken in het glas maken het waarderen van het uiterlijk wat lastiger, maar dankzij de brede mond komen de aroma's van het bier goed naar voren. Meestal gebruikt voor lompe lagerbieren.",
  "glasscopy7": "Bavarische steins zijn de meest beeldrijke vorm van bierhouders. Meestal van aardewerk, ceramisch of steen, en zijn kunstig versierd met afbeeldingen van natuurlandschappen, kastelen en dorpen. De Stein doet weinig voor het uiterlijk van het bier, maar zijn vaak wel een prachtige vorm van volkskunst. Aromatisch gezien doet dit type bierhouder weinig voor het bier, maar de smaak is ongeëvenaard. Gebruik deze voor elke traditionele lager.",
  "glasscopy8": "Een smal en lang glas met bijna rechte zijdes bovenop een voetje van een paar centimeter. Dit glas heeft in de basis een ietwat knolvormige onderkant en een wat smallere mond, waardoor het meer een 'drinkglas' dan een 'ruikglas' is. Het glas benadrukt ook vooral het uiterlijk van het bier. Het heeft iets meer stijl dan sommige andere glazen en is daarmee vooral geschikt voor de wat betere pilseners, cream ales en golden ales.",
  "glasscopy9": "Vaak een favoriet bij de doorgewinterde proever. Het Duvelglas is één van de bekenste voorbeelden, en ook het glas in het Ratebeer logo is hiervan een perfect voorbeeld.",
  "header.my2017": "Mijn jaar in bier",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Topbieren naar stijl",
  "home.Top Rated Beers Nearby": "Best beoordeelde bieren in de buurt",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Download de app",
  "home.downloadBlurb": "Neem RateBeer overal mee naartoe! We bieden geweldige functies om nieuwe bieren te ontdekken die je lekker zult vinden.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Bericht verzonden!",
  "home.porter": "Porter",
  "home.retailers": "winkels",
  "home.saison": "Saison",
  "home.searchPrompt": "Vind bieren, brouwerijen of bars…",
  "home.shopOnline": "Koop online",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Tekstlink",
  "home.top50Beers": "Beste 50 bieren",
  "home.worldOfBeer": "Je gids voor de bierwereld",
  "landing.TOS": "Servicevoorwaarden",
  "landing.feedback": "Dit is onze eerste poging voor een RateBeer-app.{br}Help ons om de app nog beter te maken!",
  "landing.h1": "De beste bierbeoordelingswebsite{br}van de wereld is nu een app!",
  "landing.h2": "Nu kun je bier beoordelen{br}vanuit de palm van je hand",
  "landing.label": "beoordelingen",
  "landing.li1": "Doorzoek onze database met 500.000+ bieren",
  "landing.li2": "Beoordeel, recenseer en deel",
  "landing.li3": "Houd elk bier bij dat je ooit geproefd hebt",
  "landing.privacy": "PRIVACYBELEID",
  "landing.review": "RateBeer is de betrouwbaarste bron van informatie over bier en brouwers op internet.",
  "landing.rightsReserved": "Alle rechten voorbehouden.",
  "landing.submit": "Stuur feedback",
  "landing.visit": "Bezoek ratebeer.com",
  "place.addBeerIds": "Bier-ID's toevoegen",
  "place.addBeers": "Bieren toevoegen",
  "place.addEvent": "Evenement toevoegen",
  "place.ambiance": "Ambiance",
  "place.available": "Beschikbaar",
  "place.beerMenu": "Biermenu",
  "place.close": "Sluiten",
  "place.customizeShelftags": "Schapetiketten aanpassen",
  "place.editBeers": "Bieren bewerken",
  "place.editPlace": "Locatie bewerken",
  "place.flights": "Vluchten",
  "place.food": "Eten",
  "place.foodMenu": "Menukaart",
  "place.getDirection": "Ontvang routebeschrijving",
  "place.hours": "Uren",
  "place.lessInfo": "Minder info",
  "place.map": "Kaart",
  "place.moreInfo": "Meer informatie",
  "place.overall": "Totaal",
  "place.phone": "Telefoonnummer",
  "place.printShelftags": "Schapetiketten afdrukken",
  "place.selection": "Selectie",
  "place.sendToPhone": "Naar telefoon verzenden",
  "place.service": "Service",
  "place.shelftags": "Schapetiketten",
  "place.showBreakdown": "Verdeling score bekijken",
  "place.value": "Waarde",
  "place.wifi": "Gratis WiFi",
  "rate.BOTTLE": "Flesje",
  "rate.CAN": "Blikje",
  "rate.CASK": "Vat",
  "rate.TAP": "Tap",
  "rate.commentPlaceholder": "Voeg smaaknotities, omschrijvingen enzovoort toe...",
  "rate.infoSubtitle": "Om de kwaliteit van onze bierscores te garanderen, nemen we alleen gedetailleerde recensies op",
  "rate.locationPlaceholder": "Voeg locatie toe",
  "rate.nearbyPlaces": "Locaties in de buurt",
  "rate.ourRatingSystem": "Ons beoordelingssysteem",
  "rate.private1": "0 - 74 tekens",
  "rate.private2": "Opgeslagen voor persoonlijk gebruik",
  "rate.private3": "Telt niet mee voor totale core",
  "rate.privateLabel": "PRIVÉ",
  "rate.public1": "75+ tekens ",
  "rate.public2": "Telt mee voor de totale bierscore",
  "rate.public3": "Zichtbaar voor de community",
  "rate.publicLabel": "OPENBAAR",
  "rate.save": "Opslaan",
  "rate.searchResults": "Resultaten",
  "rate.servedIn": "Geserveerd in",
  "rate.totalScore": "TOTALE SCORE",
  "search.advanced": "Geavanceerd zoeken",
  "search.beerCaps": "BIEREN",
  "search.beerHeader": "Bieren",
  "search.beerSearchFor": "Typ in de zoekbalk om een bier te vinden",
  "search.brewersCaps": "BROUWERS",
  "search.brewersHeader": "Brouwers",
  "search.brewersSearchFor": "Typ in de zoekbalk om een brouwer te vinden",
  "search.closestMatch": "Beste match",
  "search.for": "Zoek naar",
  "search.in": "in",
  "search.mostRatings": "Meeste beoordelingen",
  "search.myRating": "Mijn beoordeling:",
  "search.noResults": "Geen resultaten voor ",
  "search.pCaps": "LOCATIES",
  "search.pHeader": "Locaties",
  "search.pSearchFor": "Typ in de zoekbalk om een locatie te vinden",
  "search.resultFor": "resultaat voor",
  "search.resultLabelStr": "Beoordelingen",
  "search.resultsFor": "resultaten voor",
  "search.sortBy": "Sorteren op",
  "search.sortByCaps": "SORTEREN OP",
  "search.topRated": "Best beoordeeld",
  "search.userHeader": "Gebruikers",
  "search.userSearchFor": "Typ in de zoekbalk om een gebruiker te vinden"
}
