import React, { Suspense, useState } from 'react'
import { retry, wait } from 'lib/utils'

type LoadableInput<P> = {
  loader: () => Promise<{ default: React.ComponentType<P> }>
  Loader?: React.ReactType
  delay?: number
}

export const loadable = <P extends any>({
  loader,
  Loader = () => null,
  delay
}: LoadableInput<P>) => {
  let componentReady = false
  const realLoader = () =>
    retry(4, loader, retries => wait(500 * 2 ** retries)).then(res => {
      componentReady = true
      return res
    })

  const LazyComp = React.lazy(realLoader) as any

  const SuspenseLazyComp: React.FunctionComponent<P> = props => {
    const [showLoader, setShowLoader] = useState(!delay)

    if (!showLoader && !componentReady) {
      setTimeout(() => {
        if (!componentReady) {
          setShowLoader(true)
        }
      }, delay)
    }

    return (
      <Suspense fallback={showLoader ? <Loader /> : null}>
        <LazyComp {...props} />
      </Suspense>
    )
  }

  return SuspenseLazyComp
}
