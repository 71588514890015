import { IntlProvider, InjectedIntl } from 'react-intl'
import { config } from './config'
import { IntlService, Translation } from './constants'

import { METERS_T0_MILES, METERS_TO_KM, IMPERIAL_SYSTEM_COUNTRIES } from 'utils/constants'

import { getLanguage } from './'


export const createIntlService = (defaultIntl: InjectedIntl): IntlService => {
  return {
    ...defaultIntl,

    translate: (translation: Translation) => {
      const { msgId, values, defaultMessage } = translation
      return intl.formatMessage({ id: msgId, defaultMessage }, values)
    },

    formatMessage: (descriptor, values) => {
      return defaultIntl.formatMessage(
        typeof descriptor === 'string' ? { id: descriptor } : descriptor,
        values
      )
    },

    formatDistance: (meters: number, precision: number = 1): string => {
      const useMiles = IMPERIAL_SYSTEM_COUNTRIES.includes(getLanguage().toLowerCase())

      return useMiles
        ? `${defaultIntl.formatNumber((meters / METERS_T0_MILES).toFixed(
            precision
          ) as any)} mi`
        : `${defaultIntl.formatNumber((meters / METERS_TO_KM).toFixed(
            precision
          ) as any)} km`
    }
  }
}

const { intl: defaultIntl } = new IntlProvider(config, {}).getChildContext()

export const intl = createIntlService(defaultIntl)
