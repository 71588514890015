import { css } from 'styled-components'
import { ICON_SIZES } from 'styles'
import { theme } from 'theme'

export default css`
  .icon-xs {
    width: ${ICON_SIZES.XS}px !important;
    height: ${ICON_SIZES.XS}px !important;
  }

  .icon-sm {
    width: ${ICON_SIZES.SM}px !important;
    height: ${ICON_SIZES.SM}px !important;
  }

  .icon-md {
    width: ${ICON_SIZES.MD}px !important;
    height: ${ICON_SIZES.MD}px !important;
  }

  .icon-lg {
    width: ${ICON_SIZES.LG}px !important;
    height: ${ICON_SIZES.LG}px !important;
  }

  .icon-xl {
    width: ${ICON_SIZES.XL}px !important;
    height: ${ICON_SIZES.XL}px !important;
  }
`
