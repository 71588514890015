import React from 'react'
import { config } from 'tools/config'
import { initOptimizer, ExperimentConfig } from './experiment'
import { initAnalytics, GAEvent as GAEventType } from './analytics'
// export { sentry } from './sentry'
import { isEmpty } from 'ramda'
import { StoreProvider } from './store'
export * from './analytics'
export type GAEvent = GAEventType
export { useExperiment, Experiment } from './experiment'

export const AnalyticsProvider: React.FC = ({ children }) => {
  const gaConfig = { clientId: config.GA_CLIENT_ID }
  const optimizeConfig = config.AB_EXPERIMENT_CONFIG
  initAnalytics(gaConfig.clientId)
  switch (optimizeConfig.kind) {
    case 'dev':
      break
    case 'server':
      if (!isEmpty(optimizeConfig.experimentSettings)) {
        initOptimizer(optimizeConfig.gtmId)
      }
  }

  return (
    <StoreProvider
      initialState={{
        gaConfig,
        optimizeConfig: optimizeConfig,
        experiments: {},
        optimizeInitialised: getOptimizerInitilised(optimizeConfig)
      }}
    >
      {children}
    </StoreProvider>
  )
}
const getOptimizerInitilised = async (config: ExperimentConfig) => {
  const calledBack = new Promise<void>(resolve => {
    const { dataLayer } = window
    if (!dataLayer || !dataLayer.hide || !dataLayer.hide.end) {
      return resolve()
    }
    const fn = dataLayer.hide.end
    dataLayer.hide.end = () => {
      resolve()
      fn()
    }
  })
  const timeOut = new Promise<void>(resolver => setTimeout(resolver, 4000))
  const isDev = new Promise<void>(resolve => config.kind === 'dev' && resolve())
  return Promise.race([calledBack, timeOut, isDev])
}
