import { addLocaleData } from 'react-intl'
import { messages, LanguageCode, Messages } from './constants'

// When adding a support for a new locale you'll want to pull
// locale data here from react-intl. Note that regional locales
// such as en-UK are included as part of the greater set of
// locale data associated with the country code.
import de from 'react-intl/locale-data/de'
import es from 'react-intl/locale-data/es'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'
import nl from 'react-intl/locale-data/nl'
import pl from 'react-intl/locale-data/pl'
import pt from 'react-intl/locale-data/pt'
import sv from 'react-intl/locale-data/sv'

// Check to see if Javascript version has global Intl.
import areIntlLocalesSupported from 'intl-locales-supported'
// We also need to add the locale here to see if we need to Polyfill on Android.
const supportedLocales = ['de', 'es', 'en', 'fr', 'it', 'nl', 'pl', 'pt', 'sv']

// https://date-fns.org/v1.29.0/docs/I18n

// Android Polyfill
if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.
  if (!areIntlLocalesSupported(supportedLocales)) {
    const IntlPolyfill = require('intl')
    global.Intl.NumberFormat = IntlPolyfill.NumberFormat
    global.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    // Needed for use of <FormattedDate/>
    require('intl/locale-data/jsonp/de')
    require('intl/locale-data/jsonp/es')
    require('intl/locale-data/jsonp/en')
    require('intl/locale-data/jsonp/fr')
    require('intl/locale-data/jsonp/it')
    require('intl/locale-data/jsonp/nl')
    require('intl/locale-data/jsonp/pl')
    require('intl/locale-data/jsonp/pt')
    require('intl/locale-data/jsonp/sv')
  }
} else {
  global.Intl = require('intl')
}

// You then destructure the import and add the locale data here.
addLocaleData([...de, ...es, ...en, ...fr, ...it, ...nl, ...pl, ...pt, ...sv])

const getLocaleFromURL = (url = window.location.href): LanguageCode | null => {
  const pattern = /^http(s:\/\/www.ratebeer\.com|s:\/\/www\.r8\.beer|:\/\/localhost:3000)\/(\D{2})\//
  const matches = pattern.exec(url)
  return matches && matches.length > 2 ? (matches[2] as LanguageCode) : null
}

// Check the browser for preferred language--default set to 'en'
const getLocaleAndMessages = (nav = navigator): [LanguageCode, Messages] => {
  const defaultLocale = 'en'
  const { languages } = nav
  const topLang =
    languages && languages.length ? (languages[0] as LanguageCode) : undefined
  const locale = getLocaleFromURL() || topLang || (nav.language as LanguageCode)
  const localeWithoutRegion = (locale
    ? locale.toLowerCase().split(/[_-]+/)[0]
    : '') as LanguageCode
  // Try to find messages for locale, fallback to without region, fallback to default
  let bestLocale
  let localeMessages
  if (messages[locale]) {
    bestLocale = locale
    localeMessages = messages[locale]
  } else if (localeWithoutRegion && messages[localeWithoutRegion]) {
    bestLocale = localeWithoutRegion
    localeMessages = messages[localeWithoutRegion]
  } else {
    bestLocale = defaultLocale
    localeMessages = messages[defaultLocale]
  }
  return [
    bestLocale as LanguageCode,
    { ...messages.en, ...localeMessages } as Messages
  ]
}

const [bestLocale, localeMessages] = getLocaleAndMessages()

export const config = {
  locale: bestLocale,
  messages: localeMessages,
  formats: {
    date: {
      'truncated-month': {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
    }
  }
}
