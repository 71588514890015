import React, { useState, Fragment, memo } from 'react'
import { noopTemplate as css, parseQueryString } from 'lib/utils'
import { theme } from 'theme'

import { IconButton, Link } from 'lib/components'
import { SearchIcon } from 'lib/icons'

import {
  default as RateBeerBestBanner,
  RateBeerBestBannerProvider,
} from 'components/RateBeerBestBanner'

import {
  default as ChristmasHolidaysBanner,
  ChristmasHolidaysBannerProvider,
} from 'components/ChristmasHolidaysBanner'

import RateBeerLogoWhite from 'images/HeaderLogo.svg'

import { SearchInput } from './components'
import NavLinks from './NavLinks'
import AccountLinks from './AccountLinks'

const getInitialSearchText = (): string => {
  if (
    window.location.pathname.includes('/search') &&
    (window.location.search.includes('q=') ||
      window.location.search.includes('beername='))
  ) {
    const queryObject = parseQueryString(window.location.search)
    return (queryObject && (queryObject.q || queryObject.beername)) || ''
  }

  return ''
}

const INPUT_BREAKPOINT = 750

export const HEADER_HEIGHT = 64

type HeaderProps = {
  background?: string
  showSearch?: boolean
}

export default memo(
  ({ background = theme.colors.primary, showSearch = true }: HeaderProps) => {
    const [searchQuery, setSearchQuery] = useState(getInitialSearchText())
    const [showMoblieSearchInput, setShowMobileSearchInput] = useState(false)

    return (
      <ChristmasHolidaysBannerProvider>
        <RateBeerBestBannerProvider>
            <div
              css={css`
                visibility: hidden;
                pointer-events: none;
              `}
            >
              <RateBeerBestBanner />
              <ChristmasHolidaysBanner />
            </div>

            <header
              css={css`
                z-index: ${theme.zIndex.aboveBasePriority};
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
              `}
            >
              <div
                css={css`
                  /* position: absolute;
                top: 100%;
                left: 0; */
                  width: 100%;
                `}
              >
                <RateBeerBestBanner />
                <ChristmasHolidaysBanner />
              </div>

              <div
                className="px-5 py-3 fj-sb fj-c fa-c"
                css={css`
                  background: ${background};
                  transition: 0.4s all cubic-bezier(0.55, 0.055, 0.675, 0.19);
                  height: ${HEADER_HEIGHT}px;
                  max-height: ${HEADER_HEIGHT}px;
                  width: 100%;
                `}
              >
                <div
                  className="fa-c fg-1 mr-4"
                  css={css`
                    @media (max-width: ${INPUT_BREAKPOINT}px) {
                      margin-right: 0;
                    }
                  `}
                >
                  <div
                    css={css`
                      max-height: 40px;
                    `}
                  >
                    <Link
                      to="/"
                      css={css`
                        line-height: 0;
                      `}
                    >
                      <RateBeerLogoWhite
                        height={24}
                        preserveAspectRatio="xMinYMid meet"
                      />
                    </Link>
                  </div>

                  <div
                    className="fg-1 ml-8"
                    css={css`
                      @media (max-width: ${INPUT_BREAKPOINT}px) {
                        display: none;
                      }
                    `}
                  >
                    {showSearch ? (
                      <SearchInput
                        css={css`
                          max-width: 500px;
                        `}
                        numberOfBeerResults={6}
                        searchText={searchQuery}
                        onSearchTextChange={setSearchQuery}
                      />
                    ) : null}
                  </div>
                </div>

                <div
                  css={css`
                    @media (min-width: ${INPUT_BREAKPOINT}px) {
                      display: none;
                    }
                  `}
                >
                  <IconButton
                    onClick={() => setShowMobileSearchInput((show) => !show)}
                  >
                    <SearchIcon color="white" />
                  </IconButton>
                </div>

                <NavLinks className="fa-c" />
                <AccountLinks className="fa-c" />
              </div>

              {showSearch && showMoblieSearchInput ? (
                <div
                  css={css`
                    @media (min-width: ${INPUT_BREAKPOINT}px) {
                      display: none;
                    }
                  `}
                >
                  <SearchInput
                    autoFocus
                    searchText={searchQuery}
                    onSearchTextChange={setSearchQuery}
                    onClear={() => setShowMobileSearchInput(false)}
                  />
                </div>
              ) : null}
            </header>
        </RateBeerBestBannerProvider>
      </ChristmasHolidaysBannerProvider>
    )
  }
)
