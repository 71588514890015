import { urlSlug } from 'lib/utils'

import { getLocaleFromURL } from 'tools/intl'

export const BASE_URL = '/p'

type PlaceProfileURLInput = {
  name: string
  id: ID
  lang?: Language | null
}

export const placeProfileURL = ({
  name,
  id,
  lang: _lang
}: PlaceProfileURLInput): string => {
  const lang = _lang || (getLocaleFromURL() as (Language | null))
  return canonicalPlaceProfileURL({ name, id, lang })
}

export const canonicalPlaceProfileURL = ({
  name,
  id,
  lang
}: PlaceProfileURLInput): string => {
  return `${lang ? `/${lang}` : ''}${BASE_URL}/${urlSlug(name)}/${id}/`
}
