// https://github.com/reactivestack/cookies/tree/master/packages/react-cookie
import { Cookies } from 'react-cookie'
import makeConfig from './config'
const config = makeConfig()
const path = '/' // Path is required for proper cookie removal

const isLocalhost = location.hostname === 'localhost'

// secure cookies don't work on localhost, could try implement https in development instead
export const SECURE = !isLocalhost

class Jar {
  constructor() {
    // Remove the subdomain so cookies are available across everything *.ratebeer.com
    let site =
      config.AUTH_CALLBACK.indexOf('.beer') !== -1
        ? '.r8.beer'
        : '.ratebeer.com'
    this.domain =
      config.AUTH_CALLBACK.indexOf('localhost') !== -1 ? 'localhost' : site

    this.cookieCutter = new Cookies()
  }

  set(key, val, expiration) {
    const options = { domain: this.domain, path, secure: SECURE }
    if (expiration) {
      options.expires = expiration
    }
    this.cookieCutter.set(key, val, options)
  }

  get(key) {
    return this.cookieCutter.get(key)
  }

  remove(key) {
    this.cookieCutter.remove(key, { domain: this.domain, path, secure: SECURE })
  }
}

export default new Jar()
