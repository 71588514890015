import { User } from './store'

type IsAdminUser = {
  securityLevelId: number
}

export const isAdmin = (user: IsAdminUser | null): boolean => {
  return !!user && user.securityLevelId > 5
}

export const isPlaceAssociate = (
  user: User | null,
  placeId?: ID | null
): boolean => {
  return !!user && user?.place?.id == placeId
}
