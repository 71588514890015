export default {
  "Add description": "Aggiungi descrizione",
  "Associated Brewer": "Birrificio associato",
  "Available here": "Disponibile qui",
  "Average": "Media",
  "Beer name": "Nome birra",
  "Beers Spotted Here": "Birre contrassegnate qui",
  "Bottles {bottlesCount}": "Bottiglie {bottlesCount}",
  "Cancel": "Annulla",
  "Copied to clipboard": "Copiato negli appunti",
  "Copy to clipboard": "Copia negli appunti",
  "Filter": "Filtra",
  "Follow on Social Media": "Segui sui social media",
  "No results found for": "Nessun risultato trovato per {query}",
  "Read more": "ulteriori informazioni",
  "Review this place": "Recensisci questo luogo",
  "Save": "Salva",
  "Search beers spotted here": "Cerca birre contrassegnate qui",
  "Seasonal beer": "Birra stagionale",
  "Taps {tapsCount}": "Spine {tapsCount}",
  "This score could not be updated": "Non è stato possibile aggiornare il punteggio",
  "This score has been updated": "Il punteggio è stato aggiornato",
  "View beer menu": "Visualizza menu delle birre",
  "View food menu": "Visualizza menu delle pietanze",
  "We apologise for the delay you are experiencing.": "Ci scusiamo per il ritardo riscontrato.",
  "We are currently updating the website and expect this page to be back shortly.": "Il sito è attualmente in aggiornamento. La pagina sarà nuovamente disponibile a breve.",
  "Your review has been updated": "La tua recensione è stata aggiornata",
  "add.abv": "% vol",
  "add.abvPlaceholder": "(per es. 11%)",
  "add.addBeer": "Aggiungi una nuova birra",
  "add.addBrewery": "Aggiungi un nuovo birrificio",
  "add.autumn": "Autunno",
  "add.beerGuidelines": "Linee guida",
  "add.beerGuidelines1": "Le birre devono essere presenti sul mercato e in produzione.",
  "add.beerGuidelines2": "Le ricette devono essere uniche. Non sono consentite birre azotate, birre alla spina e varianti di una ricetta.",
  "add.beerGuidelines3": "I sidri alla frutta devono avere una fermentazione della mela superiore al 50% e una gradazione alcolica superiore al 2%.",
  "add.beerGuidelines4": "Includi il nome del produttore davanti a quello della birra, per es. {correctExample} e non {incorrectExample}.",
  "add.beerImage": "Aggiungi immagine",
  "add.beerName": "Nome birra",
  "add.beerNameExplanation": "La convenzione di denominazione RateBeer deve includere sia il nome del birrificio, sia il nome della birra.",
  "add.beerNameInfo": "(includi birraio)",
  "add.beerNamePlaceholder": "(per es. Elysian Space Dust)",
  "add.breweryName": "Nome birrificio",
  "add.breweryNamePlaceholder": "(per es. Elysian)",
  "add.contractBrewer": "Prodotta da",
  "add.contractBrewerPlaceholder": "Cerca birrifici...",
  "add.contractBrewerPrompt": "Questa è una birra a contratto",
  "add.description": "Descrizione commerciale",
  "add.descriptionPlaceholder": "Cosa dice l'etichetta del prodotto",
  "add.duplicateFound": "Trovato duplicato",
  "add.duplicateMessage": "Stai cercando di aggiungere questa birra?",
  "add.ibuPlaceholder": "(per es. 57)",
  "add.imageInstructions": "*Usa immagini di lattine o bottiglie",
  "add.invalid": "Non valido",
  "add.newBeer": "Aggiungi una nuova birra",
  "add.noBrewery": "Non riesci a trovare il tuo birrificio?",
  "add.optional": "Opzionale",
  "add.removeBeerImage": "Rimuovi immagine",
  "add.seasonalPlaceholder": "È una birra stagionale?",
  "add.series": "Serie",
  "add.signInToAddBeer": "Accedi per aggiungere una birra",
  "add.special": "Novità",
  "add.spring": "Primavera",
  "add.stylePlaceholder": "Scegli lo stile",
  "add.summer": "Estate",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Cifre del codice a barre)",
  "add.upcInstructions": "Inserisci tutti le 8, 12, 13 o 14 cifre",
  "add.upcPlaceholder": "Tutti le cifre sul codice a barre",
  "add.view": "Vedi",
  "add.winter": "Inverno",
  "all.adblock": "Pare che tu abbia installato un adblocker. Valuta la possibilità di aggiungere RateBeer.com all'elenco delle eccezioni. Grazie!",
  "all.ago": "{date} fa",
  "all.back": "Back",
  "all.beer": "Birra",
  "all.beers": "Birre",
  "all.cider": "Sidro",
  "all.ciders": "Sidri",
  "all.cookies": "Utilizziamo cookies ed elementi simili",
  "all.error": "Ops! Si è verificato un errore di rete",
  "all.joinBody": "Sfrutta i vantaggi offerti da Ratebeer. Leggi recensioni complete, osserva statistiche dettagliate e scopri l'opinione della nostra community.",
  "all.joinTitle": "Unisciti alla nostra Community!",
  "all.learnMore": "Ulteriori informazioni",
  "all.loading": "Caricamento in corso",
  "all.mead": "Idromele",
  "all.meads": "Idromele",
  "all.noSuchBeer": "Questa birra non esiste nel database. Prova a cercarne un'altra.",
  "all.ok": "OK",
  "all.sake": "Sakè",
  "all.sakes": "Sakè",
  "banner.free": "Gratuita – Google Play",
  "banner.getIt": "Scarica",
  "banner.officialApp": "App ufficiale",
  "beer.aboutBeer": "Informazioni su questa Birra",
  "beer.aboutCider": "Informazioni su questo Sidro",
  "beer.aboutMead": "Informazioni su questo Idromele",
  "beer.aboutSake": "Informazioni su questo Sake",
  "beer.abv": "% vol.",
  "beer.aka": "Conosciuto anche come",
  "beer.aliasBeer": "Alias della birra",
  "beer.aliasCopy": "Il birrificio vende questo stesso prodotto o uno molto simile sotto nomi differenti. Ciò può risultare dalla distribuzione da parte di un birrificio di birre con nomi diversi a seconda delle nazioni, o dalla semplice modifica in un secondo momento del nome della birra, ma non della sua ricetta, da parte del produttore.",
  "beer.allStyles": "Tutti gli stili di birra per ",
  "beer.appearance": "Aspetto",
  "beer.applyToSellWithUs": "Fai domanda per vendere con noi",
  "beer.aroma": "Aroma",
  "beer.autumn": "Autunno",
  "beer.availability": "Disponibilità",
  "beer.availableAt": "Disponibile in",
  "beer.averageRatings": "Valutazioni medie",
  "beer.bottled": "In bottiglia",
  "beer.brewedBy": "Prodotta da ",
  "beer.brewedFor": "Prodotta per ",
  "beer.buyFrom": "Compra da",
  "beer.calories": "CALORIE",
  "beer.clearRating": "Chiudi",
  "beer.copy": "Copia",
  "beer.delete": "Elimina birra/valutazioni",
  "beer.deleteAlias": "Elimina alias",
  "beer.deleteConfirm": "Sì, elimina",
  "beer.deleteOption": "Elimina",
  "beer.deleteRating": "Elimina la valutazione?",
  "beer.distribution": "Distribuzione",
  "beer.edit": "Modifica",
  "beer.editAlias": "Aggiungi/Modifica Alias",
  "beer.editBarcodes": "Aggiungi/Modifica codici a barre",
  "beer.editBeer": "Modifica questa birra",
  "beer.editPicture": "Aggiungi/Modifica immagine",
  "beer.editTags": "Aggiungi/Modifica Tags",
  "beer.findFriendsText": "Trova amici per condividere con loro ciò che stai bevendo e scopri quali birre hanno provato",
  "beer.findFriendsTitle": "Trova i tuoi amici",
  "beer.findIt": "Trova",
  "beer.findItNearYou": "Nelle tue vicinanze",
  "beer.fix": "Correggi il punteggio",
  "beer.flavor": "Gusto",
  "beer.from": "da ",
  "beer.highestScore": "Punteggio più alto",
  "beer.howItWorks": "Come funziona",
  "beer.howItWorksContent": "Ci impegniamo al massimo per trovare i rivenditori migliori con la più ampia gamma di birre e prezzi eccellenti, così potrai acquistare la birra che ti piace di più",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Individua i venditori",
  "beer.locations": "{count} {count, plural, one {posizione} other {posizioni}}",
  "beer.locationsNearby": "{count} {count, plural, one {posizione nelle vicinanze} other {posizioni nelle vicinanze}}",
  "beer.mostLiked": "Le più popolari",
  "beer.mostRecent": "Più recente",
  "beer.mouthfeel": "Sapore",
  "beer.moveRating": "Sposta la valutazione",
  "beer.myFriends": "I miei amici",
  "beer.myReview": "La mia recensione",
  "beer.noDescription": "Nessuna descrizione disponibile",
  "beer.noRating": "Nessuna valutazione",
  "beer.noReviewsText": "Fai sapere a tutti cosa pensi. Sii il primo ad aggiungere una valutazione e a scrivere una recensione!",
  "beer.noReviewsTitle": "Hai provato questa birra?",
  "beer.onTap": "Alla spina",
  "beer.outOfProd": "FUORI PRODUZIONE",
  "beer.overall": "Complessivamente",
  "beer.per": "per",
  "beer.privacyNotice": "Facendo clic sul / i link / i negozio / i in alto, verrai inoltrato al sito Web del rivenditore e da lì verrà successivamente applicata la loro Informativa sulla privacy.",
  "beer.private": "PRIVATO",
  "beer.quickRate": "Valutazione rapida",
  "beer.quickRateSaved": "Valutazione rapida salvata",
  "beer.quickRated": "Valutazione rapida: ",
  "beer.rateAndReview": "Valuta & Scrivi una recensione",
  "beer.rateIt": "L'hai provata? Valutala!",
  "beer.ratedBy": "Valutato da",
  "beer.ratingPrivate": "Valutazione privata",
  "beer.ratingPublic": "Valutazione pubblica",
  "beer.readMore": "ulteriori informazioni",
  "beer.reviews": "Recensioni",
  "beer.reviewsRatings": "Recensioni e valutazioni",
  "beer.saveRating": "Salva la valutazione!",
  "beer.saveRatingText": "Crea un account per salvare le valutazioni, leggi le recensioni di utenti esperti, visualizza statistiche dettagliate di valutazioni e recensioni, e molto altro.",
  "beer.seasonal": "Stagionale",
  "beer.seasonalLabel": "STAGIONALE",
  "beer.seeAll": "Visualizza tutto",
  "beer.seeAllXReviews": "Visualizza tutte le {count} recensioni",
  "beer.sendCorrections": "Invia le correzioni",
  "beer.series": "Serie",
  "beer.serveIn": "Servi in",
  "beer.share": "Condividi",
  "beer.shareTutorial": "Condividi, invia correzioni, aggiungi immagini",
  "beer.shelfTags": "Visualizza tag scaffale",
  "beer.shopNow": "Acquista ora",
  "beer.showLess": "mostra meno risultati",
  "beer.signInToFavorite": "Registrati per inserire questa birra tra i Preferiti",
  "beer.signInToFind": "Accedi per trovare questa birra.",
  "beer.signInToLikeReview": "Accedi per poter mettere like a questa recensione",
  "beer.similarBeer": "Birre Simili",
  "beer.similarCider": "Sidri Simili",
  "beer.similarMead": "Idromele Simili",
  "beer.similarSake": "Sakè Simili",
  "beer.special": "Speciale",
  "beer.spring": "Primavera",
  "beer.style": "Stile ",
  "beer.summer": "Estate",
  "beer.tags": "Tag",
  "beer.top50": "Le migliori 50",
  "beer.topRaters": "Giudici più attivi",
  "beer.totalRetailers": "Questa birra è venduta da {count} venditori verificati di terzi",
  "beer.undoRating": "Annulla valutazione",
  "beer.unrateable": "Impossibile valutare questa birra",
  "beer.verified": "VERIFICATA",
  "beer.winter": "Inverno",
  "beer.writeAReview": "Scrivi una recensione",
  "beer.writeFullReview": "Scrivi una recensione completa",
  "beer.yourReview": "La tua recensione",
  "error.refresh": "Aggiorna",
  "favorites.instructions": "Quando trovi una birra che ti piace, clicca sul pulsante Salva per salvarla per dopo. Vedi le birre che hai salvato nello stesso posto su tutti i tuoi dispositivi.",
  "favorites.noSavedBeers": "Nessuna birra salvata",
  "favorites.remove": "Rimuovi",
  "favorites.removeConfirmation": "Sì, rimuovi",
  "favorites.removePrompt": "Rimuovi dai Preferiti",
  "favorites.saved": "Salvata",
  "favorites.savedConfirmation": "Salva tra i Preferiti",
  "favorites.signInToViewFavorites": "Registrati per inserire questa birra tra i Preferiti",
  "favorites.viewAll": "Vedi tutto",
  "feedback.android": "App mobile Android",
  "feedback.androidReproduceInfo": "(ad es.: Accedi, cerca per scansione con codice a barre, tocca il risultato della birra, ingrandisci il pannello delle valutazioni, invia una valutazione da esperto)",
  "feedback.bug": "Segnalare un bug",
  "feedback.bugMessageInfo": "(Che risultati ti aspettavi? Che risultati hai ottenuto invece?)",
  "feedback.bugMessagePrompt": "Riepilogo del bug",
  "feedback.buildInfo": "(ad es.: Versione app 1.7.0. Per trovare l'elemento, tocca il simbolo ? nel lato superiore destro della schermata home)",
  "feedback.buildPrompt": "Versione app",
  "feedback.categoryPrompt": "Quale azione vuoi compiere?",
  "feedback.correction": "Inviare una correzione",
  "feedback.correctionMessageInfo": "(Come ti aspettavi che comparisse questa informazione? Al momento vengono visualizzate informazioni non corrette?)",
  "feedback.correctionMessagePrompt": "Riepilogo della correzione",
  "feedback.email": "Email",
  "feedback.header": "Feedback di",
  "feedback.iOS": "App mobile iOS",
  "feedback.iOSReproduceInfo": "(ad es.: Accedi, cerca per scansione con codice a barre, tocca il risultato della birra, ingrandisci il pannello delle valutazioni, invia una valutazione da esperto)",
  "feedback.mediumPrompt": "Dove hai avuto questo problema?",
  "feedback.reproducePrompt": "Quali passi abbiamo dovuto compiere per simulare noi stessi il problema?",
  "feedback.submit": "Invia",
  "feedback.submitted": "Grazie per averci inviato il tuo feedback!",
  "feedback.suggestion": "Dare un consiglio",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Quali novità vorresti fossero aggiunte a RateBeer?",
  "feedback.url": "URL",
  "feedback.username": "Nome utente",
  "feedback.web": "Sito web",
  "feedback.webReproduceInfo": "(ad es.: Accedi, visita ratebeer.com/community, fai clic sul link nella barra laterale)",
  "footer.FAQs": "Domande frequenti",
  "footer.RBBest": "Migliori RateBeer",
  "footer.TOS": "Termini servizio",
  "footer.aboutRateBeer": "Su RateBeer",
  "footer.aboutUs": "Su di noi",
  "footer.account": "Account",
  "footer.addBeer": "Aggiungi una birra",
  "footer.addBrewer": "Aggiungi un birrificio",
  "footer.addEvent": "Aggiungi un evento",
  "footer.addPlace": "Aggiungi un luogo",
  "footer.addPremium": "Aggiungi Premium",
  "footer.addToRB": "Aggiungi a RateBeer",
  "footer.contactUs": "Contattaci",
  "footer.copyright": "Tutti i diritti riservati.",
  "footer.developers": "Sviluppatori",
  "footer.editAccount": "Modifica account",
  "footer.privacy": "Politica sulla privacy",
  "The most comprehensive ratings and reviews of beers from around the world": "Le valutazioni e recensioni più complete delle birre di tutto il mondo",
  "footer.theBest": "Migliori",
  "footer.top50Beers": "Le 50 migliori birre",
  "footer.topReviewers": "I migliori recensori",
  "glasscopy1": "Tall, thin and footed with a short stem. Flutes are delicate and show off a beer’s body. These vessels are ideal for light and sparkling beers.",
  "glasscopy10": "Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.",
  "glasscopy11": "A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.",
  "glasscopy12": "This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!",
  "glasscopy13": "Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.",
  "glasscopy14": "A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.",
  "glasscopy15": "Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.",
  "glasscopy16": "A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.",
  "glasscopy17": "Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.",
  "glasscopy18": "A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.",
  "glasscopy19": "A small cup, often fluted at the edge, and larger than ochoko.",
  "glasscopy2": "Short glasses, holds around 12oz. of beer. Slightly wider at the mouth than the foot, with gradual, evenly sloping sides. Great basic glass, well-suited for pale lagers.",
  "glasscopy20": "A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.",
  "glasscopy21": "Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.",
  "glasscopy3": "Designed to be drained in a few gulps. These glasses allow for billowing heads to form. Neither are ideal for developing aroma or discerning subtleties.",
  "glasscopy4": "The American microbrewer’s standard. Gently sloped 16oz. glass made for session-type beers. Typically used to serve Ambers and English & American pales.",
  "glasscopy5": "Similar to a shaker but often straight-sided, usually with an additional flair or bulb to retain aroma. These glasses are often marked to measure an imperial pint.",
  "glasscopy6": "A classic in North America, the dimpled mug is large and has a handle. It is convex, with the mouth larger than the base. Dimples make appreciating the appearance of the beer difficult, but the wide mouth releases the aroma nicely. Commonly used for raunchy lagers.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "A small, almost straight-sided glass sits on an inch-long stem and foot. The basic footed pilsner has a slightly bulbous bottom and narrower mouth, which makes it better for drinking than for smelling, and places the most emphasis on the appearance. It has a bit more style than some other glasses, so it is best used for pilsners, and decent cream or golden ales than for the lowliest lagers.",
  "glasscopy9": "Often a favorite of top tasters. The Duvel glass is a well-known variant of the tulip style, and the tasting glass in the RateBeer logo is an almost perfect example.",
  "Account Settings": "Impostazioni dell'account",
  "Community": "Community",
  "Events": "Eventi",
  "Forums": "Forum",
  "Log In": "Accedi",
  "Log Out": "Esci",
  "Manage Breweries Followed": "Gestisci i birrifici seguiti",
  "Manage People Followed": "Gestisci gli utenti seguiti",
  "header.my2017": "Il mio anno nella birra",
  "My Beer Cellar": "La mia cantina",
  "My Beer Ratings": "Le mie valutazioni sulla birra",
  "My Beer Styles": "I miei stili di birra",
  "My Breweries": "I miei birrifici",
  "My Countries & States": "I miei paesi e stati",
  "My Favorites": "I miei Preferiti",
  "Latest Activity": "Attività più recente",
  "My Place Ratings": "Le mie valutazioni sui luoghi",
  "My Profile": "Il mio profilo",
  "New Releases": "Novità",
  "Places": "Luoghi",
  "Ratings": "Valutazioni",
  "Sign Up": "Registrati",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Migliori birre per stile",
  "home.Top Rated Beers Nearby": "Le migliori birre nelle vicinanze",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Scarica l'app",
  "home.downloadBlurb": "Porta con te RateBeer, ovunque tu sia. Le sue efficaci funzionalità ti aiuteranno a trovare le tue nuove birre preferite!",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Messaggio inviato!",
  "home.porter": "Porter",
  "home.retailers": "Rivenditori",
  "home.saison": "Saison",
  "home.searchPrompt": "Trovare birre, birrifici o bar…",
  "home.shopOnline": "Compra Online",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Invia link per messaggio",
  "home.top50Beers": "50 migliori birre",
  "home.worldOfBeer": "La tua guida per il mondo delle birre",
  "landing.TOS": "Termini del servizio",
  "landing.feedback": "È il nostro primo tentativo con l'app di RateBeer.{br}Aiutaci a migliorarla!",
  "landing.h1": "Il sito di valutazioni di birre{br}numero 1 al mondo è ora disponibile come app!",
  "landing.h2": "Ora puoi valutare le birre{br}direttamente dal palmo della tua mano",
  "landing.label": "valutazioni",
  "landing.li1": "Cerca nel nostro database di oltre 500 000 birre",
  "landing.li2": "Valuta, recensisci e condividi",
  "landing.li3": "Tieni traccia di ogni birra che hai assaggiato",
  "landing.privacy": "POLITICA SULLA PRIVACY",
  "landing.review": "RateBeer è la fonte di informazioni relativa a birre e birrifici più affidabile del web.",
  "landing.rightsReserved": "Tutti i diritti riservati.",
  "landing.submit": "Invia il feedback",
  "landing.visit": "Visita ratebeer.com",
  "place.addBeerIds": "Aggiungi ID birre",
  "place.addBeers": "Aggiungi birre",
  "place.addEvent": "Aggiungi evento",
  "place.ambiance": "Atmosfera",
  "place.available": "Disponibile",
  "place.beerMenu": "Menu delle birre",
  "place.close": "Chiudi",
  "place.customizeShelftags": "Personalizza tag scaffale",
  "place.editBeers": "Modifica birre",
  "place.editPlace": "Modifica luogo",
  "place.flights": "Voli",
  "place.food": "Pietanze",
  "place.foodMenu": "Menu delle pietanze",
  "place.getDirection": "Ottieni indicazioni",
  "place.hours": "Ore",
  "place.lessInfo": "Contrai",
  "place.map": "Mappa",
  "place.moreInfo": "Mostra di più",
  "place.overall": "Complessivamente",
  "place.phone": "Numero di telefono",
  "place.printShelftags": "Stampa tag scaffale",
  "place.selection": "Seleziona",
  "place.sendToPhone": "Invia al telefono",
  "place.service": "Servizio",
  "place.shelftags": "Tag scaffale",
  "place.showBreakdown": "Mostra il punteggio nel dettaglio",
  "place.value": "Valore",
  "place.wifi": "Wi-Fi gratuito",
  "rate.BOTTLE": "Bottiglia",
  "rate.CAN": "Lattina",
  "rate.CASK": "Botte",
  "rate.TAP": "Spina",
  "rate.commentPlaceholder": "Aggiungi note, descrizioni e altre informazioni sulla degustazione.",
  "rate.infoSubtitle": "Per assicurare la qualità dei nostri punteggi per le birre, includeremo unicamente recensioni dettagliate.",
  "rate.locationPlaceholder": "Aggiungi posizione",
  "rate.nearbyPlaces": "Posizioni nelle vicinanze",
  "rate.ourRatingSystem": "Il nostro sistema di valutazione",
  "rate.private1": "0 - 74 caratteri",
  "rate.private2": "Salvata per uso personale",
  "rate.private3": "Non contribuisce al punteggio complessivo",
  "rate.privateLabel": "PRIVATA",
  "rate.public1": "oltre 75 caratteri ",
  "rate.public2": "Contribuisce al punteggio complessivo della birra",
  "rate.public3": "Visibile alla comunità",
  "rate.publicLabel": "PUBBLICA",
  "rate.save": "Salva",
  "rate.searchResults": "Risultati",
  "rate.servedIn": "Servita in",
  "rate.totalScore": "PUNTEGGIO TOTALE",
  "search.advanced": "Ricerca avanzata",
  "search.beerCaps": "BIRRE",
  "search.beerHeader": "Birre",
  "search.beerSearchFor": "Digita all'interno della barra di ricerca per trovare una birra",
  "search.brewersCaps": "BIRRAIO",
  "search.brewersHeader": "Birraio",
  "search.brewersSearchFor": "Digita all'interno della barra di ricerca per trovare un birrificio",
  "search.closestMatch": "Corrispondenza più vicina",
  "search.for": "Cerca",
  "search.in": "su",
  "search.mostRatings": "Con più valutazioni",
  "search.myRating": "La mia valutazione:",
  "search.noResults": "Nessun risultato per ",
  "search.pCaps": "LUOGHI",
  "search.pHeader": "Luoghi",
  "search.pSearchFor": "Digita all'interno della barra di ricerca per trovare un luogo",
  "Search for Beers, Breweries, or Bars...": "Cerca birre, birrifici o bar…",
  "search.resultFor": "risultato per",
  "search.resultLabelStr": "Valutazioni",
  "search.resultsFor": "risultati per",
  "search.sortBy": "Ordina per",
  "search.sortByCaps": "ORDINA PER",
  "search.topRated": "Le più votate",
  "search.userHeader": "Utenti",
  "search.userSearchFor": "Digita all'interno della barra di ricerca per trovare un utente"
}
