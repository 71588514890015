import { useState } from 'react'
import { useInstance } from 'lib/class-hooks'

export const useDebouncedValue = <T extends any>(
  newValue: T,
  debounceTime: number
): T => {
  const [value, setValue] = useState(newValue)
  const instance = useInstance({
    timeoutId: null as number | null
  })

  if (instance.timeoutId) {
    clearTimeout(instance.timeoutId)
  }

  instance.timeoutId = setTimeout(() => setValue(newValue), debounceTime)

  return value
}
