import { css } from 'styled-components'
import { theme } from 'theme'
export default css`
  .auth0-lock.auth0-lock .auth0-lock-submit {
    background: ${theme.colors.primary}!important;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap,
  .auth0-lock.auth0-lock .auth0-lock-social-button,
  .auth0-lock.auth0-lock .auth0-lock-cred-pane {
    border-radius: 12px;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    border-radius: 0 12px 12px 0;
  }
  .auth0-lock.auth0-lock .auth0-lock-header {
    border-radius: 12px 12px 0 0;
  }
  .auth0-lock.auth0-lock .auth0-lock-submit {
    border-radius: 0 0 12px 12px;
  }
  #auth0-container {
    width: 320px;
    max-height: 498px;
    margin: 40px auto;
    padding: 10px;
    box-sizing: border-box;
  }
  .auth0-lock.auth0-lock .auth0-lock-header-logo {
    height: 92px;
  }
  .auth0-lock-name {
    display: none;
  }
`
