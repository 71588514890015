const en = require('./placeholder').default
import { filter, toPairs, map, fromPairs, pipe } from 'ramda'
type Status = 'to-update' | 'obsolete' | 'stable'
type Value = string | { message: string; status: Status }
type Entry = {
  [key: string]: Value
}

const ret = pipe(
  (x: Entry) => toPairs(x),
  x =>
    filter(
      ([name, value]: [string, Value]) =>
        typeof value === 'string' || value.status !== 'obsolete'
    )(x),
  x =>
    map(([name, value]: [string, Value]) => [
      name,
      typeof value === 'string' ? value : value.message
    ])(x) as [string, string][],
  x => fromPairs(x)
)(en as Entry)

export default ret
