import React from 'react'

import MMenuItem, {
  MenuItemProps as MMenuItemProps
} from '@material-ui/core/MenuItem'

export type MenuItemProps<P> = FlexibleRootComponent<MMenuItemProps, P>

const MaterialMenuItem = MMenuItem as any

export const MenuItem = <P extends any>(props: MenuItemProps<P>) => {
  return <MaterialMenuItem {...props} />
}
