import React from 'react'

import MButton, { ButtonProps as MButtonProps } from '@material-ui/core/Button'

import { colorized, ColorizedProps } from 'lib/components/tools'

import { noopTemplate as css } from 'lib/utils'

export type ButtonProps<P> = FlexibleRootComponent<
  ColorizedProps<MButtonProps>,
  P
>

type ButtonColor = MButtonProps['color']
type ButtonVariant = MButtonProps['variant']

const MaterialButton = MButton as any

const buttonColors: ButtonColor[] = [
  'primary',
  'secondary',
  'inherit',
  'default'
]

const getCSS = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'contained': {
      return css`
        background-color: currentColor;
        cursor: pointer;
        & * {
          color: white;
        }
      `
    }
    // case 'text': {
    //   return css`
    //     font-size: 12px;
    //   `
    // }
    default: {
      return ''
    }
  }
}

const Button = <P extends any>({
  color = 'primary',
  variant = 'text',
  ...rest
}: ButtonProps<P>) => {
  return (
    <MaterialButton
      css={css`
        text-transform: none;

        ${getCSS(variant)}
      `}
      {...rest}
      variant={variant}
      color={buttonColors.includes(color as ButtonColor) ? color : 'inherit'}
    />
  )
}

export default colorized({
  defaultColorProp: 'primary'
})(Button) as typeof Button
