import React, { useState } from 'react'

type UsePopoverMenuOutput = {
  show: boolean
  anchorEl: HTMLElement | null
  closeMenu: () => void
  openMenu: (e: React.SyntheticEvent<HTMLElement>) => void
}

export const usePopoverMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null as HTMLElement | null)

  const closeMenu = () => setAnchorEl(null)

  const openMenu = (e: React.SyntheticEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget)

  const show = !!anchorEl

  const output: UsePopoverMenuOutput = {
    anchorEl,
    closeMenu,
    show,
    openMenu
  }

  return output
}
