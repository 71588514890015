import React, { useEffect, Fragment } from 'react'
import { createStore } from 'lib/state-container'
import {
  merge,
  map,
  pipe,
  evolve,
  identity,
  fromPairs,
  filter,
  toPairs,
  init
} from 'ramda'
import { Head } from 'lib/components'

type OpenGraphProperty =
  | 'og:description'
  | 'og:title'
  | 'og:image'
  | 'og:image:secure_url'
  | 'og:image:width'
  | 'og:image:height'
  | 'og:url'

type TwitterName =
  | 'twitter:card'
  | 'twitter:site'
  | 'twitter:title'
  | 'twitter:description'
  | 'twitter:image'
  | 'twitter:image:alt'

type Title = {
  type: 'title'
  content: string
}

type Apple = {
  type: 'apple'
  attributes: {
    property: 'apple-itunes-app'
    content: string
  }
}

type OpenGraph = {
  type: 'open-graph'
  attributes: {
    property: OpenGraphProperty
    content: string | string[] | null
  }
}

type Twitter = {
  type: 'twitter'
  attributes: {
    name: TwitterName
    content: string | null
  }
}

type Link = {
  type: 'link'
  attributes: {
    rel: 'canonical' | 'alternate'
    href: string
    hreflang?: Language | 'x-default'
  }
}

export type HeadMetaItem = Title | OpenGraph | Twitter | Apple | Link

type State = { [key: string]: HeadMetaItem[] }

const initialState: State = {}

const { Provider: StoreProvider, useStore } = createStore<State>()

const getKeyFromItem = (item: HeadMetaItem): string => {
  switch (item.type) {
    case 'title':
      return 'title'
    case 'apple':
      return `apple_${item.attributes.property}`
    case 'open-graph':
      return `open-graph_${item.attributes.property}`
    case 'twitter':
      return `twitter_${item.attributes.name}`
    case 'link':
      return `link_${item.attributes.rel}_${item.attributes.href}_${
        item.attributes.hreflang
      }`
  }
}

export const useSetHeadMeta = (items: HeadMetaItem[], deps: any[] = []) => {
  const [setState] = useStore()

  const placehold = merge<State>(
    pipe<HeadMetaItem[], string[], [string, HeadMetaItem[]][], State>(
      map(getKeyFromItem),
      map(key => [key, [] as HeadMetaItem[]]),
      fromPairs
    )(items)
  )

  const updates = items.reduce(
    (prev, x) => ({
      ...prev,
      [getKeyFromItem(x)]: (v: HeadMetaItem[]) => [...v, x]
    }),
    {}
  )

  const deletes = items.reduce(
    (prev, x) => ({
      ...prev,
      [getKeyFromItem(x)]: (v: HeadMetaItem[]) => init(v)
    }),
    {}
  )

  useEffect(() => {
    setState(
      pipe(
        placehold,
        evolve(updates)
      )
    )
    return () => setState(state => evolve(deletes, state))
  }, deps)
}

type FinalView = { [key: string]: HeadMetaItem }

const GlobalHeadMeta: React.FunctionComponent = () => {
  const [state] = useStore(identity)
  const finalView = pipe<
    State,
    [string, HeadMetaItem[]][],
    [string, HeadMetaItem[]][],
    [string, HeadMetaItem][],
    FinalView
  >(
    toPairs,
    filter(([_, items]) => items && items.length > 0),
    map(([key, items]) => [key, items[items.length - 1]]),
    fromPairs
  )(state)

  const ret = pipe<FinalView, [string, HeadMetaItem][], (JSX.Element | null)[]>(
    toPairs,
    map(([key, item]) => {
      switch (item.type) {
        case 'apple':
          return <meta key={key} property={item.attributes.property} />

        case 'open-graph': {
          const {
            attributes: { property, content }
          } = item
          if (!content) {
            return null
          }
          return typeof content === 'string' ? (
            <meta key={key} property={property} content={content} />
          ) : (
            <Fragment key={key}>
              {content.map((c, index) => (
                <meta key={index} property={property} content={c} />
              ))}
            </Fragment>
          )
        }

        case 'title':
          return <title key={key}>{item.content}</title>

        case 'twitter': {
          const {
            attributes: { name, content }
          } = item
          if (!content) return null
          return <meta key={key} name={name} content={content} />
        }

        case 'link': {
          const {
            attributes: { rel, href, hreflang }
          } = item
          return <link key={key} rel={rel} href={href} hrefLang={hreflang} />
        }
      }
    })
  )(finalView)

  return <Head>{ret}</Head>
}

export const HeadMetaProvider: React.FunctionComponent = ({ children }) => {
  return (
    <StoreProvider initialState={initialState}>
      {children}
      <GlobalHeadMeta />
    </StoreProvider>
  )
}
