import React, { useState, useContext, createContext } from 'react'
import { noopTemplate as css } from 'lib/utils'

import store from 'store'

import { Paper, Button, Text, Collapse, Link } from 'lib/components'

import { ratebeerBestUrl } from 'features/ratebeerbest/routing'

const RateBeerBestKey = 'show-ratebeerbest-banner'

const Context = createContext({
  showRateBeerBanner: false,
  toggleShowRateBeerBanner: () => {},
})

export const RateBeerBestBannerProvider: React.FC = ({ children }) => {
  const storageVal = store.get(RateBeerBestKey)
  const [show, setShow] = useState(
    storageVal === undefined ? true : Boolean(storageVal)
  )

  const toggle = () => {
    setShow((val) => {
      const newVal = !val
      store.set(RateBeerBestKey, newVal)
      return newVal
    })
  }

  return (
    <Context.Provider
      value={{
        showRateBeerBanner: show,
        toggleShowRateBeerBanner: toggle,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default () => {
  const { showRateBeerBanner, toggleShowRateBeerBanner } = useContext(Context)

  return (
    <Collapse in={showRateBeerBanner}>
      <Paper
        className="fj-c"
        css={css`
          border-radius: 0;
        `}
      >
        <div
          className="fa-c fj-sb f-wrap py-4 px-4"
          css={css`
            max-width: 1000px;
            width: 100%;
          `}
        >
          <div>
            <Text bold inline variant="body1">
              RateBeer Best Update:{' '}
            </Text>
            <Text inline variant="body1">
              Our annual beer awards have been announced for 2020!
            </Text>
          </div>

          <div className="fa-c">
            <Link to={ratebeerBestUrl()}>
              <Button
                className="-ml-3 mr-4"
                css={css`
                  text-transform: uppercase;
                `}
                onClick={toggleShowRateBeerBanner}
              >
                View Results
              </Button>
            </Link>
            <Button
              css={css`
                text-transform: uppercase;
              `}
              onClick={toggleShowRateBeerBanner}
            >
              Dismiss
            </Button>
          </div>
        </div>
      </Paper>
    </Collapse>
  )
}
