import React from 'react'
import { createStore } from 'lib/state-container'
import { identity } from 'ramda'
import { Fragment } from 'react'
import Page404 from './404'

type ErrorCode = 404
type State = {
  error?: ErrorCode | null
}
const initialState: State = {}

const { Provider: StoreProvider, useStore } = createStore<State>()

const GlobalErrorPageWrapper: React.FunctionComponent = ({ children }) => {
  const [{ error }] = useStore(identity)
  if (!error) return <Fragment>{children}</Fragment>
  return <Fragment>{getPage(children, error)}</Fragment>
}

export const Provider: React.FC = ({ children }) => {
  return (
    <StoreProvider initialState={initialState}>
      <GlobalErrorPageWrapper>{children}</GlobalErrorPageWrapper>
    </StoreProvider>
  )
}

const getPage = (
  normalPage: React.ReactNode,
  error?: ErrorCode
): React.ReactNode => {
  if (!error) return normalPage
  switch (error) {
    case 404:
      return <Page404 />
  }
}

export const useSetErrorPage = () => {
  const [setState] = useStore()

  return (error: ErrorCode | null) => {
    setState(() => ({ error: error }))
  }
}
