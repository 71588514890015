import {
  ExperimentConfig,
  ServerExperimentConfig,
  DevExperimentConfig
} from 'services/analytics/experiment'
type Config = {
  ENVIRONMENT: string
  PRIVACY_POLICY_VERSION: string
  GA_CLIENT_ID: string
  AUTH_CLIENT_ID: string
  AUTH_CALLBACK: string
  AUTH_LOGOUT_URI: string
  AUTO_SSO_URI: string
  AUTH0_CUSTOM_DOMAIN: string
  SSO_URI: string
  GRAPHQL_URI: string
  DISCOURSE_URI: string
  IPSTACK_API_KEY: string
  SMS_API_URL: string
  GOOGLE_MAPS_API_KEY: string
  FACEBOOK_APP_ID: string
  SSO_CALLBACK?: string
  AB_EXPERIMENT_CONFIG: ExperimentConfig
}

const makeConfig = () => {
  let config: Config = {
    ENVIRONMENT: 'development',
    PRIVACY_POLICY_VERSION: '9-26-2016',
    GA_CLIENT_ID: process.env.REACT_APP_GA_QA_CLIENT_ID as string,
    AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_QA_CLIENT_ID as string,
    AUTH_CALLBACK: process.env.REACT_APP_AUTH_LOCAL_CALLBACK_URL as string,
    AUTH_LOGOUT_URI: process.env.REACT_APP_AUTH_LOCAL_LOGOUT_URL as string,
    AUTO_SSO_URI: process.env.REACT_APP_AUTO_SSO_LOCAL_URL as string,
    AUTH0_CUSTOM_DOMAIN: process.env.REACT_APP_AUTH_QA_CUSTOM_DOMAIN as string,
    SSO_URI: process.env.REACT_APP_SSO_LOCAL_URL as string,
    GRAPHQL_URI: process.env.REACT_APP_GRAPHQL_LOCAL_URI as string,
    DISCOURSE_URI: process.env.REACT_APP_DISCOURSE_QA_URI as string,
    IPSTACK_API_KEY: process.env.REACT_APP_IPSTACK_API_KEY as string,
    SMS_API_URL: process.env.REACT_APP_SMS_API_URL_QA as string,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID as string,
    AB_EXPERIMENT_CONFIG: devAbExperiementConfig
  }
  if (process.env.REACT_APP_ENV === 'qa') {
    config.ENVIRONMENT = 'qa'
    config.AUTH_CALLBACK = process.env.REACT_APP_AUTH_QA_CALLBACK_URL as string
    config.AUTH_LOGOUT_URI = process.env.REACT_APP_AUTH_QA_LOGOUT_URL as string
    config.AUTO_SSO_URI = process.env.REACT_APP_AUTO_SSO_QA_URL as string
    config.AUTH0_CUSTOM_DOMAIN = process.env
      .REACT_APP_AUTH_QA_CUSTOM_DOMAIN as string
    config.SSO_URI = process.env.REACT_APP_SSO_QA_URL as string
    config.GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_QA_URI as string
    config.SSO_CALLBACK = process.env.REACT_APP_SSO_QA_CALLBACK_URL as string
    config.AB_EXPERIMENT_CONFIG = qaAbExperimentConfig
  } else if (process.env.REACT_APP_ENV === 'production') {
    config.ENVIRONMENT = 'production'
    config.GA_CLIENT_ID = process.env.REACT_APP_GA_PROD_CLIENT_ID as string
    config.AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_PROD_CLIENT_ID as string
    config.AUTH_CALLBACK = process.env
      .REACT_APP_AUTH_PROD_CALLBACK_URL as string
    config.AUTH_LOGOUT_URI = process.env
      .REACT_APP_AUTH_PROD_LOGOUT_URL as string
    config.AUTO_SSO_URI = process.env.REACT_APP_AUTO_SSO_PROD_URL as string
    config.AUTH0_CUSTOM_DOMAIN = process.env
      .REACT_APP_AUTH_PROD_CUSTOM_DOMAIN as string
    config.SSO_URI = process.env.REACT_APP_SSO_PROD_URL as string
    config.GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_PROD_URI as string
    config.DISCOURSE_URI = process.env.REACT_APP_DISCOURSE_PROD_URI as string
    config.SMS_API_URL = process.env.REACT_APP_SMS_API_URL_PROD as string
    config.AB_EXPERIMENT_CONFIG = prodAbExperimentConfig
  }

  return config
}

const qaAbExperimentConfig: ServerExperimentConfig = {
  kind: 'server',
  gtmId: process.env.REACT_APP_AB_TEST_QA_GTM_ID as string,
  experimentSettings: {
    forUnitTest: {
      id: 'ydg3O7hfTMqIiZNw9Oe9yg',
      event: 'optimize.activate'
    },
    similarBeerWithOrWithoutPurchaseInfo: {
      id: 'gjraSQkYQ0y2iCFkDIlklA',
      event: 'optimize.similar-beers-with-without-purchase-info'
    },
    onlineShoppingDescription: {
      id: 'BuTtdopnSGekH-PvwEfY2g',
      event: 'optimize.online-shopping-description'
    }
  }
}
const prodAbExperimentConfig: ServerExperimentConfig = {
  kind: 'server',
  gtmId: process.env.REACT_APP_AB_TEST_PROD_GTM_ID as string,
  experimentSettings: {
    similarBeerWithOrWithoutPurchaseInfo: {
      id: 'JojLyWFoS52isQCw-g5MdQ',
      event: 'optimize.similar-beers-with-without-purchase-info'
    },
    onlineShoppingDescription: {
      id: 'niDidQn9R2CnZpwelxIfaQ',
      event: 'optimize.online-shopping-description'
    }
  }
}
const devAbExperiementConfig: DevExperimentConfig = {
  kind: 'dev',
  experimentSettings: {
    similarBeerWithOrWithoutPurchaseInfo: {
      simulation: 'B'
    },
    onlineShoppingDescription: {
      simulation: 'B'
    }
  }
}

export const config = makeConfig()
export default makeConfig
