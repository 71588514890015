import React, { Fragment } from 'react'
import { theme } from 'theme'
import { noopTemplate as css, classNames } from 'lib/utils'
import {
  Avatar,
  usePopoverMenu,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  A,
  AProps,
  Text,
  Link
} from 'lib/components'
import {
  HeartOutlinedIcon,
  SettingsOutlinedIcon,
  PowerSettingsNewIcon
} from 'lib/icons'

import { useLogout, User } from 'services/auth'
import { intl } from 'services/intl'

import { favoritesPageUrl } from 'features/favorites/routing'

type AccountLinksProps = Stylable & {
  user: User
}

const AccountMenuAvatar = ({ className, style, user }: AccountLinksProps) => {
  const logout = useLogout()

  const { show, closeMenu, openMenu, anchorEl } = usePopoverMenu()

  return (
    <Fragment>
      <Avatar
        className={classNames(className, 'cursor-pointer')}
        style={style}
        src={user.imageUrl}
        onClick={openMenu}
      />

      <Menu
        open={show}
        onClose={closeMenu}
        anchorEl={anchorEl}
        className="py-0"
      >
        <div
          className="fa-c p-4"
          css={css`
            background: ${theme.colors.pageBackground};
          `}
        >
          <Avatar className="mr-4" src={user.imageUrl} />

          <div>
            <Text bold>{user.username}</Text>
            <MenuAnchor href={`/user/${user.id}/`} bold>
              {intl.formatMessage('My Profile')}
            </MenuAnchor>
          </div>
        </div>

        <MenuAnchor href="/latest">
          <MenuItem>{intl.formatMessage('Latest Activity')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href="/latest/breweries/new">
          <MenuItem>{intl.formatMessage('New Releases')}</MenuItem>
        </MenuAnchor>

        <Divider />

        <Link to={favoritesPageUrl()} color="textPrimary">
          <MenuItem className="pl-0">
            <IconButton>
              <HeartOutlinedIcon />
            </IconButton>
            {intl.formatMessage('My Favorites')}
          </MenuItem>
        </Link>

        <Divider />

        <MenuAnchor href={`/user/${user.id}/beer-ratings/`}>
          <MenuItem>{intl.formatMessage('My Beer Ratings')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/place-ratings/`}>
          <MenuItem>{intl.formatMessage('My Place Ratings')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/breweries/`}>
          <MenuItem>{intl.formatMessage('My Breweries')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/style-ratings/`}>
          <MenuItem>{intl.formatMessage('My Beer Styles')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/countries-rated/`}>
          <MenuItem>{intl.formatMessage('My Countries & States')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/beer-cellar/`}>
          <MenuItem>{intl.formatMessage('My Beer Cellar')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/my-year-in-beer/${user.id}/`}>
          <MenuItem>{intl.formatMessage('My Year In Beer')}</MenuItem>
        </MenuAnchor>

        <Divider />

        <MenuAnchor href="/profile">
          <MenuItem className="pl-0">
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
            {intl.formatMessage('Account Settings')}
          </MenuItem>
        </MenuAnchor>

        <Divider />

        <MenuAnchor href={`/user/${user.id}/friends/`}>
          <MenuItem>{intl.formatMessage('Manage People Followed')}</MenuItem>
        </MenuAnchor>

        <MenuAnchor href={`/user/${user.id}/following/`}>
          <MenuItem>{intl.formatMessage('Manage Breweries Followed')}</MenuItem>
        </MenuAnchor>

        <Divider />

        <MenuItem className="pl-0" onClick={() => logout()}>
          <IconButton>
            <PowerSettingsNewIcon />
          </IconButton>
          {intl.formatMessage('Log Out')}
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

const MenuAnchor = (props: AProps<any>) => {
  return <A {...props} color={props.color || 'textPrimary'} />
}

export default AccountMenuAvatar
