import React, { Fragment } from 'react'
import { noopTemplate as css, classNames } from 'lib/utils'

import { config } from 'tools/config'

import { intl } from 'services/intl'

import HeaderLinkButton from './HeaderLinkButton'
import NavMenuIcon from './NavMenuIcon'

const THE_BREAKPOINT = 1200

type NavLinksProps = Stylable

const NavLinks = ({ className, style }: NavLinksProps) => {
  return (
    <Fragment>
      <div
        css={css`
          @media (min-width: ${THE_BREAKPOINT}px) {
            display: none;
          }
        `}
        className={className}
        style={style}
      >
        <NavMenuIcon />
      </div>

      <div
        css={css`
          @media (max-width: ${THE_BREAKPOINT}px) {
            display: none;
          }
        `}
        className={classNames(className, 'fa-c mr-4')}
        style={style}
      >
        <HeaderLinkButton external to="/beer-ratings/" className="mr-4">
          <span style={{ color: 'white' }}>
            {intl.formatMessage('Ratings')}
          </span>
        </HeaderLinkButton>

        <HeaderLinkButton external to="/users" className="mr-4">
          <span style={{ color: 'white' }}>
            {intl.formatMessage('Community')}
          </span>
        </HeaderLinkButton>

        <HeaderLinkButton external to="/events" className="mr-4">
          <span style={{ color: 'white' }}>{intl.formatMessage('Events')}</span>
        </HeaderLinkButton>

        <HeaderLinkButton external to="/places/" className="mr-4">
          <span style={{ color: 'white' }}>{intl.formatMessage('Places')}</span>
        </HeaderLinkButton>

        {config.ENVIRONMENT === 'production' && (
          <HeaderLinkButton external to={config.DISCOURSE_URI} className="mr-4">
            <span style={{ color: 'white' }}>
              {intl.formatMessage('Forums')}
            </span>
          </HeaderLinkButton>
        )}
      </div>
    </Fragment>
  )
}

export default NavLinks
