import { css } from 'styled-components'

export default css`
  .w-100 {
    width: 100%;
  }
  .w-75 {
    width: 75%;
  }
  .w-50 {
    width: 50%;
  }
  .w-25 {
    width: 25%;
  }
  .w-auto {
    width: auto;
  }

  .h-100 {
    height: 100%;
  }
  .h-75 {
    height: 75%;
  }
  .h-50 {
    height: 50%;
  }
  .h-25 {
    height: 25%;
  }
  .h-auto {
    height: auto;
  }

  .mw-100 {
    max-width: 100%;
  }
  .mw-100vw {
    max-width: 100vw;
  }
`
