import React, { Fragment, useEffect } from 'react'
import { Provider, initialState } from './store'
import { useGetUser } from './actions'

const StateContainer: React.FunctionComponent = ({ children }) => {
  const getUser = useGetUser()

  useEffect(() => {
    getUser()
  }, [])

  return <Fragment>{children}</Fragment>
}

const Wrapper: React.FunctionComponent = ({ children }) => {
  return (
    <Provider initialState={initialState}>
      <StateContainer>{children}</StateContainer>
    </Provider>
  )
}

export default Wrapper
