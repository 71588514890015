import { generateUserImageUrl, captureError } from './utils'
import ReactGA from 'react-ga'
import { EVENT_ERROR } from './constants'
import Jar from 'tools/cookies'
import qql from 'graphql-tag'
import { client } from 'services/graphql'

import { User } from 'services/auth'

// ⚠️ changes to the structure of this class will require changes to
// 1) ratebeer-legacy-site/authCallbackSessionHandler.asp
// 2) ratebeer-legacy-site/includes/inc_phx_session_sync.asp
// ⚠️ When idToken is included in the session cookie, it becomes too large
// for ASP to deal with, so it needs to live inside its own cookie
export class Session {
  username = ''
  isPremiumMember = false
  securityLevelId = 0
  userId = ''
  imageUrl = ''
}

export const getCookie = name => {
  var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  let cookie = b ? b.pop() : ''
  return cookie
}

export const saveSessionToCooke = user => {
  try {
    const val = encodeURIComponent(
      window.btoa(JSON.stringify({ ...user, userId: user.id }))
    )
    Jar.set('session', val)
  } catch (e) {
    return
  }
}

export const validate = session => {
  if (!session) throw 'Null session'
  // if (!session.username) throw 'Invalid session: missing username'
  if (!session.userId) throw 'Invalid session: missing userId'
}

export function sessionFromCookie() {
  const data = Jar.get('session')
  try {
    if (data) {
      const sessionJSON = window.atob(decodeURIComponent(data))
      let sessionObj
      //FIXME: to investigate sentry.io error: https://sentry.io/organizations/zx-ventures-56/issues/988470079/?project=1009721&query=is%3Aunresolved
      try {
        sessionObj = JSON.parse(sessionJSON)

        /**
         * 23/9/2019
         * A change was made to the session cookies that does not work on legacy.
         * These cookies have a __typename property.
         * If such a cookie is encountered remove the cookie.
         */
        if (sessionObj.__typename || sessionObj.imageUrl) {
          throw new Error('Removing new session cookie')
        }
      } catch (e) {
        captureError(e, 'parse session json string', sessionJSON)
        throw e
      }
      const session = Object.assign(new Session(), sessionObj)
      validate(session)
      session.securityLevelId = !session.securityLevelId
        ? 0
        : session.securityLevelId
      return Object.assign({}, session, {
        // imageUrl: generateUserImageUrl(session.username)
      })
    }
  } catch (e) {
    captureError(e, 'Attempting to extract the session from the cookie')

    // You weren't logged in anyway :/
    Jar.remove('session')

    // Do not use /tools/analytics to avoid recursive exceptions
    ReactGA.event({
      category: EVENT_ERROR,
      action: 'Invalid session cookie encountered',
      label: data
    })
  }
  return null
}

// Pulls back the data from graphql needed to create a session
// in phoenix using the idtoken found in the browser's cookie
export async function sessionFromToken() {
  const idtoken = Jar.get('idtoken')
  if (!idtoken || idtoken.length === 0) return null

  try {
    const query = qql`{
      me {
        userId: id
        username
        isPremiumMember
        securityLevelId
      }
    }`

    const result = await client.query({ query })

    if (result && result.data && result.data.me) {
      const {
        username,
        userId,
        securityLevelId,
        isPremiumMember
      } = result.data.me
      return Object.assign({}, new Session(), {
        username,
        userId,
        securityLevelId,
        isPremiumMember
        // imageUrl: generateUserImageUrl(username)
      })
    }
  } catch (e) {
    captureError(e, 'Attempting to create session from idtoken')

    // If there was a problem, remove the token so we're not here again
    Jar.remove('idtoken')

    // Do not use /tools/analytics to avoid recursive exceptions here
    ReactGA.event({
      category: EVENT_ERROR,
      action: 'Missing phoenix session encountered'
    })
  }
}
