import React, { Fragment } from 'react'
import styled from 'styled-components'
import { noopTemplate as css } from 'lib/utils'

import RateBeerLogo from 'images/FooterLogo.svg'

import { Text, Divider, Paper, A, Link } from 'lib/components'

import { intl } from 'services/intl'

import {
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  AppleIcon,
  GooglePlayIcon,
} from 'lib/icons'

import { config } from 'tools/config'

import { faqPageURL } from 'features/faq/routing'
import { useMediaQuery } from 'services/window-size'

import { privacyPolicyPageUrl } from 'features/privacy-policy/routing'
import { cookiePolicyPageUrl } from 'features/cookie-policy/routing'
import { optOutPageUrl } from 'features/opt-out/routing'

const LINK_BLOCK_WIDTH = 170
const BLOCK_WIDTH = 300
const HORIZONTAL_PADDING = 160

const collapseWidth = BLOCK_WIDTH * 2 + HORIZONTAL_PADDING * 2

const Footer = () => {
  const { mobile } = useMediaQuery({
    mobile: {
      start: 0,
      end: 1000,
    },
  })

  return (
    <Fragment>
      <Paper
        css={css`
          &,
          & * {
            box-sizing: border-box;
          }
          box-shadow: none;
          border-radius: 0;
          padding-top: 64px;
        `}
      >
        <div
          css={css`
            margin: auto;
            width: 100%;
            padding: 0 10em;

            @media (max-width: 1000px) {
              padding: 0 2em;
              margin-bottom: 100pt;
            }
          `}
        >
          <div
            className="w-100 fa-s fj-se fg-1 f-wrap"
            css={css`
              @media (max-width: ${collapseWidth}px) {
                justify-content: center;
              }
            `}
          >
            <Block className="px-4 my-4">
              <Link to="/">
                <RateBeerLogo height={32} preserveAspectRatio="xMinYMid meet" />
              </Link>

              <Text
                variant="subtitle2"
                color="textSecondary"
                className="mt-5"
                css={css`
                  max-width: 300px;
                  @media (max-width: ${collapseWidth}px) {
                    text-align: center;
                  }
                `}
              >
                {intl.formatMessage(
                  'The most comprehensive ratings and reviews of beers from around the world'
                )}
              </Text>
            </Block>

            <Block className="px-4 my-4 fd-r fj-se">
              <div
                className={`${mobile ? 'fa-c fa-c' : 'mr-8'} fd-c fa-s f-wrap `}
              >
                <Text variant="h7" bold>
                  {intl.formatMessage('Get the RateBeer app')}
                </Text>
                <div className="fd-r mt-5">
                  <A
                    color="textPrimary"
                    className="mr-7"
                    href="https://itunes.apple.com/us/app/keynote/id1221018606?mt=8&utm_source=RateBeer&utm_medium=footer"
                  >
                    <div className={`${mobile ? 'fd-c' : 'fd-r fa-c'}`}>
                      <AppleIcon
                        className={mobile ? 'mb-5' : 'mr-5'}
                        color="textSecondary"
                      />
                      <div className="fd-c">
                        <Text variant="note">
                          {intl.formatMessage('Download on')}
                        </Text>
                        <Text>{intl.formatMessage('App Store')}</Text>
                      </div>
                    </div>
                  </A>

                  <A
                    color="textPrimary"
                    href="https://play.google.com/store/apps/details?id=com.ratebeer&utm_source=RateBeer&utm_medium=footer"
                  >
                    <div className={`${mobile ? 'fd-c' : 'fd-r fa-c'}`}>
                      <GooglePlayIcon
                        className={mobile ? 'mb-5' : 'mr-5'}
                        color="textSecondary"
                      />
                      <div className="fd-c">
                        <Text variant="note">
                          {intl.formatMessage('Download on')}
                        </Text>
                        <Text>{intl.formatMessage('Google Play Store')}</Text>
                      </div>
                    </div>
                  </A>
                </div>
              </div>
              <div
                css={css`
                  @media (max-width: 1000px) {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    align-items: center;
                  }
                `}
                className={mobile ? 'mt-5' : ''}
              >
                <Text variant="h7" bold>
                  {intl.formatMessage('Follow us')}
                </Text>

                <div className="fa-c mt-5">
                  <A href="https://www.facebook.com/ratebeer/">
                    <FacebookIcon className="mr-5" color="textSecondary" />
                  </A>
                  <A href="https://twitter.com/ratebeer">
                    <TwitterIcon className="mr-5" color="textSecondary" />
                  </A>
                  <A href="https://www.instagram.com/ratebeer/">
                    <InstagramIcon className="mr-5" color="textSecondary" />
                  </A>
                </div>
              </div>
            </Block>
          </div>

          <Divider className="my-6" />

          <div className="w-100 fj-sb f-wrap">
            {LINK_GROUPS.map(({ header, items }) => (
              <LinkBlock
                key={header}
                className="px-4 my-4 fg-1 fb-0 fd-c"
                css={css`
                  min-width: ${LINK_BLOCK_WIDTH}px;
                `}
              >
                <Text gutterBottom variant="h7" bold>
                  {header}
                </Text>

                {items.map(
                  (item) =>
                    item && (
                      <div key={item.label} className="mb-3">
                        {item.external ? (
                          <A href={item.to} color="textSecondary">
                            {item.label}
                          </A>
                        ) : (
                          <Link to={item.to} color="textSecondary">
                            {item.label}
                          </Link>
                        )}
                      </div>
                    )
                )}
              </LinkBlock>
            ))}

            <LinkBlock
              className="px-4 my-4 fg-1 fb-0"
              css={css`
                min-width: ${LINK_BLOCK_WIDTH}px;
              `}
            >
              <div className="mb-3">
                <Text
                  variant="caption"
                  color="textSecondary"
                  css={css`
                    font-size: 10px;
                  `}
                >
                  {intl.formatMessage('© 2000-2021 RateBeer, LLC.')}
                </Text>

                <Text
                  variant="caption"
                  color="textSecondary"
                  css={css`
                    font-size: 10px;
                  `}
                >
                  {intl.formatMessage('All Rights Reserved')}
                </Text>
              </div>
            </LinkBlock>
          </div>

          <Text
            variant="caption"
            color="textSecondary"
            css={css`
              font-size: 10px;
              padding: 0 0 20px 0;
            `}
          >
            RateBeer is committed to improving the accessibility of our website
            for all users, including those with disabilities. If you experience
            issues or difficulties accessing any information on our site as a
            result of a disability, we want to hear from you. Please contact us
            at{' '}
            <a href="mailto:disabilityaccommodation@anheuser-busch.com">
              disabilityaccommodation@anheuser-busch.com
            </a>{' '}
            with your contact information, the web address where you experienced
            the issue, and a brief description of the problem. This email
            address is intended for accessibility-related inquiries only. If you
            have an unrelated complaint or feedback, please visit{' '}
            <a href="https://www.ratebeer.com/feedback.asp">
              https://www.ratebeer.com/feedback.asp
            </a>
          </Text>
        </div>
      </Paper>
    </Fragment>
  )
}

export default React.memo(Footer)

const LINK_GROUPS = [
  {
    header: 'Explore',
    items: [
      { label: 'Ratings', external: true, to: '/beer-ratings/' },
      { label: 'Community', external: true, to: '/users' },
      { label: 'Events', external: true, to: '/events' },
      { label: 'Places', external: true, to: '/places/' },
      config.ENVIRONMENT === 'production'
        ? { label: 'Forums', external: true, to: config.DISCOURSE_URI }
        : null,
    ],
  },
  {
    header: 'About',
    items: [
      { label: 'About RateBeer', external: true, to: '/about.asp' },
      { label: 'Our scores', external: true, to: faqPageURL() },
      { label: 'FAQs', external: true, to: '/faq.asp' },
      { label: 'Developers', external: true, to: '/api-documentation.asp' },
      { label: 'Contact us', external: true, to: '/feedback.asp' },
    ],
  },
  {
    header: 'Discover',
    items: [
      { label: 'Top 50 beers', external: true, to: '/beer/top-50/' },
      { label: 'RateBeer Best', external: true, to: '/ratebeerbest/' },
      { label: 'Top reviewers', external: true, to: '/ratings/beerclub.asp' },
    ],
  },
  {
    header: 'Contribute',
    items: [
      { label: 'Add new beer', external: true, to: '/add-beer' },
      { label: 'Add new brewer', external: true, to: '/addbrewer.asp' },
      { label: 'Add new place', external: true, to: '/Places/AddAPlace.asp' },
      { label: 'Add new event', external: true, to: '/events.asp' },
    ],
  },
  {
    header: 'Legal',
    items: [
      { label: 'Terms of service', external: true, to: '/useragreement.asp' },
      { label: 'Privacy policy', external: true, to: privacyPolicyPageUrl() },
      { label: 'Cookie policy', external: true, to: cookiePolicyPageUrl() },
      { label: 'Do not sell my data', external: true, to: optOutPageUrl() },
    ],
  },
]

const Block = styled.div`
  min-width: ${BLOCK_WIDTH}px;
  max-width: 100%;
  flex-shrink: 0;
  flex-grow: 1;

  @media (max-width: ${BLOCK_WIDTH * 2 + HORIZONTAL_PADDING * 2}px) {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

const LinkBlock = styled.div`
  min-width: ${LINK_BLOCK_WIDTH}px;
`
