// @ts-ignore
import queryString from 'query-string'
import getSlug from 'speakingurl'
// import * as Sentry from '@sentry/browser'

export const makeQueryString = (q: string) => {
  return encodeURIComponent(q).split('%20').join('+')
}

export const findNecessaryQueryStringParams = (
  incomingUrl: string,
  paramsToRemove: string[]
) => {
  const { url, query } = queryString.parseUrl(incomingUrl)
  if (!query) return [url, '']
  paramsToRemove.forEach((param) => delete (query as any)[param])
  const filteredQueryString = queryString.stringify(query)
  return [url, filteredQueryString]
}

export const makeURLName = (result: string) => {
  const options = {
    custom: {
      '&': '-and-',
    },
  }
  return getSlug(result, options)
}

export const generateBeerImageUrl = (
  beerId = '',
  asIcon = false,
  width?: string | number,
  height?: string | number
) => {
  const defaultImgTag = asIcon ? 'icon' : 'img'

  return height
    ? `https://res.cloudinary.com/ratebeer/image/upload/w_${width},h_${height},c_limit,d_Default_Beer_qqrv7k.png,f_auto/beer_${beerId}`
    : `https://res.cloudinary.com/ratebeer/image/upload/d_Default_Beer_qqrv7k.png,f_auto/beer_${beerId}`
}

export const generateUserImageUrl = (username?: string | null, size = '40') => {
  if (!username) return '' //todo: change later
  const usr = encodeURIComponent(username)
  return `https://res.cloudinary.com/ratebeer/image/upload/w_${size},h_${size},c_fill,d_Default_Profile_aoipvd.png,f_auto/user_${usr}`
}

export const generateBrewerImageUrl = (
  brewerId = '',
  width: string | number,
  height?: string | number
) => {
  return height
    ? `https://res.cloudinary.com/ratebeer/image/upload/w_${width},h_${height},c_fill,d_Default_Brewery_q4okin.png,f_auto/brew_${brewerId}`
    : `https://res.cloudinary.com/ratebeer/image/upload/w_${width},c_fill,d_Default_Brewery_q4okin.png,f_auto/brew_${brewerId}`
}

export const generatePlaceImageUrl = (
  placeId = '',
  width = '40',
  height?: string | number
) => {
  return height
    ? `https://res.cloudinary.com/ratebeer/image/upload/w_${width},h_${height},c_fill,d_Default_Places_fdcgpy.png,f_auto/plac_${placeId}`
    : `https://res.cloudinary.com/ratebeer/image/upload/w_${width},c_fill,d_Default_Places_fdcgpy.png,f_auto/plac_${placeId}`
}

export const formatLocation = ({ city, state, country }: any) => {
  const cityName = city && city.name ? city.name : city
  const stateName = state && state.name ? state.name : state
  const countryName = country && country.name ? country.name : country

  let firstLocation = cityName ? cityName : stateName ? stateName : null

  let secondLocation = countryName
    ? countryName
    : stateName && firstLocation !== stateName
    ? stateName
    : null

  if (
    secondLocation &&
    secondLocation.indexOf('United States') !== -1 &&
    stateName
  )
    secondLocation = stateName

  if (!secondLocation) {
    return firstLocation
  } else if (!firstLocation) {
    return secondLocation
  } else {
    return `${firstLocation}, ${secondLocation}`
  }
}

export const isInViewport = (element: HTMLElement) => {
  const bounds = element.getBoundingClientRect()
  return (
    bounds.top >= 0 &&
    bounds.left >= 0 &&
    bounds.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounds.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const categorizeStyle = (name: string) => {
  const styleName = name || ''
  return styleName.indexOf('Saké') !== -1 || styleName.indexOf('Sake') !== -1
    ? 'Sake'
    : styleName.indexOf('Mead') !== -1
    ? 'Mead'
    : styleName.indexOf('Cider') !== -1 || styleName.indexOf('Perry') !== -1
    ? 'Cider'
    : 'Beer'
}

export const capitalizeFirstLetter = (word: string) =>
  word ? `${word[0].toUpperCase()}${word.slice(1)}` : ''

export const toTitleCase = (words: string) =>
  words
    ? words.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )
    : ''

const isNumber = (n: any): n is number => !isNaN(parseFloat(n)) && isFinite(n)

export const parseBeerRoute = ({ params }: any) => {
  if (!params) return {}

  const { lang, part1, part2, part3 } = params
  const route: any = { parsed: {} }

  // /Brewer/BeerName/123
  if (!isNumber(part1) && !isNumber(part2) && isNumber(part3)) {
    route.parsed = { brewerName: part1, beerName: part2, beerId: part3 }
  }

  // /BeerName/123
  if (!isNumber(part1) && isNumber(part2)) {
    route.parsed = { beerName: part1, beerId: part2 }
    // Add userID if available: /BeerName/BeerID/UserID
    if (isNumber(part3)) route.parsed.userId = part3
  }

  if (lang) {
    return Object.assign({}, route.parsed, { lang })
  }

  return route.parsed
}

import Jar from './cookies'
export const captureError = (e: Error | string = '', action = '') => {
  const error = typeof e === 'string' ? new Error(e) : e

  console.error(e)

  const errorsToIgnore = [
    // user has connectivity problems
    'Network error: Failed to fetch',
    'Network error: The Internet connection appears to be offline',
    // adblock related issues
    'adsbygoogle',
    'googleads.g.doubleclick.net',
  ]

  const ignore = errorsToIgnore.some((err) => error.toString().includes(err))
  if (!ignore) {
    const session = Jar.get('session') || ''
    // Sentry.setTags({ session, action })
    // Sentry.captureException(error)
  }
}

export const makeSliderTickObject = (
  loopStart: any,
  loopEnd: any,
  stepCalcFunc: any,
  startingObj: any
) => {
  const sliderTickObject = startingObj || {}
  for (
    let hashMark = loopStart;
    hashMark <= loopEnd;
    hashMark = stepCalcFunc(hashMark)
  ) {
    sliderTickObject[hashMark.toString()] = {}
  }
  return sliderTickObject
}
