import { createStore } from 'lib/state-container'
import { sessionFromCookie } from 'tools/session'

export type User = {
  id: ID
  securityLevelId: number
  imageUrl?: string
  username?: string
  isPremiumMember?: boolean
  place?: {
    id: ID
  }
}

export type State = {
  user: null | User
  initialFetchComplete: boolean
}

export const initialState = {
  initialFetchComplete: false,
  user: sessionFromCookie() || null
}

export const { Provider, useStore } = createStore<State>()
