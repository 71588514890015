export * from './Provider'

export { intl } from './format'

import {
  Translation as TranslationType,
  TranslationKey as TranslationKeyType,
  IntlService as IntlServiceType
} from './constants'
export type Translation = TranslationType
export type TranslationKey = TranslationKeyType
export type IntlService = IntlServiceType

export const getLanguage = () =>
  navigator.language ||
  (navigator as any).userLanguage ||
  (navigator as any).browserLanguage ||
  (navigator as any).systemLanguage ||
  'en-US'
