// Add typescript support for the styled-components css prop (https://www.styled-components.com/docs/api#css-prop)
/// <reference types="styled-components/cssprop" />

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { initialize as initializeGdpr } from 'services/gdpr'

import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'Root'

// import { initializeSentry } from 'services/analytics/sentry'
import { onPrerender } from 'services/prerender'

initializeGdpr()

if (onPrerender()) {
  const styleTag = document.createElement('style')
  styleTag.id = 'prerender-styles'

  document.head.appendChild(styleTag)

  const originalInsertRule = CSSStyleSheet.prototype.insertRule

  CSSStyleSheet.prototype.insertRule = function(rule, ...rest) {
    const res = originalInsertRule.call(this, rule, ...rest)
    styleTag.appendChild(document.createTextNode(rule))
    return res
  }
// } else {
//   initializeSentry()
}

ReactDOM.render(<Root />, document.getElementById('root'))
