import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  createContext,
  useMemo,
  useContext
} from 'react'
import shallowEquals from 'shallowequal'

type Subscription = {
  unsubscribe: () => void
}

type Subscriber<S> = (state: S) => void

type Updater<S> = (state: S) => S
export type SetState<S> = (updater: Updater<S>) => void
type GetState<S> = () => S

type ContextValue<S> = {
  getState: () => S
  setState: SetState<S>
  subscribe: (fn: Subscriber<S>) => Subscription
}

type Selector<S, R> = (state: S) => R
type UseStore<S> = {
  <R extends any>(selector: Selector<S, R>): [R, SetState<S>, GetState<S>]
  (): [SetState<S>, GetState<S>]
}

let NEXT_SUBSCRIBER_ID = 1

export const createStore = <S extends any>() => {
  const Context = createContext((null as unknown) as ContextValue<S>)

  type ProviderProps = {
    initialState: S
  }

  const Provider: React.FunctionComponent<ProviderProps> = ({
    children,
    initialState
  }) => {
    const subscribersRef = useRef(new Map<number, Subscriber<S>>())
    const stateRef = useRef(initialState)
    const schedulerRef = useRef(null as null | number | NodeJS.Timer)

    const scheduleUpdate = useCallback(() => {
      if (schedulerRef.current) {
        return
      }

      schedulerRef.current = setTimeout(() => {
        schedulerRef.current = null
        subscribersRef.current.forEach(sub => {
          sub(stateRef.current)
        })
      }, 0)
    }, [])

    const setState = useCallback((updater: Updater<S>) => {
      const st =
        typeof updater === 'function' ? updater(stateRef.current) : updater

      stateRef.current = {
        ...stateRef.current,
        ...st
      }
      scheduleUpdate()
    }, [])

    return (
      <Context.Provider
        value={useMemo(
          () => ({
            subscribe: (subscriber: Subscriber<S>) => {
              const currentSubId = NEXT_SUBSCRIBER_ID++
              subscribersRef.current.set(currentSubId, subscriber)
              return {
                unsubscribe: () => subscribersRef.current.delete(currentSubId)
              }
            },

            getState: () => {
              return stateRef.current
            },

            setState
          }),
          []
        )}
      >
        {children}
      </Context.Provider>
    )
  }

  const useStore: UseStore<S> = (<R extends any>(selector?: Selector<S, R>) => {
    const { getState, subscribe, setState } = useContext(Context)

    if (!selector) {
      return [setState, getState]
    }

    const [selectedState, setSelectedState] = useState(
      useMemo(() => selector(getState()), [])
    )

    const stateRef = useRef(selectedState)
    stateRef.current = selectedState

    useEffect(() => {
      const subscription = subscribe(state => {
        const newSelectedState = selector(state)

        if (!shallowEquals(stateRef.current, newSelectedState)) {
          setSelectedState(newSelectedState)
        }
      })

      return () => subscription.unsubscribe()
    }, [])

    return [selectedState, setState, getState]
  }) as UseStore<S>

  return {
    Context,
    Provider,
    useStore
  }
}

export const createSubStore = <S, K extends keyof S>(
  useStore: UseStore<S>,
  key: K
): UseStore<S[K]> => (selector?: any): any => {
  const [subState, setState, getState] = useStore(state => selector(state[key]))

  const setSubState = (subUpdater: Updater<S[K]>) =>
    setState(state => ({
      ...state,
      [key]: subUpdater(state[key])
    }))

  const getSubState = () => getState()[key]

  return selector
    ? [subState, setSubState, getSubState]
    : [setSubState, getSubState]
}
