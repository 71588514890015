export default {
  "Account Settings": "Paramètres de compte",
  "Add description": "Ajouter une description",
  "Add location": "Ajouter un lieu",
  "Appearance": "Apparence",
  "Apply to sell with us": "S'inscrire pour rejoindre notre cercle de vendeurs",
  "Are you sure you want to delete your review?": "Voulez-vous vraiment supprimer votre avis ?",
  "Aroma": "Arôme",
  "Associated Brewer": "Brasserie associée",
  "Available here": "Dans cet établissement",
  "Available to buy from {count} trusted retail partners": "Disponible à l'achat auprès de {count} détaillant(s) de confiance",
  "Average": "Moyenne",
  "Beer name": "Nom de la bière",
  "Beer styles": "Types de bière",
  "Beers Spotted Here": "Bières à y déguster",
  "Bottles {bottlesCount}": "Bouteilles {bottlesCount}",
  "Buy from": "Acheter chez",
  "Cancel": "Annuler",
  "Community": "Communauté",
  "Copied to clipboard": "Copié dans le presse-papier",
  "Copy to clipboard": "Copier dans le presse-papier",
  "Country of origin": "Pays d'origine",
  "Events": "Événements",
  "Filter": "Filtrer",
  "Find a beer or brewer": "Trouver une bière ou une brasserie",
  "Find a country": "Trouver un pays",
  "Find a style": "Trouver un type",
  "Follow on Social Media": "Suivre sur les médias sociaux",
  "Forums": "Forums",
  "From {price} per {serve}": "À partir de {price} pour {serve}",
  "Latest Activity": "Activité récente",
  "Log In": "Connexion",
  "Log Out": "Déconnexion",
  "Manage Breweries Followed": "Gérer les brasseries suivies",
  "Manage People Followed": "Gérer les personnes suivies",
  "Mouthfeel": "Équilibre en bouche",
  "My Beer Cellar": "Ma cave à bières",
  "My Beer Ratings": "Mes bières notées",
  "My Beer Styles": "Mes types de bières",
  "My Breweries": "Mes brasseries",
  "My Countries & States": "Mes pays",
  "My Favorites": "Mes favoris",
  "My Place Ratings": "Mes lieux notés",
  "My Profile": "Mon profil",
  "New Releases": "Nouvelles sorties",
  "No results found for": "Aucun résultat trouvé pour {query}",
  "Overall": "Global",
  "Places": "Lieux",
  "Rate this beer on these attributes": "Notez les différentes caractéristiques de cette bière",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "RateBeer s'associe aux plus grands détaillants afin d'offrir aux amoureux de la bière une gamme incomparable de choix à des prix compétitifs.",
  "Ratings": "Notes",
  "Read more": "afficher plus",
  "Retailers": "Détaillants",
  "Review this place": "Évaluer ce lieu",
  "Save": "Enregistrer",
  "Search beers spotted here": "Rechercher les bières à y déguster",
  "Search for Beers, Breweries, or Bars...": "Recherchez des bières, des brasseries ou des bars…",
  "Seasonal beer": "Bière de saison",
  "Shop now": "Acheter",
  "Sign Up": "Inscription",
  "Similar beers": "Bières similaires",
  "Taps {tapsCount}": "Pression {tapsCount}",
  "Taste": "Goût",
  "The most comprehensive ratings and reviews of beers from around the world": "La plateforme d'avis et de notations de bières du monde entier la plus complète.",
  "This score could not be updated": "Impossible de mettre à jour le score",
  "This score has been updated": "Le score a été mis à jour",
  "Total score": "SCORE TOTAL",
  "View beer menu": "Afficher la carte des bières",
  "View food menu": "Voir le menu",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Consulter les offres de {count} {count, plural, one {retailer} other {retailers}}",
  "We apologise for the delay you are experiencing.": "Nous nous excusons pour les délais que vous rencontrez.",
  "We are currently updating the website and expect this page to be back shortly.": "Nous procédons actuellement à la mise à jour du site Web ; cette page sera de nouveau disponible sous peu.",
  "Your review has been updated": "Votre avis a été mis à jour",
  "add.abv": "TAV en %",
  "add.abvPlaceholder": "(p. ex. 11 %)",
  "add.addBeer": "Ajouter une nouvelle bière",
  "add.addBrewery": "Ajouter une nouvelle brasserie",
  "add.autumn": "Automne",
  "add.beerGuidelines": "Indications",
  "add.beerGuidelines1": "Les bières doivent être commercialisées et en production.",
  "add.beerGuidelines2": "Leur recette doit être unique. Aucune bière en fût, à l'azote ni aucune variante de recette ne sera acceptée.",
  "add.beerGuidelines3": "Les cidres infusés aux fruits doivent être préparés à partir d'au moins 50 % de pommes fermentées et contenir plus de 2 % d'alcool.",
  "add.beerGuidelines4": "Le nom du producteur doit être précéder celui de la bière. On écrira par exemple {correctExample} et non {incorrectExample}.",
  "add.beerImage": "Ajouter une image",
  "add.beerName": "Nom de la bière",
  "add.beerNameExplanation": "Selon la convention de dénomination RateBeer, il vous faut inclure le nom de la brasserie et celui de la bière.",
  "add.beerNameInfo": "(Inclure celui du brasseur)",
  "add.beerNamePlaceholder": "(p. ex. Elysian Space Dust)",
  "add.breweryName": "Nom de la brasserie",
  "add.breweryNamePlaceholder": "(p. ex. Elysian)",
  "add.contractBrewer": "Brassé par",
  "add.contractBrewerPlaceholder": "Rechercher une brasserie...",
  "add.contractBrewerPrompt": "Il s'agit d'une bière à façon",
  "add.description": "Description du brasseur",
  "add.descriptionPlaceholder": "Texte de l'étiquette",
  "add.duplicateFound": "Duplicate Found",
  "add.duplicateMessage": "Is this the beer you're trying to add?",
  "add.ibuPlaceholder": "(p. ex. 57)",
  "add.imageInstructions": "*Utiliser des images de canettes ou de bouteilles",
  "add.invalid": "Non valide",
  "add.newBeer": "Ajouter une nouvelle bière",
  "add.noBrewery": "Vous ne trouvez pas votre brasserie ?",
  "add.optional": "Facultatif",
  "add.removeBeerImage": "Supprimer l'image",
  "add.seasonalPlaceholder": "S'agit-il d'une bière de saison ?",
  "add.series": "Variante d'une gamme",
  "add.signInToAddBeer": "Connectez-vous pour ajouter une bière",
  "add.special": "Édition spéciale",
  "add.spring": "Printemps",
  "add.stylePlaceholder": "Sélectionner un style...",
  "add.summer": "Été",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Chiffres du code barre)",
  "add.upcInstructions": "Inclure tous les chiffres (8, 12, 13 ou 14)",
  "add.upcPlaceholder": "Tous les chiffres du code barre",
  "add.view": "View",
  "add.winter": "Hiver",
  "all.adblock": "Il semblerait qu'un bloqueur de publicités soit installé. Veuillez ajouter RateBeer.com à vos exceptions. Merci !",
  "all.ago": "Il y a {date}",
  "all.back": "Back",
  "all.beer": "Bière",
  "all.beers": "Bières",
  "all.cider": "Cidre",
  "all.ciders": "Cidres",
  "all.cookies": "Nous utilisons des cookies",
  "all.error": "Désolé, une erreur réseau s'est produite",
  "all.joinBody": "Profitez de tous les avantages de RateBeer : nous vous proposons des avis complets et des statistiques détaillées. De plus, vous pourrez consulter tout ce que la communauté partage.",
  "all.joinTitle": "Rejoignez notre communauté !",
  "all.learnMore": "En savoir plus",
  "all.loading": "Chargement",
  "all.mead": "Hydromel",
  "all.meads": "Hydromels",
  "all.noSuchBeer": "Aucune bière correspondante dans la base de données. Veuillez rechercher une autre bière.",
  "all.ok": "OK",
  "all.sake": "Saké",
  "all.sakes": "Sakés",
  "banner.free": "Gratuite – Google Play",
  "banner.getIt": "Télécharger",
  "banner.officialApp": "Application officielle",
  "beer.aboutBeer": "À propos de cette Bière",
  "beer.aboutCider": "À propos de cette Cidre",
  "beer.aboutMead": "À propos de cette Hydromel",
  "beer.aboutSake": "À propos de cette Saké",
  "beer.abv": "(Teneur en alcool)",
  "beer.aka": "Aussi connue sous le nom",
  "beer.aliasBeer": "Bière avec alias",
  "beer.aliasCopy": "Le brasseur commercialise ce même produit ou un produit extrêmement similaire sous différents noms. Il se peut que le brasseur distribue cette bière sous un nom différent dans chaque pays, ou qu'il ait décidé à un moment donné d'en changer le nom sans changer la recette.",
  "beer.allStyles": "Tous les types de bières pour ",
  "beer.appearance": "Apparence",
  "beer.applyToSellWithUs": "Inscrivez-vous pour rejoindre notre cercle de vendeurs",
  "beer.aroma": "Arôme",
  "beer.autumn": "Automne",
  "beer.availability": "Disponibilité",
  "beer.availableAt": "Disponible dans",
  "beer.averageRatings": "Notes moyennes",
  "beer.bottled": "Bouteille",
  "beer.brewedBy": "Brassé par ",
  "beer.brewedFor": "Brassé pour ",
  "beer.buyFrom": "Acheter chez",
  "beer.calories": "(kcal estimées)",
  "beer.clearRating": "Annuler",
  "beer.copy": "Copier",
  "beer.delete": "Supprimer la bière/note",
  "beer.deleteAlias": "Supprimer l'alias",
  "beer.deleteConfirm": "Oui, supprimer",
  "beer.deleteOption": "Supprimer",
  "beer.deleteRating": "Supprimer la note ?",
  "beer.distribution": "Distribution",
  "beer.edit": "Modifier",
  "beer.editAlias": "Ajouter/Modifier l'alias",
  "beer.editBarcodes": "Ajouter/Modifier le code-barres",
  "beer.editBeer": "Modifier cette bière",
  "beer.editPicture": "Ajouter/Modifier l'image",
  "beer.editTags": "Ajouter/Modifier Tags",
  "beer.findFriendsText": "Trouvez vos amis pour leur faire part de vos dégustations et découvrir les leurs",
  "beer.findFriendsTitle": "Trouver des amis",
  "beer.findIt": "Rechercher",
  "beer.findItNearYou": "Rechercher à proximité",
  "beer.fix": "Corriger le score",
  "beer.flavor": "Goût",
  "beer.from": "de ",
  "beer.highestScore": "Notes les plus élevées",
  "beer.howItWorks": "Fonctionnement",
  "beer.howItWorksContent": "Nous travaillons dur pour vous proposer les meilleurs détaillants, offrant un grand choix de bières à des prix compétitifs, et vous permettre ainsi de trouver votre bonheur",
  "beer.ibu": "(IBU)",
  "beer.locateSellers": "Trouver un distributeur",
  "beer.locations": "{count} {count, plural, one {établissement} other {établissements}}",
  "beer.locationsNearby": "{count} {count, plural, one {établissement à proximité} other {établissements à proximité}}",
  "beer.mostLiked": "Avis les plus populaires",
  "beer.mostRecent": "Avis les plus récents",
  "beer.mouthfeel": "Palais",
  "beer.moveRating": "Déplacer la note",
  "beer.myFriends": "Mes amis",
  "beer.myReview": "Mon avis",
  "beer.noDescription": "Aucune description disponible",
  "beer.noRating": "Aucune note",
  "beer.noReviewsText": "Dites au monde entier ce que vous en pensez. Soyez le premier à l'évaluer !",
  "beer.noReviewsTitle": "Vous avez essayé cette bière ?",
  "beer.onTap": "Pression",
  "beer.outOfProd": "HORS PRODUCTION",
  "beer.overall": "Global",
  "beer.per": "pour",
  "beer.privacyNotice": "En cliquant sur le ou les liens de la boutique ci-dessus, vous serez redirigé vers le site Web du détaillant et à partir de là, sa politique de confidentialité s'appliquera par la suite.",
  "beer.private": "PRIVÉ",
  "beer.quickRate": "Note rapide",
  "beer.quickRateSaved": "Notes rapides enregistrées",
  "beer.quickRated": "Note rapide : ",
  "beer.rateAndReview": "Noter et évaluer",
  "beer.rateIt": "Vous l'avez essayée ? Notez-la !",
  "beer.ratedBy": "Notée par",
  "beer.ratingPrivate": "Note privée",
  "beer.ratingPublic": "Note publique",
  "beer.readMore": "afficher plus",
  "beer.reviews": "Avis",
  "beer.reviewsRatings": "Avis et notes",
  "beer.saveRating": "Enregistrez cette note !",
  "beer.saveRatingText": "Créez un compte pour enregistrer vos notes, lire des avis d'experts, consulter des statistiques détaillées sur les avis et notes, et bien plus encore.",
  "beer.seasonal": "Saison",
  "beer.seasonalLabel": "SAISON",
  "beer.seeAll": "Tout afficher",
  "beer.seeAllXReviews": "Afficher les {count} avis",
  "beer.sendCorrections": "Envoyer les modifications",
  "beer.series": "Variante d'une gamme",
  "beer.serveIn": "À servir dans",
  "beer.share": "Partager",
  "beer.shareTutorial": "Partager, envoyer des corrections, ajouter des images",
  "beer.shelfTags": "Voir le résumé des avis",
  "beer.shopNow": "Acheter",
  "beer.showLess": "afficher moins",
  "beer.signInToFavorite": "Connectez-vous pour ajouter cette bière à vos favoris",
  "beer.signInToFind": "Connectez-vous pour trouver cette bière.",
  "beer.signInToLikeReview": "Connectez-vous pour aimer cet avis",
  "beer.similarBeer": "Bière similaire",
  "beer.similarCider": "Cider similaire",
  "beer.similarMead": "Hydromel similaire",
  "beer.similarSake": "Sake similaire",
  "beer.special": "Édition spéciale",
  "beer.spring": "Printemps",
  "beer.style": "Type ",
  "beer.summer": "Été",
  "beer.tags": "Mots-clés",
  "beer.top50": "Top 50",
  "beer.topRaters": "Utilisateurs les plus actifs",
  "beer.totalRetailers": "Cette bière est vendue par {count} vendeurs tiers vérifiés",
  "beer.undoRating": "Supprimer la note",
  "beer.unrateable": "Impossible d'évaluer cette bière",
  "beer.verified": "VÉRIFIÉ",
  "beer.winter": "Hiver",
  "beer.writeAReview": "Rédiger un avis",
  "beer.writeFullReview": "Rédiger un avis complet",
  "beer.yourReview": "Votre avis",
  "error.refresh": "Rafraîchir",
  "favorites.instructions": "Lorsque vous aimez une bière, appuyez sur le bouton d'ajout à vos favoris pour l'enregistrer et la retrouver plus tard. Vous pouvez ainsi voir vos bières enregistrées au même endroit depuis tous vos appareils.",
  "favorites.noSavedBeers": "Vous n'avez enregistré aucune bière",
  "favorites.remove": "Supprimer",
  "favorites.removeConfirmation": "Oui, supprimer",
  "favorites.removePrompt": "Supprimer des favoris ?",
  "favorites.saved": "Enregistré",
  "favorites.savedConfirmation": "Enregistré dans les favoris",
  "favorites.signInToViewFavorites": "Connectez-vous pour ajouter cette bière à vos favoris",
  "favorites.viewAll": "Tout afficher",
  "feedback.android": "Appli mobile Android",
  "feedback.androidReproduceInfo": "(par ex., se connecter, rechercher par code-barres scanné, appuyer sur le résultat, ouvrir le panneau de notation par les experts, envoyer une note d'expert)",
  "feedback.bug": "Signaler un bug",
  "feedback.bugMessageInfo": "(Quels résultats attendiez-vous ? Qu'avez-vous expérimenté ?)",
  "feedback.bugMessagePrompt": "Récapitulatif du bug",
  "feedback.buildInfo": "(par ex., version 1.7.0. Pour trouver cette information, appuyez sur le point d'interrogation situé dans le coin supérieur droit de l'écran d'accueil)",
  "feedback.buildPrompt": "Version de l'application",
  "feedback.categoryPrompt": "Que souhaitez-vous faire ?",
  "feedback.correction": "Envoyer une modification",
  "feedback.correctionMessageInfo": "(De quelle façon pensiez-vous trouver cette information ? Une information erronée est-elle actuellement affichée ?)",
  "feedback.correctionMessagePrompt": "Récapitulatif de la modification",
  "feedback.email": "Adresse e-mail",
  "feedback.header": "Formulaire de commentaires",
  "feedback.iOS": "Appli mobile iOS",
  "feedback.iOSReproduceInfo": "(par ex., se connecter, rechercher par code-barres scanné, appuyer sur le résultat, ouvrir le panneau de notation par les experts, envoyer une note d'expert)",
  "feedback.mediumPrompt": "Où avez-vous rencontré ce problème ?",
  "feedback.reproducePrompt": "Que pouvons-nous faire pour reproduire cette erreur ?",
  "feedback.submit": "Envoyer",
  "feedback.submitted": "Merci de nous avoir fait part de vos commentaires !",
  "feedback.suggestion": "Faire une suggestion",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Que souhaitez-vous voir sur RateBeer ?",
  "feedback.url": "URL",
  "feedback.username": "Identifiant",
  "feedback.web": "Site Web",
  "feedback.webReproduceInfo": "(par ex., se connecter, se rendre sur ratebeer.com/community, cliquer sur le lien dans la barre latérale)",
  "footer.FAQs": "FAQ",
  "footer.RBBest": "RateBeer Best",
  "footer.TOS": "Conditions générales",
  "footer.aboutRateBeer": "À propos de RateBeer",
  "footer.aboutUs": "Qui sommes-nous",
  "footer.account": "Compte",
  "footer.addBeer": "Ajouter une bière",
  "footer.addBrewer": "Ajouter une brasserie",
  "footer.addEvent": "Ajouter un événement",
  "footer.addPlace": "Ajouter un lieu",
  "footer.addPremium": "Passer Premium",
  "footer.addToRB": "Ajouter à RateBeer",
  "footer.contactUs": "Nous contacter",
  "footer.copyright": "Tous droits réservés.",
  "footer.developers": "Développeurs",
  "footer.editAccount": "Modifier le compte",
  "footer.privacy": "Politique de confidentialité",
  "footer.theBest": "Le meilleur",
  "footer.top50Beers": "Classement des 50 meilleures bières",
  "footer.topReviewers": "Évaluateurs les plus actifs",
  "glasscopy1": "La flûte est grande, mince et a un pied court. Elle est délicate et met en valeur le corps de la bière. Ce verre est idéal pour les bières légères et pétillantes.",
  "glasscopy10": "Le chardon est un verre peu commun que les Belges utilisent pour les bières de style scotch ale. Le chardon ressemble à la fleur du même nom, un fond en bulbe avec une ouverture droite en pente. Il est difficile d'y boire, comme un verre à martini et les arômes se dissipent rapidement par la grande ouverture.",
  "glasscopy11": "Un verre étiré d'environ 1m de long avec une grande ouverture et un pied sphérique. Ce verre nécéssite un pied pour le tenir, le meilleur exemple commercial provient de la brasserie Belge Pauwel Kwak.",
  "glasscopy12": "Ce verre allemand typique pour les bières de blé est plutôt grand avec une ouverture en bulbe vers le haut, une section plus mince pour avoir une bonne prise et un pied légèrement plus évasé. La forme permet de contenir beaucoup de col et laisse passer différentes quantités de lumière ce qui donne un effet visuel agréable. Ce verre réchauffe rapidement son contenu alors santé!",
  "glasscopy13": "Version généralement plus courte et stylisée de la pinte américaine. Le verre Hoegaarden est l'exemple le plus reconnu.",
  "glasscopy14": "Un bol a la forme cyclindrique et trapue avec les rebords droits ou légèrement en angle. Il a pour qualité de bien contenir une mousse épaisse et généreuse. Ceux sur pied on souvent un fond arrondi et ressemblent plus au calice.",
  "glasscopy15": "Le calice est le verre de choix pour les bières trappistes. Ce magnifique verre sur pied en forme de grand balon met parfaitement en valeur le col généreux des bières belges et fourni une grande surface de contact à l'air pour libérer les arômes.",
  "glasscopy16": "Verre droit et cylindrique au bords légèrement en pente mais sur pied. Les exemples classiques sont les verres de Celebrator et Harvey Porter",
  "glasscopy17": "Verre sur pied en forme de ballon qui rétrécit vers le haut. Plusieurs le choisissent pour sa capacité sans égal à concentrer les arômes de la bière. Il est souvent utilisé en petit format pour la dégustation.",
  "glasscopy18": "Un petit gobelet de plusieurs formes variées. Les goblets possèdent généralement une grande ouverture pour laisser les arômes du saké se volatiliser doucement.",
  "glasscopy19": "Petit gobelet à sake, plus grand que le o-choko et généralement destiné à la consommation personnelle.",
  "glasscopy2": "Le verre à lager est généralement court et contient environ 355ml de bière. Il est légèrement plus ouvert au rebord qu'à la base avec une cheminée légèrement en angle. Excellent verre de base, il est bien adapté aux lagers blondes.",
  "glasscopy20": "Gobelet en bois de forme cubique, il servait autrefois à mesurer le riz. Il n'est plus très populaire car ses arômes de bois dominent les saveurs délicates des sake fins d'aujourd'hui.",
  "glasscopy21": "Habituellement utilisé pour camoufler une bouteille de bière forte en alcool et de mauvaise qualité. Le sac en papier est parfois utiliser dans le marketing de certains styles de bière malfamés.",
  "glasscopy3": "Conçu pour être vidé en quelques gorgées, ce verre permet l'obtention d'une mousse généreuse. Il n'est pas très efficace pour laisser les aromes subtiles se développer.",
  "glasscopy4": "Le verre étalon en Amérique. Elle possède une ouverture légèrement plus grande que sa base pour les bières qui se boivent en quantité. Typique pour les pale ales américaines et anglaises.",
  "glasscopy5": "Similaire à la pinte américaine, la pinte nonic a un rebord droit et un petit renflement près du haut qui aide la rétention des arômes. Ces verres contiennent généralement une pinte anglaise.",
  "glasscopy6": "La chope est un incontournable des verres à bière. Elle n'a pas de pied, est généralement de forme arrondie, avec une grand ouverture et une poignée solide. Les alvéoles rendent plus difficile l'appréciation de la robe mais sa forme et son ouverture rendent justice aux arômes.",
  "glasscopy7": "Les mass de Bavière sont parmis les verres les plus décorés. Elles sont généralement faites de terre cuite et sont ornées de paysages, de chateaux ou de villages. Il est impossible d'avoir une appréciation visuelle de sa bière dans une mass en terre cuite mais elles sont en soi très jolies. L'aspect aromatique de la bière souffre aussi de ce choix de matériau mais le goût demeure inégalé. À utiliser pour toutes les lagers traditionnelles.",
  "glasscopy8": "Un petit verre au bords presque droits sur un pied très court. Le verre à pilsner met en valeur l'apparence de la bière plus que l'arôme. Il est un peu plus stylisé que plusieurs autres verres et met ainsi en valeur les bonnes blondes.",
  "glasscopy9": "La tulipe est la préférée des grands dégustateurs. Le verre de la brasserie Duvel sert souvent de référence au style, le logo de RateBeer en est un parfait exemple!",
  "header.my2017": "Mon année dans la bière",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Meilleures bières par type",
  "home.Top Rated Beers Nearby": "Bières les mieux notées à proximité",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Téléchargez l'application",
  "home.downloadBlurb": "Accédez à RateBeer où que vous soyez ! Nous vous proposons des fonctionnalités pour vous aider à trouver de nouvelles bières que vous adorerez.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Message envoyé!",
  "home.porter": "Porter",
  "home.retailers": "Détaillants",
  "home.saison": "Saison",
  "home.searchPrompt": "Trouvez des bières, brasseries ou bars…",
  "home.shopOnline": "Acheter en ligne",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Lien de texte",
  "home.top50Beers": "Classement des 50 meilleures bières",
  "home.worldOfBeer": "Votre guide du monde de la bière",
  "landing.TOS": "Conditions générales",
  "landing.feedback": "Ceci n'est que la première mouture d'application RateBeer.{br}Aidez-nous à l'améliorer !",
  "landing.h1": "Le site Web le plus populaire pour noter ses bières{br}possède désormais son application !",
  "landing.h2": "Vous pouvez maintenant ajouter{br}une note en toute simplicité.",
  "landing.label": "notes",
  "landing.li1": "Recherchez notre base de données de plus de 500 000 bières",
  "landing.li2": "Notez, évaluez et partagez",
  "landing.li3": "Gardez une trace de toutes les bières que vous avez goûtées",
  "landing.privacy": "POLITIQUE DE CONFIDENTIALITÉ",
  "landing.review": "RateBeer est la source ultime pour trouver des informations sur des bières ou des brasseries sur Internet.",
  "landing.rightsReserved": "Tous droits réservés.",
  "landing.submit": "Envoyer les commentaires",
  "landing.visit": "Rendez-vous sur ratebeer.com",
  "place.addBeerIds": "Ajouter des ID de bière",
  "place.addBeers": "Ajouter des bières",
  "place.addEvent": "Ajouter un événement",
  "place.ambiance": "Ambiance",
  "place.available": "Disponible",
  "place.beerMenu": "Carte des bières",
  "place.close": "Fermer",
  "place.customizeShelftags": "Personnaliser les étiquettes en rayon",
  "place.editBeers": "Modifier des bières",
  "place.editPlace": "Modifier le lieu",
  "place.flights": "Vols",
  "place.food": "Restauration",
  "place.foodMenu": "Menu",
  "place.getDirection": "Obtenir l'itinéraire",
  "place.hours": "Heures",
  "place.lessInfo": "Afficher moins",
  "place.map": "Carte",
  "place.moreInfo": "Plus d'infos",
  "place.overall": "Global",
  "place.phone": "Numéro de téléphone",
  "place.printShelftags": "Imprimer les étiquettes en rayon",
  "place.selection": "Sélection",
  "place.sendToPhone": "Envoyer sur le téléphone",
  "place.service": "Service",
  "place.shelftags": "Étiquettes en rayon",
  "place.showBreakdown": "Afficher le détail du score",
  "place.value": "Valeur",
  "place.wifi": "Wi-Fi gratuit",
  "rate.BOTTLE": "Bouteille",
  "rate.CAN": "Canette",
  "rate.CASK": "Fût",
  "rate.TAP": "Pression",
  "rate.commentPlaceholder": "Ajouter des remarques sur le goût, une description, etc.",
  "rate.infoSubtitle": "Afin de garantir la qualité des scores associés aux bières, nous publierons uniquement les avis détaillés.",
  "rate.locationPlaceholder": "Ajouter un lieu",
  "rate.nearbyPlaces": "Établissements à proximité",
  "rate.ourRatingSystem": "Notre système de notation",
  "rate.private1": "Entre 0 et 74 caractères",
  "rate.private2": "Conservé pour une utilisation personnelle",
  "rate.private3": "N'influence pas le score général d'une bière",
  "rate.privateLabel": "PRIVÉE",
  "rate.public1": "Plus de 75 caractères ",
  "rate.public2": "Influence le score général d'une bière",
  "rate.public3": "Visible pour la communauté",
  "rate.publicLabel": "PUBLIQUE",
  "rate.save": "Enregistrer",
  "rate.searchResults": "Résultats",
  "rate.servedIn": "À servir dans ",
  "rate.totalScore": "SCORE TOTAL",
  "search.advanced": "Recherche avancée",
  "search.beerCaps": "BIÉRES",
  "search.beerHeader": "Bières",
  "search.beerSearchFor": "Saisissez votre recherche dans la barre pour trouver des bières",
  "search.brewersCaps": "BRASSERIES",
  "search.brewersHeader": "Brasseries",
  "search.brewersSearchFor": "Saisissez votre recherche dans la barre pour trouver des brasseries",
  "search.closestMatch": "Résultat le plus proche",
  "search.for": "Rechercher",
  "search.in": "dans",
  "search.mostRatings": "Le plus de notes",
  "search.myRating": "Ma note :",
  "search.noResults": "Aucun résultat pour ",
  "search.pCaps": "LIEUX",
  "search.pHeader": "Lieux",
  "search.pSearchFor": "Saisissez votre recherche dans la barre pour trouver des lieux",
  "search.resultFor": "résultat pour",
  "search.resultLabelStr": "Notes",
  "search.resultsFor": "résultats pour",
  "search.sortBy": "Trier par",
  "search.sortByCaps": "TRIER PAR",
  "search.topRated": "Les mieux notées",
  "search.userHeader": "Utilisateurs",
  "search.userSearchFor": "Saisissez votre recherche dans la barre pour trouver des utilisateurs"
}
