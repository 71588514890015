import React, { Fragment } from 'react'
import { Provider, initialState } from './store'
import { Spinner } from 'lib/components'
import { noopTemplate as css } from 'lib/utils'
import { useGetGeoLocation } from './actions'
const StateContainer: React.FunctionComponent = ({ children }) => {
  const loading = useGetGeoLocation()

  if (loading)
    return (
      <div
        css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
        className="fd-r fd-c fj-c"
      >
        <Spinner />
      </div>
    )

  return <Fragment>{children}</Fragment>
}

const Wrapper: React.FunctionComponent = ({ children }) => {
  return (
    <Provider initialState={initialState}>
      <StateContainer>{children}</StateContainer>
    </Provider>
  )
}

export default Wrapper
