import React, { Fragment } from 'react'
import { Text, Button, A, Link, Head } from 'lib/components'
import { noopTemplate as css } from 'lib/utils'
import GlobalStyles from 'styles/global/util'

import { useSetErrorPage } from 'services/error-page'

const Page404: React.FC = () => {
  const setErrorPage = useSetErrorPage()

  return (
    <Fragment>
      <Head>
        <meta name="prerender-status-code" content="404" />
      </Head>

      <div
        className="fd-c fj-c fa-c p-5"
        css={css`
          height: 100vh;
        `}
      >
        <div>
          <img
            src="/styles/spill.png"
            css={css`
              flex-shrink: 0;
              flex-grow: 0;
            `}
          />
        </div>
        <Text variant="h5" align="center">
          We cannot find the page you’re looking for.
        </Text>

        <Text className="mt-3" align="center">
          This page may no longer exist or may have been moved.
        </Text>

        <Link to="/" onClick={() => setErrorPage(null)}>
          <Button variant="contained" className="mt-4">
            Go Back Home
          </Button>
        </Link>
      </div>

      <GlobalStyles />
    </Fragment>
  )
}

export default Page404
