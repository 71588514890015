import React from 'react'
import { FormattedMessage } from 'react-intl'
import { theme } from 'theme'
import { noopTemplate as css } from 'lib/utils'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import { colorized } from './tools/colorized'
import { Translation } from 'services/intl'

import { classNames } from 'lib/utils'

export type TextProps<P> = FlexibleRootComponent<
  Omit<TypographyProps, 'variant'> & {
    variant?: TypographyProps['variant'] | 'h7' | 'note'
    bold?: boolean
    underline?: boolean
  } & Partial<Translation>,
  P
>

const TypographyTypeless = Typography as any

const Text = <P extends any>({
  msgId,
  children,
  inline,
  component,
  className,
  color,
  bold,
  underline,
  values,
  variant = 'body2',
  ...typographyProps
}: TextProps<P>) => {
  return (
    <TypographyTypeless
      css={css`
        ${variant === 'subtitle2' ? 'line-height: 20px;' : ''}
        ${variant === 'caption' ? 'line-height: 16px;' : ''}
        ${variant === 'overline' ? 'font-size: 10px;  line-height: 1.5;' : ''}
        ${
          variant === 'h7'
            ? css`
                font-size: 18px;
                font-family: 'Fira Sans', 'sans serif' !important;
              `
            : ''
        }
        ${
          variant === 'h5'
            ? css`
                font-size: 24px;
                font-family: 'Fira Sans', 'sans serif' !important;
              `
            : ''
        }
        ${underline ? 'text-decoration: underline' : ''}
        ${
          variant === 'note'
            ? css`
                color: ${color || theme.colors.textTertiary};
                line-height: 16px;
                font-size: 10px;
              `
            : ''
        }
      `}
      {...typographyProps}
      color={undefined}
      variant={
        variant === 'h7' ? 'h6' : variant === 'note' ? 'caption' : variant
      }
      className={classNames(
        bold === undefined ? undefined : bold ? 'text-500' : 'text-400',
        className
      )}
      inline={inline}
      component={component || (inline ? 'span' : 'div')}
    >
      {msgId ? <FormattedMessage id={msgId} values={values} /> : children}
    </TypographyTypeless>
  )
}

export default colorized()(Text)
