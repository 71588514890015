import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Upload: any;
};

export type GAbvRange = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type GAction = GReview | GCheckin;

export type GActionList = {
   __typename?: 'ActionList';
  items: Array<Maybe<GAction>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GAddBrewerInput = {
  data: GAddBrewerInputData;
};

export type GAddBrewerInputData = {
  brewerTypeId?: Maybe<Scalars['ID']>;
  brewerStateId?: Maybe<Scalars['ID']>;
  brewerCountryId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  adminNotes?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  metroId?: Maybe<Scalars['ID']>;
};

export type GAddBrewerPayload = {
   __typename?: 'AddBrewerPayload';
  brewer: GBrewer;
};

export type GAddPlaceInput = {
  data: GAddPlaceInputData;
};

export type GAddPlaceInputData = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  metroId?: Maybe<Scalars['ID']>;
  placeType?: Maybe<Scalars['ID']>;
  countryId?: Maybe<Scalars['ID']>;
  beerMenuURL?: Maybe<Scalars['String']>;
  foodMenuURL?: Maybe<Scalars['String']>;
  taps?: Maybe<Scalars['String']>;
  bottles?: Maybe<Scalars['String']>;
  wifi?: Maybe<Scalars['Boolean']>;
  singles?: Maybe<Scalars['Boolean']>;
  mailOrder?: Maybe<Scalars['Boolean']>;
  mailOrderIntl?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Scalars['Boolean']>;
  seasonals?: Maybe<Scalars['Boolean']>;
  patio?: Maybe<Scalars['Boolean']>;
  realAles?: Maybe<Scalars['Boolean']>;
  growlers?: Maybe<Scalars['Boolean']>;
  glassware?: Maybe<Scalars['Boolean']>;
  brewerId?: Maybe<Scalars['ID']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

export type GAddPlacePayload = {
   __typename?: 'AddPlacePayload';
  place: GPlace;
};

export enum GAllowedEntitiesToAssociateUserWith {
  BREWER = 'BREWER',
  PLACE = 'PLACE'
}

export type GAssociateUserWithEntityInput = {
  userId: Scalars['ID'];
  entityId: Scalars['ID'];
  entity: GAllowedEntitiesToAssociateUserWith;
};

export type GAssociateUserWithEntityPayload = {
   __typename?: 'AssociateUserWithEntityPayload';
  user: GUser;
};

export type GBeer = {
   __typename?: 'Beer';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<GPublicUser>;
  updatedBy?: Maybe<GPublicUser>;
  description?: Maybe<Scalars['String']>;
  brewer?: Maybe<GBrewer>;
  contractBrewer?: Maybe<GBrewer>;
  abv?: Maybe<Scalars['Float']>;
  ibu?: Maybe<Scalars['Int']>;
  calories?: Maybe<Scalars['Int']>;
  isAlias?: Maybe<Scalars['Boolean']>;
  isOneOff?: Maybe<Scalars['Boolean']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  isUnrateable?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<GBeerLabel>>>;
  seasonal?: Maybe<GBeerSeason>;
  styleScore?: Maybe<Scalars['Float']>;
  overallScore?: Maybe<Scalars['Float']>;
  /** @deprecated Please use normalizedAverageReview instead */
  averageRating?: Maybe<Scalars['Float']>;
  /** @deprecated Please use averageReview instead */
  realAverage?: Maybe<Scalars['Float']>;
  normalizedAverageReview?: Maybe<Scalars['Float']>;
  averageReview?: Maybe<Scalars['Float']>;
  averageQuickRating?: Maybe<Scalars['Float']>;
  /** @deprecated Please use ratingsCount or reviewsCount instead */
  ratingCount?: Maybe<Scalars['Int']>;
  ratingsCount?: Maybe<Scalars['Int']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  style: GBeerStyle;
  imageUrl: Scalars['String'];
  availability: GBeerAvailability;
  /** @deprecated Please use purchaseOptions instead */
  purchase?: Maybe<GBeerPurchaseInfo>;
  purchaseOptions: GBeerPurchaseInfoList;
  confidence?: Maybe<Scalars['Float']>;
  adminNotes?: Maybe<Scalars['String']>;
};


export type GBeerpurchaseOptionsArgs = {
  options?: Maybe<GPurchaseOptionsCondition>;
};

export type GBeerAvailability = {
   __typename?: 'BeerAvailability';
  bottle: Scalars['String'];
  tap: Scalars['String'];
  distribution: Scalars['String'];
};

export type GBeerBarcode = {
   __typename?: 'BeerBarcode';
  id: Scalars['ID'];
  author?: Maybe<GPublicUser>;
  beer: GBeer;
  barcode: Scalars['String'];
};

export type GBeerBarcodeList = {
   __typename?: 'BeerBarcodeList';
  items: Array<Maybe<GBeerBarcode>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GBeerInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  abv?: Maybe<Scalars['Float']>;
  ibu?: Maybe<Scalars['Int']>;
  brewerId: Scalars['ID'];
  contractBrewerId?: Maybe<Scalars['ID']>;
  styleId: Scalars['ID'];
  seasonal?: Maybe<GBeerSeason>;
  verified?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

export enum GBeerLabel {
  top50style = 'top50style'
}

export type GBeerList = {
   __typename?: 'BeerList';
  items: Array<GBeer>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GBeerLog = {
   __typename?: 'BeerLog';
  id: Scalars['ID'];
  author: GPublicUser;
  beer: GBeer;
  createdAt: Scalars['Date'];
};

export type GBeerLogList = {
   __typename?: 'BeerLogList';
  items: Array<Maybe<GBeerLog>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GBeerPurchaseInfo = {
   __typename?: 'BeerPurchaseInfo';
  store: GBeerStore;
  productId: Scalars['ID'];
  productUrl: Scalars['String'];
  bottleSize?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  priceValue: Scalars['Float'];
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
};

export type GBeerPurchaseInfoList = {
   __typename?: 'BeerPurchaseInfoList';
  items: Array<GBeerPurchaseInfo>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export enum GBeerSeason {
  UNKNOWN = 'UNKNOWN',
  WINTER = 'WINTER',
  SPRING = 'SPRING',
  SUMMER = 'SUMMER',
  AUTUMN = 'AUTUMN',
  SPECIAL = 'SPECIAL',
  SERIES = 'SERIES'
}

export type GBeerShopSearchCondition = {
  query?: Maybe<Scalars['String']>;
  retailerIds?: Maybe<Array<Scalars['ID']>>;
  beerStyleIds?: Maybe<Array<Scalars['ID']>>;
  originCountryIds?: Maybe<Array<Scalars['ID']>>;
  scoreRanges?: Maybe<Array<GScoreRange>>;
  latlng: Array<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order: GBeerShopSearchResultOrder;
};

export enum GBeerShopSearchResultOrder {
  AVERAGE_RATING = 'AVERAGE_RATING',
  STYLE_SCORE = 'STYLE_SCORE'
}

export type GBeerStore = {
   __typename?: 'BeerStore';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  sellingPoints: Array<Scalars['String']>;
  logo: GImage;
};


export type GBeerStorelogoArgs = {
  input?: Maybe<GImageOptionsInput>;
};

export type GBeerStyle = {
   __typename?: 'BeerStyle';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  parent?: Maybe<GBeerStyle>;
  glasses: Array<GGlass>;
  numberOfBeers?: Maybe<Scalars['Int']>;
};

export type GBeerStyleList = {
   __typename?: 'BeerStyleList';
  items: Array<Maybe<GBeerStyle>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export enum GBeerStyleOrder {
  BeerStyleName = 'BeerStyleName',
  NumberOfBeers = 'NumberOfBeers'
}

export type GBeerTag = {
   __typename?: 'BeerTag';
  id: Scalars['ID'];
  author: GPublicUser;
  name: Scalars['String'];
  plain: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type GBeerTagList = {
   __typename?: 'BeerTagList';
  items: Array<Maybe<GBeerTag>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GBrewer = {
   __typename?: 'Brewer';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** @deprecated Please use brewerType instead */
  type?: Maybe<Scalars['String']>;
  brewerType?: Maybe<GBrewerType>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<GState>;
  country?: Maybe<GCountry>;
  zip?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  associatedPlaces: Array<GPlace>;
  affliatedUsers: Array<GPublicUser>;
  followersCount: Scalars['Int'];
  isFollowedByMe?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  adminNotes?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdBy: GPublicUser;
  updatedBy?: Maybe<GPublicUser>;
  metro?: Maybe<GMetro>;
};

export type GBrewerBeerReviewsInput = {
  brewerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};

export type GBrewerBeerReviewsPayload = {
   __typename?: 'BrewerBeerReviewsPayload';
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
  items: Array<GBrewerBeerReviewsPayloadItem>;
};

export type GBrewerBeerReviewsPayloadItem = {
   __typename?: 'BrewerBeerReviewsPayloadItem';
  review: GReview;
};

export type GBrewerBeersInput = {
  query?: Maybe<Scalars['String']>;
  brewerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order?: Maybe<GBrewerBeersInputOrder>;
  orderDirection?: Maybe<GOrderDirection>;
  minRatings?: Maybe<Scalars['Int']>;
  hideUserRatedBeers?: Maybe<Scalars['Boolean']>;
  hideUserHasNotRatedBeers?: Maybe<Scalars['Boolean']>;
  hideAliasedBeers?: Maybe<Scalars['Boolean']>;
  hideRetiredBeers?: Maybe<Scalars['Boolean']>;
  hideVerifiedBeers?: Maybe<Scalars['Boolean']>;
  hideUnverifiedBeers?: Maybe<Scalars['Boolean']>;
};

export enum GBrewerBeersInputOrder {
  NAME = 'NAME',
  DATE_ADDED = 'DATE_ADDED',
  ABV = 'ABV',
  OVERALL_SCORE = 'OVERALL_SCORE',
  STYLE_SCORE = 'STYLE_SCORE',
  AVERAGE_SCORE = 'AVERAGE_SCORE',
  REVIEW_COUNT = 'REVIEW_COUNT',
  USER_RATING = 'USER_RATING'
}

export type GBrewerBeersPayload = {
   __typename?: 'BrewerBeersPayload';
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
  items: Array<GBrewerBeersPayloadItem>;
  totalBeersBrewed: Scalars['Int'];
  userReviewsCount: Scalars['Int'];
};

export type GBrewerBeersPayloadItem = {
   __typename?: 'BrewerBeersPayloadItem';
  beer: GBeer;
  review?: Maybe<GReview>;
};

export type GBrewerList = {
   __typename?: 'BrewerList';
  items: Array<GBrewer>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GBrewerTopRatersInput = {
  brewerId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

export type GBrewerTopRatersPayload = {
   __typename?: 'BrewerTopRatersPayload';
  topRaters: Array<GTopRaterInfo>;
};

export type GBrewerType = {
   __typename?: 'BrewerType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum GCacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type GCheckin = {
   __typename?: 'Checkin';
  id: Scalars['ID'];
  author: GPublicUser;
  beer: GBeer;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  place: GPlace;
};

export type GCheckinList = {
   __typename?: 'CheckinList';
  items: Array<Maybe<GCheckin>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GCheckInPlaceInput = {
  userId: Scalars['ID'];
  placeId: Scalars['ID'];
};

export type GCheckInPlacePayload = {
   __typename?: 'CheckInPlacePayload';
  userActivity: GUserActivity;
};

export type GCloneBrewerToPlaceInput = {
  brewerId: Scalars['ID'];
};

export type GCloneBrewerToPlacePayload = {
   __typename?: 'CloneBrewerToPlacePayload';
  place: GPlace;
};

export type GCoordinates = {
   __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GCountry = {
   __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  states: Array<GState>;
  priority?: Maybe<Scalars['Int']>;
};

export enum GCountryCodeEnum {
  FJ = 'FJ',
  TZ = 'TZ',
  EH = 'EH',
  CA = 'CA',
  US = 'US',
  KZ = 'KZ',
  UZ = 'UZ',
  PG = 'PG',
  ID = 'ID',
  AR = 'AR',
  CL = 'CL',
  ZR = 'ZR',
  SO = 'SO',
  KE = 'KE',
  SD = 'SD',
  TD = 'TD',
  HT = 'HT',
  DO = 'DO',
  RU = 'RU',
  BS = 'BS',
  FK = 'FK',
  NOR = 'NOR',
  GL = 'GL',
  TF = 'TF',
  TP = 'TP',
  ZA = 'ZA',
  LS = 'LS',
  MX = 'MX',
  UY = 'UY',
  BR = 'BR',
  BO = 'BO',
  PE = 'PE',
  CO = 'CO',
  PA = 'PA',
  CR = 'CR',
  NI = 'NI',
  HN = 'HN',
  SV = 'SV',
  GT = 'GT',
  BZ = 'BZ',
  VE = 'VE',
  GY = 'GY',
  SR = 'SR',
  FR = 'FR',
  EC = 'EC',
  PR = 'PR',
  JM = 'JM',
  CU = 'CU',
  ZW = 'ZW',
  BW = 'BW',
  NA = 'NA',
  SN = 'SN',
  ML = 'ML',
  MR = 'MR',
  BJ = 'BJ',
  NE = 'NE',
  NG = 'NG',
  CM = 'CM',
  TG = 'TG',
  GH = 'GH',
  CI = 'CI',
  GN = 'GN',
  GW = 'GW',
  LR = 'LR',
  SL = 'SL',
  BF = 'BF',
  CF = 'CF',
  CG = 'CG',
  GA = 'GA',
  GQ = 'GQ',
  ZM = 'ZM',
  MW = 'MW',
  MZ = 'MZ',
  SZ = 'SZ',
  AO = 'AO',
  BI = 'BI',
  IL = 'IL',
  LB = 'LB',
  MG = 'MG',
  GZ = 'GZ',
  GM = 'GM',
  TN = 'TN',
  DZ = 'DZ',
  JO = 'JO',
  AE = 'AE',
  QA = 'QA',
  KW = 'KW',
  IQ = 'IQ',
  OM = 'OM',
  VU = 'VU',
  KH = 'KH',
  TH = 'TH',
  LA = 'LA',
  MM = 'MM',
  VN = 'VN',
  KP = 'KP',
  KR = 'KR',
  MN = 'MN',
  IN = 'IN',
  BD = 'BD',
  BT = 'BT',
  NP = 'NP',
  PK = 'PK',
  AF = 'AF',
  TJ = 'TJ',
  KG = 'KG',
  TM = 'TM',
  IR = 'IR',
  SY = 'SY',
  AM = 'AM',
  SE = 'SE',
  BY = 'BY',
  UA = 'UA',
  PL = 'PL',
  AT = 'AT',
  HU = 'HU',
  MD = 'MD',
  RO = 'RO',
  LT = 'LT',
  LV = 'LV',
  EE = 'EE',
  DE = 'DE',
  BG = 'BG',
  GR = 'GR',
  TR = 'TR',
  AL = 'AL',
  HR = 'HR',
  CH = 'CH',
  LU = 'LU',
  BE = 'BE',
  NL = 'NL',
  PT = 'PT',
  ES = 'ES',
  IE = 'IE',
  NC = 'NC',
  SB = 'SB',
  NZ = 'NZ',
  AU = 'AU',
  LK = 'LK',
  CN = 'CN',
  TW = 'TW',
  IT = 'IT',
  DK = 'DK',
  GB = 'GB',
  IS = 'IS',
  AZ = 'AZ',
  GE = 'GE',
  PH = 'PH',
  MY = 'MY',
  BN = 'BN',
  SI = 'SI',
  FI = 'FI',
  SK = 'SK',
  CZ = 'CZ',
  ER = 'ER',
  JP = 'JP',
  PY = 'PY',
  RY = 'RY',
  SA = 'SA',
  AQ = 'AQ',
  CYN = 'CYN',
  CY = 'CY',
  MA = 'MA',
  EG = 'EG',
  LY = 'LY',
  ET = 'ET',
  DJ = 'DJ',
  SOL = 'SOL',
  UG = 'UG',
  RW = 'RW',
  BA = 'BA',
  MK = 'MK',
  YF = 'YF',
  ME = 'ME',
  KV = 'KV',
  TT = 'TT',
  SS = 'SS'
}


export type GFavorite = {
   __typename?: 'Favorite';
  id: Scalars['ID'];
  author: GPublicUser;
  beer: GBeer;
  review?: Maybe<GReview>;
  createdAt: Scalars['Date'];
  namedList: GNamedList;
};

export type GFavoriteList = {
   __typename?: 'FavoriteList';
  items: Array<GFavorite>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GFestival = {
   __typename?: 'Festival';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Int']>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  distance?: Maybe<Scalars['Int']>;
  beers?: Maybe<Array<Maybe<GBeer>>>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<GCountry>;
  state?: Maybe<GState>;
  zip?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  images?: Maybe<GFestivalImages>;
  competitionConfig?: Maybe<Scalars['String']>;
};

export type GFestivalBeersList = {
   __typename?: 'FestivalBeersList';
  items: Array<Maybe<GReviewedBeer>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export enum GFestivalBeersOrder {
  ALPHABETICAL = 'ALPHABETICAL',
  SCORE = 'SCORE',
  MOST_REVIEWED = 'MOST_REVIEWED'
}

export type GFestivalImages = {
   __typename?: 'FestivalImages';
  logo?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
};

export type GFestivalList = {
   __typename?: 'FestivalList';
  items: Array<Maybe<GFestival>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GFieldError = {
   __typename?: 'FieldError';
  field: Scalars['String'];
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type GFollowBrewerInput = {
  brewerId: Scalars['ID'];
};

export type GFollowBrewerPayload = {
   __typename?: 'FollowBrewerPayload';
  brewer: GBrewer;
};

export type GFolloweesInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GFolloweesPayload = {
   __typename?: 'FolloweesPayload';
  items: Array<GPublicUser>;
  totalCount?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['ID']>;
};

export type GFollowersInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GFollowersPayload = {
   __typename?: 'FollowersPayload';
  items: Array<GPublicUser>;
  totalCount?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['ID']>;
};

export type GFollowUserInput = {
  userId: Scalars['ID'];
};

export type GFollowUserPayload = {
   __typename?: 'FollowUserPayload';
  user: GPublicUser;
};

export type GFriendActivityFeedPayload = {
   __typename?: 'FriendActivityFeedPayload';
  items: Array<GUserActivity>;
};

export enum GGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type GGeoLocationPayLoad = {
   __typename?: 'GeoLocationPayLoad';
  position?: Maybe<GCoordinates>;
};

export type GGlass = {
   __typename?: 'Glass';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type GImage = {
   __typename?: 'Image';
  uri: Scalars['String'];
  format: GImageFormat;
  dimension?: Maybe<GImageDimension>;
};

export type GImageDimension = {
   __typename?: 'ImageDimension';
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type GImageDimensionInput = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export enum GImageFormat {
  PNG = 'PNG'
}

export type GImageOptionsInput = {
  format?: Maybe<GImageFormat>;
  dimension?: Maybe<GImageDimensionInput>;
};

export type GLikePlaceReviewInput = {
  placeRatingId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GLikePlaceReviewPayload = {
   __typename?: 'LikePlaceReviewPayload';
  review: GPlaceReview;
};

export type GMetro = {
   __typename?: 'Metro';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentMetro?: Maybe<GMetro>;
};

export type GMutation = {
   __typename?: 'Mutation';
  anonymize: GUser;
  barcodeInsert: GBeerBarcode;
  mobileRatingInsert?: Maybe<GReview>;
  mobileRatingUpdate?: Maybe<GReview>;
  mobileRatingDelete?: Maybe<Scalars['ID']>;
  beerInsert?: Maybe<GBeer>;
  beerLogInsert?: Maybe<GBeerLog>;
  beerLogDelete?: Maybe<Scalars['ID']>;
  checkinInsert?: Maybe<GCheckin>;
  favoriteInsert?: Maybe<GFavorite>;
  favoriteDelete?: Maybe<Scalars['ID']>;
  namedListDelete?: Maybe<Scalars['Boolean']>;
  namedListInsert?: Maybe<Scalars['Boolean']>;
  namedListNew?: Maybe<GNamedList>;
  namedListRemove?: Maybe<Scalars['Boolean']>;
  namedListRename?: Maybe<GNamedList>;
  notificationSubscribe?: Maybe<Scalars['Boolean']>;
  notificationUnsubscribe?: Maybe<Scalars['Boolean']>;
  placeReviewInsert?: Maybe<GPlaceReview>;
  placeReviewUpdate?: Maybe<GPlaceReview>;
  placeReviewDelete?: Maybe<Scalars['ID']>;
  reviewLike?: Maybe<GReviewLike>;
  reviewUnlike?: Maybe<GReview>;
  updateNotificationSettings?: Maybe<GNotificationSettings>;
  updatePlaceInventory?: Maybe<Scalars['Date']>;
  userUpdate: GUserUpdate;
  userUpdateProfileImage: GUser;
  addBrewer: GAddBrewerPayload;
  addPlace: GAddPlacePayload;
  associateUserWithEntity: GAssociateUserWithEntityPayload;
  checkInPlace: GCheckInPlacePayload;
  cloneBrewerToPlace: GCloneBrewerToPlacePayload;
  followBrewer: GFollowBrewerPayload;
  followUser: GFollowUserPayload;
  likePlaceReview: GLikePlaceReviewPayload;
  unfollowBrewer: GUnfollowBrewerPayload;
  unfollowUser: GUnfollowUserPayload;
  unlikePlaceReview: GUnlikePlaceReviewPayload;
  updateBeer: GUpdateBeerPayload;
  updateBrewer?: Maybe<GUpdateBrewerPayload>;
  updatePlace: GUpdatePlacePayload;
  updateUser: GUpdateUserPayload;
  uploadBrewerImage: GUploadBrewerImagePayload;
};


export type GMutationbarcodeInsertArgs = {
  beerId: Scalars['ID'];
  barcode: Scalars['String'];
};


export type GMutationmobileRatingInsertArgs = {
  beerId: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
};


export type GMutationmobileRatingUpdateArgs = {
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
};


export type GMutationmobileRatingDeleteArgs = {
  id: Scalars['ID'];
};


export type GMutationbeerInsertArgs = {
  beer?: Maybe<GBeerInput>;
};


export type GMutationbeerLogInsertArgs = {
  beerId: Scalars['ID'];
};


export type GMutationbeerLogDeleteArgs = {
  id: Scalars['ID'];
};


export type GMutationcheckinInsertArgs = {
  beerId: Scalars['ID'];
  placeId: Scalars['ID'];
};


export type GMutationfavoriteInsertArgs = {
  beerId: Scalars['ID'];
};


export type GMutationfavoriteDeleteArgs = {
  id: Scalars['ID'];
};


export type GMutationnamedListDeleteArgs = {
  id: Scalars['ID'];
};


export type GMutationnamedListInsertArgs = {
  listId: Scalars['ID'];
  beerId: Scalars['ID'];
};


export type GMutationnamedListNewArgs = {
  name: Scalars['String'];
};


export type GMutationnamedListRemoveArgs = {
  listId: Scalars['ID'];
  beerId: Scalars['ID'];
};


export type GMutationnamedListRenameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type GMutationnotificationSubscribeArgs = {
  token: Scalars['String'];
};


export type GMutationnotificationUnsubscribeArgs = {
  token: Scalars['String'];
};


export type GMutationplaceReviewInsertArgs = {
  review: GPlaceReviewInsertInput;
};


export type GMutationplaceReviewUpdateArgs = {
  id: Scalars['ID'];
  review: GPlaceReviewUpdateInput;
};


export type GMutationplaceReviewDeleteArgs = {
  id: Scalars['ID'];
};


export type GMutationreviewLikeArgs = {
  id: Scalars['ID'];
};


export type GMutationreviewUnlikeArgs = {
  id: Scalars['ID'];
};


export type GMutationupdateNotificationSettingsArgs = {
  notificationType: GNotificationType;
  value: Scalars['Boolean'];
};


export type GMutationupdatePlaceInventoryArgs = {
  id: Scalars['ID'];
  inventory: Array<Maybe<GPlaceInventoryInput>>;
};


export type GMutationuserUpdateArgs = {
  userUpdateInput: GUserUpdateInput;
};


export type GMutationuserUpdateProfileImageArgs = {
  profileImage: Scalars['Upload'];
};


export type GMutationaddBrewerArgs = {
  input: GAddBrewerInput;
};


export type GMutationaddPlaceArgs = {
  input: GAddPlaceInput;
};


export type GMutationassociateUserWithEntityArgs = {
  input: GAssociateUserWithEntityInput;
};


export type GMutationcheckInPlaceArgs = {
  input: GCheckInPlaceInput;
};


export type GMutationcloneBrewerToPlaceArgs = {
  input: GCloneBrewerToPlaceInput;
};


export type GMutationfollowBrewerArgs = {
  input: GFollowBrewerInput;
};


export type GMutationfollowUserArgs = {
  input: GFollowUserInput;
};


export type GMutationlikePlaceReviewArgs = {
  input: GLikePlaceReviewInput;
};


export type GMutationunfollowBrewerArgs = {
  input: GUnfollowBrewerInput;
};


export type GMutationunfollowUserArgs = {
  input: GUnfollowUserInput;
};


export type GMutationunlikePlaceReviewArgs = {
  input: GUnlikePlaceReviewInput;
};


export type GMutationupdateBeerArgs = {
  input: GUpdateBeerInput;
};


export type GMutationupdateBrewerArgs = {
  input: GUpdateBrewerInput;
};


export type GMutationupdatePlaceArgs = {
  input: GUpdatePlaceInput;
};


export type GMutationupdateUserArgs = {
  input: GUpdateUserInput;
};


export type GMutationuploadBrewerImageArgs = {
  input: GUploadBrewerImageInput;
};

export type GMyTopRatedBeersInput = {
  year: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type GMyTopRatedBeersPayload = {
   __typename?: 'MyTopRatedBeersPayload';
  items: Array<GMyTopRatedBeersPayloadItem>;
};

export type GMyTopRatedBeersPayloadItem = {
   __typename?: 'MyTopRatedBeersPayloadItem';
  beer: GBeer;
  review: GReview;
};

export type GMyTopRatedPlacesInput = {
  year: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type GMyTopRatedPlacesPayload = {
   __typename?: 'MyTopRatedPlacesPayload';
  items: Array<GMyTopRatedPlacesPayloadItem>;
};

export type GMyTopRatedPlacesPayloadItem = {
   __typename?: 'MyTopRatedPlacesPayloadItem';
  place: GPlace;
  review: GPlaceReview;
};

export type GMyTopRatedStylesInput = {
  year: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type GMyTopRatedStylesPayload = {
   __typename?: 'MyTopRatedStylesPayload';
  items: Array<GMyTopRatedStylesPayloadItem>;
};

export type GMyTopRatedStylesPayloadItem = {
   __typename?: 'MyTopRatedStylesPayloadItem';
  style: GBeerStyle;
  averageRating: Scalars['Float'];
  ratingsCount: Scalars['Int'];
};

export type GMyYearInBeerSummaryInput = {
  year: Scalars['Int'];
  userId: Scalars['ID'];
};

export type GMyYearInBeerSummaryPayload = {
   __typename?: 'MyYearInBeerSummaryPayload';
  ratingsCount: Scalars['Int'];
  stylesRated: Scalars['Int'];
  averageRating?: Maybe<Scalars['Float']>;
  breweriesRated: Scalars['Int'];
  firstTimeBreweriesRated: Scalars['Int'];
  openingBreweriesRated: Scalars['Int'];
};

export type GNamedList = {
   __typename?: 'NamedList';
  id: Scalars['ID'];
  author: GPublicUser;
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  favorites?: Maybe<GFavoriteList>;
};

export type GNamedListList = {
   __typename?: 'NamedListList';
  items: Array<Maybe<GNamedList>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export enum GNamedListOrder {
  RECENT = 'RECENT',
  SCORE = 'SCORE',
  ALPHABETICAL = 'ALPHABETICAL'
}

export type GNotificationSettings = {
   __typename?: 'NotificationSettings';
  optedOutReviewLikes: Scalars['Boolean'];
};

export enum GNotificationType {
  OPTED_OUT_REVIEW_LIKES = 'OPTED_OUT_REVIEW_LIKES'
}

export enum GOrderByDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum GOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type GPlace = {
   __typename?: 'Place';
  id: Scalars['ID'];
  brewer?: Maybe<GBrewer>;
  mayor?: Maybe<GPublicUser>;
  name: Scalars['String'];
  bottles?: Maybe<Scalars['String']>;
  taps?: Maybe<Scalars['String']>;
  averageRating?: Maybe<Scalars['Float']>;
  percentile?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  type?: Maybe<GPlaceType>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<GState>;
  country?: Maybe<GCountry>;
  zip?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rateCount?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  beerMenu?: Maybe<Scalars['String']>;
  foodMenu?: Maybe<Scalars['String']>;
  wifi?: Maybe<Scalars['Boolean']>;
  flights?: Maybe<Scalars['String']>;
  userReviews?: Maybe<Array<GPlaceReview>>;
  email?: Maybe<Scalars['String']>;
  metro?: Maybe<GMetro>;
  singles?: Maybe<Scalars['Boolean']>;
  mailOrder?: Maybe<Scalars['Boolean']>;
  mailOrderIntl?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Scalars['Boolean']>;
  seasonals?: Maybe<Scalars['Boolean']>;
  patio?: Maybe<Scalars['Boolean']>;
  realAles?: Maybe<Scalars['Boolean']>;
  growlers?: Maybe<Scalars['Boolean']>;
  glassware?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  lastCheckedIn?: Maybe<GUserActivity>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<GPublicUser>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<GPublicUser>;
};

export type GPlaceInventory = {
   __typename?: 'PlaceInventory';
  items: Array<GPlaceInventoryItem>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GPlaceInventoryInput = {
  beerId: Scalars['ID'];
  type: GPlaceInventoryType;
  price?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['Date']>;
};

export type GPlaceInventoryItem = {
   __typename?: 'PlaceInventoryItem';
  beer: GBeer;
  type?: Maybe<GPlaceInventoryType>;
  price?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['Date']>;
};

export enum GPlaceInventoryOrder {
  ALPHABETICAL = 'ALPHABETICAL',
  SCORE = 'SCORE',
  RECENT = 'RECENT'
}

export enum GPlaceInventoryType {
  BOTTLE = 'BOTTLE',
  CAN = 'CAN',
  DRAFT = 'DRAFT',
  CASK = 'CASK',
  UNKNOWN = 'UNKNOWN'
}

export type GPlaceList = {
   __typename?: 'PlaceList';
  items: Array<GPlace>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GPlaceReview = {
   __typename?: 'PlaceReview';
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  scores: GPlaceReviewScores;
  author: GPublicUser;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  place: GPlace;
  likeCount: Scalars['Int'];
  likedByMe?: Maybe<Scalars['Boolean']>;
};

export type GPlaceReviewInsertInput = {
  placeId: Scalars['ID'];
  scores: GPlaceReviewScoresInput;
  comment?: Maybe<Scalars['String']>;
};

export type GPlaceReviewList = {
   __typename?: 'PlaceReviewList';
  items: Array<GPlaceReview>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GPlaceReviewScores = {
   __typename?: 'PlaceReviewScores';
  ambiance?: Maybe<Scalars['Int']>;
  service?: Maybe<Scalars['Int']>;
  selection?: Maybe<Scalars['Int']>;
  food?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  overall?: Maybe<Scalars['Int']>;
};

export type GPlaceReviewScoresInput = {
  ambiance: Scalars['Int'];
  service: Scalars['Int'];
  selection: Scalars['Int'];
  food: Scalars['Int'];
  value: Scalars['Int'];
  overall: Scalars['Int'];
};

export type GPlaceReviewsInput = {
  placeId: Scalars['ID'];
  authorId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order?: Maybe<GPlaceReviewsOrder>;
  direction?: Maybe<GOrderDirection>;
};

export enum GPlaceReviewsOrder {
  SCORE = 'SCORE',
  DATE_ADDED = 'DATE_ADDED'
}

export type GPlaceReviewsPayload = {
   __typename?: 'PlaceReviewsPayload';
  items: Array<GPlaceReview>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GPlaceReviewUpdateInput = {
  scores?: Maybe<GPlaceReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
};

export type GPlaceSearchFilters = {
  placeTypes: Array<Scalars['ID']>;
  minScore?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Float']>;
  showUnratedPlaces?: Maybe<Scalars['Boolean']>;
  showRatedPlaces?: Maybe<Scalars['Boolean']>;
  showClosedPlaces?: Maybe<Scalars['Boolean']>;
};

export type GPlaceType = {
   __typename?: 'PlaceType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GPublicUser = {
   __typename?: 'PublicUser';
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  brewerID?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<GCountry>;
  festivalRatingCount?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GGender>;
  imageUrl: Scalars['String'];
  isPremiumMember: Scalars['Boolean'];
  membershipJoinDate?: Maybe<Scalars['Date']>;
  birthday?: Maybe<Scalars['Date']>;
  biography?: Maybe<Scalars['String']>;
  favoriteBeerStyle?: Maybe<GBeerStyle>;
  lastLogin?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  placeReviewCount: Scalars['Int'];
  reviewCount: Scalars['Int'];
  ratingsCount: Scalars['Int'];
  stylesRatedCount: Scalars['Int'];
  securityLevelId: Scalars['Int'];
  state?: Maybe<GState>;
  stats: GUserStats;
  recentActivity: GUserActivityList;
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  isFollowingMe?: Maybe<Scalars['Boolean']>;
  isFollowedByMe?: Maybe<Scalars['Boolean']>;
  place?: Maybe<GPlace>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type GPublicUserList = {
   __typename?: 'PublicUserList';
  items: Array<GPublicUser>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GPurchaseOptionsCondition = {
  latlng: Array<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order?: Maybe<GPurchaseOptionsOrder>;
};

export enum GPurchaseOptionsOrder {
  PRICE = 'PRICE'
}

export type GQuery = {
   __typename?: 'Query';
  activity?: Maybe<GActionList>;
  me?: Maybe<GUser>;
  countries: Array<GCountry>;
  barcodes?: Maybe<GBeerBarcodeList>;
  beer?: Maybe<GBeer>;
  beerByAlias?: Maybe<GBeer>;
  beerExists?: Maybe<GBeer>;
  beerHistory?: Maybe<GReviewedBeerList>;
  beerInStock?: Maybe<GBeerPurchaseInfo>;
  beerInStockMulti?: Maybe<Array<Maybe<GBeerPurchaseInfo>>>;
  beerLog?: Maybe<GBeerLog>;
  beerLogs?: Maybe<GBeerLogList>;
  beerReviews: GReviewList;
  beerReviewsDraft?: Maybe<GReviewList>;
  beerReviewsPublished?: Maybe<GReviewList>;
  beerRecommendations?: Maybe<GBeerList>;
  beerStyleRecommendations?: Maybe<GBeerList>;
  beerStyles: Array<GBeerStyle>;
  beerStyleSearch?: Maybe<GBeerStyleList>;
  beersByBarcode?: Maybe<GReviewedBeerList>;
  beersByStyleRandom: GBeerList;
  beerSearch: GReviewedBeerList;
  beerTags?: Maybe<GBeerTagList>;
  brewerSearch: GBrewerList;
  checkins?: Maybe<GCheckinList>;
  favorite?: Maybe<GFavorite>;
  isFavorite?: Maybe<GFavorite>;
  favorites: GFavoriteList;
  festivalBeers?: Maybe<GFestivalBeersList>;
  festival?: Maybe<GFestival>;
  festivals?: Maybe<GFestivalList>;
  festivalStyles?: Maybe<Array<Maybe<GBeerStyle>>>;
  festivalLeaderboard?: Maybe<GPublicUserList>;
  friends?: Maybe<GPublicUserList>;
  friendBeerReviews?: Maybe<GReviewList>;
  mobileRating?: Maybe<GReview>;
  myBeerReview?: Maybe<GReview>;
  namedList?: Maybe<GNamedList>;
  namedLists?: Maybe<GNamedListList>;
  namedListsByBeer?: Maybe<GNamedListList>;
  nearbyBeers?: Maybe<GBeerList>;
  nearbyPlaces?: Maybe<GPlaceList>;
  version: Scalars['String'];
  place?: Maybe<GPlace>;
  placeInventory: GPlaceInventory;
  placeReview?: Maybe<GPlaceReview>;
  placeSearch: GPlaceList;
  placeStyles?: Maybe<Array<Maybe<GBeerStyle>>>;
  placeTypes: Array<GPlaceType>;
  recentPlaces?: Maybe<GPlaceList>;
  retailers: Array<GRetailer>;
  reviewLikes?: Maybe<GReviewLikeList>;
  topBeers?: Maybe<GReviewedBeerList>;
  topBeersShuffle?: Maybe<Array<Maybe<GReviewedBeer>>>;
  user?: Maybe<GPublicUser>;
  userBeerReview?: Maybe<GReview>;
  userSearch: GPublicUserList;
  /** @deprecated Use brewerBeers instead */
  beersByBrewer?: Maybe<GReviewedBeerList>;
  beershopSearch?: Maybe<GBeerList>;
  brewer?: Maybe<GBrewer>;
  brewerBeerReviews: GBrewerBeerReviewsPayload;
  brewerBeers: GBrewerBeersPayload;
  brewerTopRaters: GBrewerTopRatersPayload;
  brewerTypes: Array<GBrewerType>;
  followees: GFolloweesPayload;
  followers: GFollowersPayload;
  friendActivityFeed?: Maybe<GFriendActivityFeedPayload>;
  geoLocation?: Maybe<GCoordinates>;
  metros: Array<GMetro>;
  myTopRatedBeers: GMyTopRatedBeersPayload;
  myTopRatedPlaces: GMyTopRatedPlacesPayload;
  myTopRatedStyles: GMyTopRatedStylesPayload;
  myYearInBeerSummary: GMyYearInBeerSummaryPayload;
  placeReviews: GPlaceReviewsPayload;
  regionInfo?: Maybe<GRegionInfoPayload>;
  shopInfo?: Maybe<GShopInfoPayload>;
  states: GStatesPayload;
  topBeersSearch?: Maybe<GTopBeersSearchPayload>;
  userBeerRatingsByStyle: GUserRatingsByStylePayload;
  userReviews: GUserReviewsPayload;
};


export type GQueryactivityArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybarcodesArgs = {
  beerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerArgs = {
  id: Scalars['ID'];
};


export type GQuerybeerByAliasArgs = {
  aliasId: Scalars['ID'];
};


export type GQuerybeerExistsArgs = {
  brewerId?: Maybe<Scalars['ID']>;
  styleId?: Maybe<Scalars['ID']>;
  abv?: Maybe<Scalars['Float']>;
  ibu?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type GQuerybeerHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerInStockArgs = {
  beerId: Scalars['ID'];
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
};


export type GQuerybeerInStockMultiArgs = {
  beerId: Scalars['ID'];
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
};


export type GQuerybeerLogArgs = {
  id: Scalars['ID'];
};


export type GQuerybeerLogsArgs = {
  beerId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerReviewsArgs = {
  beerId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  order?: Maybe<GReviewOrder>;
  reverse?: Maybe<Scalars['Boolean']>;
  includePrivate?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
};


export type GQuerybeerReviewsDraftArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerReviewsPublishedArgs = {
  authorId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerRecommendationsArgs = {
  minRating?: Maybe<Scalars['Float']>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  beerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GQuerybeerStyleRecommendationsArgs = {
  minRating?: Maybe<Scalars['Float']>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  beerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GQuerybeerStylesArgs = {
  order?: Maybe<GBeerStyleOrder>;
};


export type GQuerybeerStyleSearchArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
};


export type GQuerybeersByBarcodeArgs = {
  barcode: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeersByStyleRandomArgs = {
  styleId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  latlng?: Maybe<Array<Scalars['Float']>>;
  excludedBeerIds?: Maybe<Array<Scalars['ID']>>;
};


export type GQuerybeerSearchArgs = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeerTagsArgs = {
  beerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybrewerSearchArgs = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerycheckinsArgs = {
  beerId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryfavoriteArgs = {
  id: Scalars['ID'];
};


export type GQueryisFavoriteArgs = {
  beerId: Scalars['ID'];
};


export type GQueryfavoritesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryfestivalBeersArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  styleId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GFestivalBeersOrder>;
};


export type GQueryfestivalArgs = {
  id: Scalars['ID'];
};


export type GQueryfestivalsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  latlng: Array<Maybe<Scalars['Float']>>;
  radius?: Maybe<Scalars['Int']>;
  withinDays?: Maybe<Scalars['Int']>;
};


export type GQueryfestivalStylesArgs = {
  festivalId: Scalars['ID'];
};


export type GQueryfestivalLeaderboardArgs = {
  festivalId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryfriendsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryfriendBeerReviewsArgs = {
  beerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerymobileRatingArgs = {
  id: Scalars['ID'];
};


export type GQuerymyBeerReviewArgs = {
  beerId: Scalars['ID'];
};


export type GQuerynamedListArgs = {
  listId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order?: Maybe<GNamedListOrder>;
};


export type GQuerynamedListsArgs = {
  userId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerynamedListsByBeerArgs = {
  beerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerynearbyBeersArgs = {
  latlng: Array<Maybe<Scalars['Float']>>;
  within?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerynearbyPlacesArgs = {
  latlng: Array<Maybe<Scalars['Float']>>;
  beerId?: Maybe<Scalars['ID']>;
  within?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryplaceArgs = {
  id: Scalars['ID'];
};


export type GQueryplaceInventoryArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  styleId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GPlaceInventoryOrder>;
};


export type GQueryplaceReviewArgs = {
  id: Scalars['ID'];
};


export type GQueryplaceSearchArgs = {
  query?: Maybe<Scalars['String']>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  order?: Maybe<GSearchOrder>;
  filters?: Maybe<GPlaceSearchFilters>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};


export type GQueryplaceStylesArgs = {
  placeId: Scalars['ID'];
};


export type GQueryrecentPlacesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQueryretailersArgs = {
  options?: Maybe<GRetailerCondition>;
};


export type GQueryreviewLikesArgs = {
  reviewId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerytopBeersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerytopBeersShuffleArgs = {
  first: Scalars['Int'];
  of: Scalars['Int'];
};


export type GQueryuserArgs = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  authUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type GQueryuserBeerReviewArgs = {
  beerId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type GQueryuserSearchArgs = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeersByBrewerArgs = {
  brewerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GQuerybeershopSearchArgs = {
  options: GBeerShopSearchCondition;
};


export type GQuerybrewerArgs = {
  id: Scalars['ID'];
};


export type GQuerybrewerBeerReviewsArgs = {
  input: GBrewerBeerReviewsInput;
};


export type GQuerybrewerBeersArgs = {
  input: GBrewerBeersInput;
};


export type GQuerybrewerTopRatersArgs = {
  input: GBrewerTopRatersInput;
};


export type GQueryfolloweesArgs = {
  input: GFolloweesInput;
};


export type GQueryfollowersArgs = {
  input: GFollowersInput;
};


export type GQuerymyTopRatedBeersArgs = {
  input: GMyTopRatedBeersInput;
};


export type GQuerymyTopRatedPlacesArgs = {
  input: GMyTopRatedPlacesInput;
};


export type GQuerymyTopRatedStylesArgs = {
  input: GMyTopRatedStylesInput;
};


export type GQuerymyYearInBeerSummaryArgs = {
  input: GMyYearInBeerSummaryInput;
};


export type GQueryplaceReviewsArgs = {
  placeId?: Maybe<Scalars['ID']>;
  authorId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  input?: Maybe<GPlaceReviewsInput>;
};


export type GQueryregionInfoArgs = {
  input: GRegionInfoInput;
};


export type GQueryshopInfoArgs = {
  input: GShopInfoInput;
};


export type GQuerystatesArgs = {
  input?: Maybe<GStatesInput>;
};


export type GQuerytopBeersSearchArgs = {
  input: GTopBeersSearchInput;
};


export type GQueryuserBeerRatingsByStyleArgs = {
  input: GUserBeerRatingsByStyleInput;
};


export type GQueryuserReviewsArgs = {
  input: GUserReviewsInput;
};

export type GRatingCountRange = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type GRegionCountry = {
   __typename?: 'RegionCountry';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: GCountryCodeEnum;
};

export type GRegionInfoInput = {
  latlng: Array<Scalars['Float']>;
};

export type GRegionInfoPayload = {
   __typename?: 'RegionInfoPayload';
  country: GRegionCountry;
  state?: Maybe<GRegionState>;
};

export type GRegionState = {
   __typename?: 'RegionState';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: GStateCodeEnum;
};

export type GRetailer = {
   __typename?: 'Retailer';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};

export type GRetailerCondition = {
  latlng?: Maybe<Array<Scalars['Float']>>;
};

export type GReview = {
   __typename?: 'Review';
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  scores: GReviewScores;
  likeCount: Scalars['Int'];
  likedByMe?: Maybe<Scalars['Boolean']>;
  author: GPublicUser;
  beer: GBeer;
  servedIn?: Maybe<GServedIn>;
  checkin?: Maybe<GCheckin>;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type GReviewedBeer = {
   __typename?: 'ReviewedBeer';
  beer: GBeer;
  review?: Maybe<GReview>;
};

export type GReviewedBeerList = {
   __typename?: 'ReviewedBeerList';
  items: Array<GReviewedBeer>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GReviewLike = {
   __typename?: 'ReviewLike';
  id: Scalars['ID'];
  author: GPublicUser;
  review: GReview;
  createdAt: Scalars['Date'];
};

export type GReviewLikeList = {
   __typename?: 'ReviewLikeList';
  items: Array<Maybe<GReviewLike>>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GReviewList = {
   __typename?: 'ReviewList';
  items: Array<GReview>;
  totalCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export enum GReviewOrder {
  RECENT = 'RECENT',
  SCORE = 'SCORE',
  TOP_RATERS = 'TOP_RATERS',
  LIKES = 'LIKES'
}

export type GReviewScores = {
   __typename?: 'ReviewScores';
  appearance?: Maybe<Scalars['Int']>;
  aroma?: Maybe<Scalars['Int']>;
  flavor?: Maybe<Scalars['Int']>;
  mouthfeel?: Maybe<Scalars['Int']>;
  overall?: Maybe<Scalars['Int']>;
};

export type GReviewScoresInput = {
  appearance: Scalars['Int'];
  aroma: Scalars['Int'];
  flavor: Scalars['Int'];
  mouthfeel: Scalars['Int'];
  overall: Scalars['Int'];
};

export type GScoreRange = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export enum GSearchOrder {
  MATCH = 'MATCH',
  RATING_COUNT = 'RATING_COUNT',
  AVERAGE_RATING = 'AVERAGE_RATING',
  OVERALL_SCORE = 'OVERALL_SCORE'
}

export enum GServedIn {
  CASK = 'CASK',
  TAP = 'TAP',
  BOTTLE = 'BOTTLE',
  CAN = 'CAN',
  UNKNOWN = 'UNKNOWN'
}

export type GShopInfoInput = {
  latlng: Array<Scalars['Float']>;
};

export type GShopInfoPayload = {
   __typename?: 'ShopInfoPayload';
  retailers: Array<GRetailer>;
};

export type GState = {
   __typename?: 'State';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  country?: Maybe<GCountry>;
};

export enum GStateCodeEnum {
  MN = 'MN',
  MT = 'MT',
  ND = 'ND',
  HI = 'HI',
  ID = 'ID',
  WA = 'WA',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  NV = 'NV',
  NM = 'NM',
  OR = 'OR',
  UT = 'UT',
  WY = 'WY',
  AR = 'AR',
  IA = 'IA',
  KS = 'KS',
  MO = 'MO',
  NE = 'NE',
  OK = 'OK',
  SD = 'SD',
  LA = 'LA',
  TX = 'TX',
  CT = 'CT',
  MA = 'MA',
  NH = 'NH',
  RI = 'RI',
  VT = 'VT',
  AL = 'AL',
  FL = 'FL',
  GA = 'GA',
  MS = 'MS',
  SC = 'SC',
  IL = 'IL',
  IN = 'IN',
  KY = 'KY',
  NC = 'NC',
  OH = 'OH',
  TN = 'TN',
  VA = 'VA',
  WI = 'WI',
  WV = 'WV',
  DE = 'DE',
  DC = 'DC',
  MD = 'MD',
  NJ = 'NJ',
  NY = 'NY',
  PA = 'PA',
  ME = 'ME',
  MI = 'MI',
  AK = 'AK'
}

export type GStatesInput = {
  countryId?: Maybe<Scalars['ID']>;
};

export type GStatesPayload = {
   __typename?: 'StatesPayload';
  items: Array<GState>;
};

export enum GTimeSpanEnum {
  ALL = 'ALL',
  DAYS_90 = 'DAYS_90'
}

export type GTopBeersSearchInput = {
  beerCreatedWithin: GTimeSpanEnum;
  reviewCreatedWithin: GTimeSpanEnum;
  orderBy: GTopBeersSearchOrderByEnum;
  orderByDirection: GOrderByDirectionEnum;
  styleIds?: Maybe<Array<Scalars['ID']>>;
  countryIds?: Maybe<Array<Scalars['ID']>>;
  statesIds?: Maybe<Array<Scalars['ID']>>;
  includingRetired?: Maybe<Scalars['Boolean']>;
  abvRange?: Maybe<GAbvRange>;
  ratingCountRange?: Maybe<GRatingCountRange>;
  first?: Maybe<Scalars['Int']>;
};

export enum GTopBeersSearchOrderByEnum {
  AVERAGE_SCORE = 'AVERAGE_SCORE'
}

export type GTopBeersSearchPayload = {
   __typename?: 'TopBeersSearchPayload';
  items: Array<GBeer>;
  myRatedBeerIds?: Maybe<Array<Scalars['ID']>>;
};

export type GTopRaterInfo = {
   __typename?: 'TopRaterInfo';
  reviewCount: Scalars['Int'];
  user: GUser;
};

export type GUnfollowBrewerInput = {
  brewerId: Scalars['ID'];
};

export type GUnfollowBrewerPayload = {
   __typename?: 'UnfollowBrewerPayload';
  brewer: GBrewer;
};

export type GUnfollowUserInput = {
  userId: Scalars['ID'];
};

export type GUnfollowUserPayload = {
   __typename?: 'UnfollowUserPayload';
  user: GPublicUser;
};

export type GUnlikePlaceReviewInput = {
  placeRatingId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GUnlikePlaceReviewPayload = {
   __typename?: 'UnlikePlaceReviewPayload';
  review: GPlaceReview;
};

export type GUpdateBeerInput = {
  beerId: Scalars['ID'];
  data: GUpdateBeerInputData;
};

export type GUpdateBeerInputData = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  abv?: Maybe<Scalars['Float']>;
  ibu?: Maybe<Scalars['Int']>;
  brewerId?: Maybe<Scalars['ID']>;
  contractBrewerId?: Maybe<Scalars['ID']>;
  styleId?: Maybe<Scalars['ID']>;
  seasonal?: Maybe<GBeerSeason>;
  verified?: Maybe<Scalars['Boolean']>;
  unrateable?: Maybe<Scalars['Boolean']>;
  retired?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['Upload']>;
  adminNotes?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
};

export type GUpdateBeerPayload = {
   __typename?: 'UpdateBeerPayload';
  beer: GBeer;
};

export type GUpdateBrewerInput = {
  id: Scalars['ID'];
  data: GUpdateBrewerInputData;
};

export type GUpdateBrewerInputData = {
  brewerTypeId?: Maybe<Scalars['ID']>;
  brewerStateId?: Maybe<Scalars['ID']>;
  brewerCountryId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  adminNotes?: Maybe<Scalars['String']>;
  metroId?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['Upload']>;
};

export type GUpdateBrewerPayload = {
   __typename?: 'UpdateBrewerPayload';
  brewer?: Maybe<GBrewer>;
};

export type GUpdatePlaceInput = {
  id: Scalars['ID'];
  data: GUpdatePlaceInputData;
};

export type GUpdatePlaceInputData = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  dateOpened?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  metroId?: Maybe<Scalars['ID']>;
  placeType?: Maybe<Scalars['ID']>;
  countryId?: Maybe<Scalars['ID']>;
  beerMenuURL?: Maybe<Scalars['String']>;
  foodMenuURL?: Maybe<Scalars['String']>;
  taps?: Maybe<Scalars['String']>;
  bottles?: Maybe<Scalars['String']>;
  wifi?: Maybe<Scalars['Boolean']>;
  singles?: Maybe<Scalars['Boolean']>;
  mailOrder?: Maybe<Scalars['Boolean']>;
  mailOrderIntl?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Scalars['Boolean']>;
  seasonals?: Maybe<Scalars['Boolean']>;
  patio?: Maybe<Scalars['Boolean']>;
  realAles?: Maybe<Scalars['Boolean']>;
  growlers?: Maybe<Scalars['Boolean']>;
  glassware?: Maybe<Scalars['Boolean']>;
  brewerId?: Maybe<Scalars['ID']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

export type GUpdatePlacePayload = {
   __typename?: 'UpdatePlacePayload';
  place: GPlace;
};

export type GUpdateUserInput = {
  userId: Scalars['ID'];
  data: GUpdateUserInputData;
};

export type GUpdateUserInputData = {
  username?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  favoriteBeerStyle?: Maybe<Scalars['ID']>;
  biography?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
  postalCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  forumNotifications?: Maybe<Scalars['Boolean']>;
  notifyEmails?: Maybe<Scalars['Boolean']>;
  notifyFreebies?: Maybe<Scalars['Boolean']>;
  showTradeForums?: Maybe<Scalars['Boolean']>;
};

export type GUpdateUserPayload = {
   __typename?: 'UpdateUserPayload';
  user: GPublicUser;
};


export type GUploadBrewerImageInput = {
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
};

export type GUploadBrewerImagePayload = {
   __typename?: 'UploadBrewerImagePayload';
  brewer: GBrewer;
};

export type GUser = {
   __typename?: 'User';
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<GCountry>;
  email?: Maybe<Scalars['String']>;
  festivalRatingCount?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GGender>;
  imageUrl: Scalars['String'];
  isPremiumMember: Scalars['Boolean'];
  membershipJoinDate?: Maybe<Scalars['Date']>;
  birthday?: Maybe<Scalars['Date']>;
  biography?: Maybe<Scalars['String']>;
  favoriteBeerStyle?: Maybe<GBeerStyle>;
  lastLogin?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  placeReviewCount: Scalars['Int'];
  reviewCount: Scalars['Int'];
  ratingsCount: Scalars['Int'];
  stylesRatedCount: Scalars['Int'];
  securityLevelId: Scalars['Int'];
  state?: Maybe<GState>;
  stats: GUserStats;
  streetAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  notificationSettings: GNotificationSettings;
  recentActivity: GUserActivityList;
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  isFollowingMe?: Maybe<Scalars['Boolean']>;
  isFollowedByMe?: Maybe<Scalars['Boolean']>;
  place?: Maybe<GPlace>;
  showTradeForums?: Maybe<Scalars['Boolean']>;
  notifyFreebies?: Maybe<Scalars['Boolean']>;
  forumNotifications?: Maybe<Scalars['Boolean']>;
  notifyEmails?: Maybe<Scalars['Boolean']>;
};

export type GUserActivity = {
   __typename?: 'UserActivity';
  id: Scalars['ID'];
  user: GPublicUser;
  type: GUserActivityType;
  data?: Maybe<GUserActivityData>;
  createdAt: Scalars['Date'];
};

export type GUserActivityData = {
   __typename?: 'UserActivityData';
  beer?: Maybe<GBeer>;
  place?: Maybe<GPlace>;
  beerReview?: Maybe<GReview>;
  placeReview?: Maybe<GPlaceReview>;
};

export type GUserActivityList = {
   __typename?: 'UserActivityList';
  items: Array<GUserActivity>;
};

export enum GUserActivityType {
  BEER_REVIEW = 'BEER_REVIEW',
  PLACE_REVIEW = 'PLACE_REVIEW'
}

export type GUserBeerRatingsByStyleInput = {
  userId: Scalars['ID'];
};

export type GUserRatingsByStylePayload = {
   __typename?: 'UserRatingsByStylePayload';
  results: Array<GUserRatingsByStylePayloadItem>;
};

export type GUserRatingsByStylePayloadItem = {
   __typename?: 'UserRatingsByStylePayloadItem';
  styleId: Scalars['ID'];
  styleName: Scalars['String'];
  averageRating: Scalars['Float'];
  ratingsCount?: Maybe<Scalars['Float']>;
};

export type GUserReviewsInput = {
  userId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};

export type GUserReviewsPayload = {
   __typename?: 'UserReviewsPayload';
  items: Array<GReview>;
  totalCount: Scalars['Int'];
  totalReviewsCount: Scalars['Int'];
  last?: Maybe<Scalars['ID']>;
};

export type GUserReviewStats = {
   __typename?: 'UserReviewStats';
  total: Scalars['Int'];
  pub: Scalars['Int'];
  styles: Scalars['Int'];
  countries: Scalars['Int'];
  brewers: Scalars['Int'];
  likes: Scalars['Int'];
};

export type GUserStats = {
   __typename?: 'UserStats';
  reviews: GUserReviewStats;
  checkins: Scalars['Int'];
};

export type GUserUpdate = {
   __typename?: 'UserUpdate';
  user?: Maybe<GUser>;
  errors?: Maybe<Array<Maybe<GFieldError>>>;
};

export type GUserUpdateInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GGender>;
  lastName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['ID']>;
  streetAddress?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type GBFBIsFavoriteQueryVariables = {
  beerId: Scalars['ID'];
};


export type GBFBIsFavoriteQuery = (
  { __typename?: 'Query' }
  & { isFavorite?: Maybe<(
    { __typename?: 'Favorite' }
    & Pick<GFavorite, 'id'>
    & { beer: (
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id'>
    ) }
  )> }
);

export type GBFBFavoriteInsertMutationVariables = {
  beerId: Scalars['ID'];
};


export type GBFBFavoriteInsertMutation = (
  { __typename?: 'Mutation' }
  & { favoriteInsert?: Maybe<(
    { __typename?: 'Favorite' }
    & Pick<GFavorite, 'id'>
  )> }
);

export type GBFBFavoriteDeleteMutationVariables = {
  favoriteId: Scalars['ID'];
};


export type GBFBFavoriteDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'favoriteDelete'>
);

export type GbeerStylesQueryVariables = {};


export type GbeerStylesQuery = (
  { __typename?: 'Query' }
  & { beerStyles: Array<(
    { __typename?: 'BeerStyle' }
    & Pick<GBeerStyle, 'id' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id'>
    )> }
  )> }
);

export type GShopButtonShopInfoQueryVariables = {
  latlng: Array<Scalars['Float']>;
};


export type GShopButtonShopInfoQuery = (
  { __typename?: 'Query' }
  & { shopInfo?: Maybe<(
    { __typename?: 'ShopInfoPayload' }
    & { retailers: Array<(
      { __typename?: 'Retailer' }
      & Pick<GRetailer, 'id'>
    )> }
  )> }
);

export type GHeaderSearchQueryVariables = {
  query: Scalars['String'];
  firstNBeers?: Maybe<Scalars['Int']>;
};


export type GHeaderSearchQuery = (
  { __typename?: 'Query' }
  & { beers: (
    { __typename?: 'ReviewedBeerList' }
    & { items: Array<(
      { __typename?: 'ReviewedBeer' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'imageUrl'>
      ) }
    )> }
  ), brewers: (
    { __typename?: 'BrewerList' }
    & { items: Array<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'imageUrl'>
    )> }
  ), places: (
    { __typename?: 'PlaceList' }
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'imageUrl'>
    )> }
  ) }
);

export type GSimilarBeersQueryVariables = {
  styleId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  latlng: Array<Scalars['Float']>;
  includePurchaseOptions: Scalars['Boolean'];
  excludedBeerIds: Array<Scalars['ID']>;
};


export type GSimilarBeersQuery = (
  { __typename?: 'Query' }
  & { beersByStyleRandom: (
    { __typename?: 'BeerList' }
    & { items: Array<(
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'ratingsCount'>
      & { brewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )> }
      )>, contractBrewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )> }
      )>, style: (
        { __typename?: 'BeerStyle' }
        & Pick<GBeerStyle, 'id' | 'name'>
      ), purchaseOptions: (
        { __typename?: 'BeerPurchaseInfoList' }
        & { items: Array<(
          { __typename?: 'BeerPurchaseInfo' }
          & Pick<GBeerPurchaseInfo, 'productId' | 'priceValue' | 'price' | 'bottleSize' | 'currency' | 'currencySymbol'>
          & { store: (
            { __typename?: 'BeerStore' }
            & Pick<GBeerStore, 'id' | 'url' | 'name'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type GbeerInsertMutationVariables = {
  beerName: Scalars['String'];
  brewerId: Scalars['ID'];
  styleId: Scalars['ID'];
  abv?: Maybe<Scalars['Float']>;
  ibu?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  seasonal?: Maybe<GBeerSeason>;
  contractBrewerId?: Maybe<Scalars['ID']>;
  verified?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};


export type GbeerInsertMutation = (
  { __typename?: 'Mutation' }
  & { beerInsert?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name'>
  )> }
);

export type GbrewerSearchQueryVariables = {
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type GbrewerSearchQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'BrewerList' }
    & { items: Array<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )> }
    )> }
  ) }
);

export type GAddBeerGetBrewerQueryQueryVariables = {
  brewerId: Scalars['ID'];
};


export type GAddBeerGetBrewerQueryQuery = (
  { __typename?: 'Query' }
  & { brewer?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id' | 'name' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name'>
    )> }
  )> }
);

export type GbeerSearchQueryVariables = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GbeerSearchQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'ReviewedBeerList' }
    & { items: Array<(
      { __typename?: 'ReviewedBeer' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl' | 'id'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type GAddBrewerMutationVariables = {
  input: GAddBrewerInput;
};


export type GAddBrewerMutation = (
  { __typename?: 'Mutation' }
  & { addBrewer: (
    { __typename?: 'AddBrewerPayload' }
    & { brewer: (
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'description'>
    ) }
  ) }
);

export type GAddPlaceMutationVariables = {
  input: GAddPlaceInput;
};


export type GAddPlaceMutation = (
  { __typename?: 'Mutation' }
  & { addPlace: (
    { __typename?: 'AddPlacePayload' }
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'description'>
    ) }
  ) }
);

export type GBeerMapPlaceSearchQueryVariables = {
  order?: Maybe<GSearchOrder>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  filters?: Maybe<GPlaceSearchFilters>;
  query?: Maybe<Scalars['String']>;
};


export type GBeerMapPlaceSearchQuery = (
  { __typename?: 'Query' }
  & { placeSearch: (
    { __typename?: 'PlaceList' }
    & Pick<GPlaceList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'latitude' | 'longitude' | 'streetAddress' | 'city' | 'rateCount' | 'imageUrl' | 'percentile' | 'phone' | 'hours' | 'bottles' | 'taps' | 'wifi'>
      & { type?: Maybe<(
        { __typename?: 'PlaceType' }
        & Pick<GPlaceType, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )> }
    )> }
  ) }
);

export type GBeerMapPlaceTypesQueryQueryVariables = {};


export type GBeerMapPlaceTypesQueryQuery = (
  { __typename?: 'Query' }
  & { placeTypes: Array<(
    { __typename?: 'PlaceType' }
    & Pick<GPlaceType, 'id' | 'name'>
  )> }
);

export type GPlaceQueryQueryVariables = {
  placeId: Scalars['ID'];
};


export type GPlaceQueryQuery = (
  { __typename?: 'Query' }
  & { place?: Maybe<(
    { __typename?: 'Place' }
    & Pick<GPlace, 'id' | 'name' | 'latitude' | 'longitude' | 'streetAddress' | 'city' | 'rateCount' | 'imageUrl' | 'percentile' | 'phone' | 'hours' | 'bottles' | 'taps' | 'wifi'>
    & { type?: Maybe<(
      { __typename?: 'PlaceType' }
      & Pick<GPlaceType, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  )> }
);

export type GBeerAliasPageQueryVariables = {
  aliasId: Scalars['ID'];
};


export type GBeerAliasPageQuery = (
  { __typename?: 'Query' }
  & { beerByAlias?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'isVerified' | 'isRetired'>
  )> }
);

export type GShopInfoQueryVariables = {
  latlng: Array<Scalars['Float']>;
};


export type GShopInfoQuery = (
  { __typename?: 'Query' }
  & { shopInfo?: Maybe<(
    { __typename?: 'ShopInfoPayload' }
    & { retailers: Array<(
      { __typename?: 'Retailer' }
      & Pick<GRetailer, 'id'>
    )> }
  )> }
);

export type GplaceSearchQueryVariables = {
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type GplaceSearchQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'PlaceList' }
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'city'>
    )> }
  ) }
);

export type GLocationDropdownNearbyPlacesQueryVariables = {
  latlng: Array<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
};


export type GLocationDropdownNearbyPlacesQuery = (
  { __typename?: 'Query' }
  & { results?: Maybe<(
    { __typename?: 'PlaceList' }
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'city'>
    )> }
  )> }
);

export type GBeerReviewsQueryVariables = {
  beerId: Scalars['ID'];
  authorId?: Maybe<Scalars['ID']>;
  order?: Maybe<GReviewOrder>;
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  includePrivate?: Maybe<Scalars['Boolean']>;
};


export type GBeerReviewsQuery = (
  { __typename?: 'Query' }
  & { beerList: (
    { __typename?: 'ReviewList' }
    & Pick<GReviewList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Review' }
      & GReviewItemFragment
    )> }
  ) }
);

export type GReviewItemFragment = (
  { __typename?: 'Review' }
  & Pick<GReview, 'id' | 'comment' | 'score' | 'servedIn' | 'likeCount' | 'likedByMe' | 'createdAt' | 'updatedAt'>
  & { scores: (
    { __typename?: 'ReviewScores' }
    & Pick<GReviewScores, 'appearance' | 'aroma' | 'flavor' | 'mouthfeel' | 'overall'>
  ), author: (
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'reviewCount' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  ), checkin?: Maybe<(
    { __typename?: 'Checkin' }
    & Pick<GCheckin, 'id'>
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'city'>
      & { state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GfriendBeerReviewsQueryVariables = {
  beerId: Scalars['ID'];
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
};


export type GfriendBeerReviewsQuery = (
  { __typename?: 'Query' }
  & { beerList?: Maybe<(
    { __typename?: 'ReviewList' }
    & Pick<GReviewList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Review' }
      & GReviewItemFragment
    )> }
  )> }
);

export type GReviewFragFragment = (
  { __typename?: 'Review' }
  & Pick<GReview, 'id' | 'comment' | 'score' | 'servedIn' | 'updatedAt' | 'likeCount' | 'likedByMe'>
  & { scores: (
    { __typename?: 'ReviewScores' }
    & Pick<GReviewScores, 'appearance' | 'aroma' | 'flavor' | 'mouthfeel' | 'overall'>
  ), checkin?: Maybe<(
    { __typename?: 'Checkin' }
    & Pick<GCheckin, 'id'>
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name'>
    ) }
  )>, author: (
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'reviewCount' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name'>
    )> }
  ) }
);

export type GmyBeerReview2QueryVariables = {
  beerId: Scalars['ID'];
};


export type GmyBeerReview2Query = (
  { __typename?: 'Query' }
  & { myReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingInsert2MutationVariables = {
  beerId: Scalars['ID'];
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
  score?: Maybe<Scalars['Float']>;
};


export type GmobileRatingInsert2Mutation = (
  { __typename?: 'Mutation' }
  & { userReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingUpdate2MutationVariables = {
  id: Scalars['ID'];
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
  score?: Maybe<Scalars['Float']>;
};


export type GmobileRatingUpdate2Mutation = (
  { __typename?: 'Mutation' }
  & { userReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingDelete2MutationVariables = {
  id: Scalars['ID'];
};


export type GmobileRatingDelete2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'mobileRatingDelete'>
);

export type GUserBeerReviewQueryVariables = {
  beerId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type GUserBeerReviewQuery = (
  { __typename?: 'Query' }
  & { userBeerReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewItemFragment
  )> }
);

export type GupdateBeerRetiredMutationVariables = {
  beerId: Scalars['ID'];
  retired: Scalars['Boolean'];
};


export type GupdateBeerRetiredMutation = (
  { __typename?: 'Mutation' }
  & { updateBeer: (
    { __typename?: 'UpdateBeerPayload' }
    & { beer: (
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'isRetired'>
    ) }
  ) }
);

export type GupdateBeerVerifiedMutationVariables = {
  beerId: Scalars['ID'];
  verified: Scalars['Boolean'];
};


export type GupdateBeerVerifiedMutation = (
  { __typename?: 'Mutation' }
  & { updateBeer: (
    { __typename?: 'UpdateBeerPayload' }
    & { beer: (
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'isVerified'>
    ) }
  ) }
);

export type GGetBeerEditedInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GGetBeerEditedInfoQuery = (
  { __typename?: 'Query' }
  & { beer?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'createdAt'>
    & { updatedBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'username'>
    )>, createdBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'username'>
    )> }
  )> }
);

export type GreviewLikeMutationVariables = {
  id: Scalars['ID'];
};


export type GreviewLikeMutation = (
  { __typename?: 'Mutation' }
  & { reviewLike?: Maybe<(
    { __typename?: 'ReviewLike' }
    & { review: (
      { __typename?: 'Review' }
      & Pick<GReview, 'id' | 'likedByMe' | 'likeCount'>
    ) }
  )> }
);

export type GreviewUnlikeMutationVariables = {
  id: Scalars['ID'];
};


export type GreviewUnlikeMutation = (
  { __typename?: 'Mutation' }
  & { reviewUnlike?: Maybe<(
    { __typename?: 'Review' }
    & Pick<GReview, 'id' | 'likedByMe' | 'likeCount'>
  )> }
);

export type GBeerJSONLDQueryVariables = {
  beerId: Scalars['ID'];
};


export type GBeerJSONLDQuery = (
  { __typename?: 'Query' }
  & { beer?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'description' | 'averageQuickRating' | 'ratingsCount' | 'imageUrl'>
    & { brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name'>
    )> }
  )>, beerReviews: (
    { __typename?: 'ReviewList' }
    & { items: Array<(
      { __typename?: 'Review' }
      & Pick<GReview, 'id' | 'comment' | 'score' | 'createdAt'>
      & { author: (
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id' | 'username'>
      ) }
    )> }
  ) }
);

export type GReviewFragmentFragment = (
  { __typename?: 'Review' }
  & Pick<GReview, 'id' | 'comment' | 'score' | 'servedIn' | 'updatedAt' | 'createdAt' | 'likeCount' | 'likedByMe'>
  & { scores: (
    { __typename?: 'ReviewScores' }
    & Pick<GReviewScores, 'appearance' | 'aroma' | 'flavor' | 'mouthfeel' | 'overall'>
  ), checkin?: Maybe<(
    { __typename?: 'Checkin' }
    & Pick<GCheckin, 'id'>
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name'>
    ) }
  )>, author: (
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'reviewCount' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name'>
    )> }
  ) }
);

export type GmyBeerReviewQueryVariables = {
  beerId: Scalars['ID'];
};


export type GmyBeerReviewQuery = (
  { __typename?: 'Query' }
  & { myReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingInsertMutationVariables = {
  beerId: Scalars['ID'];
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
  score?: Maybe<Scalars['Float']>;
};


export type GmobileRatingInsertMutation = (
  { __typename?: 'Mutation' }
  & { userReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingUpdateMutationVariables = {
  id: Scalars['ID'];
  scores?: Maybe<GReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  servedIn?: Maybe<GServedIn>;
  score?: Maybe<Scalars['Float']>;
};


export type GmobileRatingUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userReview?: Maybe<(
    { __typename?: 'Review' }
    & GReviewFragmentFragment
  )> }
);

export type GmobileRatingDeleteMutationVariables = {
  id: Scalars['ID'];
};


export type GmobileRatingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'mobileRatingDelete'>
);

export type GisFavoriteQueryVariables = {
  beerId: Scalars['ID'];
};


export type GisFavoriteQuery = (
  { __typename?: 'Query' }
  & { isFavorite?: Maybe<(
    { __typename?: 'Favorite' }
    & Pick<GFavorite, 'id'>
  )> }
);

export type GfavoriteInsertMutationVariables = {
  beerId: Scalars['ID'];
};


export type GfavoriteInsertMutation = (
  { __typename?: 'Mutation' }
  & { favoriteInsert?: Maybe<(
    { __typename?: 'Favorite' }
    & Pick<GFavorite, 'id'>
  )> }
);

export type GfavoriteDeleteMutationVariables = {
  id: Scalars['ID'];
};


export type GfavoriteDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'favoriteDelete'>
);

export type GbeerQueryVariables = {
  beerId: Scalars['ID'];
};


export type GbeerQuery = (
  { __typename?: 'Query' }
  & { beer?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'imageUrl' | 'isAlias' | 'description' | 'styleScore' | 'overallScore' | 'averageQuickRating' | 'normalizedAverageReview' | 'averageReview' | 'createdAt' | 'updatedAt' | 'abv' | 'ibu' | 'calories' | 'ratingsCount' | 'reviewsCount' | 'isRetired' | 'isVerified' | 'isUnrateable' | 'seasonal' | 'labels'>
    & { style: (
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id' | 'name'>
      & { glasses: Array<(
        { __typename?: 'Glass' }
        & Pick<GGlass, 'id' | 'name'>
      )> }
    ), brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'city'>
      & { state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )>, affliatedUsers: Array<(
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id'>
      )> }
    )>, contractBrewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'city'>
      & { state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, affliatedUsers: Array<(
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )> }
    )>, availability: (
      { __typename?: 'BeerAvailability' }
      & Pick<GBeerAvailability, 'bottle' | 'tap' | 'distribution'>
    ) }
  )> }
);

export type GbeerInStockMultiQueryVariables = {
  latlng: Array<Scalars['Float']>;
  beerId: Scalars['ID'];
};


export type GbeerInStockMultiQuery = (
  { __typename?: 'Query' }
  & { beerInStockMulti?: Maybe<Array<Maybe<(
    { __typename?: 'BeerPurchaseInfo' }
    & Pick<GBeerPurchaseInfo, 'price' | 'priceValue' | 'currencySymbol' | 'currency' | 'bottleSize' | 'productUrl' | 'productId'>
    & { store: (
      { __typename?: 'BeerStore' }
      & Pick<GBeerStore, 'id' | 'name' | 'url' | 'sellingPoints'>
      & { logo: (
        { __typename?: 'Image' }
        & Pick<GImage, 'uri'>
      ) }
    ) }
  )>>> }
);

export type GtagDisplayQueryVariables = {
  beerId: Scalars['ID'];
};


export type GtagDisplayQuery = (
  { __typename?: 'Query' }
  & { beerTags?: Maybe<(
    { __typename?: 'BeerTagList' }
    & { items: Array<Maybe<(
      { __typename?: 'BeerTag' }
      & Pick<GBeerTag, 'id' | 'plain'>
    )>> }
  )> }
);

export type GnearbyPlacesQueryVariables = {
  latlng: Array<Scalars['Float']>;
  beerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
};


export type GnearbyPlacesQuery = (
  { __typename?: 'Query' }
  & { nearbyPlaces?: Maybe<(
    { __typename?: 'PlaceList' }
    & Pick<GPlaceList, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'distance' | 'streetAddress' | 'hours' | 'latitude' | 'longitude' | 'city'>
      & { type?: Maybe<(
        { __typename?: 'PlaceType' }
        & Pick<GPlaceType, 'id' | 'name'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GBeeryChristmasQueryVariables = {
  latlng: Array<Scalars['Float']>;
};


export type GBeeryChristmasQuery = (
  { __typename?: 'Query' }
  & { regionInfo?: Maybe<(
    { __typename?: 'RegionInfoPayload' }
    & { country: (
      { __typename?: 'RegionCountry' }
      & Pick<GRegionCountry, 'id' | 'name' | 'code'>
    ) }
  )> }
);

export type GCloneBrewerMutationVariables = {
  input: GCloneBrewerToPlaceInput;
};


export type GCloneBrewerMutation = (
  { __typename?: 'Mutation' }
  & { cloneBrewerToPlace: (
    { __typename?: 'CloneBrewerToPlacePayload' }
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'rateCount' | 'percentile' | 'distance' | 'averageRating' | 'city' | 'isRetired' | 'imageUrl'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, type?: Maybe<(
        { __typename?: 'PlaceType' }
        & Pick<GPlaceType, 'name'>
      )> }
    ) }
  ) }
);

export type GGetBrewerBeersQueryVariables = {
  brewerId: Scalars['ID'];
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<GBrewerBeersInputOrder>;
  orderDirection?: Maybe<GOrderDirection>;
  minRatings?: Maybe<Scalars['Int']>;
  hideRetired?: Maybe<Scalars['Boolean']>;
  hideVerified?: Maybe<Scalars['Boolean']>;
  hideUnverified?: Maybe<Scalars['Boolean']>;
  hideAliased?: Maybe<Scalars['Boolean']>;
  hideUserRatedBeers?: Maybe<Scalars['Boolean']>;
  hideUserHasNotRated?: Maybe<Scalars['Boolean']>;
};


export type GGetBrewerBeersQuery = (
  { __typename?: 'Query' }
  & { brewerBeers: (
    { __typename?: 'BrewerBeersPayload' }
    & Pick<GBrewerBeersPayload, 'totalCount' | 'last' | 'totalBeersBrewed' | 'userReviewsCount'>
    & { items: Array<(
      { __typename?: 'BrewerBeersPayloadItem' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'createdAt' | 'abv' | 'overallScore' | 'styleScore' | 'ratingsCount' | 'averageQuickRating' | 'imageUrl' | 'isRetired' | 'isAlias' | 'isVerified'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )> }
        )>, contractBrewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )> }
        )> }
      ), review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<GReview, 'id' | 'score' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type GAssociateWithBrewerMutationVariables = {
  input: GAssociateUserWithEntityInput;
};


export type GAssociateWithBrewerMutation = (
  { __typename?: 'Mutation' }
  & { associateUserWithEntity: (
    { __typename?: 'AssociateUserWithEntityPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<GUser, 'id' | 'username'>
    ) }
  ) }
);

export type GFollowBrewerMutationVariables = {
  brewerId: Scalars['ID'];
};


export type GFollowBrewerMutation = (
  { __typename?: 'Mutation' }
  & { followBrewer: (
    { __typename?: 'FollowBrewerPayload' }
    & { brewer: (
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name'>
    ) }
  ) }
);

export type GUnfollowBrewerMutationVariables = {
  brewerId: Scalars['ID'];
};


export type GUnfollowBrewerMutation = (
  { __typename?: 'Mutation' }
  & { unfollowBrewer: (
    { __typename?: 'UnfollowBrewerPayload' }
    & { brewer: (
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name'>
    ) }
  ) }
);

export type GGetBrewerPageInfoQueryVariables = {
  brewerId: Scalars['ID'];
};


export type GGetBrewerPageInfoQuery = (
  { __typename?: 'Query' }
  & { brewer?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id' | 'name' | 'description' | 'email' | 'streetAddress' | 'city' | 'type' | 'zip' | 'twitter' | 'facebook' | 'instagram' | 'imageUrl' | 'isRetired' | 'phone' | 'areaCode' | 'web' | 'hours' | 'isFollowedByMe'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'code' | 'name'>
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & Pick<GState, 'name' | 'id'>
    )>, affliatedUsers: Array<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'username' | 'id'>
    )>, associatedPlaces: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'distance' | 'rateCount' | 'city' | 'isRetired' | 'percentile' | 'averageRating' | 'imageUrl'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, type?: Maybe<(
        { __typename?: 'PlaceType' }
        & Pick<GPlaceType, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GGetRecentBrewerReviewsQueryVariables = {
  brewerId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GGetRecentBrewerReviewsQuery = (
  { __typename?: 'Query' }
  & { brewerBeerReviews: (
    { __typename?: 'BrewerBeerReviewsPayload' }
    & Pick<GBrewerBeerReviewsPayload, 'last'>
    & { items: Array<(
      { __typename?: 'BrewerBeerReviewsPayloadItem' }
      & { review: (
        { __typename?: 'Review' }
        & Pick<GReview, 'comment' | 'id' | 'score' | 'createdAt' | 'likedByMe' | 'likeCount'>
        & { author: (
          { __typename?: 'PublicUser' }
          & Pick<GPublicUser, 'id' | 'username' | 'imageUrl' | 'reviewCount'>
        ), beer: (
          { __typename?: 'Beer' }
          & Pick<GBeer, 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GGetTopRatersQueryVariables = {
  brewerId: Scalars['ID'];
};


export type GGetTopRatersQuery = (
  { __typename?: 'Query' }
  & { brewerTopRaters: (
    { __typename?: 'BrewerTopRatersPayload' }
    & { topRaters: Array<(
      { __typename?: 'TopRaterInfo' }
      & Pick<GTopRaterInfo, 'reviewCount'>
      & { user: (
        { __typename?: 'User' }
        & Pick<GUser, 'id' | 'username' | 'city' | 'imageUrl'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )> }
      ) }
    )> }
  ) }
);

export type GUpdateBeerMutationVariables = {
  input: GUpdateBeerInput;
};


export type GUpdateBeerMutation = (
  { __typename?: 'Mutation' }
  & { updateBeer: (
    { __typename?: 'UpdateBeerPayload' }
    & { beer: (
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'name' | 'isVerified'>
    ) }
  ) }
);

export type GGetBeerInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GGetBeerInfoQuery = (
  { __typename?: 'Query' }
  & { beer?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'description' | 'styleScore' | 'overallScore' | 'averageQuickRating' | 'createdAt' | 'adminNotes' | 'isRetired' | 'isUnrateable' | 'updatedAt' | 'abv' | 'ibu' | 'seasonal' | 'isVerified' | 'imageUrl' | 'ratingsCount'>
    & { createdBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    )>, brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'name' | 'id' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'name' | 'id' | 'code'>
      )>, affliatedUsers: Array<(
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id'>
      )> }
    )>, contractBrewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'name' | 'id' | 'city'>
      & { affliatedUsers: Array<(
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'name' | 'id' | 'code'>
      )> }
    )>, style: (
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'BeerStyle' }
        & Pick<GBeerStyle, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GEditBrewerPreviewDataQueryVariables = {
  brewerId: Scalars['ID'];
};


export type GEditBrewerPreviewDataQuery = (
  { __typename?: 'Query' }
  & { brewer?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id' | 'createdAt' | 'updatedAt' | 'imageUrl'>
    & { createdBy: (
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    ), updatedBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    )> }
  )> }
);

export type GEditBrewerInitialValuesQueryVariables = {
  brewerId: Scalars['ID'];
};


export type GEditBrewerInitialValuesQuery = (
  { __typename?: 'Query' }
  & { brewer?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id' | 'dateOpened' | 'isRetired' | 'name' | 'description' | 'streetAddress' | 'city' | 'areaCode' | 'zip' | 'phone' | 'email' | 'web' | 'twitter' | 'facebook' | 'instagram' | 'hours' | 'adminNotes' | 'imageUrl'>
    & { brewerType?: Maybe<(
      { __typename?: 'BrewerType' }
      & Pick<GBrewerType, 'id' | 'name'>
    )>, metro?: Maybe<(
      { __typename?: 'Metro' }
      & Pick<GMetro, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )>, affliatedUsers: Array<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id'>
    )> }
  )> }
);

export type GUpdateBrewerMutationVariables = {
  input: GUpdateBrewerInput;
};


export type GUpdateBrewerMutation = (
  { __typename?: 'Mutation' }
  & { updateBrewer?: Maybe<(
    { __typename?: 'UpdateBrewerPayload' }
    & { brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type GUploadBrewerImageMutationVariables = {
  input: GUploadBrewerImageInput;
};


export type GUploadBrewerImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadBrewerImage: (
    { __typename?: 'UploadBrewerImagePayload' }
    & { brewer: (
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'imageUrl'>
    ) }
  ) }
);

export type GUpdatePlaceIntiialValuesQueryVariables = {
  placeId: Scalars['ID'];
};


export type GUpdatePlaceIntiialValuesQuery = (
  { __typename?: 'Query' }
  & { place?: Maybe<(
    { __typename?: 'Place' }
    & Pick<GPlace, 'id' | 'name' | 'bottles' | 'taps' | 'description' | 'latitude' | 'longitude' | 'twitter' | 'facebook' | 'web' | 'streetAddress' | 'city' | 'zip' | 'countryCode' | 'areaCode' | 'phone' | 'imageUrl' | 'isRetired' | 'hours' | 'beerMenu' | 'foodMenu' | 'wifi' | 'flights' | 'email' | 'singles' | 'mailOrder' | 'mailOrderIntl' | 'children' | 'seasonals' | 'patio' | 'realAles' | 'growlers' | 'glassware' | 'dateOpened'>
    & { brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name'>
    )>, type?: Maybe<(
      { __typename?: 'PlaceType' }
      & Pick<GPlaceType, 'id' | 'name'>
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & Pick<GState, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )>, metro?: Maybe<(
      { __typename?: 'Metro' }
      & Pick<GMetro, 'id' | 'name'>
    )> }
  )> }
);

export type GUpdatePlaceMutationVariables = {
  input: GUpdatePlaceInput;
};


export type GUpdatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlace: (
    { __typename?: 'UpdatePlacePayload' }
    & { place: (
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'description'>
    ) }
  ) }
);

export type GEditPlacePreviewDataQueryVariables = {
  placeId: Scalars['ID'];
};


export type GEditPlacePreviewDataQuery = (
  { __typename?: 'Query' }
  & { place?: Maybe<(
    { __typename?: 'Place' }
    & Pick<GPlace, 'id' | 'createdAt' | 'updatedAt' | 'imageUrl'>
    & { createdBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    )>, updatedBy?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username'>
    )> }
  )> }
);

export type GFavoritesListQueryVariables = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GFavoritesListQuery = (
  { __typename?: 'Query' }
  & { favorites: (
    { __typename?: 'FavoriteList' }
    & Pick<GFavoriteList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Favorite' }
      & Pick<GFavorite, 'id' | 'createdAt'>
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'styleScore' | 'overallScore' | 'imageUrl'>
      ), review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<GReview, 'id' | 'score'>
      )> }
    )> }
  ) }
);

export type GDeleteFavoriteMutationVariables = {
  id: Scalars['ID'];
};


export type GDeleteFavoriteMutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'favoriteDelete'>
);

export type GfestivalBeersQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  styleId?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  order?: Maybe<GFestivalBeersOrder>;
  query?: Maybe<Scalars['String']>;
};


export type GfestivalBeersQuery = (
  { __typename?: 'Query' }
  & { festivalBeers?: Maybe<(
    { __typename?: 'FestivalBeersList' }
    & Pick<GFestivalBeersList, 'totalCount' | 'last'>
    & { items: Array<Maybe<(
      { __typename?: 'ReviewedBeer' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl' | 'isRetired' | 'isAlias'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
        )> }
      ) }
    )>> }
  )> }
);

export type GfestivalQueryVariables = {
  id: Scalars['ID'];
};


export type GfestivalQuery = (
  { __typename?: 'Query' }
  & { festival?: Maybe<(
    { __typename?: 'Festival' }
    & Pick<GFestival, 'id' | 'createdAt' | 'name' | 'description' | 'start' | 'end' | 'radius' | 'latlng' | 'distance' | 'address' | 'city' | 'zip' | 'url'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name'>
    )>, images?: Maybe<(
      { __typename?: 'FestivalImages' }
      & Pick<GFestivalImages, 'logo' | 'hero' | 'banner'>
    )> }
  )> }
);

export type GfestivalStylesQueryVariables = {
  festivalId: Scalars['ID'];
};


export type GfestivalStylesQuery = (
  { __typename?: 'Query' }
  & { festivalStyles?: Maybe<Array<Maybe<(
    { __typename?: 'BeerStyle' }
    & Pick<GBeerStyle, 'id' | 'name'>
  )>>> }
);

export type GGetTopBeersQueryVariables = {};


export type GGetTopBeersQuery = (
  { __typename?: 'Query' }
  & { topBeersSearch?: Maybe<(
    { __typename?: 'TopBeersSearchPayload' }
    & { items: Array<(
      { __typename?: 'Beer' }
      & GBeerInfoFragment
    )> }
  )> }
);

export type GBeerInfoFragment = (
  { __typename?: 'Beer' }
  & Pick<GBeer, 'id' | 'name' | 'ratingsCount' | 'averageQuickRating' | 'overallScore' | 'styleScore' | 'imageUrl' | 'abv'>
  & { style: (
    { __typename?: 'BeerStyle' }
    & Pick<GBeerStyle, 'name'>
  ), brewer?: Maybe<(
    { __typename?: 'Brewer' }
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'code'>
    )> }
  )>, contractBrewer?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  )> }
);

export type GGetTopTrendingBeersQueryVariables = {};


export type GGetTopTrendingBeersQuery = (
  { __typename?: 'Query' }
  & { topBeersSearch?: Maybe<(
    { __typename?: 'TopBeersSearchPayload' }
    & { items: Array<(
      { __typename?: 'Beer' }
      & GBeerInfoFragment
    )> }
  )> }
);

export type GGetBrewerInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GGetBrewerInfoQuery = (
  { __typename?: 'Query' }
  & { brewerInfo?: Maybe<(
    { __typename?: 'Brewer' }
    & Pick<GBrewer, 'id' | 'name' | 'city' | 'type' | 'imageUrl'>
    & { state?: Maybe<(
      { __typename?: 'State' }
      & Pick<GState, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  )> }
);

export type GMyYearInBeerQueryVariables = {
  userId: Scalars['ID'];
  year: Scalars['Int'];
};


export type GMyYearInBeerQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'imageUrl'>
  )>, myTopRatedBeers: (
    { __typename?: 'MyTopRatedBeersPayload' }
    & { items: Array<(
      { __typename?: 'MyTopRatedBeersPayloadItem' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'imageUrl' | 'averageQuickRating' | 'ratingsCount' | 'isRetired' | 'overallScore' | 'styleScore' | 'abv'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), contractBrewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'code'>
          )> }
        )>, brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'code'>
          )> }
        )> }
      ), review: (
        { __typename?: 'Review' }
        & Pick<GReview, 'id' | 'score' | 'comment' | 'createdAt'>
      ) }
    )> }
  ), myTopRatedPlaces: (
    { __typename?: 'MyTopRatedPlacesPayload' }
    & { items: Array<(
      { __typename?: 'MyTopRatedPlacesPayloadItem' }
      & { place: (
        { __typename?: 'Place' }
        & Pick<GPlace, 'id' | 'name' | 'rateCount' | 'averageRating' | 'createdAt' | 'city' | 'percentile' | 'imageUrl'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name'>
        )>, state?: Maybe<(
          { __typename?: 'State' }
          & Pick<GState, 'id' | 'name'>
        )> }
      ), review: (
        { __typename?: 'PlaceReview' }
        & Pick<GPlaceReview, 'id' | 'score' | 'comment' | 'createdAt'>
      ) }
    )> }
  ), myTopRatedStyles: (
    { __typename?: 'MyTopRatedStylesPayload' }
    & { items: Array<(
      { __typename?: 'MyTopRatedStylesPayloadItem' }
      & Pick<GMyTopRatedStylesPayloadItem, 'averageRating' | 'ratingsCount'>
      & { style: (
        { __typename?: 'BeerStyle' }
        & Pick<GBeerStyle, 'id' | 'name'>
      ) }
    )> }
  ), myYearInBeerSummary: (
    { __typename?: 'MyYearInBeerSummaryPayload' }
    & Pick<GMyYearInBeerSummaryPayload, 'stylesRated' | 'ratingsCount' | 'averageRating' | 'breweriesRated' | 'openingBreweriesRated' | 'firstTimeBreweriesRated'>
  ) }
);

export type GGetPlaceInventoryQueryVariables = {
  placeId: Scalars['ID'];
  order?: Maybe<GPlaceInventoryOrder>;
  keyword?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GGetPlaceInventoryQuery = (
  { __typename?: 'Query' }
  & { placeInventory: (
    { __typename?: 'PlaceInventory' }
    & Pick<GPlaceInventory, 'totalCount' | 'last'>
    & { inventoryItems: Array<(
      { __typename?: 'PlaceInventoryItem' }
      & Pick<GPlaceInventoryItem, 'lastUpdated'>
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl' | 'isRetired' | 'isAlias'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type GBeersSpottedHereQueryVariables = {
  placeId: Scalars['ID'];
  order?: Maybe<GPlaceInventoryOrder>;
  keyword?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GBeersSpottedHereQuery = (
  { __typename?: 'Query' }
  & { placeInventory: (
    { __typename?: 'PlaceInventory' }
    & Pick<GPlaceInventory, 'totalCount' | 'last'>
    & { inventoryItems: Array<(
      { __typename?: 'PlaceInventoryItem' }
      & Pick<GPlaceInventoryItem, 'lastUpdated'>
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl' | 'isRetired' | 'isAlias'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GPlaceCheckinStatusQueryVariables = {
  placeId: Scalars['ID'];
};


export type GPlaceCheckinStatusQuery = (
  { __typename?: 'Query' }
  & { place?: Maybe<(
    { __typename?: 'Place' }
    & Pick<GPlace, 'id'>
    & { lastCheckedIn?: Maybe<(
      { __typename?: 'UserActivity' }
      & Pick<GUserActivity, 'id' | 'createdAt'>
    )> }
  )> }
);

export type GPlaceCheckinMutationVariables = {
  input: GCheckInPlaceInput;
};


export type GPlaceCheckinMutation = (
  { __typename?: 'Mutation' }
  & { checkInPlace: (
    { __typename?: 'CheckInPlacePayload' }
    & { userActivity: (
      { __typename?: 'UserActivity' }
      & Pick<GUserActivity, 'id' | 'createdAt'>
    ) }
  ) }
);

export type GLikePlaceReviewMutationVariables = {
  input: GLikePlaceReviewInput;
};


export type GLikePlaceReviewMutation = (
  { __typename?: 'Mutation' }
  & { likePlaceReview: (
    { __typename?: 'LikePlaceReviewPayload' }
    & { review: (
      { __typename?: 'PlaceReview' }
      & Pick<GPlaceReview, 'id' | 'likeCount' | 'likedByMe'>
    ) }
  ) }
);

export type GUnlikePlaceReviewMutationVariables = {
  input: GUnlikePlaceReviewInput;
};


export type GUnlikePlaceReviewMutation = (
  { __typename?: 'Mutation' }
  & { unlikePlaceReview: (
    { __typename?: 'UnlikePlaceReviewPayload' }
    & { review: (
      { __typename?: 'PlaceReview' }
      & Pick<GPlaceReview, 'id' | 'likeCount' | 'likedByMe'>
    ) }
  ) }
);

export type GPlaceReviewsListQueryVariables = {
  input?: Maybe<GPlaceReviewsInput>;
};


export type GPlaceReviewsListQuery = (
  { __typename?: 'Query' }
  & { placeReviews: (
    { __typename?: 'PlaceReviewsPayload' }
    & Pick<GPlaceReviewsPayload, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'PlaceReview' }
      & GPlaceReviewFieldsFragment
    )> }
  ) }
);

export type GPlaceReviewFieldsFragment = (
  { __typename?: 'PlaceReview' }
  & Pick<GPlaceReview, 'id' | 'comment' | 'createdAt' | 'updatedAt' | 'likeCount' | 'likedByMe' | 'score'>
  & { author: (
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'imageUrl' | 'placeReviewCount' | 'city'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  ), scores: (
    { __typename?: 'PlaceReviewScores' }
    & Pick<GPlaceReviewScores, 'ambiance' | 'service' | 'selection' | 'food' | 'value' | 'overall'>
  ), place: (
    { __typename?: 'Place' }
    & Pick<GPlace, 'id'>
  ) }
);

export type GplaceReviewInsertMutationVariables = {
  placeId: Scalars['ID'];
  scores: GPlaceReviewScoresInput;
  comment?: Maybe<Scalars['String']>;
};


export type GplaceReviewInsertMutation = (
  { __typename?: 'Mutation' }
  & { placeReviewInsert?: Maybe<(
    { __typename?: 'PlaceReview' }
    & { scores: (
      { __typename?: 'PlaceReviewScores' }
      & Pick<GPlaceReviewScores, 'ambiance' | 'service' | 'selection' | 'food' | 'value' | 'overall'>
    ) }
    & GPlaceReviewFieldsFragment
  )> }
);

export type GplaceReviewUpdateMutationVariables = {
  reviewId: Scalars['ID'];
  scores?: Maybe<GPlaceReviewScoresInput>;
  comment?: Maybe<Scalars['String']>;
};


export type GplaceReviewUpdateMutation = (
  { __typename?: 'Mutation' }
  & { placeReviewUpdate?: Maybe<(
    { __typename?: 'PlaceReview' }
    & { scores: (
      { __typename?: 'PlaceReviewScores' }
      & Pick<GPlaceReviewScores, 'ambiance' | 'service' | 'selection' | 'food' | 'value' | 'overall'>
    ) }
    & GPlaceReviewFieldsFragment
  )> }
);

export type GPlaceReviewDeleteMutationVariables = {
  id: Scalars['ID'];
};


export type GPlaceReviewDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<GMutation, 'placeReviewDelete'>
);

export type GPlaceReviewQueryQueryVariables = {
  placeId: Scalars['ID'];
  authorId?: Maybe<Scalars['ID']>;
};


export type GPlaceReviewQueryQuery = (
  { __typename?: 'Query' }
  & { placeReviews: (
    { __typename?: 'PlaceReviewsPayload' }
    & { items: Array<(
      { __typename?: 'PlaceReview' }
      & Pick<GPlaceReview, 'id' | 'comment' | 'score' | 'createdAt' | 'updatedAt'>
      & { scores: (
        { __typename?: 'PlaceReviewScores' }
        & Pick<GPlaceReviewScores, 'ambiance' | 'service' | 'selection' | 'food' | 'value' | 'overall'>
      ), author: (
        { __typename?: 'PublicUser' }
        & Pick<GPublicUser, 'id'>
      ) }
    )> }
  ) }
);

export type GPlaceProfilePlaceStoreQueryVariables = {
  placeId: Scalars['ID'];
};


export type GPlaceProfilePlaceStoreQuery = (
  { __typename?: 'Query' }
  & { place?: Maybe<(
    { __typename?: 'Place' }
    & Pick<GPlace, 'id' | 'name' | 'description' | 'imageUrl' | 'averageRating' | 'rateCount' | 'percentile' | 'latitude' | 'longitude' | 'twitter' | 'facebook' | 'web' | 'email' | 'streetAddress' | 'city' | 'zip' | 'countryCode' | 'areaCode' | 'phone' | 'hours' | 'foodMenu' | 'beerMenu' | 'taps' | 'bottles' | 'wifi' | 'flights' | 'isRetired' | 'children' | 'patio' | 'growlers'>
    & { type?: Maybe<(
      { __typename?: 'PlaceType' }
      & Pick<GPlaceType, 'id' | 'name'>
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & Pick<GState, 'id' | 'name' | 'code'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )>, mayor?: Maybe<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'username' | 'imageUrl'>
    )>, brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'imageUrl' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )> }
    )> }
  )> }
);

export type GplaceReviewsQueryVariables = {
  placeId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GplaceReviewsQuery = (
  { __typename?: 'Query' }
  & { placeReviews: (
    { __typename?: 'PlaceReviewsPayload' }
    & Pick<GPlaceReviewsPayload, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'PlaceReview' }
      & GPlaceReviewFieldsFragment
    )> }
  ) }
);

export type GUpdateUserProfileMutationVariables = {
  input: GUpdateUserInput;
};


export type GUpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserPayload' }
    & { user: (
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id'>
    ) }
  ) }
);

export type GUpdateUserProfileUserQueryVariables = {};


export type GUpdateUserProfileUserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<GUser, 'id' | 'username' | 'biography' | 'birthday' | 'city' | 'zip' | 'email' | 'showTradeForums' | 'notifyEmails' | 'notifyFreebies' | 'forumNotifications' | 'imageUrl'>
    & { favoriteBeerStyle?: Maybe<(
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )> }
  )> }
);

export type GUpdateUserProfileImageMutationVariables = {
  profileImage: Scalars['Upload'];
};


export type GUpdateUserProfileImageMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateProfileImage: (
    { __typename?: 'User' }
    & Pick<GUser, 'id' | 'imageUrl'>
  ) }
);

export type GBeerByAliasQueryVariables = {
  aliasId: Scalars['ID'];
};


export type GBeerByAliasQuery = (
  { __typename?: 'Query' }
  & { beerByAlias?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'isVerified'>
  )> }
);

export type GSearchResultsBeerQueryVariables = {
  includePurchaseOptions: Scalars['Boolean'];
  latlng: Array<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GSearchResultsBeerQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'ReviewedBeerList' }
    & Pick<GReviewedBeerList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'ReviewedBeer' }
      & { review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<GReview, 'id' | 'score' | 'likedByMe' | 'updatedAt' | 'createdAt'>
      )>, beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl' | 'isRetired' | 'isAlias'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'code'>
          )> }
        )>, contractBrewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'code'>
          )> }
        )>, purchaseOptions: (
          { __typename?: 'BeerPurchaseInfoList' }
          & { items: Array<(
            { __typename?: 'BeerPurchaseInfo' }
            & Pick<GBeerPurchaseInfo, 'productId' | 'price' | 'currency' | 'currencySymbol' | 'priceValue'>
            & { store: (
              { __typename?: 'BeerStore' }
              & Pick<GBeerStore, 'id'>
            ) }
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type GSearchResultsBrewerQueryVariables = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GSearchResultsBrewerQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'BrewerList' }
    & Pick<GBrewerList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name' | 'imageUrl' | 'type' | 'city'>
      & { state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type GSearchResultsPlaceQueryVariables = {
  query?: Maybe<Scalars['String']>;
  latlng?: Maybe<Array<Maybe<Scalars['Float']>>>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GSearchResultsPlaceQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'PlaceList' }
    & Pick<GPlaceList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id' | 'name' | 'averageRating' | 'percentile' | 'rateCount' | 'imageUrl' | 'distance' | 'city'>
      & { type?: Maybe<(
        { __typename?: 'PlaceType' }
        & Pick<GPlaceType, 'id' | 'name'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name'>
      )>, userReviews?: Maybe<Array<(
        { __typename?: 'PlaceReview' }
        & Pick<GPlaceReview, 'id' | 'comment' | 'score' | 'updatedAt' | 'createdAt'>
      )>> }
    )> }
  ) }
);

export type GSearchResultsUserQueryVariables = {
  query?: Maybe<Scalars['String']>;
  order?: Maybe<GSearchOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};


export type GSearchResultsUserQuery = (
  { __typename?: 'Query' }
  & { results: (
    { __typename?: 'PublicUserList' }
    & Pick<GPublicUserList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'id' | 'imageUrl' | 'username' | 'reviewCount'>
    )> }
  ) }
);

export type GPlaceBeerShelfTagsQueryVariables = {
  placeId: Scalars['ID'];
};


export type GPlaceBeerShelfTagsQuery = (
  { __typename?: 'Query' }
  & { placeInventory: (
    { __typename?: 'PlaceInventory' }
    & Pick<GPlaceInventory, 'totalCount'>
    & { items: Array<(
      { __typename?: 'PlaceInventoryItem' }
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'abv' | 'ibu' | 'styleScore' | 'overallScore' | 'ratingsCount'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'city'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )>, state?: Maybe<(
            { __typename?: 'State' }
            & Pick<GState, 'id' | 'name'>
          )> }
        )>, contractBrewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'city'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )>, state?: Maybe<(
            { __typename?: 'State' }
            & Pick<GState, 'id' | 'name'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GShelfTagBeerQueryVariables = {
  beerId: Scalars['ID'];
};


export type GShelfTagBeerQuery = (
  { __typename?: 'Query' }
  & { beer?: Maybe<(
    { __typename?: 'Beer' }
    & Pick<GBeer, 'id' | 'name' | 'abv' | 'ibu' | 'styleScore' | 'overallScore' | 'ratingsCount'>
    & { style: (
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id' | 'name'>
    ), brewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )> }
    )>, contractBrewer?: Maybe<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'city'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<GCountry, 'id' | 'name' | 'code'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<GState, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GBeerShopSearchQueryVariables = {
  query?: Maybe<Scalars['String']>;
  retailerIds?: Maybe<Array<Scalars['ID']>>;
  beerStyleIds?: Maybe<Array<Scalars['ID']>>;
  originCountryIds?: Maybe<Array<Scalars['ID']>>;
  scoreRanges?: Maybe<Array<GScoreRange>>;
  latlng: Array<Scalars['Float']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
  order: GBeerShopSearchResultOrder;
};


export type GBeerShopSearchQuery = (
  { __typename?: 'Query' }
  & { results?: Maybe<(
    { __typename?: 'BeerList' }
    & Pick<GBeerList, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'name' | 'description' | 'overallScore' | 'styleScore' | 'averageQuickRating' | 'abv' | 'ibu' | 'ratingsCount' | 'imageUrl'>
      & { style: (
        { __typename?: 'BeerStyle' }
        & Pick<GBeerStyle, 'id' | 'name'>
      ), brewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id' | 'name' | 'city'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'code' | 'name'>
        )>, state?: Maybe<(
          { __typename?: 'State' }
          & Pick<GState, 'id' | 'name'>
        )> }
      )>, contractBrewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id' | 'name' | 'city'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )>, state?: Maybe<(
          { __typename?: 'State' }
          & Pick<GState, 'id' | 'name'>
        )> }
      )>, purchaseOptions: (
        { __typename?: 'BeerPurchaseInfoList' }
        & { items: Array<(
          { __typename?: 'BeerPurchaseInfo' }
          & Pick<GBeerPurchaseInfo, 'productId' | 'productUrl' | 'price' | 'priceValue' | 'bottleSize' | 'currency' | 'currencySymbol'>
          & { store: (
            { __typename?: 'BeerStore' }
            & Pick<GBeerStore, 'id' | 'name' | 'url' | 'sellingPoints'>
            & { logo: (
              { __typename?: 'Image' }
              & Pick<GImage, 'uri'>
            ) }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GRetailersQueryQueryVariables = {
  input: GShopInfoInput;
};


export type GRetailersQueryQuery = (
  { __typename?: 'Query' }
  & { shopInfo?: Maybe<(
    { __typename?: 'ShopInfoPayload' }
    & { retailers: Array<(
      { __typename?: 'Retailer' }
      & Pick<GRetailer, 'id' | 'name' | 'link'>
    )> }
  )> }
);

export type GCountriesQueryQueryVariables = {};


export type GCountriesQueryQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<GCountry, 'id' | 'name' | 'code' | 'priority'>
  )> }
);

export type GBeerStylesQueryQueryVariables = {};


export type GBeerStylesQueryQuery = (
  { __typename?: 'Query' }
  & { beerStyles: Array<(
    { __typename?: 'BeerStyle' }
    & Pick<GBeerStyle, 'id' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id'>
    )> }
  )> }
);

export type GMobileShopRegionInfoQueryVariables = {
  input: GRegionInfoInput;
};


export type GMobileShopRegionInfoQuery = (
  { __typename?: 'Query' }
  & { regionInfo?: Maybe<(
    { __typename?: 'RegionInfoPayload' }
    & { country: (
      { __typename?: 'RegionCountry' }
      & Pick<GRegionCountry, 'id' | 'name' | 'code'>
    ) }
  )> }
);

export type GTopBeersListQueryVariables = {
  input: GTopBeersSearchInput;
};


export type GTopBeersListQuery = (
  { __typename?: 'Query' }
  & { topBeersSearch?: Maybe<(
    { __typename?: 'TopBeersSearchPayload' }
    & Pick<GTopBeersSearchPayload, 'myRatedBeerIds'>
    & { items: Array<(
      { __typename?: 'Beer' }
      & Pick<GBeer, 'id' | 'name' | 'imageUrl' | 'ratingsCount' | 'normalizedAverageReview' | 'abv' | 'overallScore' | 'styleScore'>
      & { style: (
        { __typename?: 'BeerStyle' }
        & Pick<GBeerStyle, 'id' | 'name'>
      ), brewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )> }
      )>, contractBrewer?: Maybe<(
        { __typename?: 'Brewer' }
        & Pick<GBrewer, 'id'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<GCountry, 'id' | 'name' | 'code'>
        )> }
      )> }
    )> }
  )> }
);

export type GTopBeersCountriesQueryVariables = {};


export type GTopBeersCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<GCountry, 'id' | 'name'>
    & { states: Array<(
      { __typename?: 'State' }
      & Pick<GState, 'id' | 'name'>
    )> }
  )> }
);

export type GTopBeersStylesQueryVariables = {};


export type GTopBeersStylesQuery = (
  { __typename?: 'Query' }
  & { beerStyles: Array<(
    { __typename?: 'BeerStyle' }
    & Pick<GBeerStyle, 'id' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'BeerStyle' }
      & Pick<GBeerStyle, 'id'>
    )> }
  )> }
);

export type GUserFolloweesQueryVariables = {
  input: GFolloweesInput;
};


export type GUserFolloweesQuery = (
  { __typename?: 'Query' }
  & { followees: (
    { __typename?: 'FolloweesPayload' }
    & Pick<GFolloweesPayload, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'username' | 'id' | 'imageUrl'>
    )> }
  ) }
);

export type GUserFollowersQueryVariables = {
  input: GFollowersInput;
};


export type GUserFollowersQuery = (
  { __typename?: 'Query' }
  & { followers: (
    { __typename?: 'FollowersPayload' }
    & Pick<GFollowersPayload, 'totalCount' | 'last'>
    & { items: Array<(
      { __typename?: 'PublicUser' }
      & Pick<GPublicUser, 'username' | 'id' | 'imageUrl'>
    )> }
  ) }
);

export type GReviewsTabReviewsQueryVariables = {
  input: GUserReviewsInput;
};


export type GReviewsTabReviewsQuery = (
  { __typename?: 'Query' }
  & { userReviews: (
    { __typename?: 'UserReviewsPayload' }
    & Pick<GUserReviewsPayload, 'totalCount'>
    & { items: Array<(
      { __typename?: 'Review' }
      & Pick<GReview, 'id' | 'score' | 'createdAt'>
      & { beer: (
        { __typename?: 'Beer' }
        & Pick<GBeer, 'id' | 'name' | 'averageQuickRating'>
        & { style: (
          { __typename?: 'BeerStyle' }
          & Pick<GBeerStyle, 'id' | 'name'>
        ), brewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )> }
        )>, contractBrewer?: Maybe<(
          { __typename?: 'Brewer' }
          & Pick<GBrewer, 'id' | 'name'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<GCountry, 'id' | 'name' | 'code'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GUserProfileUserQueryVariables = {
  userId: Scalars['ID'];
};


export type GUserProfileUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'PublicUser' }
    & Pick<GPublicUser, 'id' | 'username' | 'lastLogin' | 'membershipJoinDate' | 'imageUrl' | 'streetAddress' | 'securityLevelId'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<GCountry, 'id' | 'name' | 'code'>
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & Pick<GState, 'id' | 'name'>
    )> }
  )> }
);

export type GmeQueryVariables = {};


export type GmeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<GUser, 'id' | 'username' | 'city' | 'email' | 'firstName' | 'festivalRatingCount' | 'gender' | 'imageUrl' | 'isPremiumMember' | 'lastName' | 'placeReviewCount' | 'securityLevelId' | 'streetAddress' | 'zip'>
    & { place?: Maybe<(
      { __typename?: 'Place' }
      & Pick<GPlace, 'id'>
    )> }
  )> }
);

export type GFormsBrewerDropdownQueryVariables = {
  query?: Maybe<Scalars['String']>;
};


export type GFormsBrewerDropdownQuery = (
  { __typename?: 'Query' }
  & { brewerSearch: (
    { __typename?: 'BrewerList' }
    & { items: Array<(
      { __typename?: 'Brewer' }
      & Pick<GBrewer, 'id' | 'name'>
    )> }
  ) }
);

export type GFormsBrewerTypesDropdownQueryVariables = {};


export type GFormsBrewerTypesDropdownQuery = (
  { __typename?: 'Query' }
  & { brewerTypes: Array<(
    { __typename?: 'BrewerType' }
    & Pick<GBrewerType, 'id' | 'name'>
  )> }
);

export type GFormsCountryDropdownQueryVariables = {};


export type GFormsCountryDropdownQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<GCountry, 'id' | 'name' | 'code'>
  )> }
);

export type GFormsMetroDropdownQueryVariables = {};


export type GFormsMetroDropdownQuery = (
  { __typename?: 'Query' }
  & { metros: Array<(
    { __typename?: 'Metro' }
    & Pick<GMetro, 'id' | 'name'>
  )> }
);

export type GFormsPlaceTypesDropdownQueryVariables = {};


export type GFormsPlaceTypesDropdownQuery = (
  { __typename?: 'Query' }
  & { placeTypes: Array<(
    { __typename?: 'PlaceType' }
    & Pick<GPlaceType, 'id' | 'name'>
  )> }
);

export type GGetGeoLocationQueryVariables = {};


export type GGetGeoLocationQuery = (
  { __typename?: 'Query' }
  & { geoLocation?: Maybe<(
    { __typename?: 'Coordinates' }
    & Pick<GCoordinates, 'latitude' | 'longitude'>
  )> }
);

export const ReviewItemFragmentDoc = gql`
    fragment ReviewItem on Review {
  id
  comment
  score
  scores {
    appearance
    aroma
    flavor
    mouthfeel
    overall
  }
  author {
    id
    username
    reviewCount
    city
    country {
      id
      name
      code
    }
  }
  checkin {
    id
    place {
      id
      name
      city
      state {
        id
        name
      }
      country {
        id
        name
      }
    }
  }
  servedIn
  likeCount
  likedByMe
  createdAt
  updatedAt
}
    `;
export const ReviewFragFragmentDoc = gql`
    fragment ReviewFrag on Review {
  id
  comment
  score
  scores {
    appearance
    aroma
    flavor
    mouthfeel
    overall
  }
  checkin {
    id
    place {
      id
      name
    }
  }
  servedIn
  updatedAt
  likeCount
  likedByMe
  author {
    id
    username
    reviewCount
    city
    country {
      id
      name
    }
  }
}
    `;
export const ReviewFragmentFragmentDoc = gql`
    fragment ReviewFragment on Review {
  id
  comment
  score
  scores {
    appearance
    aroma
    flavor
    mouthfeel
    overall
  }
  checkin {
    id
    place {
      id
      name
    }
  }
  servedIn
  updatedAt
  createdAt
  likeCount
  likedByMe
  author {
    id
    username
    reviewCount
    city
    country {
      id
      name
    }
  }
}
    `;
export const BeerInfoFragmentDoc = gql`
    fragment BeerInfo on Beer {
  id
  name
  ratingsCount
  averageQuickRating
  overallScore
  styleScore
  imageUrl
  style {
    name
  }
  brewer {
    country {
      id
      code
    }
  }
  abv
  contractBrewer {
    id
    country {
      id
      name
      code
    }
  }
}
    `;
export const PlaceReviewFieldsFragmentDoc = gql`
    fragment PlaceReviewFields on PlaceReview {
  id
  comment
  createdAt
  updatedAt
  author {
    id
    username
    imageUrl
    placeReviewCount
    city
    country {
      id
      name
      code
    }
  }
  likeCount
  likedByMe
  createdAt
  score
  scores {
    ambiance
    service
    selection
    food
    value
    overall
  }
  place {
    id
  }
}
    `;
export const BFBIsFavoriteDocument = gql`
    query BFBIsFavorite($beerId: ID!) {
  isFavorite(beerId: $beerId) {
    id
    beer {
      id
    }
  }
}
    `;
export type BFBIsFavoriteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>, 'query'> & ({ variables: GBFBIsFavoriteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BFBIsFavoriteComponent = (props: BFBIsFavoriteComponentProps) => (
      <ApolloReactComponents.Query<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables> query={BFBIsFavoriteDocument} {...props} />
    );
    

/**
 * __useBFBIsFavoriteQuery__
 *
 * To run a query within a React component, call `useBFBIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBFBIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBFBIsFavoriteQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function useBFBIsFavoriteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>) {
        return ApolloReactHooks.useQuery<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>(BFBIsFavoriteDocument, baseOptions);
      }
export function useBFBIsFavoriteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>(BFBIsFavoriteDocument, baseOptions);
        }
export type BFBIsFavoriteQueryHookResult = ReturnType<typeof useBFBIsFavoriteQuery>;
export type BFBIsFavoriteLazyQueryHookResult = ReturnType<typeof useBFBIsFavoriteLazyQuery>;
export type BFBIsFavoriteQueryResult = ApolloReactCommon.QueryResult<GBFBIsFavoriteQuery, GBFBIsFavoriteQueryVariables>;
export const BFBFavoriteInsertDocument = gql`
    mutation BFBFavoriteInsert($beerId: ID!) {
  favoriteInsert(beerId: $beerId) {
    id
  }
}
    `;
export type GBFBFavoriteInsertMutationFn = ApolloReactCommon.MutationFunction<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables>;
export type BFBFavoriteInsertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables>, 'mutation'>;

    export const BFBFavoriteInsertComponent = (props: BFBFavoriteInsertComponentProps) => (
      <ApolloReactComponents.Mutation<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables> mutation={BFBFavoriteInsertDocument} {...props} />
    );
    

/**
 * __useBFBFavoriteInsertMutation__
 *
 * To run a mutation, you first call `useBFBFavoriteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBFBFavoriteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bfbFavoriteInsertMutation, { data, loading, error }] = useBFBFavoriteInsertMutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function useBFBFavoriteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables>(BFBFavoriteInsertDocument, baseOptions);
      }
export type BFBFavoriteInsertMutationHookResult = ReturnType<typeof useBFBFavoriteInsertMutation>;
export type BFBFavoriteInsertMutationResult = ApolloReactCommon.MutationResult<GBFBFavoriteInsertMutation>;
export type BFBFavoriteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GBFBFavoriteInsertMutation, GBFBFavoriteInsertMutationVariables>;
export const BFBFavoriteDeleteDocument = gql`
    mutation BFBFavoriteDelete($favoriteId: ID!) {
  favoriteDelete(id: $favoriteId)
}
    `;
export type GBFBFavoriteDeleteMutationFn = ApolloReactCommon.MutationFunction<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables>;
export type BFBFavoriteDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables>, 'mutation'>;

    export const BFBFavoriteDeleteComponent = (props: BFBFavoriteDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables> mutation={BFBFavoriteDeleteDocument} {...props} />
    );
    

/**
 * __useBFBFavoriteDeleteMutation__
 *
 * To run a mutation, you first call `useBFBFavoriteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBFBFavoriteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bfbFavoriteDeleteMutation, { data, loading, error }] = useBFBFavoriteDeleteMutation({
 *   variables: {
 *      favoriteId: // value for 'favoriteId'
 *   },
 * });
 */
export function useBFBFavoriteDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables>(BFBFavoriteDeleteDocument, baseOptions);
      }
export type BFBFavoriteDeleteMutationHookResult = ReturnType<typeof useBFBFavoriteDeleteMutation>;
export type BFBFavoriteDeleteMutationResult = ApolloReactCommon.MutationResult<GBFBFavoriteDeleteMutation>;
export type BFBFavoriteDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GBFBFavoriteDeleteMutation, GBFBFavoriteDeleteMutationVariables>;
export const beerStylesDocument = gql`
    query beerStyles {
  beerStyles {
    id
    name
    parent {
      id
    }
  }
}
    `;
export type beerStylesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GbeerStylesQuery, GbeerStylesQueryVariables>, 'query'>;

    export const beerStylesComponent = (props: beerStylesComponentProps) => (
      <ApolloReactComponents.Query<GbeerStylesQuery, GbeerStylesQueryVariables> query={beerStylesDocument} {...props} />
    );
    

/**
 * __usebeerStylesQuery__
 *
 * To run a query within a React component, call `usebeerStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `usebeerStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usebeerStylesQuery({
 *   variables: {
 *   },
 * });
 */
export function usebeerStylesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GbeerStylesQuery, GbeerStylesQueryVariables>) {
        return ApolloReactHooks.useQuery<GbeerStylesQuery, GbeerStylesQueryVariables>(beerStylesDocument, baseOptions);
      }
export function usebeerStylesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbeerStylesQuery, GbeerStylesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GbeerStylesQuery, GbeerStylesQueryVariables>(beerStylesDocument, baseOptions);
        }
export type beerStylesQueryHookResult = ReturnType<typeof usebeerStylesQuery>;
export type beerStylesLazyQueryHookResult = ReturnType<typeof usebeerStylesLazyQuery>;
export type beerStylesQueryResult = ApolloReactCommon.QueryResult<GbeerStylesQuery, GbeerStylesQueryVariables>;
export const ShopButtonShopInfoDocument = gql`
    query ShopButtonShopInfo($latlng: [Float!]!) {
  shopInfo(input: {latlng: $latlng}) {
    retailers {
      id
    }
  }
}
    `;
export type ShopButtonShopInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>, 'query'> & ({ variables: GShopButtonShopInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShopButtonShopInfoComponent = (props: ShopButtonShopInfoComponentProps) => (
      <ApolloReactComponents.Query<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables> query={ShopButtonShopInfoDocument} {...props} />
    );
    

/**
 * __useShopButtonShopInfoQuery__
 *
 * To run a query within a React component, call `useShopButtonShopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopButtonShopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopButtonShopInfoQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *   },
 * });
 */
export function useShopButtonShopInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>(ShopButtonShopInfoDocument, baseOptions);
      }
export function useShopButtonShopInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>(ShopButtonShopInfoDocument, baseOptions);
        }
export type ShopButtonShopInfoQueryHookResult = ReturnType<typeof useShopButtonShopInfoQuery>;
export type ShopButtonShopInfoLazyQueryHookResult = ReturnType<typeof useShopButtonShopInfoLazyQuery>;
export type ShopButtonShopInfoQueryResult = ApolloReactCommon.QueryResult<GShopButtonShopInfoQuery, GShopButtonShopInfoQueryVariables>;
export const HeaderSearchDocument = gql`
    query HeaderSearch($query: String!, $firstNBeers: Int) {
  beers: beerSearch(query: $query, first: $firstNBeers) {
    items {
      beer {
        id
        name
        imageUrl
      }
    }
  }
  brewers: brewerSearch(query: $query, first: 1) {
    items {
      id
      name
      imageUrl
    }
  }
  places: placeSearch(query: $query, first: 1) {
    items {
      id
      name
      imageUrl
    }
  }
}
    `;
export type HeaderSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GHeaderSearchQuery, GHeaderSearchQueryVariables>, 'query'> & ({ variables: GHeaderSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HeaderSearchComponent = (props: HeaderSearchComponentProps) => (
      <ApolloReactComponents.Query<GHeaderSearchQuery, GHeaderSearchQueryVariables> query={HeaderSearchDocument} {...props} />
    );
    

/**
 * __useHeaderSearchQuery__
 *
 * To run a query within a React component, call `useHeaderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      firstNBeers: // value for 'firstNBeers'
 *   },
 * });
 */
export function useHeaderSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GHeaderSearchQuery, GHeaderSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GHeaderSearchQuery, GHeaderSearchQueryVariables>(HeaderSearchDocument, baseOptions);
      }
export function useHeaderSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GHeaderSearchQuery, GHeaderSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GHeaderSearchQuery, GHeaderSearchQueryVariables>(HeaderSearchDocument, baseOptions);
        }
export type HeaderSearchQueryHookResult = ReturnType<typeof useHeaderSearchQuery>;
export type HeaderSearchLazyQueryHookResult = ReturnType<typeof useHeaderSearchLazyQuery>;
export type HeaderSearchQueryResult = ApolloReactCommon.QueryResult<GHeaderSearchQuery, GHeaderSearchQueryVariables>;
export const SimilarBeersDocument = gql`
    query SimilarBeers($styleId: ID!, $first: Int, $latlng: [Float!]!, $includePurchaseOptions: Boolean!, $excludedBeerIds: [ID!]!) {
  beersByStyleRandom(styleId: $styleId, first: $first, latlng: $latlng, excludedBeerIds: $excludedBeerIds) {
    items {
      id
      name
      overallScore
      styleScore
      averageQuickRating
      ratingsCount
      brewer {
        id
        country {
          id
          name
          code
        }
      }
      contractBrewer {
        id
        country {
          id
          name
          code
        }
      }
      style {
        id
        name
      }
      purchaseOptions(options: {latlng: $latlng}) @include(if: $includePurchaseOptions) {
        items {
          productId
          priceValue
          price
          bottleSize
          currency
          currencySymbol
          store {
            id
            url
            name
          }
        }
      }
    }
  }
}
    `;
export type SimilarBeersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GSimilarBeersQuery, GSimilarBeersQueryVariables>, 'query'> & ({ variables: GSimilarBeersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SimilarBeersComponent = (props: SimilarBeersComponentProps) => (
      <ApolloReactComponents.Query<GSimilarBeersQuery, GSimilarBeersQueryVariables> query={SimilarBeersDocument} {...props} />
    );
    

/**
 * __useSimilarBeersQuery__
 *
 * To run a query within a React component, call `useSimilarBeersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarBeersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarBeersQuery({
 *   variables: {
 *      styleId: // value for 'styleId'
 *      first: // value for 'first'
 *      latlng: // value for 'latlng'
 *      includePurchaseOptions: // value for 'includePurchaseOptions'
 *      excludedBeerIds: // value for 'excludedBeerIds'
 *   },
 * });
 */
export function useSimilarBeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GSimilarBeersQuery, GSimilarBeersQueryVariables>) {
        return ApolloReactHooks.useQuery<GSimilarBeersQuery, GSimilarBeersQueryVariables>(SimilarBeersDocument, baseOptions);
      }
export function useSimilarBeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GSimilarBeersQuery, GSimilarBeersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GSimilarBeersQuery, GSimilarBeersQueryVariables>(SimilarBeersDocument, baseOptions);
        }
export type SimilarBeersQueryHookResult = ReturnType<typeof useSimilarBeersQuery>;
export type SimilarBeersLazyQueryHookResult = ReturnType<typeof useSimilarBeersLazyQuery>;
export type SimilarBeersQueryResult = ApolloReactCommon.QueryResult<GSimilarBeersQuery, GSimilarBeersQueryVariables>;
export const beerInsertDocument = gql`
    mutation beerInsert($beerName: String!, $brewerId: ID!, $styleId: ID!, $abv: Float, $ibu: Int, $description: String, $seasonal: BeerSeason, $contractBrewerId: ID, $verified: Boolean, $barcode: String, $image: Upload) {
  beerInsert(beer: {name: $beerName, brewerId: $brewerId, styleId: $styleId, abv: $abv, ibu: $ibu, description: $description, seasonal: $seasonal, contractBrewerId: $contractBrewerId, verified: $verified, barcode: $barcode, image: $image}) {
    id
    name
  }
}
    `;
export type GbeerInsertMutationFn = ApolloReactCommon.MutationFunction<GbeerInsertMutation, GbeerInsertMutationVariables>;
export type beerInsertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GbeerInsertMutation, GbeerInsertMutationVariables>, 'mutation'>;

    export const beerInsertComponent = (props: beerInsertComponentProps) => (
      <ApolloReactComponents.Mutation<GbeerInsertMutation, GbeerInsertMutationVariables> mutation={beerInsertDocument} {...props} />
    );
    

/**
 * __usebeerInsertMutation__
 *
 * To run a mutation, you first call `usebeerInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usebeerInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beerInsertMutation, { data, loading, error }] = usebeerInsertMutation({
 *   variables: {
 *      beerName: // value for 'beerName'
 *      brewerId: // value for 'brewerId'
 *      styleId: // value for 'styleId'
 *      abv: // value for 'abv'
 *      ibu: // value for 'ibu'
 *      description: // value for 'description'
 *      seasonal: // value for 'seasonal'
 *      contractBrewerId: // value for 'contractBrewerId'
 *      verified: // value for 'verified'
 *      barcode: // value for 'barcode'
 *      image: // value for 'image'
 *   },
 * });
 */
export function usebeerInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GbeerInsertMutation, GbeerInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GbeerInsertMutation, GbeerInsertMutationVariables>(beerInsertDocument, baseOptions);
      }
export type beerInsertMutationHookResult = ReturnType<typeof usebeerInsertMutation>;
export type beerInsertMutationResult = ApolloReactCommon.MutationResult<GbeerInsertMutation>;
export type beerInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GbeerInsertMutation, GbeerInsertMutationVariables>;
export const brewerSearchDocument = gql`
    query brewerSearch($query: String, $first: Int) {
  results: brewerSearch(query: $query, first: $first) {
    items {
      id
      name
      city
      country {
        id
        name
        code
      }
    }
  }
}
    `;
export type brewerSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GbrewerSearchQuery, GbrewerSearchQueryVariables>, 'query'>;

    export const brewerSearchComponent = (props: brewerSearchComponentProps) => (
      <ApolloReactComponents.Query<GbrewerSearchQuery, GbrewerSearchQueryVariables> query={brewerSearchDocument} {...props} />
    );
    

/**
 * __usebrewerSearchQuery__
 *
 * To run a query within a React component, call `usebrewerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usebrewerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usebrewerSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usebrewerSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GbrewerSearchQuery, GbrewerSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GbrewerSearchQuery, GbrewerSearchQueryVariables>(brewerSearchDocument, baseOptions);
      }
export function usebrewerSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbrewerSearchQuery, GbrewerSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GbrewerSearchQuery, GbrewerSearchQueryVariables>(brewerSearchDocument, baseOptions);
        }
export type brewerSearchQueryHookResult = ReturnType<typeof usebrewerSearchQuery>;
export type brewerSearchLazyQueryHookResult = ReturnType<typeof usebrewerSearchLazyQuery>;
export type brewerSearchQueryResult = ApolloReactCommon.QueryResult<GbrewerSearchQuery, GbrewerSearchQueryVariables>;
export const AddBeerGetBrewerQueryDocument = gql`
    query AddBeerGetBrewerQuery($brewerId: ID!) {
  brewer(id: $brewerId) {
    id
    name
    city
    country {
      id
      name
    }
  }
}
    `;
export type AddBeerGetBrewerQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>, 'query'> & ({ variables: GAddBeerGetBrewerQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AddBeerGetBrewerQueryComponent = (props: AddBeerGetBrewerQueryComponentProps) => (
      <ApolloReactComponents.Query<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables> query={AddBeerGetBrewerQueryDocument} {...props} />
    );
    

/**
 * __useAddBeerGetBrewerQueryQuery__
 *
 * To run a query within a React component, call `useAddBeerGetBrewerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddBeerGetBrewerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddBeerGetBrewerQueryQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useAddBeerGetBrewerQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>(AddBeerGetBrewerQueryDocument, baseOptions);
      }
export function useAddBeerGetBrewerQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>(AddBeerGetBrewerQueryDocument, baseOptions);
        }
export type AddBeerGetBrewerQueryQueryHookResult = ReturnType<typeof useAddBeerGetBrewerQueryQuery>;
export type AddBeerGetBrewerQueryLazyQueryHookResult = ReturnType<typeof useAddBeerGetBrewerQueryLazyQuery>;
export type AddBeerGetBrewerQueryQueryResult = ApolloReactCommon.QueryResult<GAddBeerGetBrewerQueryQuery, GAddBeerGetBrewerQueryQueryVariables>;
export const beerSearchDocument = gql`
    query beerSearch($query: String, $order: SearchOrder, $first: Int, $after: ID) {
  results: beerSearch(query: $query, order: $order, first: $first, after: $after) {
    items {
      beer {
        name
        style {
          id
          name
        }
        overallScore
        styleScore
        averageQuickRating
        abv
        ibu
        brewer {
          id
          name
        }
        ratingsCount
        imageUrl
        id
      }
    }
  }
}
    `;
export type beerSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GbeerSearchQuery, GbeerSearchQueryVariables>, 'query'>;

    export const beerSearchComponent = (props: beerSearchComponentProps) => (
      <ApolloReactComponents.Query<GbeerSearchQuery, GbeerSearchQueryVariables> query={beerSearchDocument} {...props} />
    );
    

/**
 * __usebeerSearchQuery__
 *
 * To run a query within a React component, call `usebeerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usebeerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usebeerSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usebeerSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GbeerSearchQuery, GbeerSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GbeerSearchQuery, GbeerSearchQueryVariables>(beerSearchDocument, baseOptions);
      }
export function usebeerSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbeerSearchQuery, GbeerSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GbeerSearchQuery, GbeerSearchQueryVariables>(beerSearchDocument, baseOptions);
        }
export type beerSearchQueryHookResult = ReturnType<typeof usebeerSearchQuery>;
export type beerSearchLazyQueryHookResult = ReturnType<typeof usebeerSearchLazyQuery>;
export type beerSearchQueryResult = ApolloReactCommon.QueryResult<GbeerSearchQuery, GbeerSearchQueryVariables>;
export const AddBrewerDocument = gql`
    mutation AddBrewer($input: AddBrewerInput!) {
  addBrewer(input: $input) {
    brewer {
      id
      name
      description
    }
  }
}
    `;
export type GAddBrewerMutationFn = ApolloReactCommon.MutationFunction<GAddBrewerMutation, GAddBrewerMutationVariables>;
export type AddBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GAddBrewerMutation, GAddBrewerMutationVariables>, 'mutation'>;

    export const AddBrewerComponent = (props: AddBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GAddBrewerMutation, GAddBrewerMutationVariables> mutation={AddBrewerDocument} {...props} />
    );
    

/**
 * __useAddBrewerMutation__
 *
 * To run a mutation, you first call `useAddBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBrewerMutation, { data, loading, error }] = useAddBrewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GAddBrewerMutation, GAddBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GAddBrewerMutation, GAddBrewerMutationVariables>(AddBrewerDocument, baseOptions);
      }
export type AddBrewerMutationHookResult = ReturnType<typeof useAddBrewerMutation>;
export type AddBrewerMutationResult = ApolloReactCommon.MutationResult<GAddBrewerMutation>;
export type AddBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GAddBrewerMutation, GAddBrewerMutationVariables>;
export const AddPlaceDocument = gql`
    mutation AddPlace($input: AddPlaceInput!) {
  addPlace(input: $input) {
    place {
      id
      name
      description
    }
  }
}
    `;
export type GAddPlaceMutationFn = ApolloReactCommon.MutationFunction<GAddPlaceMutation, GAddPlaceMutationVariables>;
export type AddPlaceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GAddPlaceMutation, GAddPlaceMutationVariables>, 'mutation'>;

    export const AddPlaceComponent = (props: AddPlaceComponentProps) => (
      <ApolloReactComponents.Mutation<GAddPlaceMutation, GAddPlaceMutationVariables> mutation={AddPlaceDocument} {...props} />
    );
    

/**
 * __useAddPlaceMutation__
 *
 * To run a mutation, you first call `useAddPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlaceMutation, { data, loading, error }] = useAddPlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPlaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GAddPlaceMutation, GAddPlaceMutationVariables>) {
        return ApolloReactHooks.useMutation<GAddPlaceMutation, GAddPlaceMutationVariables>(AddPlaceDocument, baseOptions);
      }
export type AddPlaceMutationHookResult = ReturnType<typeof useAddPlaceMutation>;
export type AddPlaceMutationResult = ApolloReactCommon.MutationResult<GAddPlaceMutation>;
export type AddPlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<GAddPlaceMutation, GAddPlaceMutationVariables>;
export const BeerMapPlaceSearchDocument = gql`
    query BeerMapPlaceSearch($order: SearchOrder, $latlng: [Float], $filters: PlaceSearchFilters, $query: String) {
  placeSearch(latlng: $latlng, filters: $filters, order: $order, first: 100, query: $query) {
    items {
      id
      name
      latitude
      longitude
      type {
        id
        name
      }
      streetAddress
      city
      country {
        id
        name
        code
      }
      rateCount
      imageUrl
      percentile
      phone
      hours
      bottles
      taps
      wifi
    }
    totalCount
    last
  }
}
    `;
export type BeerMapPlaceSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>, 'query'>;

    export const BeerMapPlaceSearchComponent = (props: BeerMapPlaceSearchComponentProps) => (
      <ApolloReactComponents.Query<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables> query={BeerMapPlaceSearchDocument} {...props} />
    );
    

/**
 * __useBeerMapPlaceSearchQuery__
 *
 * To run a query within a React component, call `useBeerMapPlaceSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerMapPlaceSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerMapPlaceSearchQuery({
 *   variables: {
 *      order: // value for 'order'
 *      latlng: // value for 'latlng'
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBeerMapPlaceSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>(BeerMapPlaceSearchDocument, baseOptions);
      }
export function useBeerMapPlaceSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>(BeerMapPlaceSearchDocument, baseOptions);
        }
export type BeerMapPlaceSearchQueryHookResult = ReturnType<typeof useBeerMapPlaceSearchQuery>;
export type BeerMapPlaceSearchLazyQueryHookResult = ReturnType<typeof useBeerMapPlaceSearchLazyQuery>;
export type BeerMapPlaceSearchQueryResult = ApolloReactCommon.QueryResult<GBeerMapPlaceSearchQuery, GBeerMapPlaceSearchQueryVariables>;
export const BeerMapPlaceTypesQueryDocument = gql`
    query BeerMapPlaceTypesQuery {
  placeTypes {
    id
    name
  }
}
    `;
export type BeerMapPlaceTypesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>, 'query'>;

    export const BeerMapPlaceTypesQueryComponent = (props: BeerMapPlaceTypesQueryComponentProps) => (
      <ApolloReactComponents.Query<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables> query={BeerMapPlaceTypesQueryDocument} {...props} />
    );
    

/**
 * __useBeerMapPlaceTypesQueryQuery__
 *
 * To run a query within a React component, call `useBeerMapPlaceTypesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerMapPlaceTypesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerMapPlaceTypesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBeerMapPlaceTypesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>(BeerMapPlaceTypesQueryDocument, baseOptions);
      }
export function useBeerMapPlaceTypesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>(BeerMapPlaceTypesQueryDocument, baseOptions);
        }
export type BeerMapPlaceTypesQueryQueryHookResult = ReturnType<typeof useBeerMapPlaceTypesQueryQuery>;
export type BeerMapPlaceTypesQueryLazyQueryHookResult = ReturnType<typeof useBeerMapPlaceTypesQueryLazyQuery>;
export type BeerMapPlaceTypesQueryQueryResult = ApolloReactCommon.QueryResult<GBeerMapPlaceTypesQueryQuery, GBeerMapPlaceTypesQueryQueryVariables>;
export const PlaceQueryDocument = gql`
    query PlaceQuery($placeId: ID!) {
  place(id: $placeId) {
    id
    name
    latitude
    longitude
    type {
      id
      name
    }
    streetAddress
    city
    country {
      id
      name
      code
    }
    rateCount
    imageUrl
    percentile
    phone
    hours
    bottles
    taps
    wifi
  }
}
    `;
export type PlaceQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceQueryQuery, GPlaceQueryQueryVariables>, 'query'> & ({ variables: GPlaceQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaceQueryComponent = (props: PlaceQueryComponentProps) => (
      <ApolloReactComponents.Query<GPlaceQueryQuery, GPlaceQueryQueryVariables> query={PlaceQueryDocument} {...props} />
    );
    

/**
 * __usePlaceQueryQuery__
 *
 * To run a query within a React component, call `usePlaceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceQueryQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function usePlaceQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceQueryQuery, GPlaceQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceQueryQuery, GPlaceQueryQueryVariables>(PlaceQueryDocument, baseOptions);
      }
export function usePlaceQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceQueryQuery, GPlaceQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceQueryQuery, GPlaceQueryQueryVariables>(PlaceQueryDocument, baseOptions);
        }
export type PlaceQueryQueryHookResult = ReturnType<typeof usePlaceQueryQuery>;
export type PlaceQueryLazyQueryHookResult = ReturnType<typeof usePlaceQueryLazyQuery>;
export type PlaceQueryQueryResult = ApolloReactCommon.QueryResult<GPlaceQueryQuery, GPlaceQueryQueryVariables>;
export const BeerAliasPageDocument = gql`
    query BeerAliasPage($aliasId: ID!) {
  beerByAlias(aliasId: $aliasId) {
    id
    name
    overallScore
    isVerified
    isRetired
  }
}
    `;
export type BeerAliasPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>, 'query'> & ({ variables: GBeerAliasPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeerAliasPageComponent = (props: BeerAliasPageComponentProps) => (
      <ApolloReactComponents.Query<GBeerAliasPageQuery, GBeerAliasPageQueryVariables> query={BeerAliasPageDocument} {...props} />
    );
    

/**
 * __useBeerAliasPageQuery__
 *
 * To run a query within a React component, call `useBeerAliasPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerAliasPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerAliasPageQuery({
 *   variables: {
 *      aliasId: // value for 'aliasId'
 *   },
 * });
 */
export function useBeerAliasPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>(BeerAliasPageDocument, baseOptions);
      }
export function useBeerAliasPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>(BeerAliasPageDocument, baseOptions);
        }
export type BeerAliasPageQueryHookResult = ReturnType<typeof useBeerAliasPageQuery>;
export type BeerAliasPageLazyQueryHookResult = ReturnType<typeof useBeerAliasPageLazyQuery>;
export type BeerAliasPageQueryResult = ApolloReactCommon.QueryResult<GBeerAliasPageQuery, GBeerAliasPageQueryVariables>;
export const ShopInfoDocument = gql`
    query ShopInfo($latlng: [Float!]!) {
  shopInfo(input: {latlng: $latlng}) {
    retailers {
      id
    }
  }
}
    `;
export type ShopInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GShopInfoQuery, GShopInfoQueryVariables>, 'query'> & ({ variables: GShopInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShopInfoComponent = (props: ShopInfoComponentProps) => (
      <ApolloReactComponents.Query<GShopInfoQuery, GShopInfoQueryVariables> query={ShopInfoDocument} {...props} />
    );
    

/**
 * __useShopInfoQuery__
 *
 * To run a query within a React component, call `useShopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopInfoQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *   },
 * });
 */
export function useShopInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GShopInfoQuery, GShopInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GShopInfoQuery, GShopInfoQueryVariables>(ShopInfoDocument, baseOptions);
      }
export function useShopInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GShopInfoQuery, GShopInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GShopInfoQuery, GShopInfoQueryVariables>(ShopInfoDocument, baseOptions);
        }
export type ShopInfoQueryHookResult = ReturnType<typeof useShopInfoQuery>;
export type ShopInfoLazyQueryHookResult = ReturnType<typeof useShopInfoLazyQuery>;
export type ShopInfoQueryResult = ApolloReactCommon.QueryResult<GShopInfoQuery, GShopInfoQueryVariables>;
export const placeSearchDocument = gql`
    query placeSearch($query: String, $first: Int) {
  results: placeSearch(query: $query, first: $first) {
    items {
      id
      name
      city
    }
  }
}
    `;
export type placeSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GplaceSearchQuery, GplaceSearchQueryVariables>, 'query'>;

    export const placeSearchComponent = (props: placeSearchComponentProps) => (
      <ApolloReactComponents.Query<GplaceSearchQuery, GplaceSearchQueryVariables> query={placeSearchDocument} {...props} />
    );
    

/**
 * __useplaceSearchQuery__
 *
 * To run a query within a React component, call `useplaceSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useplaceSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useplaceSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useplaceSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GplaceSearchQuery, GplaceSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GplaceSearchQuery, GplaceSearchQueryVariables>(placeSearchDocument, baseOptions);
      }
export function useplaceSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GplaceSearchQuery, GplaceSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GplaceSearchQuery, GplaceSearchQueryVariables>(placeSearchDocument, baseOptions);
        }
export type placeSearchQueryHookResult = ReturnType<typeof useplaceSearchQuery>;
export type placeSearchLazyQueryHookResult = ReturnType<typeof useplaceSearchLazyQuery>;
export type placeSearchQueryResult = ApolloReactCommon.QueryResult<GplaceSearchQuery, GplaceSearchQueryVariables>;
export const LocationDropdownNearbyPlacesDocument = gql`
    query LocationDropdownNearbyPlaces($latlng: [Float!]!, $first: Int) {
  results: nearbyPlaces(latlng: $latlng, first: $first) {
    items {
      id
      name
      city
    }
  }
}
    `;
export type LocationDropdownNearbyPlacesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>, 'query'> & ({ variables: GLocationDropdownNearbyPlacesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LocationDropdownNearbyPlacesComponent = (props: LocationDropdownNearbyPlacesComponentProps) => (
      <ApolloReactComponents.Query<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables> query={LocationDropdownNearbyPlacesDocument} {...props} />
    );
    

/**
 * __useLocationDropdownNearbyPlacesQuery__
 *
 * To run a query within a React component, call `useLocationDropdownNearbyPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDropdownNearbyPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDropdownNearbyPlacesQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useLocationDropdownNearbyPlacesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>) {
        return ApolloReactHooks.useQuery<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>(LocationDropdownNearbyPlacesDocument, baseOptions);
      }
export function useLocationDropdownNearbyPlacesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>(LocationDropdownNearbyPlacesDocument, baseOptions);
        }
export type LocationDropdownNearbyPlacesQueryHookResult = ReturnType<typeof useLocationDropdownNearbyPlacesQuery>;
export type LocationDropdownNearbyPlacesLazyQueryHookResult = ReturnType<typeof useLocationDropdownNearbyPlacesLazyQuery>;
export type LocationDropdownNearbyPlacesQueryResult = ApolloReactCommon.QueryResult<GLocationDropdownNearbyPlacesQuery, GLocationDropdownNearbyPlacesQueryVariables>;
export const BeerReviewsDocument = gql`
    query BeerReviews($beerId: ID!, $authorId: ID, $order: ReviewOrder, $after: ID, $first: Int, $includePrivate: Boolean) {
  beerList: beerReviews(beerId: $beerId, authorId: $authorId, order: $order, after: $after, first: $first, includePrivate: $includePrivate) {
    items {
      ...ReviewItem
    }
    totalCount
    last
  }
}
    ${ReviewItemFragmentDoc}`;
export type BeerReviewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerReviewsQuery, GBeerReviewsQueryVariables>, 'query'> & ({ variables: GBeerReviewsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeerReviewsComponent = (props: BeerReviewsComponentProps) => (
      <ApolloReactComponents.Query<GBeerReviewsQuery, GBeerReviewsQueryVariables> query={BeerReviewsDocument} {...props} />
    );
    

/**
 * __useBeerReviewsQuery__
 *
 * To run a query within a React component, call `useBeerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerReviewsQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      authorId: // value for 'authorId'
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      includePrivate: // value for 'includePrivate'
 *   },
 * });
 */
export function useBeerReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerReviewsQuery, GBeerReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerReviewsQuery, GBeerReviewsQueryVariables>(BeerReviewsDocument, baseOptions);
      }
export function useBeerReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerReviewsQuery, GBeerReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerReviewsQuery, GBeerReviewsQueryVariables>(BeerReviewsDocument, baseOptions);
        }
export type BeerReviewsQueryHookResult = ReturnType<typeof useBeerReviewsQuery>;
export type BeerReviewsLazyQueryHookResult = ReturnType<typeof useBeerReviewsLazyQuery>;
export type BeerReviewsQueryResult = ApolloReactCommon.QueryResult<GBeerReviewsQuery, GBeerReviewsQueryVariables>;
export const friendBeerReviewsDocument = gql`
    query friendBeerReviews($beerId: ID!, $after: ID, $first: Int) {
  beerList: friendBeerReviews(beerId: $beerId, after: $after, first: $first) {
    items {
      ...ReviewItem
    }
    totalCount
    last
  }
}
    ${ReviewItemFragmentDoc}`;
export type friendBeerReviewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>, 'query'> & ({ variables: GfriendBeerReviewsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const friendBeerReviewsComponent = (props: friendBeerReviewsComponentProps) => (
      <ApolloReactComponents.Query<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables> query={friendBeerReviewsDocument} {...props} />
    );
    

/**
 * __usefriendBeerReviewsQuery__
 *
 * To run a query within a React component, call `usefriendBeerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usefriendBeerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usefriendBeerReviewsQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usefriendBeerReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>(friendBeerReviewsDocument, baseOptions);
      }
export function usefriendBeerReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>(friendBeerReviewsDocument, baseOptions);
        }
export type friendBeerReviewsQueryHookResult = ReturnType<typeof usefriendBeerReviewsQuery>;
export type friendBeerReviewsLazyQueryHookResult = ReturnType<typeof usefriendBeerReviewsLazyQuery>;
export type friendBeerReviewsQueryResult = ApolloReactCommon.QueryResult<GfriendBeerReviewsQuery, GfriendBeerReviewsQueryVariables>;
export const myBeerReview2Document = gql`
    query myBeerReview2($beerId: ID!) {
  myReview: myBeerReview(beerId: $beerId) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type myBeerReview2ComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GmyBeerReview2Query, GmyBeerReview2QueryVariables>, 'query'> & ({ variables: GmyBeerReview2QueryVariables; skip?: boolean; } | { skip: boolean; });

    export const myBeerReview2Component = (props: myBeerReview2ComponentProps) => (
      <ApolloReactComponents.Query<GmyBeerReview2Query, GmyBeerReview2QueryVariables> query={myBeerReview2Document} {...props} />
    );
    

/**
 * __usemyBeerReview2Query__
 *
 * To run a query within a React component, call `usemyBeerReview2Query` and pass it any options that fit your needs.
 * When your component renders, `usemyBeerReview2Query` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usemyBeerReview2Query({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usemyBeerReview2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GmyBeerReview2Query, GmyBeerReview2QueryVariables>) {
        return ApolloReactHooks.useQuery<GmyBeerReview2Query, GmyBeerReview2QueryVariables>(myBeerReview2Document, baseOptions);
      }
export function usemyBeerReview2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GmyBeerReview2Query, GmyBeerReview2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GmyBeerReview2Query, GmyBeerReview2QueryVariables>(myBeerReview2Document, baseOptions);
        }
export type myBeerReview2QueryHookResult = ReturnType<typeof usemyBeerReview2Query>;
export type myBeerReview2LazyQueryHookResult = ReturnType<typeof usemyBeerReview2LazyQuery>;
export type myBeerReview2QueryResult = ApolloReactCommon.QueryResult<GmyBeerReview2Query, GmyBeerReview2QueryVariables>;
export const mobileRatingInsert2Document = gql`
    mutation mobileRatingInsert2($beerId: ID!, $scores: ReviewScoresInput, $comment: String, $placeId: ID, $servedIn: ServedIn, $score: Float) {
  userReview: mobileRatingInsert(beerId: $beerId, scores: $scores, comment: $comment, placeId: $placeId, servedIn: $servedIn, score: $score) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type GmobileRatingInsert2MutationFn = ApolloReactCommon.MutationFunction<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables>;
export type mobileRatingInsert2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables>, 'mutation'>;

    export const mobileRatingInsert2Component = (props: mobileRatingInsert2ComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables> mutation={mobileRatingInsert2Document} {...props} />
    );
    

/**
 * __usemobileRatingInsert2Mutation__
 *
 * To run a mutation, you first call `usemobileRatingInsert2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingInsert2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingInsert2Mutation, { data, loading, error }] = usemobileRatingInsert2Mutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *      placeId: // value for 'placeId'
 *      servedIn: // value for 'servedIn'
 *      score: // value for 'score'
 *   },
 * });
 */
export function usemobileRatingInsert2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables>(mobileRatingInsert2Document, baseOptions);
      }
export type mobileRatingInsert2MutationHookResult = ReturnType<typeof usemobileRatingInsert2Mutation>;
export type mobileRatingInsert2MutationResult = ApolloReactCommon.MutationResult<GmobileRatingInsert2Mutation>;
export type mobileRatingInsert2MutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingInsert2Mutation, GmobileRatingInsert2MutationVariables>;
export const mobileRatingUpdate2Document = gql`
    mutation mobileRatingUpdate2($id: ID!, $scores: ReviewScoresInput, $comment: String, $placeId: ID, $servedIn: ServedIn, $score: Float) {
  userReview: mobileRatingUpdate(id: $id, scores: $scores, comment: $comment, placeId: $placeId, servedIn: $servedIn, score: $score) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type GmobileRatingUpdate2MutationFn = ApolloReactCommon.MutationFunction<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables>;
export type mobileRatingUpdate2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables>, 'mutation'>;

    export const mobileRatingUpdate2Component = (props: mobileRatingUpdate2ComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables> mutation={mobileRatingUpdate2Document} {...props} />
    );
    

/**
 * __usemobileRatingUpdate2Mutation__
 *
 * To run a mutation, you first call `usemobileRatingUpdate2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingUpdate2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingUpdate2Mutation, { data, loading, error }] = usemobileRatingUpdate2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *      placeId: // value for 'placeId'
 *      servedIn: // value for 'servedIn'
 *      score: // value for 'score'
 *   },
 * });
 */
export function usemobileRatingUpdate2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables>(mobileRatingUpdate2Document, baseOptions);
      }
export type mobileRatingUpdate2MutationHookResult = ReturnType<typeof usemobileRatingUpdate2Mutation>;
export type mobileRatingUpdate2MutationResult = ApolloReactCommon.MutationResult<GmobileRatingUpdate2Mutation>;
export type mobileRatingUpdate2MutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingUpdate2Mutation, GmobileRatingUpdate2MutationVariables>;
export const mobileRatingDelete2Document = gql`
    mutation mobileRatingDelete2($id: ID!) {
  mobileRatingDelete(id: $id)
}
    `;
export type GmobileRatingDelete2MutationFn = ApolloReactCommon.MutationFunction<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables>;
export type mobileRatingDelete2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables>, 'mutation'>;

    export const mobileRatingDelete2Component = (props: mobileRatingDelete2ComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables> mutation={mobileRatingDelete2Document} {...props} />
    );
    

/**
 * __usemobileRatingDelete2Mutation__
 *
 * To run a mutation, you first call `usemobileRatingDelete2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingDelete2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingDelete2Mutation, { data, loading, error }] = usemobileRatingDelete2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usemobileRatingDelete2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables>(mobileRatingDelete2Document, baseOptions);
      }
export type mobileRatingDelete2MutationHookResult = ReturnType<typeof usemobileRatingDelete2Mutation>;
export type mobileRatingDelete2MutationResult = ApolloReactCommon.MutationResult<GmobileRatingDelete2Mutation>;
export type mobileRatingDelete2MutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingDelete2Mutation, GmobileRatingDelete2MutationVariables>;
export const UserBeerReviewDocument = gql`
    query UserBeerReview($beerId: ID!, $userId: ID!) {
  userBeerReview(beerId: $beerId, userId: $userId) {
    ...ReviewItem
  }
}
    ${ReviewItemFragmentDoc}`;
export type UserBeerReviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>, 'query'> & ({ variables: GUserBeerReviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserBeerReviewComponent = (props: UserBeerReviewComponentProps) => (
      <ApolloReactComponents.Query<GUserBeerReviewQuery, GUserBeerReviewQueryVariables> query={UserBeerReviewDocument} {...props} />
    );
    

/**
 * __useUserBeerReviewQuery__
 *
 * To run a query within a React component, call `useUserBeerReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBeerReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBeerReviewQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserBeerReviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>(UserBeerReviewDocument, baseOptions);
      }
export function useUserBeerReviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>(UserBeerReviewDocument, baseOptions);
        }
export type UserBeerReviewQueryHookResult = ReturnType<typeof useUserBeerReviewQuery>;
export type UserBeerReviewLazyQueryHookResult = ReturnType<typeof useUserBeerReviewLazyQuery>;
export type UserBeerReviewQueryResult = ApolloReactCommon.QueryResult<GUserBeerReviewQuery, GUserBeerReviewQueryVariables>;
export const updateBeerRetiredDocument = gql`
    mutation updateBeerRetired($beerId: ID!, $retired: Boolean!) {
  updateBeer(input: {beerId: $beerId, data: {retired: $retired}}) {
    beer {
      id
      isRetired
    }
  }
}
    `;
export type GupdateBeerRetiredMutationFn = ApolloReactCommon.MutationFunction<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables>;
export type updateBeerRetiredComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables>, 'mutation'>;

    export const updateBeerRetiredComponent = (props: updateBeerRetiredComponentProps) => (
      <ApolloReactComponents.Mutation<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables> mutation={updateBeerRetiredDocument} {...props} />
    );
    

/**
 * __useupdateBeerRetiredMutation__
 *
 * To run a mutation, you first call `useupdateBeerRetiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateBeerRetiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeerRetiredMutation, { data, loading, error }] = useupdateBeerRetiredMutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      retired: // value for 'retired'
 *   },
 * });
 */
export function useupdateBeerRetiredMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables>) {
        return ApolloReactHooks.useMutation<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables>(updateBeerRetiredDocument, baseOptions);
      }
export type updateBeerRetiredMutationHookResult = ReturnType<typeof useupdateBeerRetiredMutation>;
export type updateBeerRetiredMutationResult = ApolloReactCommon.MutationResult<GupdateBeerRetiredMutation>;
export type updateBeerRetiredMutationOptions = ApolloReactCommon.BaseMutationOptions<GupdateBeerRetiredMutation, GupdateBeerRetiredMutationVariables>;
export const updateBeerVerifiedDocument = gql`
    mutation updateBeerVerified($beerId: ID!, $verified: Boolean!) {
  updateBeer(input: {beerId: $beerId, data: {verified: $verified}}) {
    beer {
      id
      isVerified
    }
  }
}
    `;
export type GupdateBeerVerifiedMutationFn = ApolloReactCommon.MutationFunction<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables>;
export type updateBeerVerifiedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables>, 'mutation'>;

    export const updateBeerVerifiedComponent = (props: updateBeerVerifiedComponentProps) => (
      <ApolloReactComponents.Mutation<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables> mutation={updateBeerVerifiedDocument} {...props} />
    );
    

/**
 * __useupdateBeerVerifiedMutation__
 *
 * To run a mutation, you first call `useupdateBeerVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useupdateBeerVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeerVerifiedMutation, { data, loading, error }] = useupdateBeerVerifiedMutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useupdateBeerVerifiedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables>) {
        return ApolloReactHooks.useMutation<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables>(updateBeerVerifiedDocument, baseOptions);
      }
export type updateBeerVerifiedMutationHookResult = ReturnType<typeof useupdateBeerVerifiedMutation>;
export type updateBeerVerifiedMutationResult = ApolloReactCommon.MutationResult<GupdateBeerVerifiedMutation>;
export type updateBeerVerifiedMutationOptions = ApolloReactCommon.BaseMutationOptions<GupdateBeerVerifiedMutation, GupdateBeerVerifiedMutationVariables>;
export const GetBeerEditedInfoDocument = gql`
    query GetBeerEditedInfo($id: ID!) {
  beer(id: $id) {
    id
    createdAt
    updatedBy {
      username
    }
    createdBy {
      username
    }
  }
}
    `;
export type GetBeerEditedInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>, 'query'> & ({ variables: GGetBeerEditedInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBeerEditedInfoComponent = (props: GetBeerEditedInfoComponentProps) => (
      <ApolloReactComponents.Query<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables> query={GetBeerEditedInfoDocument} {...props} />
    );
    

/**
 * __useGetBeerEditedInfoQuery__
 *
 * To run a query within a React component, call `useGetBeerEditedInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeerEditedInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeerEditedInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBeerEditedInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>(GetBeerEditedInfoDocument, baseOptions);
      }
export function useGetBeerEditedInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>(GetBeerEditedInfoDocument, baseOptions);
        }
export type GetBeerEditedInfoQueryHookResult = ReturnType<typeof useGetBeerEditedInfoQuery>;
export type GetBeerEditedInfoLazyQueryHookResult = ReturnType<typeof useGetBeerEditedInfoLazyQuery>;
export type GetBeerEditedInfoQueryResult = ApolloReactCommon.QueryResult<GGetBeerEditedInfoQuery, GGetBeerEditedInfoQueryVariables>;
export const reviewLikeDocument = gql`
    mutation reviewLike($id: ID!) {
  reviewLike(id: $id) {
    review {
      id
      likedByMe
      likeCount
    }
  }
}
    `;
export type GreviewLikeMutationFn = ApolloReactCommon.MutationFunction<GreviewLikeMutation, GreviewLikeMutationVariables>;
export type reviewLikeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GreviewLikeMutation, GreviewLikeMutationVariables>, 'mutation'>;

    export const reviewLikeComponent = (props: reviewLikeComponentProps) => (
      <ApolloReactComponents.Mutation<GreviewLikeMutation, GreviewLikeMutationVariables> mutation={reviewLikeDocument} {...props} />
    );
    

/**
 * __usereviewLikeMutation__
 *
 * To run a mutation, you first call `usereviewLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usereviewLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewLikeMutation, { data, loading, error }] = usereviewLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usereviewLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GreviewLikeMutation, GreviewLikeMutationVariables>) {
        return ApolloReactHooks.useMutation<GreviewLikeMutation, GreviewLikeMutationVariables>(reviewLikeDocument, baseOptions);
      }
export type reviewLikeMutationHookResult = ReturnType<typeof usereviewLikeMutation>;
export type reviewLikeMutationResult = ApolloReactCommon.MutationResult<GreviewLikeMutation>;
export type reviewLikeMutationOptions = ApolloReactCommon.BaseMutationOptions<GreviewLikeMutation, GreviewLikeMutationVariables>;
export const reviewUnlikeDocument = gql`
    mutation reviewUnlike($id: ID!) {
  reviewUnlike(id: $id) {
    id
    likedByMe
    likeCount
  }
}
    `;
export type GreviewUnlikeMutationFn = ApolloReactCommon.MutationFunction<GreviewUnlikeMutation, GreviewUnlikeMutationVariables>;
export type reviewUnlikeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GreviewUnlikeMutation, GreviewUnlikeMutationVariables>, 'mutation'>;

    export const reviewUnlikeComponent = (props: reviewUnlikeComponentProps) => (
      <ApolloReactComponents.Mutation<GreviewUnlikeMutation, GreviewUnlikeMutationVariables> mutation={reviewUnlikeDocument} {...props} />
    );
    

/**
 * __usereviewUnlikeMutation__
 *
 * To run a mutation, you first call `usereviewUnlikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usereviewUnlikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewUnlikeMutation, { data, loading, error }] = usereviewUnlikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usereviewUnlikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GreviewUnlikeMutation, GreviewUnlikeMutationVariables>) {
        return ApolloReactHooks.useMutation<GreviewUnlikeMutation, GreviewUnlikeMutationVariables>(reviewUnlikeDocument, baseOptions);
      }
export type reviewUnlikeMutationHookResult = ReturnType<typeof usereviewUnlikeMutation>;
export type reviewUnlikeMutationResult = ApolloReactCommon.MutationResult<GreviewUnlikeMutation>;
export type reviewUnlikeMutationOptions = ApolloReactCommon.BaseMutationOptions<GreviewUnlikeMutation, GreviewUnlikeMutationVariables>;
export const BeerJSONLDDocument = gql`
    query BeerJSONLD($beerId: ID!) {
  beer(id: $beerId) {
    id
    name
    description
    averageQuickRating
    ratingsCount
    imageUrl
    brewer {
      id
      name
    }
  }
  beerReviews(beerId: $beerId, order: TOP_RATERS, first: 7) {
    items {
      id
      comment
      score
      createdAt
      author {
        id
        username
      }
    }
  }
}
    `;
export type BeerJSONLDComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>, 'query'> & ({ variables: GBeerJSONLDQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeerJSONLDComponent = (props: BeerJSONLDComponentProps) => (
      <ApolloReactComponents.Query<GBeerJSONLDQuery, GBeerJSONLDQueryVariables> query={BeerJSONLDDocument} {...props} />
    );
    

/**
 * __useBeerJSONLDQuery__
 *
 * To run a query within a React component, call `useBeerJSONLDQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerJSONLDQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerJSONLDQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function useBeerJSONLDQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>(BeerJSONLDDocument, baseOptions);
      }
export function useBeerJSONLDLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>(BeerJSONLDDocument, baseOptions);
        }
export type BeerJSONLDQueryHookResult = ReturnType<typeof useBeerJSONLDQuery>;
export type BeerJSONLDLazyQueryHookResult = ReturnType<typeof useBeerJSONLDLazyQuery>;
export type BeerJSONLDQueryResult = ApolloReactCommon.QueryResult<GBeerJSONLDQuery, GBeerJSONLDQueryVariables>;
export const myBeerReviewDocument = gql`
    query myBeerReview($beerId: ID!) {
  myReview: myBeerReview(beerId: $beerId) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type myBeerReviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>, 'query'> & ({ variables: GmyBeerReviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const myBeerReviewComponent = (props: myBeerReviewComponentProps) => (
      <ApolloReactComponents.Query<GmyBeerReviewQuery, GmyBeerReviewQueryVariables> query={myBeerReviewDocument} {...props} />
    );
    

/**
 * __usemyBeerReviewQuery__
 *
 * To run a query within a React component, call `usemyBeerReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usemyBeerReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usemyBeerReviewQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usemyBeerReviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>(myBeerReviewDocument, baseOptions);
      }
export function usemyBeerReviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>(myBeerReviewDocument, baseOptions);
        }
export type myBeerReviewQueryHookResult = ReturnType<typeof usemyBeerReviewQuery>;
export type myBeerReviewLazyQueryHookResult = ReturnType<typeof usemyBeerReviewLazyQuery>;
export type myBeerReviewQueryResult = ApolloReactCommon.QueryResult<GmyBeerReviewQuery, GmyBeerReviewQueryVariables>;
export const mobileRatingInsertDocument = gql`
    mutation mobileRatingInsert($beerId: ID!, $scores: ReviewScoresInput, $comment: String, $placeId: ID, $servedIn: ServedIn, $score: Float) {
  userReview: mobileRatingInsert(beerId: $beerId, scores: $scores, comment: $comment, placeId: $placeId, servedIn: $servedIn, score: $score) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type GmobileRatingInsertMutationFn = ApolloReactCommon.MutationFunction<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables>;
export type mobileRatingInsertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables>, 'mutation'>;

    export const mobileRatingInsertComponent = (props: mobileRatingInsertComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables> mutation={mobileRatingInsertDocument} {...props} />
    );
    

/**
 * __usemobileRatingInsertMutation__
 *
 * To run a mutation, you first call `usemobileRatingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingInsertMutation, { data, loading, error }] = usemobileRatingInsertMutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *      placeId: // value for 'placeId'
 *      servedIn: // value for 'servedIn'
 *      score: // value for 'score'
 *   },
 * });
 */
export function usemobileRatingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables>(mobileRatingInsertDocument, baseOptions);
      }
export type mobileRatingInsertMutationHookResult = ReturnType<typeof usemobileRatingInsertMutation>;
export type mobileRatingInsertMutationResult = ApolloReactCommon.MutationResult<GmobileRatingInsertMutation>;
export type mobileRatingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingInsertMutation, GmobileRatingInsertMutationVariables>;
export const mobileRatingUpdateDocument = gql`
    mutation mobileRatingUpdate($id: ID!, $scores: ReviewScoresInput, $comment: String, $placeId: ID, $servedIn: ServedIn, $score: Float) {
  userReview: mobileRatingUpdate(id: $id, scores: $scores, comment: $comment, placeId: $placeId, servedIn: $servedIn, score: $score) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type GmobileRatingUpdateMutationFn = ApolloReactCommon.MutationFunction<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables>;
export type mobileRatingUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables>, 'mutation'>;

    export const mobileRatingUpdateComponent = (props: mobileRatingUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables> mutation={mobileRatingUpdateDocument} {...props} />
    );
    

/**
 * __usemobileRatingUpdateMutation__
 *
 * To run a mutation, you first call `usemobileRatingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingUpdateMutation, { data, loading, error }] = usemobileRatingUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *      placeId: // value for 'placeId'
 *      servedIn: // value for 'servedIn'
 *      score: // value for 'score'
 *   },
 * });
 */
export function usemobileRatingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables>(mobileRatingUpdateDocument, baseOptions);
      }
export type mobileRatingUpdateMutationHookResult = ReturnType<typeof usemobileRatingUpdateMutation>;
export type mobileRatingUpdateMutationResult = ApolloReactCommon.MutationResult<GmobileRatingUpdateMutation>;
export type mobileRatingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingUpdateMutation, GmobileRatingUpdateMutationVariables>;
export const mobileRatingDeleteDocument = gql`
    mutation mobileRatingDelete($id: ID!) {
  mobileRatingDelete(id: $id)
}
    `;
export type GmobileRatingDeleteMutationFn = ApolloReactCommon.MutationFunction<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables>;
export type mobileRatingDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables>, 'mutation'>;

    export const mobileRatingDeleteComponent = (props: mobileRatingDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables> mutation={mobileRatingDeleteDocument} {...props} />
    );
    

/**
 * __usemobileRatingDeleteMutation__
 *
 * To run a mutation, you first call `usemobileRatingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usemobileRatingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileRatingDeleteMutation, { data, loading, error }] = usemobileRatingDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usemobileRatingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables>(mobileRatingDeleteDocument, baseOptions);
      }
export type mobileRatingDeleteMutationHookResult = ReturnType<typeof usemobileRatingDeleteMutation>;
export type mobileRatingDeleteMutationResult = ApolloReactCommon.MutationResult<GmobileRatingDeleteMutation>;
export type mobileRatingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GmobileRatingDeleteMutation, GmobileRatingDeleteMutationVariables>;
export const isFavoriteDocument = gql`
    query isFavorite($beerId: ID!) {
  isFavorite(beerId: $beerId) {
    id
  }
}
    `;
export type isFavoriteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GisFavoriteQuery, GisFavoriteQueryVariables>, 'query'> & ({ variables: GisFavoriteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const isFavoriteComponent = (props: isFavoriteComponentProps) => (
      <ApolloReactComponents.Query<GisFavoriteQuery, GisFavoriteQueryVariables> query={isFavoriteDocument} {...props} />
    );
    

/**
 * __useisFavoriteQuery__
 *
 * To run a query within a React component, call `useisFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useisFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useisFavoriteQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function useisFavoriteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GisFavoriteQuery, GisFavoriteQueryVariables>) {
        return ApolloReactHooks.useQuery<GisFavoriteQuery, GisFavoriteQueryVariables>(isFavoriteDocument, baseOptions);
      }
export function useisFavoriteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GisFavoriteQuery, GisFavoriteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GisFavoriteQuery, GisFavoriteQueryVariables>(isFavoriteDocument, baseOptions);
        }
export type isFavoriteQueryHookResult = ReturnType<typeof useisFavoriteQuery>;
export type isFavoriteLazyQueryHookResult = ReturnType<typeof useisFavoriteLazyQuery>;
export type isFavoriteQueryResult = ApolloReactCommon.QueryResult<GisFavoriteQuery, GisFavoriteQueryVariables>;
export const favoriteInsertDocument = gql`
    mutation favoriteInsert($beerId: ID!) {
  favoriteInsert(beerId: $beerId) {
    id
  }
}
    `;
export type GfavoriteInsertMutationFn = ApolloReactCommon.MutationFunction<GfavoriteInsertMutation, GfavoriteInsertMutationVariables>;
export type favoriteInsertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GfavoriteInsertMutation, GfavoriteInsertMutationVariables>, 'mutation'>;

    export const favoriteInsertComponent = (props: favoriteInsertComponentProps) => (
      <ApolloReactComponents.Mutation<GfavoriteInsertMutation, GfavoriteInsertMutationVariables> mutation={favoriteInsertDocument} {...props} />
    );
    

/**
 * __usefavoriteInsertMutation__
 *
 * To run a mutation, you first call `usefavoriteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usefavoriteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteInsertMutation, { data, loading, error }] = usefavoriteInsertMutation({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usefavoriteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GfavoriteInsertMutation, GfavoriteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GfavoriteInsertMutation, GfavoriteInsertMutationVariables>(favoriteInsertDocument, baseOptions);
      }
export type favoriteInsertMutationHookResult = ReturnType<typeof usefavoriteInsertMutation>;
export type favoriteInsertMutationResult = ApolloReactCommon.MutationResult<GfavoriteInsertMutation>;
export type favoriteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GfavoriteInsertMutation, GfavoriteInsertMutationVariables>;
export const favoriteDeleteDocument = gql`
    mutation favoriteDelete($id: ID!) {
  favoriteDelete(id: $id)
}
    `;
export type GfavoriteDeleteMutationFn = ApolloReactCommon.MutationFunction<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables>;
export type favoriteDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables>, 'mutation'>;

    export const favoriteDeleteComponent = (props: favoriteDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables> mutation={favoriteDeleteDocument} {...props} />
    );
    

/**
 * __usefavoriteDeleteMutation__
 *
 * To run a mutation, you first call `usefavoriteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usefavoriteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteDeleteMutation, { data, loading, error }] = usefavoriteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usefavoriteDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables>(favoriteDeleteDocument, baseOptions);
      }
export type favoriteDeleteMutationHookResult = ReturnType<typeof usefavoriteDeleteMutation>;
export type favoriteDeleteMutationResult = ApolloReactCommon.MutationResult<GfavoriteDeleteMutation>;
export type favoriteDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GfavoriteDeleteMutation, GfavoriteDeleteMutationVariables>;
export const beerDocument = gql`
    query beer($beerId: ID!) {
  beer(id: $beerId) {
    id
    name
    imageUrl
    isAlias
    description
    style {
      id
      name
      glasses {
        id
        name
      }
    }
    styleScore
    overallScore
    averageQuickRating
    normalizedAverageReview
    averageReview
    createdAt
    updatedAt
    abv
    ibu
    calories
    brewer {
      id
      name
      city
      state {
        id
        name
      }
      country {
        id
        name
        code
      }
      affliatedUsers {
        id
      }
    }
    contractBrewer {
      id
      name
      city
      state {
        id
        name
      }
      affliatedUsers {
        id
      }
      country {
        id
        name
        code
      }
    }
    ratingsCount
    reviewsCount
    isRetired
    isVerified
    isUnrateable
    seasonal
    labels
    availability {
      bottle
      tap
      distribution
    }
  }
}
    `;
export type beerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GbeerQuery, GbeerQueryVariables>, 'query'> & ({ variables: GbeerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const beerComponent = (props: beerComponentProps) => (
      <ApolloReactComponents.Query<GbeerQuery, GbeerQueryVariables> query={beerDocument} {...props} />
    );
    

/**
 * __usebeerQuery__
 *
 * To run a query within a React component, call `usebeerQuery` and pass it any options that fit your needs.
 * When your component renders, `usebeerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usebeerQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usebeerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GbeerQuery, GbeerQueryVariables>) {
        return ApolloReactHooks.useQuery<GbeerQuery, GbeerQueryVariables>(beerDocument, baseOptions);
      }
export function usebeerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbeerQuery, GbeerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GbeerQuery, GbeerQueryVariables>(beerDocument, baseOptions);
        }
export type beerQueryHookResult = ReturnType<typeof usebeerQuery>;
export type beerLazyQueryHookResult = ReturnType<typeof usebeerLazyQuery>;
export type beerQueryResult = ApolloReactCommon.QueryResult<GbeerQuery, GbeerQueryVariables>;
export const beerInStockMultiDocument = gql`
    query beerInStockMulti($latlng: [Float!]!, $beerId: ID!) {
  beerInStockMulti(latlng: $latlng, beerId: $beerId) {
    price
    priceValue
    currencySymbol
    currency
    bottleSize
    productUrl
    productId
    store {
      id
      name
      url
      sellingPoints
      logo {
        uri
      }
    }
  }
}
    `;
export type beerInStockMultiComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>, 'query'> & ({ variables: GbeerInStockMultiQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const beerInStockMultiComponent = (props: beerInStockMultiComponentProps) => (
      <ApolloReactComponents.Query<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables> query={beerInStockMultiDocument} {...props} />
    );
    

/**
 * __usebeerInStockMultiQuery__
 *
 * To run a query within a React component, call `usebeerInStockMultiQuery` and pass it any options that fit your needs.
 * When your component renders, `usebeerInStockMultiQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usebeerInStockMultiQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usebeerInStockMultiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>) {
        return ApolloReactHooks.useQuery<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>(beerInStockMultiDocument, baseOptions);
      }
export function usebeerInStockMultiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>(beerInStockMultiDocument, baseOptions);
        }
export type beerInStockMultiQueryHookResult = ReturnType<typeof usebeerInStockMultiQuery>;
export type beerInStockMultiLazyQueryHookResult = ReturnType<typeof usebeerInStockMultiLazyQuery>;
export type beerInStockMultiQueryResult = ApolloReactCommon.QueryResult<GbeerInStockMultiQuery, GbeerInStockMultiQueryVariables>;
export const tagDisplayDocument = gql`
    query tagDisplay($beerId: ID!) {
  beerTags(beerId: $beerId) {
    items {
      id
      plain
    }
  }
}
    `;
export type tagDisplayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GtagDisplayQuery, GtagDisplayQueryVariables>, 'query'> & ({ variables: GtagDisplayQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const tagDisplayComponent = (props: tagDisplayComponentProps) => (
      <ApolloReactComponents.Query<GtagDisplayQuery, GtagDisplayQueryVariables> query={tagDisplayDocument} {...props} />
    );
    

/**
 * __usetagDisplayQuery__
 *
 * To run a query within a React component, call `usetagDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `usetagDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usetagDisplayQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function usetagDisplayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GtagDisplayQuery, GtagDisplayQueryVariables>) {
        return ApolloReactHooks.useQuery<GtagDisplayQuery, GtagDisplayQueryVariables>(tagDisplayDocument, baseOptions);
      }
export function usetagDisplayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GtagDisplayQuery, GtagDisplayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GtagDisplayQuery, GtagDisplayQueryVariables>(tagDisplayDocument, baseOptions);
        }
export type tagDisplayQueryHookResult = ReturnType<typeof usetagDisplayQuery>;
export type tagDisplayLazyQueryHookResult = ReturnType<typeof usetagDisplayLazyQuery>;
export type tagDisplayQueryResult = ApolloReactCommon.QueryResult<GtagDisplayQuery, GtagDisplayQueryVariables>;
export const nearbyPlacesDocument = gql`
    query nearbyPlaces($latlng: [Float!]!, $beerId: ID!, $first: Int) {
  nearbyPlaces(latlng: $latlng, beerId: $beerId, first: $first) {
    totalCount
    items {
      id
      name
      distance
      streetAddress
      hours
      latitude
      longitude
      type {
        id
        name
      }
      city
      state {
        id
        name
      }
      country {
        id
        name
      }
    }
  }
}
    `;
export type nearbyPlacesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>, 'query'> & ({ variables: GnearbyPlacesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const nearbyPlacesComponent = (props: nearbyPlacesComponentProps) => (
      <ApolloReactComponents.Query<GnearbyPlacesQuery, GnearbyPlacesQueryVariables> query={nearbyPlacesDocument} {...props} />
    );
    

/**
 * __usenearbyPlacesQuery__
 *
 * To run a query within a React component, call `usenearbyPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usenearbyPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usenearbyPlacesQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *      beerId: // value for 'beerId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usenearbyPlacesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>) {
        return ApolloReactHooks.useQuery<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>(nearbyPlacesDocument, baseOptions);
      }
export function usenearbyPlacesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>(nearbyPlacesDocument, baseOptions);
        }
export type nearbyPlacesQueryHookResult = ReturnType<typeof usenearbyPlacesQuery>;
export type nearbyPlacesLazyQueryHookResult = ReturnType<typeof usenearbyPlacesLazyQuery>;
export type nearbyPlacesQueryResult = ApolloReactCommon.QueryResult<GnearbyPlacesQuery, GnearbyPlacesQueryVariables>;
export const BeeryChristmasDocument = gql`
    query BeeryChristmas($latlng: [Float!]!) {
  regionInfo(input: {latlng: $latlng}) {
    country {
      id
      name
      code
    }
  }
}
    `;
export type BeeryChristmasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>, 'query'> & ({ variables: GBeeryChristmasQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeeryChristmasComponent = (props: BeeryChristmasComponentProps) => (
      <ApolloReactComponents.Query<GBeeryChristmasQuery, GBeeryChristmasQueryVariables> query={BeeryChristmasDocument} {...props} />
    );
    

/**
 * __useBeeryChristmasQuery__
 *
 * To run a query within a React component, call `useBeeryChristmasQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeeryChristmasQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeeryChristmasQuery({
 *   variables: {
 *      latlng: // value for 'latlng'
 *   },
 * });
 */
export function useBeeryChristmasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>(BeeryChristmasDocument, baseOptions);
      }
export function useBeeryChristmasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>(BeeryChristmasDocument, baseOptions);
        }
export type BeeryChristmasQueryHookResult = ReturnType<typeof useBeeryChristmasQuery>;
export type BeeryChristmasLazyQueryHookResult = ReturnType<typeof useBeeryChristmasLazyQuery>;
export type BeeryChristmasQueryResult = ApolloReactCommon.QueryResult<GBeeryChristmasQuery, GBeeryChristmasQueryVariables>;
export const CloneBrewerDocument = gql`
    mutation CloneBrewer($input: CloneBrewerToPlaceInput!) {
  cloneBrewerToPlace(input: $input) {
    place {
      id
      name
      rateCount
      percentile
      distance
      averageRating
      city
      country {
        id
        name
        code
      }
      state {
        id
        name
      }
      isRetired
      imageUrl
      type {
        name
      }
    }
  }
}
    `;
export type GCloneBrewerMutationFn = ApolloReactCommon.MutationFunction<GCloneBrewerMutation, GCloneBrewerMutationVariables>;
export type CloneBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GCloneBrewerMutation, GCloneBrewerMutationVariables>, 'mutation'>;

    export const CloneBrewerComponent = (props: CloneBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GCloneBrewerMutation, GCloneBrewerMutationVariables> mutation={CloneBrewerDocument} {...props} />
    );
    

/**
 * __useCloneBrewerMutation__
 *
 * To run a mutation, you first call `useCloneBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneBrewerMutation, { data, loading, error }] = useCloneBrewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GCloneBrewerMutation, GCloneBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GCloneBrewerMutation, GCloneBrewerMutationVariables>(CloneBrewerDocument, baseOptions);
      }
export type CloneBrewerMutationHookResult = ReturnType<typeof useCloneBrewerMutation>;
export type CloneBrewerMutationResult = ApolloReactCommon.MutationResult<GCloneBrewerMutation>;
export type CloneBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GCloneBrewerMutation, GCloneBrewerMutationVariables>;
export const GetBrewerBeersDocument = gql`
    query GetBrewerBeers($brewerId: ID!, $after: ID, $first: Int = 15, $query: String, $orderBy: BrewerBeersInputOrder = OVERALL_SCORE, $orderDirection: OrderDirection, $minRatings: Int, $hideRetired: Boolean, $hideVerified: Boolean, $hideUnverified: Boolean, $hideAliased: Boolean, $hideUserRatedBeers: Boolean, $hideUserHasNotRated: Boolean) {
  brewerBeers(input: {brewerId: $brewerId, first: $first, after: $after, query: $query, order: $orderBy, orderDirection: $orderDirection, minRatings: $minRatings, hideRetiredBeers: $hideRetired, hideAliasedBeers: $hideAliased, hideVerifiedBeers: $hideVerified, hideUnverifiedBeers: $hideUnverified, hideUserRatedBeers: $hideUserRatedBeers, hideUserHasNotRatedBeers: $hideUserHasNotRated}) {
    totalCount
    last
    totalBeersBrewed
    items {
      beer {
        id
        name
        createdAt
        abv
        style {
          id
          name
        }
        overallScore
        styleScore
        ratingsCount
        averageQuickRating
        imageUrl
        isRetired
        isAlias
        isVerified
        brewer {
          id
          name
          country {
            id
            name
            code
          }
        }
        contractBrewer {
          id
          name
          country {
            id
            name
            code
          }
        }
      }
      review {
        id
        score
        createdAt
      }
    }
    userReviewsCount
  }
}
    `;
export type GetBrewerBeersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>, 'query'> & ({ variables: GGetBrewerBeersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBrewerBeersComponent = (props: GetBrewerBeersComponentProps) => (
      <ApolloReactComponents.Query<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables> query={GetBrewerBeersDocument} {...props} />
    );
    

/**
 * __useGetBrewerBeersQuery__
 *
 * To run a query within a React component, call `useGetBrewerBeersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrewerBeersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrewerBeersQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      minRatings: // value for 'minRatings'
 *      hideRetired: // value for 'hideRetired'
 *      hideVerified: // value for 'hideVerified'
 *      hideUnverified: // value for 'hideUnverified'
 *      hideAliased: // value for 'hideAliased'
 *      hideUserRatedBeers: // value for 'hideUserRatedBeers'
 *      hideUserHasNotRated: // value for 'hideUserHasNotRated'
 *   },
 * });
 */
export function useGetBrewerBeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>(GetBrewerBeersDocument, baseOptions);
      }
export function useGetBrewerBeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>(GetBrewerBeersDocument, baseOptions);
        }
export type GetBrewerBeersQueryHookResult = ReturnType<typeof useGetBrewerBeersQuery>;
export type GetBrewerBeersLazyQueryHookResult = ReturnType<typeof useGetBrewerBeersLazyQuery>;
export type GetBrewerBeersQueryResult = ApolloReactCommon.QueryResult<GGetBrewerBeersQuery, GGetBrewerBeersQueryVariables>;
export const AssociateWithBrewerDocument = gql`
    mutation AssociateWithBrewer($input: AssociateUserWithEntityInput!) {
  associateUserWithEntity(input: $input) {
    user {
      id
      username
    }
  }
}
    `;
export type GAssociateWithBrewerMutationFn = ApolloReactCommon.MutationFunction<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables>;
export type AssociateWithBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables>, 'mutation'>;

    export const AssociateWithBrewerComponent = (props: AssociateWithBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables> mutation={AssociateWithBrewerDocument} {...props} />
    );
    

/**
 * __useAssociateWithBrewerMutation__
 *
 * To run a mutation, you first call `useAssociateWithBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateWithBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateWithBrewerMutation, { data, loading, error }] = useAssociateWithBrewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateWithBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables>(AssociateWithBrewerDocument, baseOptions);
      }
export type AssociateWithBrewerMutationHookResult = ReturnType<typeof useAssociateWithBrewerMutation>;
export type AssociateWithBrewerMutationResult = ApolloReactCommon.MutationResult<GAssociateWithBrewerMutation>;
export type AssociateWithBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GAssociateWithBrewerMutation, GAssociateWithBrewerMutationVariables>;
export const FollowBrewerDocument = gql`
    mutation FollowBrewer($brewerId: ID!) {
  followBrewer(input: {brewerId: $brewerId}) {
    brewer {
      id
      name
    }
  }
}
    `;
export type GFollowBrewerMutationFn = ApolloReactCommon.MutationFunction<GFollowBrewerMutation, GFollowBrewerMutationVariables>;
export type FollowBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GFollowBrewerMutation, GFollowBrewerMutationVariables>, 'mutation'>;

    export const FollowBrewerComponent = (props: FollowBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GFollowBrewerMutation, GFollowBrewerMutationVariables> mutation={FollowBrewerDocument} {...props} />
    );
    

/**
 * __useFollowBrewerMutation__
 *
 * To run a mutation, you first call `useFollowBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followBrewerMutation, { data, loading, error }] = useFollowBrewerMutation({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useFollowBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GFollowBrewerMutation, GFollowBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GFollowBrewerMutation, GFollowBrewerMutationVariables>(FollowBrewerDocument, baseOptions);
      }
export type FollowBrewerMutationHookResult = ReturnType<typeof useFollowBrewerMutation>;
export type FollowBrewerMutationResult = ApolloReactCommon.MutationResult<GFollowBrewerMutation>;
export type FollowBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GFollowBrewerMutation, GFollowBrewerMutationVariables>;
export const UnfollowBrewerDocument = gql`
    mutation UnfollowBrewer($brewerId: ID!) {
  unfollowBrewer(input: {brewerId: $brewerId}) {
    brewer {
      id
      name
    }
  }
}
    `;
export type GUnfollowBrewerMutationFn = ApolloReactCommon.MutationFunction<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables>;
export type UnfollowBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables>, 'mutation'>;

    export const UnfollowBrewerComponent = (props: UnfollowBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables> mutation={UnfollowBrewerDocument} {...props} />
    );
    

/**
 * __useUnfollowBrewerMutation__
 *
 * To run a mutation, you first call `useUnfollowBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowBrewerMutation, { data, loading, error }] = useUnfollowBrewerMutation({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useUnfollowBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables>(UnfollowBrewerDocument, baseOptions);
      }
export type UnfollowBrewerMutationHookResult = ReturnType<typeof useUnfollowBrewerMutation>;
export type UnfollowBrewerMutationResult = ApolloReactCommon.MutationResult<GUnfollowBrewerMutation>;
export type UnfollowBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GUnfollowBrewerMutation, GUnfollowBrewerMutationVariables>;
export const GetBrewerPageInfoDocument = gql`
    query GetBrewerPageInfo($brewerId: ID!) {
  brewer(id: $brewerId) {
    id
    name
    description
    country {
      id
      code
      name
    }
    email
    streetAddress
    city
    type
    zip
    twitter
    facebook
    instagram
    imageUrl
    state {
      name
      id
    }
    isRetired
    phone
    areaCode
    web
    hours
    affliatedUsers {
      username
      id
    }
    isFollowedByMe
    associatedPlaces {
      id
      name
      distance
      rateCount
      city
      country {
        id
        name
        code
      }
      state {
        id
        name
      }
      isRetired
      percentile
      averageRating
      imageUrl
      type {
        id
        name
      }
    }
  }
}
    `;
export type GetBrewerPageInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>, 'query'> & ({ variables: GGetBrewerPageInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBrewerPageInfoComponent = (props: GetBrewerPageInfoComponentProps) => (
      <ApolloReactComponents.Query<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables> query={GetBrewerPageInfoDocument} {...props} />
    );
    

/**
 * __useGetBrewerPageInfoQuery__
 *
 * To run a query within a React component, call `useGetBrewerPageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrewerPageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrewerPageInfoQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useGetBrewerPageInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>(GetBrewerPageInfoDocument, baseOptions);
      }
export function useGetBrewerPageInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>(GetBrewerPageInfoDocument, baseOptions);
        }
export type GetBrewerPageInfoQueryHookResult = ReturnType<typeof useGetBrewerPageInfoQuery>;
export type GetBrewerPageInfoLazyQueryHookResult = ReturnType<typeof useGetBrewerPageInfoLazyQuery>;
export type GetBrewerPageInfoQueryResult = ApolloReactCommon.QueryResult<GGetBrewerPageInfoQuery, GGetBrewerPageInfoQueryVariables>;
export const GetRecentBrewerReviewsDocument = gql`
    query GetRecentBrewerReviews($brewerId: ID!, $first: Int, $after: ID) {
  brewerBeerReviews(input: {brewerId: $brewerId, first: $first, after: $after}) {
    last
    items {
      review {
        comment
        id
        score
        createdAt
        author {
          id
          username
          imageUrl
          reviewCount
        }
        beer {
          id
          name
        }
        likedByMe
        likeCount
      }
    }
  }
}
    `;
export type GetRecentBrewerReviewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>, 'query'> & ({ variables: GGetRecentBrewerReviewsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRecentBrewerReviewsComponent = (props: GetRecentBrewerReviewsComponentProps) => (
      <ApolloReactComponents.Query<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables> query={GetRecentBrewerReviewsDocument} {...props} />
    );
    

/**
 * __useGetRecentBrewerReviewsQuery__
 *
 * To run a query within a React component, call `useGetRecentBrewerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentBrewerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentBrewerReviewsQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetRecentBrewerReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>(GetRecentBrewerReviewsDocument, baseOptions);
      }
export function useGetRecentBrewerReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>(GetRecentBrewerReviewsDocument, baseOptions);
        }
export type GetRecentBrewerReviewsQueryHookResult = ReturnType<typeof useGetRecentBrewerReviewsQuery>;
export type GetRecentBrewerReviewsLazyQueryHookResult = ReturnType<typeof useGetRecentBrewerReviewsLazyQuery>;
export type GetRecentBrewerReviewsQueryResult = ApolloReactCommon.QueryResult<GGetRecentBrewerReviewsQuery, GGetRecentBrewerReviewsQueryVariables>;
export const GetTopRatersDocument = gql`
    query GetTopRaters($brewerId: ID!) {
  brewerTopRaters(input: {brewerId: $brewerId, limit: 6}) {
    topRaters {
      reviewCount
      user {
        id
        username
        city
        country {
          id
          name
          code
        }
        imageUrl
      }
    }
  }
}
    `;
export type GetTopRatersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetTopRatersQuery, GGetTopRatersQueryVariables>, 'query'> & ({ variables: GGetTopRatersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTopRatersComponent = (props: GetTopRatersComponentProps) => (
      <ApolloReactComponents.Query<GGetTopRatersQuery, GGetTopRatersQueryVariables> query={GetTopRatersDocument} {...props} />
    );
    

/**
 * __useGetTopRatersQuery__
 *
 * To run a query within a React component, call `useGetTopRatersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopRatersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopRatersQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useGetTopRatersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetTopRatersQuery, GGetTopRatersQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetTopRatersQuery, GGetTopRatersQueryVariables>(GetTopRatersDocument, baseOptions);
      }
export function useGetTopRatersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetTopRatersQuery, GGetTopRatersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetTopRatersQuery, GGetTopRatersQueryVariables>(GetTopRatersDocument, baseOptions);
        }
export type GetTopRatersQueryHookResult = ReturnType<typeof useGetTopRatersQuery>;
export type GetTopRatersLazyQueryHookResult = ReturnType<typeof useGetTopRatersLazyQuery>;
export type GetTopRatersQueryResult = ApolloReactCommon.QueryResult<GGetTopRatersQuery, GGetTopRatersQueryVariables>;
export const UpdateBeerDocument = gql`
    mutation UpdateBeer($input: UpdateBeerInput!) {
  updateBeer(input: $input) {
    beer {
      id
      name
      isVerified
    }
  }
}
    `;
export type GUpdateBeerMutationFn = ApolloReactCommon.MutationFunction<GUpdateBeerMutation, GUpdateBeerMutationVariables>;
export type UpdateBeerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUpdateBeerMutation, GUpdateBeerMutationVariables>, 'mutation'>;

    export const UpdateBeerComponent = (props: UpdateBeerComponentProps) => (
      <ApolloReactComponents.Mutation<GUpdateBeerMutation, GUpdateBeerMutationVariables> mutation={UpdateBeerDocument} {...props} />
    );
    

/**
 * __useUpdateBeerMutation__
 *
 * To run a mutation, you first call `useUpdateBeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeerMutation, { data, loading, error }] = useUpdateBeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUpdateBeerMutation, GUpdateBeerMutationVariables>) {
        return ApolloReactHooks.useMutation<GUpdateBeerMutation, GUpdateBeerMutationVariables>(UpdateBeerDocument, baseOptions);
      }
export type UpdateBeerMutationHookResult = ReturnType<typeof useUpdateBeerMutation>;
export type UpdateBeerMutationResult = ApolloReactCommon.MutationResult<GUpdateBeerMutation>;
export type UpdateBeerMutationOptions = ApolloReactCommon.BaseMutationOptions<GUpdateBeerMutation, GUpdateBeerMutationVariables>;
export const GetBeerInfoDocument = gql`
    query GetBeerInfo($id: ID!) {
  beer(id: $id) {
    id
    name
    description
    styleScore
    overallScore
    averageQuickRating
    createdAt
    adminNotes
    isRetired
    isUnrateable
    createdBy {
      id
      username
    }
    updatedBy {
      id
      username
    }
    updatedAt
    brewer {
      name
      id
      city
      country {
        name
        id
        code
      }
      affliatedUsers {
        id
      }
    }
    abv
    ibu
    seasonal
    contractBrewer {
      name
      id
      city
      affliatedUsers {
        id
      }
      country {
        name
        id
        code
      }
    }
    isVerified
    style {
      id
      name
      parent {
        id
        name
      }
    }
    imageUrl
    ratingsCount
  }
}
    `;
export type GetBeerInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>, 'query'> & ({ variables: GGetBeerInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBeerInfoComponent = (props: GetBeerInfoComponentProps) => (
      <ApolloReactComponents.Query<GGetBeerInfoQuery, GGetBeerInfoQueryVariables> query={GetBeerInfoDocument} {...props} />
    );
    

/**
 * __useGetBeerInfoQuery__
 *
 * To run a query within a React component, call `useGetBeerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeerInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBeerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>(GetBeerInfoDocument, baseOptions);
      }
export function useGetBeerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>(GetBeerInfoDocument, baseOptions);
        }
export type GetBeerInfoQueryHookResult = ReturnType<typeof useGetBeerInfoQuery>;
export type GetBeerInfoLazyQueryHookResult = ReturnType<typeof useGetBeerInfoLazyQuery>;
export type GetBeerInfoQueryResult = ApolloReactCommon.QueryResult<GGetBeerInfoQuery, GGetBeerInfoQueryVariables>;
export const EditBrewerPreviewDataDocument = gql`
    query EditBrewerPreviewData($brewerId: ID!) {
  brewer(id: $brewerId) {
    id
    createdAt
    updatedAt
    imageUrl
    createdBy {
      id
      username
    }
    updatedBy {
      id
      username
    }
  }
}
    `;
export type EditBrewerPreviewDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>, 'query'> & ({ variables: GEditBrewerPreviewDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EditBrewerPreviewDataComponent = (props: EditBrewerPreviewDataComponentProps) => (
      <ApolloReactComponents.Query<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables> query={EditBrewerPreviewDataDocument} {...props} />
    );
    

/**
 * __useEditBrewerPreviewDataQuery__
 *
 * To run a query within a React component, call `useEditBrewerPreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditBrewerPreviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditBrewerPreviewDataQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useEditBrewerPreviewDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>(EditBrewerPreviewDataDocument, baseOptions);
      }
export function useEditBrewerPreviewDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>(EditBrewerPreviewDataDocument, baseOptions);
        }
export type EditBrewerPreviewDataQueryHookResult = ReturnType<typeof useEditBrewerPreviewDataQuery>;
export type EditBrewerPreviewDataLazyQueryHookResult = ReturnType<typeof useEditBrewerPreviewDataLazyQuery>;
export type EditBrewerPreviewDataQueryResult = ApolloReactCommon.QueryResult<GEditBrewerPreviewDataQuery, GEditBrewerPreviewDataQueryVariables>;
export const EditBrewerInitialValuesDocument = gql`
    query EditBrewerInitialValues($brewerId: ID!) {
  brewer(id: $brewerId) {
    id
    dateOpened
    isRetired
    name
    brewerType {
      id
      name
    }
    description
    streetAddress
    city
    areaCode
    zip
    metro {
      id
      name
    }
    country {
      id
      name
      code
    }
    phone
    email
    web
    twitter
    facebook
    instagram
    hours
    adminNotes
    imageUrl
    affliatedUsers {
      id
    }
  }
}
    `;
export type EditBrewerInitialValuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>, 'query'> & ({ variables: GEditBrewerInitialValuesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EditBrewerInitialValuesComponent = (props: EditBrewerInitialValuesComponentProps) => (
      <ApolloReactComponents.Query<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables> query={EditBrewerInitialValuesDocument} {...props} />
    );
    

/**
 * __useEditBrewerInitialValuesQuery__
 *
 * To run a query within a React component, call `useEditBrewerInitialValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditBrewerInitialValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditBrewerInitialValuesQuery({
 *   variables: {
 *      brewerId: // value for 'brewerId'
 *   },
 * });
 */
export function useEditBrewerInitialValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>(EditBrewerInitialValuesDocument, baseOptions);
      }
export function useEditBrewerInitialValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>(EditBrewerInitialValuesDocument, baseOptions);
        }
export type EditBrewerInitialValuesQueryHookResult = ReturnType<typeof useEditBrewerInitialValuesQuery>;
export type EditBrewerInitialValuesLazyQueryHookResult = ReturnType<typeof useEditBrewerInitialValuesLazyQuery>;
export type EditBrewerInitialValuesQueryResult = ApolloReactCommon.QueryResult<GEditBrewerInitialValuesQuery, GEditBrewerInitialValuesQueryVariables>;
export const UpdateBrewerDocument = gql`
    mutation UpdateBrewer($input: UpdateBrewerInput!) {
  updateBrewer(input: $input) {
    brewer {
      id
      name
      description
    }
  }
}
    `;
export type GUpdateBrewerMutationFn = ApolloReactCommon.MutationFunction<GUpdateBrewerMutation, GUpdateBrewerMutationVariables>;
export type UpdateBrewerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUpdateBrewerMutation, GUpdateBrewerMutationVariables>, 'mutation'>;

    export const UpdateBrewerComponent = (props: UpdateBrewerComponentProps) => (
      <ApolloReactComponents.Mutation<GUpdateBrewerMutation, GUpdateBrewerMutationVariables> mutation={UpdateBrewerDocument} {...props} />
    );
    

/**
 * __useUpdateBrewerMutation__
 *
 * To run a mutation, you first call `useUpdateBrewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrewerMutation, { data, loading, error }] = useUpdateBrewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrewerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUpdateBrewerMutation, GUpdateBrewerMutationVariables>) {
        return ApolloReactHooks.useMutation<GUpdateBrewerMutation, GUpdateBrewerMutationVariables>(UpdateBrewerDocument, baseOptions);
      }
export type UpdateBrewerMutationHookResult = ReturnType<typeof useUpdateBrewerMutation>;
export type UpdateBrewerMutationResult = ApolloReactCommon.MutationResult<GUpdateBrewerMutation>;
export type UpdateBrewerMutationOptions = ApolloReactCommon.BaseMutationOptions<GUpdateBrewerMutation, GUpdateBrewerMutationVariables>;
export const UploadBrewerImageDocument = gql`
    mutation UploadBrewerImage($input: UploadBrewerImageInput!) {
  uploadBrewerImage(input: $input) {
    brewer {
      id
      imageUrl
    }
  }
}
    `;
export type GUploadBrewerImageMutationFn = ApolloReactCommon.MutationFunction<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables>;
export type UploadBrewerImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables>, 'mutation'>;

    export const UploadBrewerImageComponent = (props: UploadBrewerImageComponentProps) => (
      <ApolloReactComponents.Mutation<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables> mutation={UploadBrewerImageDocument} {...props} />
    );
    

/**
 * __useUploadBrewerImageMutation__
 *
 * To run a mutation, you first call `useUploadBrewerImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrewerImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrewerImageMutation, { data, loading, error }] = useUploadBrewerImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrewerImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables>) {
        return ApolloReactHooks.useMutation<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables>(UploadBrewerImageDocument, baseOptions);
      }
export type UploadBrewerImageMutationHookResult = ReturnType<typeof useUploadBrewerImageMutation>;
export type UploadBrewerImageMutationResult = ApolloReactCommon.MutationResult<GUploadBrewerImageMutation>;
export type UploadBrewerImageMutationOptions = ApolloReactCommon.BaseMutationOptions<GUploadBrewerImageMutation, GUploadBrewerImageMutationVariables>;
export const UpdatePlaceIntiialValuesDocument = gql`
    query UpdatePlaceIntiialValues($placeId: ID!) {
  place(id: $placeId) {
    id
    brewer {
      id
      name
    }
    name
    bottles
    taps
    description
    latitude
    longitude
    type {
      id
      name
    }
    twitter
    facebook
    web
    streetAddress
    city
    state {
      id
      name
    }
    country {
      id
      name
      code
    }
    zip
    countryCode
    areaCode
    phone
    imageUrl
    isRetired
    hours
    beerMenu
    foodMenu
    wifi
    flights
    email
    metro {
      id
      name
    }
    singles
    mailOrder
    mailOrderIntl
    children
    seasonals
    patio
    realAles
    growlers
    glassware
    dateOpened
  }
}
    `;
export type UpdatePlaceIntiialValuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>, 'query'> & ({ variables: GUpdatePlaceIntiialValuesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UpdatePlaceIntiialValuesComponent = (props: UpdatePlaceIntiialValuesComponentProps) => (
      <ApolloReactComponents.Query<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables> query={UpdatePlaceIntiialValuesDocument} {...props} />
    );
    

/**
 * __useUpdatePlaceIntiialValuesQuery__
 *
 * To run a query within a React component, call `useUpdatePlaceIntiialValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceIntiialValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatePlaceIntiialValuesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useUpdatePlaceIntiialValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>(UpdatePlaceIntiialValuesDocument, baseOptions);
      }
export function useUpdatePlaceIntiialValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>(UpdatePlaceIntiialValuesDocument, baseOptions);
        }
export type UpdatePlaceIntiialValuesQueryHookResult = ReturnType<typeof useUpdatePlaceIntiialValuesQuery>;
export type UpdatePlaceIntiialValuesLazyQueryHookResult = ReturnType<typeof useUpdatePlaceIntiialValuesLazyQuery>;
export type UpdatePlaceIntiialValuesQueryResult = ApolloReactCommon.QueryResult<GUpdatePlaceIntiialValuesQuery, GUpdatePlaceIntiialValuesQueryVariables>;
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($input: UpdatePlaceInput!) {
  updatePlace(input: $input) {
    place {
      id
      name
      description
    }
  }
}
    `;
export type GUpdatePlaceMutationFn = ApolloReactCommon.MutationFunction<GUpdatePlaceMutation, GUpdatePlaceMutationVariables>;
export type UpdatePlaceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUpdatePlaceMutation, GUpdatePlaceMutationVariables>, 'mutation'>;

    export const UpdatePlaceComponent = (props: UpdatePlaceComponentProps) => (
      <ApolloReactComponents.Mutation<GUpdatePlaceMutation, GUpdatePlaceMutationVariables> mutation={UpdatePlaceDocument} {...props} />
    );
    

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUpdatePlaceMutation, GUpdatePlaceMutationVariables>) {
        return ApolloReactHooks.useMutation<GUpdatePlaceMutation, GUpdatePlaceMutationVariables>(UpdatePlaceDocument, baseOptions);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = ApolloReactCommon.MutationResult<GUpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = ApolloReactCommon.BaseMutationOptions<GUpdatePlaceMutation, GUpdatePlaceMutationVariables>;
export const EditPlacePreviewDataDocument = gql`
    query EditPlacePreviewData($placeId: ID!) {
  place(id: $placeId) {
    id
    createdAt
    updatedAt
    imageUrl
    createdBy {
      id
      username
    }
    updatedBy {
      id
      username
    }
  }
}
    `;
export type EditPlacePreviewDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>, 'query'> & ({ variables: GEditPlacePreviewDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EditPlacePreviewDataComponent = (props: EditPlacePreviewDataComponentProps) => (
      <ApolloReactComponents.Query<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables> query={EditPlacePreviewDataDocument} {...props} />
    );
    

/**
 * __useEditPlacePreviewDataQuery__
 *
 * To run a query within a React component, call `useEditPlacePreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlacePreviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlacePreviewDataQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useEditPlacePreviewDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>(EditPlacePreviewDataDocument, baseOptions);
      }
export function useEditPlacePreviewDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>(EditPlacePreviewDataDocument, baseOptions);
        }
export type EditPlacePreviewDataQueryHookResult = ReturnType<typeof useEditPlacePreviewDataQuery>;
export type EditPlacePreviewDataLazyQueryHookResult = ReturnType<typeof useEditPlacePreviewDataLazyQuery>;
export type EditPlacePreviewDataQueryResult = ApolloReactCommon.QueryResult<GEditPlacePreviewDataQuery, GEditPlacePreviewDataQueryVariables>;
export const FavoritesListDocument = gql`
    query FavoritesList($first: Int, $after: ID) {
  favorites(first: $first, after: $after) {
    totalCount
    last
    items {
      id
      createdAt
      beer {
        id
        name
        styleScore
        overallScore
        imageUrl
      }
      review {
        id
        score
      }
    }
  }
}
    `;
export type FavoritesListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFavoritesListQuery, GFavoritesListQueryVariables>, 'query'>;

    export const FavoritesListComponent = (props: FavoritesListComponentProps) => (
      <ApolloReactComponents.Query<GFavoritesListQuery, GFavoritesListQueryVariables> query={FavoritesListDocument} {...props} />
    );
    

/**
 * __useFavoritesListQuery__
 *
 * To run a query within a React component, call `useFavoritesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritesListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFavoritesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFavoritesListQuery, GFavoritesListQueryVariables>) {
        return ApolloReactHooks.useQuery<GFavoritesListQuery, GFavoritesListQueryVariables>(FavoritesListDocument, baseOptions);
      }
export function useFavoritesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFavoritesListQuery, GFavoritesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFavoritesListQuery, GFavoritesListQueryVariables>(FavoritesListDocument, baseOptions);
        }
export type FavoritesListQueryHookResult = ReturnType<typeof useFavoritesListQuery>;
export type FavoritesListLazyQueryHookResult = ReturnType<typeof useFavoritesListLazyQuery>;
export type FavoritesListQueryResult = ApolloReactCommon.QueryResult<GFavoritesListQuery, GFavoritesListQueryVariables>;
export const DeleteFavoriteDocument = gql`
    mutation DeleteFavorite($id: ID!) {
  favoriteDelete(id: $id)
}
    `;
export type GDeleteFavoriteMutationFn = ApolloReactCommon.MutationFunction<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables>;
export type DeleteFavoriteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables>, 'mutation'>;

    export const DeleteFavoriteComponent = (props: DeleteFavoriteComponentProps) => (
      <ApolloReactComponents.Mutation<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables> mutation={DeleteFavoriteDocument} {...props} />
    );
    

/**
 * __useDeleteFavoriteMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteMutation, { data, loading, error }] = useDeleteFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFavoriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables>) {
        return ApolloReactHooks.useMutation<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables>(DeleteFavoriteDocument, baseOptions);
      }
export type DeleteFavoriteMutationHookResult = ReturnType<typeof useDeleteFavoriteMutation>;
export type DeleteFavoriteMutationResult = ApolloReactCommon.MutationResult<GDeleteFavoriteMutation>;
export type DeleteFavoriteMutationOptions = ApolloReactCommon.BaseMutationOptions<GDeleteFavoriteMutation, GDeleteFavoriteMutationVariables>;
export const festivalBeersDocument = gql`
    query festivalBeers($id: ID!, $first: Int, $styleId: ID, $after: ID, $order: FestivalBeersOrder, $query: String) {
  festivalBeers(id: $id, first: $first, styleId: $styleId, after: $after, order: $order, query: $query) {
    items {
      beer {
        id
        name
        style {
          id
          name
        }
        overallScore
        styleScore
        averageQuickRating
        abv
        ibu
        brewer {
          id
          name
        }
        ratingsCount
        imageUrl
        isRetired
        isAlias
      }
    }
    totalCount
    last
  }
}
    `;
export type festivalBeersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfestivalBeersQuery, GfestivalBeersQueryVariables>, 'query'> & ({ variables: GfestivalBeersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const festivalBeersComponent = (props: festivalBeersComponentProps) => (
      <ApolloReactComponents.Query<GfestivalBeersQuery, GfestivalBeersQueryVariables> query={festivalBeersDocument} {...props} />
    );
    

/**
 * __usefestivalBeersQuery__
 *
 * To run a query within a React component, call `usefestivalBeersQuery` and pass it any options that fit your needs.
 * When your component renders, `usefestivalBeersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usefestivalBeersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      styleId: // value for 'styleId'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usefestivalBeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfestivalBeersQuery, GfestivalBeersQueryVariables>) {
        return ApolloReactHooks.useQuery<GfestivalBeersQuery, GfestivalBeersQueryVariables>(festivalBeersDocument, baseOptions);
      }
export function usefestivalBeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfestivalBeersQuery, GfestivalBeersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfestivalBeersQuery, GfestivalBeersQueryVariables>(festivalBeersDocument, baseOptions);
        }
export type festivalBeersQueryHookResult = ReturnType<typeof usefestivalBeersQuery>;
export type festivalBeersLazyQueryHookResult = ReturnType<typeof usefestivalBeersLazyQuery>;
export type festivalBeersQueryResult = ApolloReactCommon.QueryResult<GfestivalBeersQuery, GfestivalBeersQueryVariables>;
export const festivalDocument = gql`
    query festival($id: ID!) {
  festival(id: $id) {
    id
    createdAt
    name
    description
    start
    end
    radius
    latlng
    distance
    address
    city
    zip
    url
    country {
      id
      name
    }
    images {
      logo
      hero
      banner
    }
  }
}
    `;
export type festivalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfestivalQuery, GfestivalQueryVariables>, 'query'> & ({ variables: GfestivalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const festivalComponent = (props: festivalComponentProps) => (
      <ApolloReactComponents.Query<GfestivalQuery, GfestivalQueryVariables> query={festivalDocument} {...props} />
    );
    

/**
 * __usefestivalQuery__
 *
 * To run a query within a React component, call `usefestivalQuery` and pass it any options that fit your needs.
 * When your component renders, `usefestivalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usefestivalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usefestivalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfestivalQuery, GfestivalQueryVariables>) {
        return ApolloReactHooks.useQuery<GfestivalQuery, GfestivalQueryVariables>(festivalDocument, baseOptions);
      }
export function usefestivalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfestivalQuery, GfestivalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfestivalQuery, GfestivalQueryVariables>(festivalDocument, baseOptions);
        }
export type festivalQueryHookResult = ReturnType<typeof usefestivalQuery>;
export type festivalLazyQueryHookResult = ReturnType<typeof usefestivalLazyQuery>;
export type festivalQueryResult = ApolloReactCommon.QueryResult<GfestivalQuery, GfestivalQueryVariables>;
export const festivalStylesDocument = gql`
    query festivalStyles($festivalId: ID!) {
  festivalStyles(festivalId: $festivalId) {
    id
    name
  }
}
    `;
export type festivalStylesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfestivalStylesQuery, GfestivalStylesQueryVariables>, 'query'> & ({ variables: GfestivalStylesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const festivalStylesComponent = (props: festivalStylesComponentProps) => (
      <ApolloReactComponents.Query<GfestivalStylesQuery, GfestivalStylesQueryVariables> query={festivalStylesDocument} {...props} />
    );
    

/**
 * __usefestivalStylesQuery__
 *
 * To run a query within a React component, call `usefestivalStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `usefestivalStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usefestivalStylesQuery({
 *   variables: {
 *      festivalId: // value for 'festivalId'
 *   },
 * });
 */
export function usefestivalStylesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfestivalStylesQuery, GfestivalStylesQueryVariables>) {
        return ApolloReactHooks.useQuery<GfestivalStylesQuery, GfestivalStylesQueryVariables>(festivalStylesDocument, baseOptions);
      }
export function usefestivalStylesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfestivalStylesQuery, GfestivalStylesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfestivalStylesQuery, GfestivalStylesQueryVariables>(festivalStylesDocument, baseOptions);
        }
export type festivalStylesQueryHookResult = ReturnType<typeof usefestivalStylesQuery>;
export type festivalStylesLazyQueryHookResult = ReturnType<typeof usefestivalStylesLazyQuery>;
export type festivalStylesQueryResult = ApolloReactCommon.QueryResult<GfestivalStylesQuery, GfestivalStylesQueryVariables>;
export const GetTopBeersDocument = gql`
    query GetTopBeers {
  topBeersSearch(input: {beerCreatedWithin: ALL, countryIds: null, orderBy: AVERAGE_SCORE, orderByDirection: DESC, reviewCreatedWithin: ALL, statesIds: null, styleIds: null, ratingCountRange: null, first: 3}) {
    items {
      ...BeerInfo
    }
  }
}
    ${BeerInfoFragmentDoc}`;
export type GetTopBeersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetTopBeersQuery, GGetTopBeersQueryVariables>, 'query'>;

    export const GetTopBeersComponent = (props: GetTopBeersComponentProps) => (
      <ApolloReactComponents.Query<GGetTopBeersQuery, GGetTopBeersQueryVariables> query={GetTopBeersDocument} {...props} />
    );
    

/**
 * __useGetTopBeersQuery__
 *
 * To run a query within a React component, call `useGetTopBeersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopBeersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopBeersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopBeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetTopBeersQuery, GGetTopBeersQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetTopBeersQuery, GGetTopBeersQueryVariables>(GetTopBeersDocument, baseOptions);
      }
export function useGetTopBeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetTopBeersQuery, GGetTopBeersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetTopBeersQuery, GGetTopBeersQueryVariables>(GetTopBeersDocument, baseOptions);
        }
export type GetTopBeersQueryHookResult = ReturnType<typeof useGetTopBeersQuery>;
export type GetTopBeersLazyQueryHookResult = ReturnType<typeof useGetTopBeersLazyQuery>;
export type GetTopBeersQueryResult = ApolloReactCommon.QueryResult<GGetTopBeersQuery, GGetTopBeersQueryVariables>;
export const GetTopTrendingBeersDocument = gql`
    query GetTopTrendingBeers {
  topBeersSearch(input: {beerCreatedWithin: ALL, countryIds: null, orderBy: AVERAGE_SCORE, orderByDirection: DESC, reviewCreatedWithin: DAYS_90, statesIds: null, styleIds: null, ratingCountRange: null, first: 3}) {
    items {
      ...BeerInfo
    }
  }
}
    ${BeerInfoFragmentDoc}`;
export type GetTopTrendingBeersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>, 'query'>;

    export const GetTopTrendingBeersComponent = (props: GetTopTrendingBeersComponentProps) => (
      <ApolloReactComponents.Query<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables> query={GetTopTrendingBeersDocument} {...props} />
    );
    

/**
 * __useGetTopTrendingBeersQuery__
 *
 * To run a query within a React component, call `useGetTopTrendingBeersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTrendingBeersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTrendingBeersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopTrendingBeersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>(GetTopTrendingBeersDocument, baseOptions);
      }
export function useGetTopTrendingBeersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>(GetTopTrendingBeersDocument, baseOptions);
        }
export type GetTopTrendingBeersQueryHookResult = ReturnType<typeof useGetTopTrendingBeersQuery>;
export type GetTopTrendingBeersLazyQueryHookResult = ReturnType<typeof useGetTopTrendingBeersLazyQuery>;
export type GetTopTrendingBeersQueryResult = ApolloReactCommon.QueryResult<GGetTopTrendingBeersQuery, GGetTopTrendingBeersQueryVariables>;
export const GetBrewerInfoDocument = gql`
    query GetBrewerInfo($id: ID!) {
  brewerInfo: brewer(id: $id) {
    id
    name
    city
    state {
      id
      name
    }
    country {
      id
      name
      code
    }
    type
    imageUrl
  }
}
    `;
export type GetBrewerInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>, 'query'> & ({ variables: GGetBrewerInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBrewerInfoComponent = (props: GetBrewerInfoComponentProps) => (
      <ApolloReactComponents.Query<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables> query={GetBrewerInfoDocument} {...props} />
    );
    

/**
 * __useGetBrewerInfoQuery__
 *
 * To run a query within a React component, call `useGetBrewerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrewerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrewerInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrewerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>(GetBrewerInfoDocument, baseOptions);
      }
export function useGetBrewerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>(GetBrewerInfoDocument, baseOptions);
        }
export type GetBrewerInfoQueryHookResult = ReturnType<typeof useGetBrewerInfoQuery>;
export type GetBrewerInfoLazyQueryHookResult = ReturnType<typeof useGetBrewerInfoLazyQuery>;
export type GetBrewerInfoQueryResult = ApolloReactCommon.QueryResult<GGetBrewerInfoQuery, GGetBrewerInfoQueryVariables>;
export const MyYearInBeerDocument = gql`
    query MyYearInBeer($userId: ID!, $year: Int!) {
  user(userId: $userId) {
    id
    username
    imageUrl
  }
  myTopRatedBeers(input: {year: $year, userId: $userId}) {
    items {
      beer {
        id
        name
        imageUrl
        averageQuickRating
        ratingsCount
        isRetired
        ratingsCount
        overallScore
        styleScore
        abv
        style {
          id
          name
        }
        contractBrewer {
          id
          name
          country {
            id
            code
          }
        }
        brewer {
          id
          name
          country {
            id
            code
          }
        }
      }
      review {
        id
        score
        comment
        createdAt
      }
    }
  }
  myTopRatedPlaces(input: {year: $year, userId: $userId}) {
    items {
      place {
        id
        name
        rateCount
        averageRating
        createdAt
        city
        percentile
        country {
          id
          name
        }
        state {
          id
          name
        }
        imageUrl
      }
      review {
        id
        score
        comment
        createdAt
      }
    }
  }
  myTopRatedStyles(input: {year: $year, userId: $userId}) {
    items {
      style {
        id
        name
      }
      averageRating
      ratingsCount
    }
  }
  myYearInBeerSummary(input: {year: $year, userId: $userId}) {
    stylesRated
    ratingsCount
    averageRating
    breweriesRated
    openingBreweriesRated
    firstTimeBreweriesRated
  }
}
    `;
export type MyYearInBeerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>, 'query'> & ({ variables: GMyYearInBeerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MyYearInBeerComponent = (props: MyYearInBeerComponentProps) => (
      <ApolloReactComponents.Query<GMyYearInBeerQuery, GMyYearInBeerQueryVariables> query={MyYearInBeerDocument} {...props} />
    );
    

/**
 * __useMyYearInBeerQuery__
 *
 * To run a query within a React component, call `useMyYearInBeerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyYearInBeerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyYearInBeerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useMyYearInBeerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>) {
        return ApolloReactHooks.useQuery<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>(MyYearInBeerDocument, baseOptions);
      }
export function useMyYearInBeerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>(MyYearInBeerDocument, baseOptions);
        }
export type MyYearInBeerQueryHookResult = ReturnType<typeof useMyYearInBeerQuery>;
export type MyYearInBeerLazyQueryHookResult = ReturnType<typeof useMyYearInBeerLazyQuery>;
export type MyYearInBeerQueryResult = ApolloReactCommon.QueryResult<GMyYearInBeerQuery, GMyYearInBeerQueryVariables>;
export const GetPlaceInventoryDocument = gql`
    query GetPlaceInventory($placeId: ID!, $order: PlaceInventoryOrder, $keyword: String, $count: Int, $after: ID) {
  placeInventory(id: $placeId, first: $count, after: $after, order: $order, query: $keyword) {
    inventoryItems: items {
      lastUpdated
      beer {
        id
        name
        style {
          id
          name
        }
        overallScore
        styleScore
        averageQuickRating
        abv
        ibu
        brewer {
          id
          name
        }
        ratingsCount
        imageUrl
        isRetired
        isAlias
      }
    }
    totalCount
    last
  }
}
    `;
export type GetPlaceInventoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>, 'query'> & ({ variables: GGetPlaceInventoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPlaceInventoryComponent = (props: GetPlaceInventoryComponentProps) => (
      <ApolloReactComponents.Query<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables> query={GetPlaceInventoryDocument} {...props} />
    );
    

/**
 * __useGetPlaceInventoryQuery__
 *
 * To run a query within a React component, call `useGetPlaceInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceInventoryQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      order: // value for 'order'
 *      keyword: // value for 'keyword'
 *      count: // value for 'count'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPlaceInventoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>(GetPlaceInventoryDocument, baseOptions);
      }
export function useGetPlaceInventoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>(GetPlaceInventoryDocument, baseOptions);
        }
export type GetPlaceInventoryQueryHookResult = ReturnType<typeof useGetPlaceInventoryQuery>;
export type GetPlaceInventoryLazyQueryHookResult = ReturnType<typeof useGetPlaceInventoryLazyQuery>;
export type GetPlaceInventoryQueryResult = ApolloReactCommon.QueryResult<GGetPlaceInventoryQuery, GGetPlaceInventoryQueryVariables>;
export const BeersSpottedHereDocument = gql`
    query BeersSpottedHere($placeId: ID!, $order: PlaceInventoryOrder, $keyword: String, $count: Int, $after: ID) {
  placeInventory(id: $placeId, first: $count, after: $after, order: $order, query: $keyword) {
    inventoryItems: items {
      lastUpdated
      beer {
        id
        name
        style {
          id
          name
        }
        overallScore
        styleScore
        averageQuickRating
        abv
        ibu
        brewer {
          id
          name
          country {
            id
            name
            code
          }
        }
        ratingsCount
        imageUrl
        isRetired
        isAlias
      }
    }
    totalCount
    last
  }
}
    `;
export type BeersSpottedHereComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>, 'query'> & ({ variables: GBeersSpottedHereQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeersSpottedHereComponent = (props: BeersSpottedHereComponentProps) => (
      <ApolloReactComponents.Query<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables> query={BeersSpottedHereDocument} {...props} />
    );
    

/**
 * __useBeersSpottedHereQuery__
 *
 * To run a query within a React component, call `useBeersSpottedHereQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeersSpottedHereQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeersSpottedHereQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      order: // value for 'order'
 *      keyword: // value for 'keyword'
 *      count: // value for 'count'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBeersSpottedHereQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>(BeersSpottedHereDocument, baseOptions);
      }
export function useBeersSpottedHereLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>(BeersSpottedHereDocument, baseOptions);
        }
export type BeersSpottedHereQueryHookResult = ReturnType<typeof useBeersSpottedHereQuery>;
export type BeersSpottedHereLazyQueryHookResult = ReturnType<typeof useBeersSpottedHereLazyQuery>;
export type BeersSpottedHereQueryResult = ApolloReactCommon.QueryResult<GBeersSpottedHereQuery, GBeersSpottedHereQueryVariables>;
export const PlaceCheckinStatusDocument = gql`
    query PlaceCheckinStatus($placeId: ID!) {
  place(id: $placeId) {
    id
    lastCheckedIn {
      id
      createdAt
    }
  }
}
    `;
export type PlaceCheckinStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>, 'query'> & ({ variables: GPlaceCheckinStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaceCheckinStatusComponent = (props: PlaceCheckinStatusComponentProps) => (
      <ApolloReactComponents.Query<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables> query={PlaceCheckinStatusDocument} {...props} />
    );
    

/**
 * __usePlaceCheckinStatusQuery__
 *
 * To run a query within a React component, call `usePlaceCheckinStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceCheckinStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceCheckinStatusQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function usePlaceCheckinStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>(PlaceCheckinStatusDocument, baseOptions);
      }
export function usePlaceCheckinStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>(PlaceCheckinStatusDocument, baseOptions);
        }
export type PlaceCheckinStatusQueryHookResult = ReturnType<typeof usePlaceCheckinStatusQuery>;
export type PlaceCheckinStatusLazyQueryHookResult = ReturnType<typeof usePlaceCheckinStatusLazyQuery>;
export type PlaceCheckinStatusQueryResult = ApolloReactCommon.QueryResult<GPlaceCheckinStatusQuery, GPlaceCheckinStatusQueryVariables>;
export const PlaceCheckinDocument = gql`
    mutation PlaceCheckin($input: CheckInPlaceInput!) {
  checkInPlace(input: $input) {
    userActivity {
      id
      createdAt
    }
  }
}
    `;
export type GPlaceCheckinMutationFn = ApolloReactCommon.MutationFunction<GPlaceCheckinMutation, GPlaceCheckinMutationVariables>;
export type PlaceCheckinComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GPlaceCheckinMutation, GPlaceCheckinMutationVariables>, 'mutation'>;

    export const PlaceCheckinComponent = (props: PlaceCheckinComponentProps) => (
      <ApolloReactComponents.Mutation<GPlaceCheckinMutation, GPlaceCheckinMutationVariables> mutation={PlaceCheckinDocument} {...props} />
    );
    

/**
 * __usePlaceCheckinMutation__
 *
 * To run a mutation, you first call `usePlaceCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeCheckinMutation, { data, loading, error }] = usePlaceCheckinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceCheckinMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GPlaceCheckinMutation, GPlaceCheckinMutationVariables>) {
        return ApolloReactHooks.useMutation<GPlaceCheckinMutation, GPlaceCheckinMutationVariables>(PlaceCheckinDocument, baseOptions);
      }
export type PlaceCheckinMutationHookResult = ReturnType<typeof usePlaceCheckinMutation>;
export type PlaceCheckinMutationResult = ApolloReactCommon.MutationResult<GPlaceCheckinMutation>;
export type PlaceCheckinMutationOptions = ApolloReactCommon.BaseMutationOptions<GPlaceCheckinMutation, GPlaceCheckinMutationVariables>;
export const LikePlaceReviewDocument = gql`
    mutation LikePlaceReview($input: LikePlaceReviewInput!) {
  likePlaceReview(input: $input) {
    review {
      id
      likeCount
      likedByMe
    }
  }
}
    `;
export type GLikePlaceReviewMutationFn = ApolloReactCommon.MutationFunction<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables>;
export type LikePlaceReviewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables>, 'mutation'>;

    export const LikePlaceReviewComponent = (props: LikePlaceReviewComponentProps) => (
      <ApolloReactComponents.Mutation<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables> mutation={LikePlaceReviewDocument} {...props} />
    );
    

/**
 * __useLikePlaceReviewMutation__
 *
 * To run a mutation, you first call `useLikePlaceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePlaceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePlaceReviewMutation, { data, loading, error }] = useLikePlaceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikePlaceReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables>(LikePlaceReviewDocument, baseOptions);
      }
export type LikePlaceReviewMutationHookResult = ReturnType<typeof useLikePlaceReviewMutation>;
export type LikePlaceReviewMutationResult = ApolloReactCommon.MutationResult<GLikePlaceReviewMutation>;
export type LikePlaceReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<GLikePlaceReviewMutation, GLikePlaceReviewMutationVariables>;
export const UnlikePlaceReviewDocument = gql`
    mutation UnlikePlaceReview($input: UnlikePlaceReviewInput!) {
  unlikePlaceReview(input: $input) {
    review {
      id
      likeCount
      likedByMe
    }
  }
}
    `;
export type GUnlikePlaceReviewMutationFn = ApolloReactCommon.MutationFunction<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables>;
export type UnlikePlaceReviewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables>, 'mutation'>;

    export const UnlikePlaceReviewComponent = (props: UnlikePlaceReviewComponentProps) => (
      <ApolloReactComponents.Mutation<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables> mutation={UnlikePlaceReviewDocument} {...props} />
    );
    

/**
 * __useUnlikePlaceReviewMutation__
 *
 * To run a mutation, you first call `useUnlikePlaceReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikePlaceReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikePlaceReviewMutation, { data, loading, error }] = useUnlikePlaceReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlikePlaceReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables>(UnlikePlaceReviewDocument, baseOptions);
      }
export type UnlikePlaceReviewMutationHookResult = ReturnType<typeof useUnlikePlaceReviewMutation>;
export type UnlikePlaceReviewMutationResult = ApolloReactCommon.MutationResult<GUnlikePlaceReviewMutation>;
export type UnlikePlaceReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<GUnlikePlaceReviewMutation, GUnlikePlaceReviewMutationVariables>;
export const PlaceReviewsListDocument = gql`
    query PlaceReviewsList($input: PlaceReviewsInput) {
  placeReviews(input: $input) {
    items {
      ...PlaceReviewFields
    }
    totalCount
    last
  }
}
    ${PlaceReviewFieldsFragmentDoc}`;
export type PlaceReviewsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>, 'query'>;

    export const PlaceReviewsListComponent = (props: PlaceReviewsListComponentProps) => (
      <ApolloReactComponents.Query<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables> query={PlaceReviewsListDocument} {...props} />
    );
    

/**
 * __usePlaceReviewsListQuery__
 *
 * To run a query within a React component, call `usePlaceReviewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceReviewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceReviewsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceReviewsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>(PlaceReviewsListDocument, baseOptions);
      }
export function usePlaceReviewsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>(PlaceReviewsListDocument, baseOptions);
        }
export type PlaceReviewsListQueryHookResult = ReturnType<typeof usePlaceReviewsListQuery>;
export type PlaceReviewsListLazyQueryHookResult = ReturnType<typeof usePlaceReviewsListLazyQuery>;
export type PlaceReviewsListQueryResult = ApolloReactCommon.QueryResult<GPlaceReviewsListQuery, GPlaceReviewsListQueryVariables>;
export const placeReviewInsertDocument = gql`
    mutation placeReviewInsert($placeId: ID!, $scores: PlaceReviewScoresInput!, $comment: String) {
  placeReviewInsert(review: {placeId: $placeId, scores: $scores, comment: $comment}) {
    ...PlaceReviewFields
    scores {
      ambiance
      service
      selection
      food
      value
      overall
    }
  }
}
    ${PlaceReviewFieldsFragmentDoc}`;
export type GplaceReviewInsertMutationFn = ApolloReactCommon.MutationFunction<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables>;
export type placeReviewInsertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables>, 'mutation'>;

    export const placeReviewInsertComponent = (props: placeReviewInsertComponentProps) => (
      <ApolloReactComponents.Mutation<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables> mutation={placeReviewInsertDocument} {...props} />
    );
    

/**
 * __useplaceReviewInsertMutation__
 *
 * To run a mutation, you first call `useplaceReviewInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useplaceReviewInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeReviewInsertMutation, { data, loading, error }] = useplaceReviewInsertMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useplaceReviewInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables>(placeReviewInsertDocument, baseOptions);
      }
export type placeReviewInsertMutationHookResult = ReturnType<typeof useplaceReviewInsertMutation>;
export type placeReviewInsertMutationResult = ApolloReactCommon.MutationResult<GplaceReviewInsertMutation>;
export type placeReviewInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GplaceReviewInsertMutation, GplaceReviewInsertMutationVariables>;
export const placeReviewUpdateDocument = gql`
    mutation placeReviewUpdate($reviewId: ID!, $scores: PlaceReviewScoresInput, $comment: String) {
  placeReviewUpdate(id: $reviewId, review: {scores: $scores, comment: $comment}) {
    ...PlaceReviewFields
    scores {
      ambiance
      service
      selection
      food
      value
      overall
    }
  }
}
    ${PlaceReviewFieldsFragmentDoc}`;
export type GplaceReviewUpdateMutationFn = ApolloReactCommon.MutationFunction<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables>;
export type placeReviewUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables>, 'mutation'>;

    export const placeReviewUpdateComponent = (props: placeReviewUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables> mutation={placeReviewUpdateDocument} {...props} />
    );
    

/**
 * __useplaceReviewUpdateMutation__
 *
 * To run a mutation, you first call `useplaceReviewUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useplaceReviewUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeReviewUpdateMutation, { data, loading, error }] = useplaceReviewUpdateMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      scores: // value for 'scores'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useplaceReviewUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables>(placeReviewUpdateDocument, baseOptions);
      }
export type placeReviewUpdateMutationHookResult = ReturnType<typeof useplaceReviewUpdateMutation>;
export type placeReviewUpdateMutationResult = ApolloReactCommon.MutationResult<GplaceReviewUpdateMutation>;
export type placeReviewUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GplaceReviewUpdateMutation, GplaceReviewUpdateMutationVariables>;
export const PlaceReviewDeleteDocument = gql`
    mutation PlaceReviewDelete($id: ID!) {
  placeReviewDelete(id: $id)
}
    `;
export type GPlaceReviewDeleteMutationFn = ApolloReactCommon.MutationFunction<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables>;
export type PlaceReviewDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables>, 'mutation'>;

    export const PlaceReviewDeleteComponent = (props: PlaceReviewDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables> mutation={PlaceReviewDeleteDocument} {...props} />
    );
    

/**
 * __usePlaceReviewDeleteMutation__
 *
 * To run a mutation, you first call `usePlaceReviewDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceReviewDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeReviewDeleteMutation, { data, loading, error }] = usePlaceReviewDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaceReviewDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables>(PlaceReviewDeleteDocument, baseOptions);
      }
export type PlaceReviewDeleteMutationHookResult = ReturnType<typeof usePlaceReviewDeleteMutation>;
export type PlaceReviewDeleteMutationResult = ApolloReactCommon.MutationResult<GPlaceReviewDeleteMutation>;
export type PlaceReviewDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GPlaceReviewDeleteMutation, GPlaceReviewDeleteMutationVariables>;
export const PlaceReviewQueryDocument = gql`
    query PlaceReviewQuery($placeId: ID!, $authorId: ID) {
  placeReviews(placeId: $placeId, authorId: $authorId) {
    items {
      id
      comment
      score
      scores {
        ambiance
        service
        selection
        food
        value
        overall
      }
      createdAt
      updatedAt
      author {
        id
      }
    }
  }
}
    `;
export type PlaceReviewQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>, 'query'> & ({ variables: GPlaceReviewQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaceReviewQueryComponent = (props: PlaceReviewQueryComponentProps) => (
      <ApolloReactComponents.Query<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables> query={PlaceReviewQueryDocument} {...props} />
    );
    

/**
 * __usePlaceReviewQueryQuery__
 *
 * To run a query within a React component, call `usePlaceReviewQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceReviewQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceReviewQueryQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      authorId: // value for 'authorId'
 *   },
 * });
 */
export function usePlaceReviewQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>(PlaceReviewQueryDocument, baseOptions);
      }
export function usePlaceReviewQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>(PlaceReviewQueryDocument, baseOptions);
        }
export type PlaceReviewQueryQueryHookResult = ReturnType<typeof usePlaceReviewQueryQuery>;
export type PlaceReviewQueryLazyQueryHookResult = ReturnType<typeof usePlaceReviewQueryLazyQuery>;
export type PlaceReviewQueryQueryResult = ApolloReactCommon.QueryResult<GPlaceReviewQueryQuery, GPlaceReviewQueryQueryVariables>;
export const PlaceProfilePlaceStoreDocument = gql`
    query PlaceProfilePlaceStore($placeId: ID!) {
  place(id: $placeId) {
    id
    name
    description
    imageUrl
    averageRating
    rateCount
    percentile
    latitude
    longitude
    type {
      id
      name
    }
    twitter
    facebook
    web
    email
    streetAddress
    city
    state {
      id
      name
      code
    }
    country {
      id
      name
      code
    }
    mayor {
      id
      username
      imageUrl
    }
    brewer {
      id
      name
      imageUrl
      city
      country {
        id
        name
        code
      }
    }
    zip
    countryCode
    areaCode
    phone
    hours
    foodMenu
    beerMenu
    taps
    bottles
    wifi
    flights
    isRetired
    children
    patio
    growlers
  }
}
    `;
export type PlaceProfilePlaceStoreComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>, 'query'> & ({ variables: GPlaceProfilePlaceStoreQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaceProfilePlaceStoreComponent = (props: PlaceProfilePlaceStoreComponentProps) => (
      <ApolloReactComponents.Query<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables> query={PlaceProfilePlaceStoreDocument} {...props} />
    );
    

/**
 * __usePlaceProfilePlaceStoreQuery__
 *
 * To run a query within a React component, call `usePlaceProfilePlaceStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceProfilePlaceStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceProfilePlaceStoreQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function usePlaceProfilePlaceStoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>(PlaceProfilePlaceStoreDocument, baseOptions);
      }
export function usePlaceProfilePlaceStoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>(PlaceProfilePlaceStoreDocument, baseOptions);
        }
export type PlaceProfilePlaceStoreQueryHookResult = ReturnType<typeof usePlaceProfilePlaceStoreQuery>;
export type PlaceProfilePlaceStoreLazyQueryHookResult = ReturnType<typeof usePlaceProfilePlaceStoreLazyQuery>;
export type PlaceProfilePlaceStoreQueryResult = ApolloReactCommon.QueryResult<GPlaceProfilePlaceStoreQuery, GPlaceProfilePlaceStoreQueryVariables>;
export const placeReviewsDocument = gql`
    query placeReviews($placeId: ID!, $first: Int, $after: ID) {
  placeReviews(placeId: $placeId, first: $first, after: $after) {
    items {
      ...PlaceReviewFields
    }
    totalCount
    last
  }
}
    ${PlaceReviewFieldsFragmentDoc}`;
export type placeReviewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GplaceReviewsQuery, GplaceReviewsQueryVariables>, 'query'> & ({ variables: GplaceReviewsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const placeReviewsComponent = (props: placeReviewsComponentProps) => (
      <ApolloReactComponents.Query<GplaceReviewsQuery, GplaceReviewsQueryVariables> query={placeReviewsDocument} {...props} />
    );
    

/**
 * __useplaceReviewsQuery__
 *
 * To run a query within a React component, call `useplaceReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useplaceReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useplaceReviewsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useplaceReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GplaceReviewsQuery, GplaceReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GplaceReviewsQuery, GplaceReviewsQueryVariables>(placeReviewsDocument, baseOptions);
      }
export function useplaceReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GplaceReviewsQuery, GplaceReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GplaceReviewsQuery, GplaceReviewsQueryVariables>(placeReviewsDocument, baseOptions);
        }
export type placeReviewsQueryHookResult = ReturnType<typeof useplaceReviewsQuery>;
export type placeReviewsLazyQueryHookResult = ReturnType<typeof useplaceReviewsLazyQuery>;
export type placeReviewsQueryResult = ApolloReactCommon.QueryResult<GplaceReviewsQuery, GplaceReviewsQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type GUpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables>;
export type UpdateUserProfileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables>, 'mutation'>;

    export const UpdateUserProfileComponent = (props: UpdateUserProfileComponentProps) => (
      <ApolloReactComponents.Mutation<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables> mutation={UpdateUserProfileDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<GUpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<GUpdateUserProfileMutation, GUpdateUserProfileMutationVariables>;
export const UpdateUserProfileUserDocument = gql`
    query UpdateUserProfileUser {
  me {
    id
    username
    biography
    birthday
    favoriteBeerStyle {
      id
      name
    }
    biography
    city
    country {
      id
      name
      code
    }
    zip
    email
    showTradeForums
    notifyEmails
    notifyFreebies
    forumNotifications
    imageUrl
  }
}
    `;
export type UpdateUserProfileUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>, 'query'>;

    export const UpdateUserProfileUserComponent = (props: UpdateUserProfileUserComponentProps) => (
      <ApolloReactComponents.Query<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables> query={UpdateUserProfileUserDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileUserQuery__
 *
 * To run a query within a React component, call `useUpdateUserProfileUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateUserProfileUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserProfileUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>(UpdateUserProfileUserDocument, baseOptions);
      }
export function useUpdateUserProfileUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>(UpdateUserProfileUserDocument, baseOptions);
        }
export type UpdateUserProfileUserQueryHookResult = ReturnType<typeof useUpdateUserProfileUserQuery>;
export type UpdateUserProfileUserLazyQueryHookResult = ReturnType<typeof useUpdateUserProfileUserLazyQuery>;
export type UpdateUserProfileUserQueryResult = ApolloReactCommon.QueryResult<GUpdateUserProfileUserQuery, GUpdateUserProfileUserQueryVariables>;
export const UpdateUserProfileImageDocument = gql`
    mutation UpdateUserProfileImage($profileImage: Upload!) {
  userUpdateProfileImage(profileImage: $profileImage) {
    id
    imageUrl
  }
}
    `;
export type GUpdateUserProfileImageMutationFn = ApolloReactCommon.MutationFunction<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables>;
export type UpdateUserProfileImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables>, 'mutation'>;

    export const UpdateUserProfileImageComponent = (props: UpdateUserProfileImageComponentProps) => (
      <ApolloReactComponents.Mutation<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables> mutation={UpdateUserProfileImageDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileImageMutation, { data, loading, error }] = useUpdateUserProfileImageMutation({
 *   variables: {
 *      profileImage: // value for 'profileImage'
 *   },
 * });
 */
export function useUpdateUserProfileImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables>) {
        return ApolloReactHooks.useMutation<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables>(UpdateUserProfileImageDocument, baseOptions);
      }
export type UpdateUserProfileImageMutationHookResult = ReturnType<typeof useUpdateUserProfileImageMutation>;
export type UpdateUserProfileImageMutationResult = ApolloReactCommon.MutationResult<GUpdateUserProfileImageMutation>;
export type UpdateUserProfileImageMutationOptions = ApolloReactCommon.BaseMutationOptions<GUpdateUserProfileImageMutation, GUpdateUserProfileImageMutationVariables>;
export const BeerByAliasDocument = gql`
    query BeerByAlias($aliasId: ID!) {
  beerByAlias(aliasId: $aliasId) {
    id
    name
    isVerified
  }
}
    `;
export type BeerByAliasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerByAliasQuery, GBeerByAliasQueryVariables>, 'query'> & ({ variables: GBeerByAliasQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeerByAliasComponent = (props: BeerByAliasComponentProps) => (
      <ApolloReactComponents.Query<GBeerByAliasQuery, GBeerByAliasQueryVariables> query={BeerByAliasDocument} {...props} />
    );
    

/**
 * __useBeerByAliasQuery__
 *
 * To run a query within a React component, call `useBeerByAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerByAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerByAliasQuery({
 *   variables: {
 *      aliasId: // value for 'aliasId'
 *   },
 * });
 */
export function useBeerByAliasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerByAliasQuery, GBeerByAliasQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerByAliasQuery, GBeerByAliasQueryVariables>(BeerByAliasDocument, baseOptions);
      }
export function useBeerByAliasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerByAliasQuery, GBeerByAliasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerByAliasQuery, GBeerByAliasQueryVariables>(BeerByAliasDocument, baseOptions);
        }
export type BeerByAliasQueryHookResult = ReturnType<typeof useBeerByAliasQuery>;
export type BeerByAliasLazyQueryHookResult = ReturnType<typeof useBeerByAliasLazyQuery>;
export type BeerByAliasQueryResult = ApolloReactCommon.QueryResult<GBeerByAliasQuery, GBeerByAliasQueryVariables>;
export const SearchResultsBeerDocument = gql`
    query SearchResultsBeer($includePurchaseOptions: Boolean!, $latlng: [Float!]!, $query: String, $order: SearchOrder, $first: Int, $after: ID) {
  results: beerSearch(query: $query, order: $order, first: $first, after: $after) {
    totalCount
    last
    items {
      review {
        id
        score
        likedByMe
        updatedAt
        createdAt
      }
      beer {
        id
        name
        style {
          id
          name
        }
        overallScore
        styleScore
        averageQuickRating
        abv
        ibu
        brewer {
          id
          name
          country {
            id
            code
          }
        }
        contractBrewer {
          id
          name
          country {
            id
            code
          }
        }
        ratingsCount
        imageUrl
        isRetired
        isAlias
        purchaseOptions(options: {latlng: $latlng}) @include(if: $includePurchaseOptions) {
          items {
            productId
            price
            currency
            currencySymbol
            priceValue
            store {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type SearchResultsBeerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>, 'query'> & ({ variables: GSearchResultsBeerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchResultsBeerComponent = (props: SearchResultsBeerComponentProps) => (
      <ApolloReactComponents.Query<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables> query={SearchResultsBeerDocument} {...props} />
    );
    

/**
 * __useSearchResultsBeerQuery__
 *
 * To run a query within a React component, call `useSearchResultsBeerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsBeerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultsBeerQuery({
 *   variables: {
 *      includePurchaseOptions: // value for 'includePurchaseOptions'
 *      latlng: // value for 'latlng'
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchResultsBeerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>) {
        return ApolloReactHooks.useQuery<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>(SearchResultsBeerDocument, baseOptions);
      }
export function useSearchResultsBeerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>(SearchResultsBeerDocument, baseOptions);
        }
export type SearchResultsBeerQueryHookResult = ReturnType<typeof useSearchResultsBeerQuery>;
export type SearchResultsBeerLazyQueryHookResult = ReturnType<typeof useSearchResultsBeerLazyQuery>;
export type SearchResultsBeerQueryResult = ApolloReactCommon.QueryResult<GSearchResultsBeerQuery, GSearchResultsBeerQueryVariables>;
export const SearchResultsBrewerDocument = gql`
    query SearchResultsBrewer($query: String, $order: SearchOrder, $first: Int, $after: ID) {
  results: brewerSearch(query: $query, order: $order, first: $first, after: $after) {
    totalCount
    last
    items {
      id
      name
      imageUrl
      type
      city
      state {
        id
        name
      }
      country {
        id
        name
      }
    }
  }
}
    `;
export type SearchResultsBrewerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>, 'query'>;

    export const SearchResultsBrewerComponent = (props: SearchResultsBrewerComponentProps) => (
      <ApolloReactComponents.Query<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables> query={SearchResultsBrewerDocument} {...props} />
    );
    

/**
 * __useSearchResultsBrewerQuery__
 *
 * To run a query within a React component, call `useSearchResultsBrewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsBrewerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultsBrewerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchResultsBrewerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>) {
        return ApolloReactHooks.useQuery<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>(SearchResultsBrewerDocument, baseOptions);
      }
export function useSearchResultsBrewerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>(SearchResultsBrewerDocument, baseOptions);
        }
export type SearchResultsBrewerQueryHookResult = ReturnType<typeof useSearchResultsBrewerQuery>;
export type SearchResultsBrewerLazyQueryHookResult = ReturnType<typeof useSearchResultsBrewerLazyQuery>;
export type SearchResultsBrewerQueryResult = ApolloReactCommon.QueryResult<GSearchResultsBrewerQuery, GSearchResultsBrewerQueryVariables>;
export const SearchResultsPlaceDocument = gql`
    query SearchResultsPlace($query: String, $latlng: [Float], $order: SearchOrder, $first: Int, $after: ID) {
  results: placeSearch(query: $query, latlng: $latlng, order: $order, first: $first, after: $after) {
    totalCount
    last
    items {
      id
      name
      averageRating
      percentile
      rateCount
      imageUrl
      distance
      type {
        id
        name
      }
      city
      state {
        id
        name
      }
      country {
        id
        name
      }
      userReviews {
        id
        comment
        score
        updatedAt
        createdAt
      }
    }
  }
}
    `;
export type SearchResultsPlaceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>, 'query'>;

    export const SearchResultsPlaceComponent = (props: SearchResultsPlaceComponentProps) => (
      <ApolloReactComponents.Query<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables> query={SearchResultsPlaceDocument} {...props} />
    );
    

/**
 * __useSearchResultsPlaceQuery__
 *
 * To run a query within a React component, call `useSearchResultsPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultsPlaceQuery({
 *   variables: {
 *      query: // value for 'query'
 *      latlng: // value for 'latlng'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchResultsPlaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>) {
        return ApolloReactHooks.useQuery<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>(SearchResultsPlaceDocument, baseOptions);
      }
export function useSearchResultsPlaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>(SearchResultsPlaceDocument, baseOptions);
        }
export type SearchResultsPlaceQueryHookResult = ReturnType<typeof useSearchResultsPlaceQuery>;
export type SearchResultsPlaceLazyQueryHookResult = ReturnType<typeof useSearchResultsPlaceLazyQuery>;
export type SearchResultsPlaceQueryResult = ApolloReactCommon.QueryResult<GSearchResultsPlaceQuery, GSearchResultsPlaceQueryVariables>;
export const SearchResultsUserDocument = gql`
    query SearchResultsUser($query: String, $order: SearchOrder, $first: Int, $after: ID) {
  results: userSearch(query: $query, order: $order, first: $first, after: $after) {
    totalCount
    last
    items {
      id
      imageUrl
      username
      reviewCount
    }
  }
}
    `;
export type SearchResultsUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>, 'query'>;

    export const SearchResultsUserComponent = (props: SearchResultsUserComponentProps) => (
      <ApolloReactComponents.Query<GSearchResultsUserQuery, GSearchResultsUserQueryVariables> query={SearchResultsUserDocument} {...props} />
    );
    

/**
 * __useSearchResultsUserQuery__
 *
 * To run a query within a React component, call `useSearchResultsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultsUserQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchResultsUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>(SearchResultsUserDocument, baseOptions);
      }
export function useSearchResultsUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>(SearchResultsUserDocument, baseOptions);
        }
export type SearchResultsUserQueryHookResult = ReturnType<typeof useSearchResultsUserQuery>;
export type SearchResultsUserLazyQueryHookResult = ReturnType<typeof useSearchResultsUserLazyQuery>;
export type SearchResultsUserQueryResult = ApolloReactCommon.QueryResult<GSearchResultsUserQuery, GSearchResultsUserQueryVariables>;
export const PlaceBeerShelfTagsDocument = gql`
    query PlaceBeerShelfTags($placeId: ID!) {
  placeInventory(id: $placeId, first: 10000) {
    totalCount
    items {
      beer {
        id
        name
        abv
        ibu
        styleScore
        overallScore
        ratingsCount
        style {
          id
          name
        }
        brewer {
          id
          city
          country {
            id
            name
            code
          }
          state {
            id
            name
          }
        }
        contractBrewer {
          id
          city
          country {
            id
            name
            code
          }
          state {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type PlaceBeerShelfTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>, 'query'> & ({ variables: GPlaceBeerShelfTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaceBeerShelfTagsComponent = (props: PlaceBeerShelfTagsComponentProps) => (
      <ApolloReactComponents.Query<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables> query={PlaceBeerShelfTagsDocument} {...props} />
    );
    

/**
 * __usePlaceBeerShelfTagsQuery__
 *
 * To run a query within a React component, call `usePlaceBeerShelfTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceBeerShelfTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceBeerShelfTagsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function usePlaceBeerShelfTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>(PlaceBeerShelfTagsDocument, baseOptions);
      }
export function usePlaceBeerShelfTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>(PlaceBeerShelfTagsDocument, baseOptions);
        }
export type PlaceBeerShelfTagsQueryHookResult = ReturnType<typeof usePlaceBeerShelfTagsQuery>;
export type PlaceBeerShelfTagsLazyQueryHookResult = ReturnType<typeof usePlaceBeerShelfTagsLazyQuery>;
export type PlaceBeerShelfTagsQueryResult = ApolloReactCommon.QueryResult<GPlaceBeerShelfTagsQuery, GPlaceBeerShelfTagsQueryVariables>;
export const ShelfTagBeerDocument = gql`
    query ShelfTagBeer($beerId: ID!) {
  beer(id: $beerId) {
    id
    name
    abv
    ibu
    styleScore
    overallScore
    ratingsCount
    style {
      id
      name
    }
    brewer {
      id
      city
      country {
        id
        name
        code
      }
      state {
        id
        name
      }
    }
    contractBrewer {
      id
      city
      country {
        id
        name
        code
      }
      state {
        id
        name
      }
    }
  }
}
    `;
export type ShelfTagBeerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>, 'query'> & ({ variables: GShelfTagBeerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShelfTagBeerComponent = (props: ShelfTagBeerComponentProps) => (
      <ApolloReactComponents.Query<GShelfTagBeerQuery, GShelfTagBeerQueryVariables> query={ShelfTagBeerDocument} {...props} />
    );
    

/**
 * __useShelfTagBeerQuery__
 *
 * To run a query within a React component, call `useShelfTagBeerQuery` and pass it any options that fit your needs.
 * When your component renders, `useShelfTagBeerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShelfTagBeerQuery({
 *   variables: {
 *      beerId: // value for 'beerId'
 *   },
 * });
 */
export function useShelfTagBeerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>) {
        return ApolloReactHooks.useQuery<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>(ShelfTagBeerDocument, baseOptions);
      }
export function useShelfTagBeerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>(ShelfTagBeerDocument, baseOptions);
        }
export type ShelfTagBeerQueryHookResult = ReturnType<typeof useShelfTagBeerQuery>;
export type ShelfTagBeerLazyQueryHookResult = ReturnType<typeof useShelfTagBeerLazyQuery>;
export type ShelfTagBeerQueryResult = ApolloReactCommon.QueryResult<GShelfTagBeerQuery, GShelfTagBeerQueryVariables>;
export const BeerShopSearchDocument = gql`
    query BeerShopSearch($query: String, $retailerIds: [ID!], $beerStyleIds: [ID!], $originCountryIds: [ID!], $scoreRanges: [ScoreRange!], $latlng: [Float!]!, $first: Int, $after: ID, $order: BeerShopSearchResultOrder!) {
  results: beershopSearch(options: {query: $query, retailerIds: $retailerIds, beerStyleIds: $beerStyleIds, originCountryIds: $originCountryIds, scoreRanges: $scoreRanges, latlng: $latlng, first: $first, after: $after, order: $order}) {
    totalCount
    last
    items {
      id
      name
      description
      style {
        id
        name
      }
      overallScore
      styleScore
      averageQuickRating
      abv
      ibu
      brewer {
        id
        name
        city
        country {
          id
          code
          name
        }
        state {
          id
          name
        }
      }
      contractBrewer {
        id
        name
        city
        country {
          id
          name
          code
        }
        state {
          id
          name
        }
      }
      ratingsCount
      imageUrl
      purchaseOptions {
        items {
          store {
            id
            name
            url
            sellingPoints
            logo {
              uri
            }
          }
          productId
          productUrl
          price
          priceValue
          bottleSize
          currency
          currencySymbol
        }
      }
    }
  }
}
    `;
export type BeerShopSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>, 'query'> & ({ variables: GBeerShopSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BeerShopSearchComponent = (props: BeerShopSearchComponentProps) => (
      <ApolloReactComponents.Query<GBeerShopSearchQuery, GBeerShopSearchQueryVariables> query={BeerShopSearchDocument} {...props} />
    );
    

/**
 * __useBeerShopSearchQuery__
 *
 * To run a query within a React component, call `useBeerShopSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerShopSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerShopSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      retailerIds: // value for 'retailerIds'
 *      beerStyleIds: // value for 'beerStyleIds'
 *      originCountryIds: // value for 'originCountryIds'
 *      scoreRanges: // value for 'scoreRanges'
 *      latlng: // value for 'latlng'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBeerShopSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>(BeerShopSearchDocument, baseOptions);
      }
export function useBeerShopSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>(BeerShopSearchDocument, baseOptions);
        }
export type BeerShopSearchQueryHookResult = ReturnType<typeof useBeerShopSearchQuery>;
export type BeerShopSearchLazyQueryHookResult = ReturnType<typeof useBeerShopSearchLazyQuery>;
export type BeerShopSearchQueryResult = ApolloReactCommon.QueryResult<GBeerShopSearchQuery, GBeerShopSearchQueryVariables>;
export const RetailersQueryDocument = gql`
    query RetailersQuery($input: ShopInfoInput!) {
  shopInfo(input: $input) {
    retailers {
      id
      name
      link
    }
  }
}
    `;
export type RetailersQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GRetailersQueryQuery, GRetailersQueryQueryVariables>, 'query'> & ({ variables: GRetailersQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RetailersQueryComponent = (props: RetailersQueryComponentProps) => (
      <ApolloReactComponents.Query<GRetailersQueryQuery, GRetailersQueryQueryVariables> query={RetailersQueryDocument} {...props} />
    );
    

/**
 * __useRetailersQueryQuery__
 *
 * To run a query within a React component, call `useRetailersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetailersQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GRetailersQueryQuery, GRetailersQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GRetailersQueryQuery, GRetailersQueryQueryVariables>(RetailersQueryDocument, baseOptions);
      }
export function useRetailersQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GRetailersQueryQuery, GRetailersQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GRetailersQueryQuery, GRetailersQueryQueryVariables>(RetailersQueryDocument, baseOptions);
        }
export type RetailersQueryQueryHookResult = ReturnType<typeof useRetailersQueryQuery>;
export type RetailersQueryLazyQueryHookResult = ReturnType<typeof useRetailersQueryLazyQuery>;
export type RetailersQueryQueryResult = ApolloReactCommon.QueryResult<GRetailersQueryQuery, GRetailersQueryQueryVariables>;
export const CountriesQueryDocument = gql`
    query CountriesQuery {
  countries {
    id
    name
    code
    priority
  }
}
    `;
export type CountriesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GCountriesQueryQuery, GCountriesQueryQueryVariables>, 'query'>;

    export const CountriesQueryComponent = (props: CountriesQueryComponentProps) => (
      <ApolloReactComponents.Query<GCountriesQueryQuery, GCountriesQueryQueryVariables> query={CountriesQueryDocument} {...props} />
    );
    

/**
 * __useCountriesQueryQuery__
 *
 * To run a query within a React component, call `useCountriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GCountriesQueryQuery, GCountriesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GCountriesQueryQuery, GCountriesQueryQueryVariables>(CountriesQueryDocument, baseOptions);
      }
export function useCountriesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GCountriesQueryQuery, GCountriesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GCountriesQueryQuery, GCountriesQueryQueryVariables>(CountriesQueryDocument, baseOptions);
        }
export type CountriesQueryQueryHookResult = ReturnType<typeof useCountriesQueryQuery>;
export type CountriesQueryLazyQueryHookResult = ReturnType<typeof useCountriesQueryLazyQuery>;
export type CountriesQueryQueryResult = ApolloReactCommon.QueryResult<GCountriesQueryQuery, GCountriesQueryQueryVariables>;
export const BeerStylesQueryDocument = gql`
    query BeerStylesQuery {
  beerStyles(order: NumberOfBeers) {
    id
    name
    parent {
      id
    }
  }
}
    `;
export type BeerStylesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>, 'query'>;

    export const BeerStylesQueryComponent = (props: BeerStylesQueryComponentProps) => (
      <ApolloReactComponents.Query<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables> query={BeerStylesQueryDocument} {...props} />
    );
    

/**
 * __useBeerStylesQueryQuery__
 *
 * To run a query within a React component, call `useBeerStylesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeerStylesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeerStylesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBeerStylesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>(BeerStylesQueryDocument, baseOptions);
      }
export function useBeerStylesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>(BeerStylesQueryDocument, baseOptions);
        }
export type BeerStylesQueryQueryHookResult = ReturnType<typeof useBeerStylesQueryQuery>;
export type BeerStylesQueryLazyQueryHookResult = ReturnType<typeof useBeerStylesQueryLazyQuery>;
export type BeerStylesQueryQueryResult = ApolloReactCommon.QueryResult<GBeerStylesQueryQuery, GBeerStylesQueryQueryVariables>;
export const MobileShopRegionInfoDocument = gql`
    query MobileShopRegionInfo($input: RegionInfoInput!) {
  regionInfo(input: $input) {
    country {
      id
      name
      code
    }
  }
}
    `;
export type MobileShopRegionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>, 'query'> & ({ variables: GMobileShopRegionInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MobileShopRegionInfoComponent = (props: MobileShopRegionInfoComponentProps) => (
      <ApolloReactComponents.Query<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables> query={MobileShopRegionInfoDocument} {...props} />
    );
    

/**
 * __useMobileShopRegionInfoQuery__
 *
 * To run a query within a React component, call `useMobileShopRegionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileShopRegionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileShopRegionInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMobileShopRegionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>(MobileShopRegionInfoDocument, baseOptions);
      }
export function useMobileShopRegionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>(MobileShopRegionInfoDocument, baseOptions);
        }
export type MobileShopRegionInfoQueryHookResult = ReturnType<typeof useMobileShopRegionInfoQuery>;
export type MobileShopRegionInfoLazyQueryHookResult = ReturnType<typeof useMobileShopRegionInfoLazyQuery>;
export type MobileShopRegionInfoQueryResult = ApolloReactCommon.QueryResult<GMobileShopRegionInfoQuery, GMobileShopRegionInfoQueryVariables>;
export const TopBeersListDocument = gql`
    query TopBeersList($input: TopBeersSearchInput!) {
  topBeersSearch(input: $input) {
    myRatedBeerIds
    items {
      id
      name
      imageUrl
      ratingsCount
      normalizedAverageReview
      abv
      overallScore
      styleScore
      style {
        id
        name
      }
      brewer {
        id
        country {
          id
          name
          code
        }
      }
      contractBrewer {
        id
        country {
          id
          name
          code
        }
      }
    }
  }
}
    `;
export type TopBeersListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GTopBeersListQuery, GTopBeersListQueryVariables>, 'query'> & ({ variables: GTopBeersListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TopBeersListComponent = (props: TopBeersListComponentProps) => (
      <ApolloReactComponents.Query<GTopBeersListQuery, GTopBeersListQueryVariables> query={TopBeersListDocument} {...props} />
    );
    

/**
 * __useTopBeersListQuery__
 *
 * To run a query within a React component, call `useTopBeersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBeersListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBeersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTopBeersListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GTopBeersListQuery, GTopBeersListQueryVariables>) {
        return ApolloReactHooks.useQuery<GTopBeersListQuery, GTopBeersListQueryVariables>(TopBeersListDocument, baseOptions);
      }
export function useTopBeersListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GTopBeersListQuery, GTopBeersListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GTopBeersListQuery, GTopBeersListQueryVariables>(TopBeersListDocument, baseOptions);
        }
export type TopBeersListQueryHookResult = ReturnType<typeof useTopBeersListQuery>;
export type TopBeersListLazyQueryHookResult = ReturnType<typeof useTopBeersListLazyQuery>;
export type TopBeersListQueryResult = ApolloReactCommon.QueryResult<GTopBeersListQuery, GTopBeersListQueryVariables>;
export const TopBeersCountriesDocument = gql`
    query TopBeersCountries {
  countries {
    id
    name
    states {
      id
      name
    }
  }
}
    `;
export type TopBeersCountriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>, 'query'>;

    export const TopBeersCountriesComponent = (props: TopBeersCountriesComponentProps) => (
      <ApolloReactComponents.Query<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables> query={TopBeersCountriesDocument} {...props} />
    );
    

/**
 * __useTopBeersCountriesQuery__
 *
 * To run a query within a React component, call `useTopBeersCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBeersCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBeersCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopBeersCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>(TopBeersCountriesDocument, baseOptions);
      }
export function useTopBeersCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>(TopBeersCountriesDocument, baseOptions);
        }
export type TopBeersCountriesQueryHookResult = ReturnType<typeof useTopBeersCountriesQuery>;
export type TopBeersCountriesLazyQueryHookResult = ReturnType<typeof useTopBeersCountriesLazyQuery>;
export type TopBeersCountriesQueryResult = ApolloReactCommon.QueryResult<GTopBeersCountriesQuery, GTopBeersCountriesQueryVariables>;
export const TopBeersStylesDocument = gql`
    query TopBeersStyles {
  beerStyles(order: NumberOfBeers) {
    id
    name
    parent {
      id
    }
  }
}
    `;
export type TopBeersStylesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>, 'query'>;

    export const TopBeersStylesComponent = (props: TopBeersStylesComponentProps) => (
      <ApolloReactComponents.Query<GTopBeersStylesQuery, GTopBeersStylesQueryVariables> query={TopBeersStylesDocument} {...props} />
    );
    

/**
 * __useTopBeersStylesQuery__
 *
 * To run a query within a React component, call `useTopBeersStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBeersStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBeersStylesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopBeersStylesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>) {
        return ApolloReactHooks.useQuery<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>(TopBeersStylesDocument, baseOptions);
      }
export function useTopBeersStylesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>(TopBeersStylesDocument, baseOptions);
        }
export type TopBeersStylesQueryHookResult = ReturnType<typeof useTopBeersStylesQuery>;
export type TopBeersStylesLazyQueryHookResult = ReturnType<typeof useTopBeersStylesLazyQuery>;
export type TopBeersStylesQueryResult = ApolloReactCommon.QueryResult<GTopBeersStylesQuery, GTopBeersStylesQueryVariables>;
export const UserFolloweesDocument = gql`
    query UserFollowees($input: FolloweesInput!) {
  followees(input: $input) {
    totalCount
    last
    items {
      username
      id
      imageUrl
    }
  }
}
    `;
export type UserFolloweesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUserFolloweesQuery, GUserFolloweesQueryVariables>, 'query'> & ({ variables: GUserFolloweesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserFolloweesComponent = (props: UserFolloweesComponentProps) => (
      <ApolloReactComponents.Query<GUserFolloweesQuery, GUserFolloweesQueryVariables> query={UserFolloweesDocument} {...props} />
    );
    

/**
 * __useUserFolloweesQuery__
 *
 * To run a query within a React component, call `useUserFolloweesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFolloweesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFolloweesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFolloweesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUserFolloweesQuery, GUserFolloweesQueryVariables>) {
        return ApolloReactHooks.useQuery<GUserFolloweesQuery, GUserFolloweesQueryVariables>(UserFolloweesDocument, baseOptions);
      }
export function useUserFolloweesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUserFolloweesQuery, GUserFolloweesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUserFolloweesQuery, GUserFolloweesQueryVariables>(UserFolloweesDocument, baseOptions);
        }
export type UserFolloweesQueryHookResult = ReturnType<typeof useUserFolloweesQuery>;
export type UserFolloweesLazyQueryHookResult = ReturnType<typeof useUserFolloweesLazyQuery>;
export type UserFolloweesQueryResult = ApolloReactCommon.QueryResult<GUserFolloweesQuery, GUserFolloweesQueryVariables>;
export const UserFollowersDocument = gql`
    query UserFollowers($input: FollowersInput!) {
  followers(input: $input) {
    totalCount
    last
    items {
      username
      id
      imageUrl
    }
  }
}
    `;
export type UserFollowersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUserFollowersQuery, GUserFollowersQueryVariables>, 'query'> & ({ variables: GUserFollowersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserFollowersComponent = (props: UserFollowersComponentProps) => (
      <ApolloReactComponents.Query<GUserFollowersQuery, GUserFollowersQueryVariables> query={UserFollowersDocument} {...props} />
    );
    

/**
 * __useUserFollowersQuery__
 *
 * To run a query within a React component, call `useUserFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFollowersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFollowersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUserFollowersQuery, GUserFollowersQueryVariables>) {
        return ApolloReactHooks.useQuery<GUserFollowersQuery, GUserFollowersQueryVariables>(UserFollowersDocument, baseOptions);
      }
export function useUserFollowersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUserFollowersQuery, GUserFollowersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUserFollowersQuery, GUserFollowersQueryVariables>(UserFollowersDocument, baseOptions);
        }
export type UserFollowersQueryHookResult = ReturnType<typeof useUserFollowersQuery>;
export type UserFollowersLazyQueryHookResult = ReturnType<typeof useUserFollowersLazyQuery>;
export type UserFollowersQueryResult = ApolloReactCommon.QueryResult<GUserFollowersQuery, GUserFollowersQueryVariables>;
export const ReviewsTabReviewsDocument = gql`
    query ReviewsTabReviews($input: UserReviewsInput!) {
  userReviews(input: $input) {
    totalCount
    items {
      id
      score
      createdAt
      beer {
        id
        name
        averageQuickRating
        style {
          id
          name
        }
        brewer {
          id
          name
          country {
            id
            name
            code
          }
        }
        contractBrewer {
          id
          name
          country {
            id
            name
            code
          }
        }
      }
    }
  }
}
    `;
export type ReviewsTabReviewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>, 'query'> & ({ variables: GReviewsTabReviewsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReviewsTabReviewsComponent = (props: ReviewsTabReviewsComponentProps) => (
      <ApolloReactComponents.Query<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables> query={ReviewsTabReviewsDocument} {...props} />
    );
    

/**
 * __useReviewsTabReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsTabReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsTabReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsTabReviewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewsTabReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>(ReviewsTabReviewsDocument, baseOptions);
      }
export function useReviewsTabReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>(ReviewsTabReviewsDocument, baseOptions);
        }
export type ReviewsTabReviewsQueryHookResult = ReturnType<typeof useReviewsTabReviewsQuery>;
export type ReviewsTabReviewsLazyQueryHookResult = ReturnType<typeof useReviewsTabReviewsLazyQuery>;
export type ReviewsTabReviewsQueryResult = ApolloReactCommon.QueryResult<GReviewsTabReviewsQuery, GReviewsTabReviewsQueryVariables>;
export const UserProfileUserDocument = gql`
    query UserProfileUser($userId: ID!) {
  user(userId: $userId) {
    id
    username
    lastLogin
    membershipJoinDate
    imageUrl
    country {
      id
      name
      code
    }
    streetAddress
    state {
      id
      name
    }
    securityLevelId
  }
}
    `;
export type UserProfileUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GUserProfileUserQuery, GUserProfileUserQueryVariables>, 'query'> & ({ variables: GUserProfileUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProfileUserComponent = (props: UserProfileUserComponentProps) => (
      <ApolloReactComponents.Query<GUserProfileUserQuery, GUserProfileUserQueryVariables> query={UserProfileUserDocument} {...props} />
    );
    

/**
 * __useUserProfileUserQuery__
 *
 * To run a query within a React component, call `useUserProfileUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserProfileUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GUserProfileUserQuery, GUserProfileUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GUserProfileUserQuery, GUserProfileUserQueryVariables>(UserProfileUserDocument, baseOptions);
      }
export function useUserProfileUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GUserProfileUserQuery, GUserProfileUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GUserProfileUserQuery, GUserProfileUserQueryVariables>(UserProfileUserDocument, baseOptions);
        }
export type UserProfileUserQueryHookResult = ReturnType<typeof useUserProfileUserQuery>;
export type UserProfileUserLazyQueryHookResult = ReturnType<typeof useUserProfileUserLazyQuery>;
export type UserProfileUserQueryResult = ApolloReactCommon.QueryResult<GUserProfileUserQuery, GUserProfileUserQueryVariables>;
export const meDocument = gql`
    query me {
  me {
    id
    username
    city
    email
    firstName
    festivalRatingCount
    firstName
    gender
    imageUrl
    isPremiumMember
    lastName
    placeReviewCount
    securityLevelId
    streetAddress
    zip
    place {
      id
    }
  }
}
    `;
export type meComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GmeQuery, GmeQueryVariables>, 'query'>;

    export const meComponent = (props: meComponentProps) => (
      <ApolloReactComponents.Query<GmeQuery, GmeQueryVariables> query={meDocument} {...props} />
    );
    

/**
 * __usemeQuery__
 *
 * To run a query within a React component, call `usemeQuery` and pass it any options that fit your needs.
 * When your component renders, `usemeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usemeQuery({
 *   variables: {
 *   },
 * });
 */
export function usemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GmeQuery, GmeQueryVariables>) {
        return ApolloReactHooks.useQuery<GmeQuery, GmeQueryVariables>(meDocument, baseOptions);
      }
export function usemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GmeQuery, GmeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GmeQuery, GmeQueryVariables>(meDocument, baseOptions);
        }
export type meQueryHookResult = ReturnType<typeof usemeQuery>;
export type meLazyQueryHookResult = ReturnType<typeof usemeLazyQuery>;
export type meQueryResult = ApolloReactCommon.QueryResult<GmeQuery, GmeQueryVariables>;
export const FormsBrewerDropdownDocument = gql`
    query FormsBrewerDropdown($query: String) {
  brewerSearch(query: $query, first: 6) {
    items {
      id
      name
    }
  }
}
    `;
export type FormsBrewerDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>, 'query'>;

    export const FormsBrewerDropdownComponent = (props: FormsBrewerDropdownComponentProps) => (
      <ApolloReactComponents.Query<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables> query={FormsBrewerDropdownDocument} {...props} />
    );
    

/**
 * __useFormsBrewerDropdownQuery__
 *
 * To run a query within a React component, call `useFormsBrewerDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsBrewerDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsBrewerDropdownQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFormsBrewerDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>(FormsBrewerDropdownDocument, baseOptions);
      }
export function useFormsBrewerDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>(FormsBrewerDropdownDocument, baseOptions);
        }
export type FormsBrewerDropdownQueryHookResult = ReturnType<typeof useFormsBrewerDropdownQuery>;
export type FormsBrewerDropdownLazyQueryHookResult = ReturnType<typeof useFormsBrewerDropdownLazyQuery>;
export type FormsBrewerDropdownQueryResult = ApolloReactCommon.QueryResult<GFormsBrewerDropdownQuery, GFormsBrewerDropdownQueryVariables>;
export const FormsBrewerTypesDropdownDocument = gql`
    query FormsBrewerTypesDropdown {
  brewerTypes {
    id
    name
  }
}
    `;
export type FormsBrewerTypesDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>, 'query'>;

    export const FormsBrewerTypesDropdownComponent = (props: FormsBrewerTypesDropdownComponentProps) => (
      <ApolloReactComponents.Query<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables> query={FormsBrewerTypesDropdownDocument} {...props} />
    );
    

/**
 * __useFormsBrewerTypesDropdownQuery__
 *
 * To run a query within a React component, call `useFormsBrewerTypesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsBrewerTypesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsBrewerTypesDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsBrewerTypesDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>(FormsBrewerTypesDropdownDocument, baseOptions);
      }
export function useFormsBrewerTypesDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>(FormsBrewerTypesDropdownDocument, baseOptions);
        }
export type FormsBrewerTypesDropdownQueryHookResult = ReturnType<typeof useFormsBrewerTypesDropdownQuery>;
export type FormsBrewerTypesDropdownLazyQueryHookResult = ReturnType<typeof useFormsBrewerTypesDropdownLazyQuery>;
export type FormsBrewerTypesDropdownQueryResult = ApolloReactCommon.QueryResult<GFormsBrewerTypesDropdownQuery, GFormsBrewerTypesDropdownQueryVariables>;
export const FormsCountryDropdownDocument = gql`
    query FormsCountryDropdown {
  countries {
    id
    name
    code
  }
}
    `;
export type FormsCountryDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>, 'query'>;

    export const FormsCountryDropdownComponent = (props: FormsCountryDropdownComponentProps) => (
      <ApolloReactComponents.Query<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables> query={FormsCountryDropdownDocument} {...props} />
    );
    

/**
 * __useFormsCountryDropdownQuery__
 *
 * To run a query within a React component, call `useFormsCountryDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsCountryDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsCountryDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsCountryDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>(FormsCountryDropdownDocument, baseOptions);
      }
export function useFormsCountryDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>(FormsCountryDropdownDocument, baseOptions);
        }
export type FormsCountryDropdownQueryHookResult = ReturnType<typeof useFormsCountryDropdownQuery>;
export type FormsCountryDropdownLazyQueryHookResult = ReturnType<typeof useFormsCountryDropdownLazyQuery>;
export type FormsCountryDropdownQueryResult = ApolloReactCommon.QueryResult<GFormsCountryDropdownQuery, GFormsCountryDropdownQueryVariables>;
export const FormsMetroDropdownDocument = gql`
    query FormsMetroDropdown {
  metros {
    id
    name
  }
}
    `;
export type FormsMetroDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>, 'query'>;

    export const FormsMetroDropdownComponent = (props: FormsMetroDropdownComponentProps) => (
      <ApolloReactComponents.Query<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables> query={FormsMetroDropdownDocument} {...props} />
    );
    

/**
 * __useFormsMetroDropdownQuery__
 *
 * To run a query within a React component, call `useFormsMetroDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsMetroDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsMetroDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsMetroDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>(FormsMetroDropdownDocument, baseOptions);
      }
export function useFormsMetroDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>(FormsMetroDropdownDocument, baseOptions);
        }
export type FormsMetroDropdownQueryHookResult = ReturnType<typeof useFormsMetroDropdownQuery>;
export type FormsMetroDropdownLazyQueryHookResult = ReturnType<typeof useFormsMetroDropdownLazyQuery>;
export type FormsMetroDropdownQueryResult = ApolloReactCommon.QueryResult<GFormsMetroDropdownQuery, GFormsMetroDropdownQueryVariables>;
export const FormsPlaceTypesDropdownDocument = gql`
    query FormsPlaceTypesDropdown {
  placeTypes {
    id
    name
  }
}
    `;
export type FormsPlaceTypesDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>, 'query'>;

    export const FormsPlaceTypesDropdownComponent = (props: FormsPlaceTypesDropdownComponentProps) => (
      <ApolloReactComponents.Query<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables> query={FormsPlaceTypesDropdownDocument} {...props} />
    );
    

/**
 * __useFormsPlaceTypesDropdownQuery__
 *
 * To run a query within a React component, call `useFormsPlaceTypesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsPlaceTypesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsPlaceTypesDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormsPlaceTypesDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>(FormsPlaceTypesDropdownDocument, baseOptions);
      }
export function useFormsPlaceTypesDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>(FormsPlaceTypesDropdownDocument, baseOptions);
        }
export type FormsPlaceTypesDropdownQueryHookResult = ReturnType<typeof useFormsPlaceTypesDropdownQuery>;
export type FormsPlaceTypesDropdownLazyQueryHookResult = ReturnType<typeof useFormsPlaceTypesDropdownLazyQuery>;
export type FormsPlaceTypesDropdownQueryResult = ApolloReactCommon.QueryResult<GFormsPlaceTypesDropdownQuery, GFormsPlaceTypesDropdownQueryVariables>;
export const GetGeoLocationDocument = gql`
    query GetGeoLocation {
  geoLocation {
    latitude
    longitude
  }
}
    `;
export type GetGeoLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>, 'query'>;

    export const GetGeoLocationComponent = (props: GetGeoLocationComponentProps) => (
      <ApolloReactComponents.Query<GGetGeoLocationQuery, GGetGeoLocationQueryVariables> query={GetGeoLocationDocument} {...props} />
    );
    

/**
 * __useGetGeoLocationQuery__
 *
 * To run a query within a React component, call `useGetGeoLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeoLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>(GetGeoLocationDocument, baseOptions);
      }
export function useGetGeoLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>(GetGeoLocationDocument, baseOptions);
        }
export type GetGeoLocationQueryHookResult = ReturnType<typeof useGetGeoLocationQuery>;
export type GetGeoLocationLazyQueryHookResult = ReturnType<typeof useGetGeoLocationLazyQuery>;
export type GetGeoLocationQueryResult = ApolloReactCommon.QueryResult<GGetGeoLocationQuery, GGetGeoLocationQueryVariables>;