import React, { Fragment } from 'react'
import { noopTemplate as css } from 'lib/utils'

import {
  Divider,
  A,
  Link,
  IconButton,
  Menu,
  MenuItem,
  usePopoverMenu,
} from 'lib/components'
import { ChevronRightIcon, MenuIcon } from 'lib/icons'

import { config } from 'tools/config'
import { shopPageUrl } from 'features/shop/routing'

import { intl } from 'services/intl'

const NavMenuIcon = () => {
  const { show, openMenu, closeMenu, anchorEl } = usePopoverMenu()

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MenuIcon color="white" />
      </IconButton>

      <Menu
        open={show}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          css={css`
            min-width: 200px;
          `}
        >
          <A href="/beer-ratings/">
            <MenuItem className="fj-sb pr-0">
              {intl.formatMessage('Ratings')}
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </MenuItem>
          </A>

          <Divider />

          <A href="/users">
            <MenuItem className="fj-sb pr-0">
              {intl.formatMessage('Community')}
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </MenuItem>
          </A>

          <Divider />

          <A href="/events">
            <MenuItem className="fj-sb pr-0">
              {intl.formatMessage('Events')}
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </MenuItem>
          </A>

          <Divider />

          <A href="/places/">
            <MenuItem className="fj-sb pr-0">
              {intl.formatMessage('Places')}
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </MenuItem>
          </A>

          <Divider />

          {config.ENVIRONMENT === 'production' && (
            <A href={config.DISCOURSE_URI}>
              <MenuItem className="fj-sb pr-0">
                {intl.formatMessage('Forums')}
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
              </MenuItem>
            </A>
          )}

          {config.ENVIRONMENT === 'production' && <Divider />}

          <Link to={shopPageUrl()}>
            <MenuItem className="fj-sb pr-0">
              <span style={{ color: 'black' }}>
                {intl.formatMessage('Shop')}
              </span>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </MenuItem>
          </Link>
        </div>
      </Menu>
    </Fragment>
  )
}

export default NavMenuIcon
