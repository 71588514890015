import { noop } from 'lib/utils'
import Jar from 'tools/cookies'
import { fromPairs } from 'ramda'

type State = {
  // AwaitingReconsent: string
  // consentId: string
  // datestamp: string
  groups: Groups
  // isIABGlobal: string
  // landingPath: string
  // version: string
}

type Groups = {
  necessary: boolean
  performance: boolean
  functional: boolean
  targeting: boolean
}

const initialState: State = {
  // All are set to true initially (Opt-out consent)
  groups: {
    necessary: true,
    performance: true,
    functional: true,
    targeting: true
  }
  // State information
}

let STATE: State = initialState

const parseGroups = (rawGroups: string): Groups => {
  const g = fromPairs(
    (rawGroups.split(',') as any).map((v: any) => v.split(':'))
  )

  return {
    necessary: g['1'] === '1',
    performance: g['2'] === '1',
    functional: g['3'] === '1',
    targeting: g['4'] === '1'
  }
}

const syncStateWithCookie = () => {
  const OptanonCookie = Jar.get('OptanonConsent')
  const OptanonAlertBoxClosed = Jar.get('OptanonAlertBoxClosed')

  if (OptanonCookie) {
    const data: any = fromPairs(
      (decodeURIComponent(OptanonCookie) as any)
        .split('&')
        .map((v: any) => v.split('='))
    )

    data.groups = data.groups ? parseGroups(data.groups) : initialState.groups

    STATE = data
  }

  if (!OptanonAlertBoxClosed) {
    STATE.groups = initialState.groups
  }
}

export const initialize = () => {
  const defaultOptanonWrapper = window.OptanonWrapper || noop

  syncStateWithCookie()

  window.OptanonWrapper = () => {
    syncStateWithCookie()
    defaultOptanonWrapper()
  }
}

const cookiesAllowed = (group: keyof State['groups']) =>
  process.env.NODE_ENV === 'test' ? true : STATE.groups[group]

export const necessaryCookiesAllowed = () => cookiesAllowed('necessary')
export const performanceCookiesAllowed = () => cookiesAllowed('performance')
export const functionalCookiesAllowed = () => cookiesAllowed('functional')
export const targetingCookiesAllowed = () => cookiesAllowed('targeting')
