export default {
  'Please enter a complete name': 'Please enter a complete name',
  Unknown: 'Unknown',
  'Average Rating': 'Average Rating',
  'Upload new photo': 'Upload new photo',
  'Style Score': 'Style Score',
  'Clone this brewery to a place': 'Clone this brewery to a place',
  'I found this review helpful': 'I found this review helpful',
  'We encountered a temporary error when trying to load this content.':
    'We encountered a temporary error when trying to load this content.',
  'Please try again in 30 seconds': 'Please try again in 30 seconds',
  'Try again now': 'Try again now',
  Rated: 'Rated',
  Closed: 'Closed',
  'An error occurred when trying to associate with this brewer':
    'An error occurred when trying to associate with this brewer',
  'You have rated ${userRatedBeersCount} beers from this brewer':
    'You have rated ${userRatedBeersCount} beers from this brewer',
  'You are now associated with': 'You are now associated with',
  'You have rated ': 'You have rated ',
  ' beers from this brewer': ' beers from this brewer',
  'You have rated': 'You have rated',
  'from this brewery': 'from this brewery',
  'Under {number}': 'Under {number}',
  'Available from {price}': 'Available from {price}',
  'Read {count} reviews': 'Read {count} reviews',
  'The best range of beers in {country}':
    'The best range of beers in {country}',
  Lists: 'Lists',
  'Type in the search bar to begin searching':
    'Type in the search bar to begin searching',
  'Advanced search': 'Advanced search',
  'No matches found': 'No matches found',
  'Show more': 'Show more',
  'Closest Match': 'Closest Match',
  'Most Ratings': 'Most Ratings',
  'Top Rated': 'Top Rated',
  'is now verified': 'is now verified',
  'is now unverified': 'is now unverified',
  'You are now unfollowing': 'You are now unfollowing',
  'You are now following': 'You are now following',
  'An error occurred when trying to unfollow this brewer':
    'An error occurred when trying to unfollow this brewer',
  'An error occurred when trying to follow this brewer':
    'An error occurred when trying to follow this brewer',
  'This beer does not have enough reviews to be given a score':
    'This beer does not have enough reviews to be given a score',
  Options: 'Options',
  Distributors: 'Distributors',
  'Distributon map': 'Distributon map',
  'No associated places': 'No associated places',
  Confirm: 'Confirm',
  'Are you sure that you want to delete this review?':
    'Are you sure that you want to delete this review?',
  'Permanently closed': 'Permanently closed',
  '© 2000-2021 RateBeer, LLC.': '© 2000-2021 RateBeer, LLC.',
  'All Rights Reserved': 'All Rights Reserved',
  'See more results for': 'See more results for',
  Shop: 'Shop',
  'My Year In Beer': 'My Year In Beer',
  'View all': 'View all',
  'Saved to favorites': 'Saved to favorites',
  'Removed from favorites': 'Removed from favorites',
  'Please use a different search term or try again with less filters applied':
    'Please use a different search term or try again with less filters applied',
  'No beers found matching your criteria':
    'No beers found matching your criteria',
  'Available from': 'Available from',
  'Reset filters': 'Reset filters',
  Apply: 'Apply',
  'From {price} per {serve}': 'From {price} per {serve}',
  'View beer profile': 'View beer profile',
  'View offers from {count} {count, plural, one {retailer} other {retailers}}':
    'View offers from {count} {count, plural, one {retailer} other {retailers}}',
  Filters: 'Filters',
  'Read more': 'Read more',
  'Show less': 'Show less',
  'Beer Styles': 'Beer Styles',
  'Similar beers': 'Similar beers',
  'Show more information': 'Show more information',
  'Show less information': 'Show less information',
  Tags: 'Tags',
  'Edit tags': 'Edit tags',
  Availability: 'Availability',
  'Served in': 'Served in',
  'N/A': 'N/A',
  Filter: 'Filter',
  'beers found': 'beers found',
  'beer found': 'beer found',
  BETA: 'BETA',
  Home: 'Home',
  'Shop now': 'Shop now',
  from: 'from',
  per: 'per',
  'Buy from': 'Buy from',
  'Available to buy from {count} trusted retail partners':
    'Available to buy from {count} trusted retail partners',
  'Apply to sell with us': 'Apply to sell with us',
  'RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices':
    'RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices',
  'View retailers': 'View retailers',
  'Find a country': 'Find a country',
  'Find a style': 'Find a style',
  Clear: 'Clear',
  'Country of origin': 'Country of origin',
  'RateBeer Score': 'RateBeer Score',
  'Beer styles': 'Beer styles',
  Retailers: 'Retailers',
  'Find a beer or brewer': 'Find a beer or brewer',
  'RateBeer Shop': 'RateBeer Shop',
  'Overall score': 'Overall score',
  'You can either leave a quick rating out of 5, or provide a more detailed rating by scoring a beer on the following attributes:':
    'You can either leave a quick rating out of 5, or provide a more detailed rating by scoring a beer on the following attributes:',
  'Rating beers on RateBeer is easy': 'Rating beers on RateBeer is easy',
  'The smell of the beer': 'The smell of the beer',
  'The color, clarity, head and visual carbonation of this beer.':
    'The color, clarity, head and visual carbonation of this beer.',
  Taste: 'Taste',
  'The flavors in this beer, thinking about the palate, bitterness and finish.':
    'The flavors in this beer, thinking about the palate, bitterness and finish.',
  'The body of the beer, carbonation and astringency.':
    'The body of the beer, carbonation and astringency.',
  'The overall characteristics and your personal experience of the beer.':
    'The overall characteristics and your personal experience of the beer.',
  'Aroma, appearance and mouthfeel are each scored out of 5. Taste is scored out of 10 and overall is scored out of 20. These all combine to give the beer a total score out of 50, which is then divided and displayed as a score out of 5 against each review.':
    'Aroma, appearance and mouthfeel are each scored out of 5. Taste is scored out of 10 and overall is scored out of 20. These all combine to give the beer a total score out of 50, which is then divided and displayed as a score out of 5 against each review.',
  'RateBeer Score Calculation Guide': 'RateBeer Score Calculation Guide',
  'We display three scores for each beer, helping you view the beer from different perspectives.':
    'We display three scores for each beer, helping you view the beer from different perspectives.',
  'Overall Score': 'Overall Score',
  'A score that ranks this beer against all other beers on RateBeer.':
    'A score that ranks this beer against all other beers on RateBeer.',
  'Style score': 'Style score',
  'A score that ranks this beer against all beers within its own style category.':
    'A score that ranks this beer against all beers within its own style category.',
  'Star Rating': 'Star Rating',
  'An average score out of 5.': 'An average score out of 5.',
  "A beer's overall score reflects the rating given by RateBeer users and how this beer compares to all other beers on RateBeer.": `A beer's overall score reflects the rating given by RateBeer users and how this beer compares to all other beers on RateBeer.`,
  'RateBeer uses an algorithm when calculating the overall score, considering both the ratings given by each user and the total number of ratings for the beer.':
    'RateBeer uses an algorithm when calculating the overall score, considering both the ratings given by each user and the total number of ratings for the beer.',
  'Our scores are calculated with the same true Bayesian estimate formula used by the Internet Movie Database for calculating their average ratings (with 2.75 as the midpoint of the scale). On RateBeer, this means that as a beer accumulates more ratings, the overall score in turn becomes more accurate.':
    'Our scores are calculated with the same true Bayesian estimate formula used by the Internet Movie Database for calculating their average ratings (with 2.75 as the midpoint of the scale). On RateBeer, this means that as a beer accumulates more ratings, the overall score in turn becomes more accurate.',
  'The highest score a beer can get on RateBeer is 100. ':
    'The highest score a beer can get on RateBeer is 100. ',
  'For increased accuracy, a beer must also receive a minimum number of ratings before an overall score is visible. To ensure the validity of all our scores and to combat abuse, ratings from new users on RateBeer do not impact the overall score until they have given several ratings.':
    'For increased accuracy, a beer must also receive a minimum number of ratings before an overall score is visible. To ensure the validity of all our scores and to combat abuse, ratings from new users on RateBeer do not impact the overall score until they have given several ratings.',
  'The style score is helpful in illustrating how a beer stacks up against other beers listed within the same style category -- it’s like comparing apples to apples and oranges to oranges (or Imperial Stouts to Imperial Stouts and Premium Lagers to Premium Lagers).':
    'The style score is helpful in illustrating how a beer stacks up against other beers listed within the same style category -- it’s like comparing apples to apples and oranges to oranges (or Imperial Stouts to Imperial Stouts and Premium Lagers to Premium Lagers).',
  'This Stout ranks well both when compared to all other beers on RateBeer and within its own style category.':
    'This Stout ranks well both when compared to all other beers on RateBeer and within its own style category.',
  'Whilst this Lager may not have a high overall score, it’s actually one of the best Lagers as rated by our users.':
    'Whilst this Lager may not have a high overall score, it’s actually one of the best Lagers as rated by our users.',
  'Quality of our scores': 'Quality of our scores',
  'RateBeer highly values the integrity of our reviews and quality of our ratings.':
    'RateBeer highly values the integrity of our reviews and quality of our ratings.',
  'To ensure the integrity of our beer scores, only ratings that are accompanied with a written review of 75 or more characters will be considered in our calculations.':
    'To ensure the integrity of our beer scores, only ratings that are accompanied with a written review of 75 or more characters will be considered in our calculations.',
  'This ensures users of RateBeer appreciate that our scores are calculated from independent ratings that are of a high standard.':
    'This ensures users of RateBeer appreciate that our scores are calculated from independent ratings that are of a high standard.',
  'A rating may also not count toward a total score for a number of reasons:':
    'A rating may also not count toward a total score for a number of reasons:',
  'The rater has left fewer than 10 ratings.':
    'The rater has left fewer than 10 ratings.',
  'Our users must have more than 10 ratings before their ratings contribute to a beer or brewers score.':
    'Our users must have more than 10 ratings before their ratings contribute to a beer or brewers score.',
  'The rating is deemed unauthentic, derogatory or abusive.':
    'The rating is deemed unauthentic, derogatory or abusive.',
  'These ratings will be deleted and in some cases, the user account will also be deactivated.':
    'These ratings will be deleted and in some cases, the user account will also be deactivated.',
  'The rating was made by a brewer or brewer affiliate.':
    'The rating was made by a brewer or brewer affiliate.',
  'Ratings by brewers (or affiliates) of their own products harm the integrity of RateBeer and will be deleted. ':
    'Ratings by brewers (or affiliates) of their own products harm the integrity of RateBeer and will be deleted. ',
  'RateBeer uses several detailed methods to ensure the validity of ratings. If you suspect harmful, invalid or abusive ratings, please contact us admin@ratebeer.com':
    'RateBeer uses several detailed methods to ensure the validity of ratings. If you suspect harmful, invalid or abusive ratings, please contact us admin@ratebeer.com',

  'Beer name must start with {shortBrewerName}':
    'Beer name must start with {shortBrewerName}',
  'Are you sure you want to delete your review?':
    'Are you sure you want to delete your review?',
  'Confirm Delete': 'Confirm Delete',
  'Brewed for': 'Brewed for',
  'Brewed by': 'Brewed by',
  'See more similar beers': 'See more similar beers',
  'Review deleted': 'Review deleted',
  Edit: 'Edit',
  'Rate this beer': 'Rate this beer',
  '{count} {count, plural, one {location nearby} other {locations nearby}}':
    '{count} {count, plural, one {location nearby} other {locations nearby}}',
  '{count} {count, plural, one {location} other {locations}}':
    '{count} {count, plural, one {location} other {locations}}',
  'Spotted at': 'Spotted at',
  'Finish review': 'Finish review',
  'Your review is private and is not counting towards the beer rating.':
    'Your review is private and is not counting towards the beer rating.',
  'How our scores are calculated': 'How our scores are calculated',
  'Open Admin Menu': 'Open Admin Menu',
  'More filters': 'More filters',
  'Show beers with at least': 'Show beers with at least',
  'ABV %': 'ABV %',
  'Open Admin Panel': 'Open Admin Panel',
  'Beer has been updated': 'Beer has been updated',
  'Unable to update beer': 'Unable to update beer',
  'Download on': 'Download on',
  'Get the RateBeer app': 'Get the RateBeer app',
  'App Store': 'App Store',
  'Google Play Store': 'Google Play Store',
  'Follow us': 'Follow us',
  'Back to': 'Back to',
  'Download the app if you haven’t already!':
    'Download the app if you haven’t already!',
  'Keep RateBeer in your back pocket and discover the world of beer with essential features like nearby locations, events and handpicked beer recommendations.':
    'Keep RateBeer in your back pocket and discover the world of beer with essential features like nearby locations, events and handpicked beer recommendations.',
  'Delete this beer': 'Delete this beer',
  Picture: 'Picture',
  Barcodes: 'Barcodes',
  Alias: 'Alias',
  'Fix score': 'Fix score',
  'View shelf tags': 'View shelf tags',
  Entered: 'Entered',
  'Entered by': 'Entered by',
  'Last edited by': 'Last edited by',
  'Save changes': 'Save changes',
  'Update beer details': 'Update beer details',
  Unratable: 'Unratable',
  Retired: 'Retired',
  'Admin notes': 'Admin notes',
  Aroma: 'Aroma',
  'Discard changes and go back': 'Discard changes and go back',
  'No results found': 'No results found',
  'No beers were found matching your filters':
    'No beers were found matching your filters',
  Appearance: 'Appearance',
  Flavor: 'Flavor',
  Mouthfeel: 'Mouthfeel',
  Overall: 'Overall',
  Friends: 'Friends',
  Reviews: 'Reviews',
  Public: 'Public',
  Private: 'Private',
  'Delete this review?': 'Delete this review?',
  'Barcode must include 8, 12, 13 or 14 numbers':
    'Barcode must include 8, 12, 13 or 14 numbers',
  'IBU must be between 0 and 100,000': 'IBU must be between 0 and 100,000',
  'IBU must not have decimal places': 'IBU must not have decimal places',
  'Description must not only include whitespace':
    'Description must not only include whitespace',
  'ABV must be between 0 and 100': 'ABV must be between 0 and 100',
  'Show me': 'Show me',
  'We found 1 beer that has a similar name':
    'We found 1 beer that has a similar name',
  'We found {count} beers that have a similar name':
    'We found {count} beers that have a similar name',
  Directions: 'Directions',
  'Please add a rating': 'Please add a rating',
  Description: 'Description',
  Search: 'Search',
  'The filter returned no results': 'The filter returned no results',
  Location: 'Location',
  'Add location': 'Add location',
  'Rate this beer on these attributes': 'Rate this beer on these attributes',
  'Rate this place on these attributes': 'Rate this place on these attributes',
  'Your review will be public and will count towards the beer rating':
    'Your review will be public and will count towards the beer rating',
  'Review this beer': 'Review this beer',
  'Your review will be private and will not count towards the beer rating':
    'Your review will be private and will not count towards the beer rating',
  'More information': 'More information',
  Save: 'Save',
  'Review this place': 'Review this place',
  'Total score': 'Total score',
  'The scores below do not count towards the total score':
    'The scores below do not count towards the total score',
  'Add a new beer': 'Add a new beer',
  'We appreciate you helping us by adding this beer to our records.':
    'We appreciate you helping us by adding this beer to our records.',
  'Similar existing beers': 'Similar existing beers',
  Preview: 'Preview',
  'Your search for "{searchTerm}" did not return any results. We appreciate you helping us by adding this beer to our records.':
    'Your search for "{searchTerm}" did not return any results. We appreciate you helping us by adding this beer to our records.',

  'Back to Search results': 'Back to Search results',
  Brewery: 'Brewery',
  'Beer name': 'Beer name',
  Style: 'Style',
  'Label description': 'Label description',
  'Input the description on the product label':
    'Input the description on the product label',
  Required: 'Required',
  'Seasonal beer': 'Seasonal beer',
  'This beer is brewed in a contract brewery':
    'This beer is brewed in a contract brewery',
  'Contract brewer': 'Contract brewer',
  Verified: 'Verified',
  'Barcode number': 'Barcode number',
  'Include all 8, 12, 13 or 14 number': 'Include all 8, 12, 13 or 14 number',
  Optional: 'Optional',
  Cancel: 'Cancel',
  'Confirm and add': 'Confirm and add',
  'Please upload images of cans or bottles only':
    'Please upload images of cans or bottles only',
  'Add Image': 'Add Image',
  'Remove Image': 'Remove Image',
  Winter: 'WINTER',
  Spring: 'SPRING',
  Summer: 'SUMMER',
  Autumn: 'AUTUMN',
  Special: 'SPECIAL',
  Series: 'SERIES',

  'Add description': 'Add description',
  'Associated Brewer': 'Associated Brewer',
  'Available here': 'Available here',
  Average: 'Average',
  'Beers Spotted Here': 'Beers spotted here',
  'Bottles {bottlesCount}': 'Bottles {bottlesCount}',
  'Copied to clipboard': 'Copied to clipboard',
  'Copy to clipboard': 'Copy to clipboard',
  'Follow on Social Media': {
    message: 'Follow on social media',
    status: 'to-update'
  },
  'No results found for': 'No results found for {query}',
  'Review This Place': {
    message: 'Review This Place',
    status: 'to-udpate'
  },
  'Search beers spotted here': 'Search beers spotted here',
  'Taps {tapsCount}': 'Taps {tapsCount}',
  'This score could not be updated': 'This score could not be updated',
  'This score has been updated': 'This score has been updated',
  'View beer menu': 'View beer menu',
  'View food menu': 'View food menu',
  'We apologise for the delay you are experiencing.':
    'We apologise for the delay you are experiencing.',
  'We are currently updating the website and expect this page to be back shortly.':
    'We are currently updating the website and expect this page to be back shortly.',
  'Your review has been updated': 'Your review has been updated',
  'add.abv': 'ABV %',
  'add.abvPlaceholder': '(ex. 11%)',
  'add.addBeer': 'Add New Beer',
  'add.addBrewery': 'Add New Brewery',
  'add.autumn': 'Autumn',
  'add.beerGuidelines': 'Guidelines',
  'add.beerGuidelines1': 'Beers must exist commercially and be in production.',
  'add.beerGuidelines2':
    'Recipes must be unique. No cask, nitro, etc. or variants of a recipe.',
  'add.beerGuidelines3':
    'Fruit infused ciders must be 50%+ apple fermentable and over 2% ABV.',
  'add.beerGuidelines4':
    'Include producers names in front of the beer name, ex. {correctExample} not {incorrectExample}.',
  'add.beerImage': 'Add Image',
  'add.beerName': 'Beer Name',
  'add.beerNameExplanation':
    'The RateBeer naming convention is to include Brewery Name + Beer Name.',
  'add.beerNameInfo': '(Include brewer)',
  'add.beerNamePlaceholder': '(ex. Elysian Space Dust)',
  'add.breweryName': 'Brewery Name',
  'add.breweryNamePlaceholder': '(ex. Elysian)',
  'add.contractBrewer': 'Brewed by',
  'add.contractBrewerPlaceholder': 'Search breweries…',
  'add.contractBrewerPrompt': 'Contract beer',
  'add.description': 'Commercial Description',
  'add.descriptionPlaceholder': 'What the product label reads',
  'add.duplicateFound': 'Duplicate Found',
  'add.duplicateMessage': "Is this the beer you're trying to add?",
  'add.ibuPlaceholder': '(ex. 57)',
  'add.imageInstructions': '*Use images of cans or bottles',
  'add.invalid': 'Invalid',
  'add.newBeer': 'Add a New Beer',
  'add.noBrewery': "Can't find your brewery?",
  'add.optional': 'Optional',
  'add.removeBeerImage': 'Remove Image',
  'add.seasonalPlaceholder': 'Is this a seasonal beer?',
  'add.series': 'Series',
  'add.signInToAddBeer': 'Sign in to add a beer',
  'add.special': 'Special Release',
  'add.spring': 'Spring',
  'add.stylePlaceholder': 'Select style',
  'add.summer': 'Summer',
  'add.upc': 'UPC/GTIN',
  'add.upcInfo': '(Barcode Numbers)',
  'add.upcInstructions': 'Include all 8, 12, 13 or 14 numbers',
  'add.upcPlaceholder': 'All numbers on barcode',
  'add.view': 'View',
  'add.winter': 'Winter',
  'all.adblock':
    'Looks like there is an adblocker installed. Please consider adding RateBeer.com as an exception. Cheers!',
  'all.ago': '{date} ago',
  'all.back': 'Back',
  'all.beer': 'Beer',
  'all.beers': 'Beers',
  'all.cider': 'Cider',
  'all.ciders': 'Ciders',
  'all.cookies': 'We use cookies n stuff',
  'all.error': 'Oops, there was a network error',
  'all.joinBody':
    'Take advantage of everything RateBeer has to offer. Read full reviews, see detailed statistics and check out what our community has to say.',
  'all.joinTitle': 'Join Our Community!',
  'all.learnMore': 'Learn More',
  'all.loading': 'Loading',
  'all.mead': 'Mead',
  'all.meads': 'Meads',
  'all.noSuchBeer':
    'No such beer in the database. Try searching for another beer.',
  'all.ok': 'OK',
  'all.sake': 'Sake',
  'all.sakes': 'Sakes',
  at: 'at',
  'banner.free': 'Free – Google Play',
  'banner.getIt': 'Get it',
  'banner.officialApp': 'Official App',
  'beer.aboutBeer': 'About this Beer',
  'beer.aboutCider': 'About this Cider',
  'beer.aboutMead': 'About this Mead',
  'beer.aboutSake': 'About this Sake',
  'beer.abv': 'ABV',
  'beer.aka': 'Also Known As',
  'beer.aliasBeer': 'Alias Beer',
  'beer.aliasCopy':
    'The brewer markets this same or near-same product by more than one name. This can be the result of a brewer distributing this beer under different names in different countries, or the brewer simply changing the name, but not the recipe, at different points in time.',
  'beer.allStyles': 'All Beer Styles for ',
  'beer.appearance': 'Appearance',
  'beer.applyToSellWithUs': 'Apply to Sell With Us',
  'beer.aroma': 'Aroma',
  'beer.autumn': 'Autumn',
  'beer.availability': 'Availability',
  'beer.availableAt': 'Available at',
  'beer.averageRatings': 'Average Ratings',
  'beer.bottled': 'Bottled',
  'beer.brewedBy': 'Brewed by ',
  'beer.brewedFor': 'Brewed for ',
  'beer.buyFrom': 'Buy from',
  'Available at': 'Available at',
  'beer.calories': 'EST. CAL.',
  'beer.clearRating': 'Clear',
  'beer.copy': 'Copy',
  'beer.delete': 'Delete Beer/Ratings',
  'beer.deleteAlias': 'Delete Alias',
  'beer.deleteConfirm': 'Yes, Delete',
  'beer.deleteOption': 'Delete',
  'beer.deleteRating': 'Delete Rating?',
  'beer.distribution': 'Distribution',
  'beer.edit': 'Edit',
  'beer.editAlias': 'Add/Edit Alias',
  'beer.editBarcodes': 'Add/Edit Barcodes',
  'beer.editBeer': 'Edit this Beer',
  'beer.editPicture': 'Add/Edit Picture',
  'beer.editTags': 'Add/Edit Tags',
  'beer.findFriendsText':
    'Find friends to share what you’re drinking and see what they’ve tried',
  'beer.findFriendsTitle': {
    message: 'Find friends',
    status: 'to-update'
  },
  'beer.findIt': 'Find It',
  'beer.findItNearYou': 'Find It Near You',
  'beer.fix': 'Fix Score',
  'beer.flavor': 'Taste',
  'Your guide to the world of beer': 'Your guide to the world of beer',
  'beer.from': 'from ',
  'beer.highestScore': 'Highest Score',
  'beer.privacyNotice': 'By clicking on the shop link/s above, you will be forwarded to the retailer’s website and from there, their Privacy Policy will subsequently apply.',
  'My ratings and reviews': 'My ratings and reviews',
  'Hide beers that are': 'Hide beers that are',
  'beers brewed': 'beers brewed',
  Name: 'Name',
  'My rating': 'My rating',
  Status: 'Status',
  Added: 'Added',
  Website: 'Website',
  'Add opening hours': 'Add opening hours',
  'Add beer': 'Add beer',
  'Brewed by/for ': 'Brewed by/for',
  'Brewed by/at ': 'Brewed by/at',
  Email: 'Email',
  'Suggest a correction': 'Suggest a correction',
  'Clear filters': 'Clear filters',
  Score: 'Score',
  'Out of business': 'Out of business',
  'Associated users': 'Associated users',
  'Are you affiliated with this brewery? Find out how you can':
    'Are you affiliated with this brewery? Find out how you can',
  ' associate yourself here.': ' associate yourself here.',
  'Opening hours': 'Opening hours',
  'beer.howItWorks': 'How it Works',
  'beer.howItWorksContent':
    'We work hard to find you the best retailers, with the widest range and great prices, so you can buy the beer you love',
  'beer.ibu': 'IBU',
  'beer.locateSellers': 'Locate Sellers',
  'beer.locations': '{count} {count, plural, one {Location} other {Locations}}',
  'beer.locationsNearby':
    '{count} {count, plural, one {Location Nearby} other {Locations Nearby}}',
  'beer.mostLiked': 'Most Liked',
  'beer.mostRecent': 'Most Recent',
  'beer.mouthfeel': 'Palate',
  'beer.moveRating': 'Move Rating',
  'beer.myFriends': 'My Friends',
  'Cancel and go back': 'Cancel and go back',
  'beer.myReview': 'My Review',
  'beer.noDescription': 'No description available',
  'beer.noRating': 'No Rating',
  'beer.noReviewsText':
    'Let the world know what you think. Be the first to rate and review!',
  'beer.noReviewsTitle': 'Tried This Beer?',
  'beer.onTap': 'On Tap',
  'beer.outOfProd': 'OUT OF PRODUCTION',
  'beer.overall': 'Overall',
  'beer.per': 'per',
  'beer.private': 'PRIVATE',
  'beer.quickRate': 'Quick Rate',
  'beer.quickRateSaved': 'Quick Rate Saved',
  'beer.quickRated': 'Quick Rated: ',
  'beer.rateAndReview': 'Rate & Review',
  'beer.rateIt': 'Tried it? Rate It!',
  'beer.ratedBy': 'Rated By',
  'beer.ratingPrivate': 'Private Ratings',
  'beer.ratingPublic': 'Public Review',
  'beer.readMore': 'Read More',
  'beer.realAvg': 'Real Avg.',
  'beer.reviews': 'Reviews',
  'beer.reviewsRatings': 'Reviews & Ratings',
  'beer.saveRating': 'Save That Rating!',
  'beer.saveRatingText':
    'Create an account to save ratings, read expert reviews, see detailed rating and review statistics, and much more.',
  'beer.seasonal': 'Seasonal',
  'beer.seasonalLabel': 'SEASONAL',
  'beer.seeAll': 'See All',
  'beer.seeAllXReviews': 'See All {count} Reviews',
  'beer.sendCorrections': 'Send Corrections',
  'beer.series': 'Series',
  'beer.serveIn': 'Serve In',
  'beer.share': 'Share',
  'beer.shareTutorial': 'Share, send corrections, add pictures',
  'beer.shelfTags': 'View Shelf Tag',
  'beer.shopNow': 'Shop Now',
  'beer.showLess': 'Show Less',
  'beer.signInToFavorite': 'Sign in to favorite this beer',
  'beer.signInToFind': 'Sign in to find this beer.',
  'beer.signInToLikeReview': 'Sign in to like this review',
  'beer.similarBeer': 'Similar Beers',
  'beer.similarCider': 'Similar Ciders',
  'beer.similarMead': 'Similar Meads',
  'beer.similarSake': 'Similar Sakes',
  'beer.special': 'Special',
  'beer.spring': 'Spring',
  'beer.style': 'Style ',
  'beer.summer': 'Summer',
  'beer.tags': 'Tags',
  'beer.top50': 'TOP 50',
  'beer.topRaters': 'Top Raters',
  'beer.totalRetailers': 'This beer is sold by {count} trusted retail partners',
  'beer.undoRating': 'Undo Rating',
  'beer.unrateable': 'This beer cannot be rated',
  'beer.verified': 'VERIFIED',
  'beer.weightedAvg': 'Weighted Avg.',
  'beer.winter': 'Winter',
  'beer.writeAReview': 'Write a Review',
  'beer.writeFullReview': 'Write Full Review',
  'beer.yourReview': 'Your Review',
  'day ago': 'day ago',
  'days ago': 'days ago',
  'error.refresh': 'Refresh',
  'favorites.instructions':
    'When you find a beer you like, tap the save button to save it for later. View your saved beers in one place, on all of your devices.',
  'favorites.noSavedBeers': `You Haven't Saved Any Beers`,
  'favorites.remove': 'Remove',
  'favorites.removeConfirmation': 'Yes, Remove',
  'favorites.removePrompt': 'Remove from favorites?',
  'favorites.saved': 'Saved',
  'favorites.savedConfirmation': 'Saved to Favorites',
  'favorites.signInToViewFavorites': 'Sign in to view your saved beers',
  'favorites.viewAll': 'View All',
  'feedback.android': 'Android Mobile App',
  'feedback.androidReproduceInfo':
    '(e.g. Log in, search by barcode scan, tap beer result, expand expert rating panel, submit expert rating)',
  'feedback.bug': 'Report a Bug',
  'feedback.bugMessageInfo':
    '(What results did you expect? What results did you experience?)',
  'feedback.bugMessagePrompt': 'Summary of Bug',
  'feedback.buildInfo':
    '(e.g. App Version 1.7.0. To find, tap the ? on the top right of home screen)',
  'feedback.buildPrompt': 'App Version',
  'feedback.categoryPrompt': 'What would you like to do today?',
  'feedback.correction': 'Submit a Correction',
  'feedback.correctionMessageInfo':
    '(How did you expect the information to appear? Is there incorrect information currently displaying?)',
  'feedback.correctionMessagePrompt': 'Summary of Correction',
  'feedback.email': 'Email',
  'feedback.header': 'Feedback Form',
  'feedback.iOS': 'iOS Mobile App',
  'feedback.iOSReproduceInfo':
    '(e.g. Log in, search by barcode scan, tap beer result, expand expert rating panel, submit expert rating)',
  'feedback.mediumPrompt': 'Where did you experience this issue?',
  'feedback.reproducePrompt':
    'What steps could we take to reproduce this issue our end?',
  'feedback.submit': 'Submit',
  'feedback.submitted': 'Thank you for submitting your feedback!',
  'feedback.suggestion': 'Make a Suggestion',
  'feedback.suggestionMessageInfo': ' ',
  'feedback.suggestionMessagePrompt':
    'What would you like to see from RateBeer?',
  'feedback.url': 'URL',
  'feedback.username': 'Username',
  'feedback.web': 'Website',
  'feedback.webReproduceInfo':
    '(e.g Log in, visit ratebeer.com/community, click on link in sidebar)',
  'footer.FAQs': 'FAQs',
  'footer.RBBest': 'RateBeer Best',
  'footer.TOS': 'Terms of Service',
  'footer.aboutRateBeer': 'About RateBeer',
  'footer.aboutUs': 'About Us',
  'footer.account': 'Account',
  'footer.addBeer': 'Add a Beer',
  'footer.addBrewer': 'Add a Brewer',
  'footer.addEvent': 'Add an Event',
  'footer.addPlace': 'Add a Place',
  'footer.addPremium': 'Add Premium',
  'footer.addToRB': 'Add to RateBeer',
  'footer.contactUs': 'Contact Us',
  'footer.copyright': 'All Rights Reserved.',
  'footer.developers': 'Developers',
  'footer.editAccount': 'Edit Account',
  'footer.privacy': 'Privacy Policy',
  'The most comprehensive ratings and reviews of beers from around the world':
    'The most comprehensive ratings and reviews of beers from around the world',
  'footer.theBest': 'The Best',
  'footer.top50Beers': 'The Top 50 Beers',
  'footer.topReviewers': 'Top Reviewers',
  glasscopy1:
    'Tall, thin and footed with a short stem. Flutes are delicate and show off a beer’s body. These vessels are ideal for light and sparkling beers.',
  glasscopy10:
    'Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.',
  glasscopy11:
    'A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.',
  glasscopy12:
    'This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!',
  glasscopy13:
    'Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.',
  glasscopy14:
    'A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.',
  glasscopy15:
    'Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.',
  glasscopy16:
    'A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.',
  glasscopy17:
    'Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.',
  glasscopy18:
    'A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.',
  glasscopy19: 'A small cup, often fluted at the edge, and larger than ochoko.',
  glasscopy2:
    'Short glasses, holds around 12oz. of beer. Slightly wider at the mouth than the foot, with gradual, evenly sloping sides. Great basic glass, well-suited for pale lagers.',
  glasscopy20:
    'A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.',
  glasscopy21:
    'Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.',
  glasscopy3:
    'Designed to be drained in a few gulps. These glasses allow for billowing heads to form. Neither are ideal for developing aroma or discerning subtleties.',
  glasscopy4:
    'The American microbrewer’s standard. Gently sloped 16oz. glass made for session-type beers. Typically used to serve Ambers and English & American pales.',
  glasscopy5:
    'Similar to a shaker but often straight-sided, usually with an additional flair or bulb to retain aroma. These glasses are often marked to measure an imperial pint.',
  glasscopy6:
    'A classic in North America, the dimpled mug is large and has a handle. It is convex, with the mouth larger than the base. Dimples make appreciating the appearance of the beer difficult, but the wide mouth releases the aroma nicely. Commonly used for raunchy lagers.',
  glasscopy7:
    'Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.',
  glasscopy8:
    'A small, almost straight-sided glass sits on an inch-long stem and foot. The basic footed pilsner has a slightly bulbous bottom and narrower mouth, which makes it better for drinking than for smelling, and places the most emphasis on the appearance. It has a bit more style than some other glasses, so it is best used for pilsners, and decent cream or golden ales than for the lowliest lagers.',
  glasscopy9:
    'Often a favorite of top tasters. The Duvel glass is a well-known variant of the tulip style, and the tasting glass in the RateBeer logo is an almost perfect example.',
  'Account Settings': 'Account Settings',
  Community: 'Community',
  Events: 'Events',
  Forums: 'Forums',
  'Log In': 'Log In',
  'Log Out': 'Log Out',
  'Manage Breweries Followed': 'Manage Breweries Followed',
  'Manage People Followed': 'Manage People Followed',
  'header.my2017': 'My year in beer',
  'My Beer Cellar': 'My Beer Cellar',
  'My Beer Ratings': 'My Beer Ratings',
  'My Beer Styles': 'My Beer Styles',
  'My Breweries': 'My Breweries',
  'My Countries & States': 'My Countries & States',
  'My Favorites': 'My Favorites',
  'Latest Activity': 'Latest Activity',
  'My Place Ratings': 'My Place Ratings',
  'My Profile': 'My Profile',
  'New Releases': 'New Releases',
  Beers: 'Beers',
  Brewers: 'Brewers',
  Places: 'Places',
  Ratings: 'Ratings',
  'Sign Up': 'Sign Up',
  'home.Recommended for You': ' Recommended for You',
  'home.Top Beers by Style': 'Top Beers by Style',
  'home.Top Rated Beers Nearby': 'Top Rated Beers Nearby',
  'home.amber-ale': 'Amber Ale',
  'home.american-pale-ale': 'American Pale Ale',
  'home.downloadApp': 'Download the App',
  'home.downloadBlurb':
    'Take RateBeer with you, wherever you are! We offer great features to help you find new beers you’ll love.',
  'home.golden-ale-blond-ale': 'Golden Ale/Blond Ale',
  'home.imperial-double-ipa': 'Imperial IPA',
  'home.imperial-stout': 'Imperial Stout',
  'home.india-pale-ale': 'India Pale Ale (IPA)',
  'home.messageSent': 'Message Sent!',
  'home.porter': 'Porter',
  'home.retailers': 'Retailers',
  'home.saison': 'Saison',
  'home.searchPrompt': 'Find Beers, Breweries or Bars…',
  'home.shopOnline': 'Shop Online',
  'home.sour-wild-ale': 'Sour/Wild Ale',
  'home.stout': 'Stout',
  'home.textLink': 'Text Link',
  'home.top50Beers': 'Top 50 Beers',
  'home.worldOfBeer': 'Your guide to the world of beer',
  'hour ago': 'hour ago',
  'hours ago': 'hours ago',
  'landing.TOS': 'Terms of Service',
  'landing.feedback':
    'This is our first try at a RateBeer app.{br}Help us make it even better!',
  'landing.h1': "The world's #1 beer rating{br}website is now an app!",
  'landing.h2': 'Now you can rate{br}from the palm of your hand',
  'landing.label': 'ratings',
  'landing.li1': 'Search our database of 500,000+ beers',
  'landing.li2': 'Rate, review, and share',
  'landing.li3': "Keep track of every beer you've tasted",
  'landing.privacy': 'Privacy Policy',
  'landing.review':
    'RateBeer is the most reliable source of beer and brewer information on the internet.',
  'landing.rightsReserved': 'All Rights Reserved.',
  'landing.submit': 'Submit Feedback',
  'landing.visit': 'Visit ratebeer.com',
  'minute ago': 'minute ago',
  'minutes ago': 'minutes ago',
  'month ago': 'month ago',
  'months ago': 'months ago',
  'place.addBeerIds': 'Add Beer IDs',
  'place.addBeers': 'Add Beers',
  'place.addEvent': 'Add Event',
  'place.ambiance': 'Ambiance',
  'place.available': 'Available',
  'place.beerMenu': 'Beer Menu',
  'place.close': 'Close',
  'place.customizeShelftags': 'Customize Shelftags',
  'place.editBeers': 'Edit Beers',
  'place.editPlace': 'Edit Place',
  'place.flights': 'Flights',
  'place.food': 'Food',
  'place.foodMenu': 'Food Menu',
  'place.getDirection': 'Get Directions',
  'place.hours': 'Hours',
  'place.lessInfo': 'Less Info',
  'place.map': 'Map',
  'place.moreInfo': 'More Info',
  'place.overall': 'Overall',
  'place.phone': 'Phone Number',
  'place.printShelftags': 'Print Shelftags',
  'place.selection': 'Selection',
  'place.sendToPhone': 'Send To Phone',
  'place.service': 'Service',
  'place.shelftags': 'Shelftags',
  'place.showBreakdown': 'Score breakdown',
  'place.includePrivate': '+ Quick ratings',
  'place.value': 'Value',
  'place.wifi': 'Free Wifi',
  'rate.BOTTLE': 'Bottle',
  'rate.CAN': 'Can',
  'rate.CASK': 'Cask',
  'rate.TAP': 'Tap',
  'rate.commentPlaceholder': 'Add taste notes, descriptions, etc…',
  'rate.infoSubtitle':
    'To ensure the quality of our beer scores we only include reviews that are detailed',
  'rate.locationPlaceholder': 'Add Location',
  'rate.nearbyPlaces': 'Locations Nearby',
  'rate.ourRatingSystem': 'Our Rating System',
  'rate.private1': '0 - 74 characters',
  'rate.private2': 'Saved for personal use',
  'rate.private3': "Doesn't contribute to overall score",
  'rate.privateLabel': 'PRIVATE',
  'rate.public1': '75 + characters ',
  'rate.public2': 'Contributes to the overall beer score',
  'rate.public3': 'Visible to the community',
  'rate.publicLabel': 'PUBLIC',
  'rate.save': 'Save',
  'rate.searchResults': 'Results',
  'rate.servedIn': 'Served In',
  'rate.totalScore': 'TOTAL SCORE',
  'search.advanced': 'Advanced Search',
  'search.beerCaps': 'BEERS',
  'search.beerHeader': 'Beers',
  'search.beerSearchFor': 'Type in the search bar to find Beers',
  'search.brewersCaps': 'BREWERS',
  'search.brewersHeader': 'Brewers',
  'search.brewersSearchFor': 'Type in the search bar to find Brewers',
  'search.closestMatch': 'Closest Match',
  'search.for': 'Search for',
  'search.in': 'in',
  'search.mostRatings': 'Most Ratings',
  'search.myRating': 'My Rating:',
  'search.noResults': 'No results for ',
  'search.pCaps': 'PLACES',
  'search.pHeader': 'Places',
  'search.pSearchFor': 'Type in the search bar to find Places',
  'Search for Beers, Breweries, or Bars...':
    'Search for Beers, Breweries, or Bars…',
  'search.resultFor': 'result for',
  'search.resultLabelStr': 'Ratings',
  'search.resultsFor': 'results for',
  'search.sortBy': 'Sort By',
  'search.sortByCaps': 'SORT BY',
  'search.topRated': 'Top Rated',
  'search.userHeader': 'Users',
  'search.userSearchFor': 'Type in the search bar to find Users',
  'second ago': 'second ago',
  'seconds ago': 'seconds ago',
  '{rateCount} ratings': '{rateCount} ratings'
}
