export default {
  "Account Settings": "Kontoeinstellungen",
  "Add description": "Beschreibung hinzufügen",
  "Add location": "Standort hinzufügen",
  "Appearance": "Aussehen",
  "Apply to sell with us": "Möchten Sie bei uns verkaufen?",
  "Are you sure you want to delete your review?": "Möchtest du deine Rezension wirklich löschen?",
  "Aroma": "Aroma",
  "Associated Brewer": "Verknüpfte Brauerei",
  "Available here": "Hier erhältlich",
  "Available to buy from {count} trusted retail partners": "Erhältlich bei {count} zuverlässigen Händlerpartnern",
  "Average": "Durchschnitt",
  "Beer name": "Name des Biers",
  "Beer styles": "Biersorten",
  "Beers Spotted Here": "Hier gespottete Biere",
  "Bottles {bottlesCount}": "Flaschen {bottlesCount}",
  "Buy from": "Kaufen bei",
  "Cancel": "Abbrechen",
  "Community": "Community",
  "Copied to clipboard": "In Zwischenablage kopiert",
  "Copy to clipboard": "In Zwischenablage kopieren",
  "Country of origin": "Herkunftsland",
  "Events": "Veranstaltungen",
  "Filter": "Filter",
  "Find a beer or brewer": "Ein Bier oder einen Brauer suchen",
  "Find a country": "Ein Land suchen",
  "Find a style": "Eine Sorte suchen",
  "Follow on Social Media": "Auf Social Media folgen",
  "Forums": "Foren",
  "From {price} per {serve}": "Ab {price} pro {serve}",
  "Latest Activity": "Letzte Aktivität",
  "Log In": "Anmelden",
  "Log Out": "Abmelden",
  "Manage Breweries Followed": "Brauereien verwalten, denen du folgst",
  "Manage People Followed": "Menschen verwalten, denen du folgst",
  "Mouthfeel": "Mundgefühl",
  "My Beer Cellar": "Mein Bierkeller",
  "My Beer Ratings": "Meine Bewertungen von Bieren",
  "My Beer Styles": "Meine Biersorten",
  "My Breweries": "Meine Brauereien",
  "My Countries & States": "Meine Länder & Staaten",
  "My Favorites": "Meine Favoriten",
  "My Place Ratings": "Meine Bewertungen von Orten",
  "My Profile": "Mein Profil",
  "New Releases": "Neuheiten",
  "No results found for": "Keine Ergebnisse für {query} gefunden",
  "Overall": "Gesamt",
  "Places": "Orte",
  "Rate this beer on these attributes": "Bewerte dieses Bier zu diesen Eigenschaften",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "RateBeer arbeitet mit den größten Händlern zusammen, um Bierliebhabern ein unschlagbares Bierangebot zu den besten Preisen zu bieten",
  "Ratings": "Bewertungen",
  "Read more": "Mehr lesen",
  "Retailers": "Händler",
  "Review this place": "Diesen Ort bewerten",
  "Save": "Speichern",
  "Search beers spotted here": "Hier gespottete Biere suchen",
  "Search for Beers, Breweries, or Bars...": "Suche nach Bier, Brauereien oder Bars …",
  "Seasonal beer": "Saisonspezifisches Bier",
  "Shop now": "Jetzt einkaufen",
  "Sign Up": "Registrieren",
  "Similar beers": "Ähnliche Biere",
  "Taps {tapsCount}": "Hähne {tapsCount}",
  "Taste": "Geschmack",
  "The most comprehensive ratings and reviews of beers from around the world": "Die umfassendsten Bewertungen und Rezensionen von Bieren weltweit",
  "This score could not be updated": "Diese Punktzahl konnte nicht aktualisiert werden",
  "This score has been updated": "Diese Punktzahl wurde aktualisiert",
  "Total score": "GESAMTPUNKTZAHL",
  "View beer menu": "Bierkarte ansehen",
  "View food menu": "Speisekarte ansehen",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Sieh dir Angebote von {count} {count, plural, one {retailer} other {retailers}} an",
  "We apologise for the delay you are experiencing.": "Wir bitten um Entschuldigung für die Verzögerung.",
  "We are currently updating the website and expect this page to be back shortly.": "Wir aktualisieren gerade die Website und erwarten die Seite schon bald wieder funktionsfähig zurück.",
  "Your review has been updated": "Deine Bewertung wurde aktualisiert",
  "add.abv": "Alkoholgehalt in %",
  "add.abvPlaceholder": "(z. B. 11 %)",
  "add.addBeer": "Neues Bier hinzufügen",
  "add.addBrewery": "Neue Brauerei hinzufügen",
  "add.autumn": "Herbst",
  "add.beerGuidelines": "Richtlinien",
  "add.beerGuidelines1": "Alle Biere müssen aktuell hergestellt werden und im Handel erhältlich sein.",
  "add.beerGuidelines2": "Die Rezepturen müssen einzigartig sein. Cask, Nitro, usw. und Rezeptvariationen sind nicht zulässig.",
  "add.beerGuidelines3": "Cider mit Fruchtgeschmack müssen mindestens 50 % gärfähigen Apfel enthalten und einen Alkoholgehalt von mehr als 2 % aufweisen.",
  "add.beerGuidelines4": "Den Namen des Herstellers vor dem Namen des Bieres angeben, z. B. {correctExample} statt {incorrectExample}.",
  "add.beerImage": "Bild hinzufügen",
  "add.beerName": "Name des Biers",
  "add.beerNameExplanation": "Die Namenskonvention von RateBeer umfasst den Namen der Brauerei und des Biers.",
  "add.beerNameInfo": "(Brauer einschließen)",
  "add.beerNamePlaceholder": "(z. B. Elysian Space Dust)",
  "add.breweryName": "Name der Brauerei",
  "add.breweryNamePlaceholder": "(z. B. Elysian)",
  "add.contractBrewer": "Gebraut von",
  "add.contractBrewerPlaceholder": "Brauereien suchen …",
  "add.contractBrewerPrompt": "Dies ist ein Vertragsbier",
  "add.description": "Produktbeschreibung",
  "add.descriptionPlaceholder": "Was steht auf dem Etikett?",
  "add.duplicateFound": "Duplikat gefunden",
  "add.duplicateMessage": "Ist das das Bier, das du hinzufügen möchtest?",
  "add.ibuPlaceholder": "(z. B. 57)",
  "add.imageInstructions": "*Bilder von Dosen oder Flaschen verwenden",
  "add.invalid": "Ungültig",
  "add.newBeer": "Neues Bier hinzufügen",
  "add.noBrewery": "Du kannst die gewünschte Brauerei nicht finden?",
  "add.optional": "Optional",
  "add.removeBeerImage": "Bild entfernen",
  "add.seasonalPlaceholder": "Ist das ein saisonales Bier?",
  "add.series": "Reihe",
  "add.signInToAddBeer": "Melde dich an, um ein Bier hinzuzufügen",
  "add.special": "Sonderausgabe",
  "add.spring": "Frühjahr",
  "add.stylePlaceholder": "Sorte auswählen",
  "add.summer": "Sommer",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Strichcode-Nummern)",
  "add.upcInstructions": "Alle 8, 12, 13 oder 14 Zahlen angeben",
  "add.upcPlaceholder": "Alle Zahlen auf dem Barcode",
  "add.view": "Anzeigen",
  "add.winter": "Winter",
  "all.adblock": "Anscheinend ist ein Werbeblocker installiert. Bitte denk daran, RateBeer.com als eine Ausnahme hinzuzufügen. Danke und Prost!",
  "all.ago": "vor {date}",
  "all.back": "Back",
  "all.beer": "Bier",
  "all.beers": "Biere",
  "all.cider": "Cider",
  "all.ciders": "Ciders",
  "all.cookies": "Wir verwenden Cookies",
  "all.error": "Hoppla, es gab einen Netzwerkfehler",
  "all.joinBody": "Nutze all die Vorteile, die RateBeer zu bieten hat. Lies ausführliche Rezensionen, schau dir detaillierte Statistiken an und finde heraus, was unsere Community zu sagen hat.",
  "all.joinTitle": "Werde Teil unserer Community!",
  "all.learnMore": "Mehr erfahren",
  "all.loading": "Lädt …",
  "all.mead": "Met",
  "all.meads": "Met",
  "all.noSuchBeer": "Ein solches Bier befindet sich nicht in der Datenbank. Suche nach einem anderen Bier.",
  "all.ok": "Okay",
  "all.sake": "Sake",
  "all.sakes": "Sakes",
  "banner.free": "Kostenlos – Google Play",
  "banner.getIt": "Jetzt holen",
  "banner.officialApp": "Offizielle App",
  "beer.aboutBeer": "Über dieses Bier",
  "beer.aboutCider": "Über dieses Cider",
  "beer.aboutMead": "Über dieses Met",
  "beer.aboutSake": "Über dieses Sake",
  "beer.abv": "Alkoholgehalt",
  "beer.aka": "Auch bekannt als",
  "beer.aliasBeer": "Alias-Bier",
  "beer.aliasCopy": "Die Brauerei vertreibt das gleiche oder ein sehr ähnliches Produkt unter mehr als einem Namen. Dies kann vorkommen, wenn die Brauerei dieses Bier unter verschiedenen Namen in verschiedenen Ländern vertreibt. Es könnte auch sein, dass die Brauerei einfach im Laufe der Zeit den Namen des Bieres, nicht jedoch die Rezeptur geändert hat.",
  "beer.allStyles": "Alle Biersorten für ",
  "beer.appearance": "Aussehen",
  "beer.applyToSellWithUs": "Als Verkäufer bewerben",
  "beer.aroma": "Aroma",
  "beer.autumn": "Herbst",
  "beer.availability": "Verfügbarkeit",
  "beer.availableAt": "Verfügbar an",
  "beer.averageRatings": "Durchschnittliche Bewertungen",
  "beer.bottled": "In Flaschen",
  "beer.brewedBy": "Gebraut von ",
  "beer.brewedFor": "Gebraut für ",
  "beer.buyFrom": "Kaufen bei",
  "beer.calories": "DURCHSCHN. KCAL.",
  "beer.clearRating": "Löschen",
  "beer.copy": "Kopieren",
  "beer.delete": "Bier/Bewertungen löschen",
  "beer.deleteAlias": "Alias löschen",
  "beer.deleteConfirm": "Ja, löschen",
  "beer.deleteOption": "Löschen",
  "beer.deleteRating": "Bewertung löschen?",
  "beer.distribution": "Vertrieb",
  "beer.edit": "Bearbeiten",
  "beer.editAlias": "Alias hinzufügen/bearbeiten",
  "beer.editBarcodes": "Barcodes hinzufügen/bearbeiten",
  "beer.editBeer": "Dieses Bier bearbeiten",
  "beer.editPicture": "Bild hinzufügen/bearbeiten",
  "beer.editTags": "Hinzufügen/Bearbeiten Tags",
  "beer.findFriendsText": "Suche Freunde, um euch über verschiedene Getränke auszutauschen und ihre Empfehlungen nachzuverfolgen",
  "beer.findFriendsTitle": "Freunde suchen",
  "beer.findIt": "Jetzt finden",
  "beer.findItNearYou": "In deiner Nähe finden",
  "beer.fix": "Punktzahl korrigieren",
  "beer.flavor": "Geschmack",
  "beer.from": "von ",
  "beer.highestScore": "Höchste Punktzahl",
  "beer.howItWorks": "So funktioniert es",
  "beer.howItWorksContent": "Wir arbeiten hart daran, die besten Händler mit dem größten Angebot und den besten Preisen zu finden, damit du immer das Bier genießen kannst, das dir schmeckt",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Verkäufer suchen",
  "beer.locations": "{count} {count, plural, one {Standort} other {Standorten}}",
  "beer.locationsNearby": "{count} {count, plural, one {Standort in der Nähe} other {Standorten in der Nähe}}",
  "beer.mostLiked": "Am beliebtesten",
  "beer.mostRecent": "Neueste",
  "beer.mouthfeel": "Empfinden",
  "beer.moveRating": "Bewertung verschieben",
  "beer.myFriends": "Meine Freunde",
  "beer.myReview": "Meine Rezension",
  "beer.noDescription": "Keine Beschreibung verfügbar",
  "beer.noRating": "Keine Bewertung",
  "beer.noReviewsText": "Sag allen, was du denkst. Gib als erster eine Bewertung ab und verfasse eine Rezension!",
  "beer.noReviewsTitle": "Hast du dieses Bier probiert?",
  "beer.onTap": "Vom Fass",
  "beer.outOfProd": "NICHT MEHR IN PRODUKTION",
  "beer.overall": "Gesamt",
  "beer.per": "pro",
  "beer.privacyNotice": "Wenn Sie oben auf den / die Shop-Link (s) klicken, werden Sie auf die Website des Einzelhändlers weitergeleitet, und von dort aus gelten anschließend deren Datenschutzbestimmungen.",
  "beer.private": "PRIVAT",
  "beer.quickRate": "Schnellbewertung abgeben",
  "beer.quickRateSaved": "Schnellbewertung gespeichert",
  "beer.quickRated": "Schnellbewertung abgegeben: ",
  "beer.rateAndReview": "Bewerten und rezensieren",
  "beer.rateIt": "Bier probiert? Bewerte es!",
  "beer.ratedBy": "Bewertet von",
  "beer.ratingPrivate": "Private Bewertung",
  "beer.ratingPublic": "Öffentliche Bewertung",
  "beer.readMore": "Mehr lesen",
  "beer.reviews": "Rezensionen",
  "beer.reviewsRatings": "Rezensionen & Bewertungen",
  "beer.saveRating": "Diese Bewertung speichern!",
  "beer.saveRatingText": "Erstelle ein Konto, um Bewertungen zu speichern, Rezensionen von Fachleuten zu lesen, detaillierte Statistiken mit Bewertungen und Rezensionen einzusehen und vieles mehr.",
  "beer.seasonal": "Saisonspezifisch",
  "beer.seasonalLabel": "SAISONSPEZIFISCH",
  "beer.seeAll": "Alle anzeigen",
  "beer.seeAllXReviews": "Alle {count} Rezensionen anzeigen",
  "beer.sendCorrections": "Berichtigungen senden",
  "beer.series": "Reihe",
  "beer.serveIn": "Servieren in",
  "beer.share": "Teilen",
  "beer.shareTutorial": "Teilen, Korrekturen senden, Bilder hinzufügen",
  "beer.shelfTags": "Bewertung anzeigen",
  "beer.shopNow": "Jetzt einkaufen",
  "beer.showLess": "weniger anzeigen",
  "beer.signInToFavorite": "Melde dich an, um dieses Bier als Favorit zu speichern",
  "beer.signInToFind": "Melde dich an, um dieses Bier zu suchen.",
  "beer.signInToLikeReview": "Melde dich an, um anzugeben, dass dir diese Rezension gefällt",
  "beer.similarBeer": "Ähnliche Biere",
  "beer.similarCider": "Ähnliche Ciders",
  "beer.similarMead": "Ähnliche Met",
  "beer.similarSake": "Ähnliche Sake",
  "beer.special": "Spezial",
  "beer.spring": "Frühjahr",
  "beer.style": "Stil ",
  "beer.summer": "Sommer",
  "beer.tags": "Tags",
  "beer.top50": "Top 50",
  "beer.topRaters": "Top-Bewerter",
  "beer.totalRetailers": "Dieses Bier wird von {count} verifizierten Drittanbietern verkauft",
  "beer.undoRating": "Bewertung rückgängig machen",
  "beer.unrateable": "Dieses Bier kann nicht bewertet werden",
  "beer.verified": "ÜBERPRÜFT",
  "beer.winter": "Winter",
  "beer.writeAReview": "Eine Rezension schreiben",
  "beer.writeFullReview": "Ausführliche Rezension schreiben",
  "beer.yourReview": "Deine Rezension",
  "error.refresh": "Neu laden",
  "favorites.instructions": "Ein Bier, das dir gefällt, kannst du mit dem Button für später speichern. Deine gespeicherten Biere findest du auf allen Geräten an einem Ort..",
  "favorites.noSavedBeers": "Du hast noch keine Biere gespeichert",
  "favorites.remove": "Entfernen",
  "favorites.removeConfirmation": "Ja, entfernen",
  "favorites.removePrompt": "Aus den Favoriten entfernen?",
  "favorites.saved": "Gespeichert",
  "favorites.savedConfirmation": "In den Favoriten gespeichert",
  "favorites.signInToViewFavorites": "Melde dich an, um dieses Bier als Favorit zu speichern",
  "favorites.viewAll": "Alle anzeigen",
  "feedback.android": "Mobile App für Android",
  "feedback.androidReproduceInfo": "(z. B. Anmelden, mit Barcode-Scan suchen, in den Suchergebnissen auf ein Bier tippen, Expertenbewertung aufklappen, Expertenbewertung einsenden)",
  "feedback.bug": "Einen Fehler melden",
  "feedback.bugMessageInfo": "(Welche Ergebnisse hast du dir erwartet? Welche dieser Erwartungen wurden erfüllt bzw. nicht erfüllt?)",
  "feedback.bugMessagePrompt": "Zusammenfassung des Fehlers",
  "feedback.buildInfo": "(z. B. App-Version 1.7.0. Um deine App-Version zu finden, tippe auf das ? oben rechts am Startbildschirm)",
  "feedback.buildPrompt": "App-Version",
  "feedback.categoryPrompt": "Was möchtest du heute tun?",
  "feedback.correction": "Eine Berichtigung einsenden",
  "feedback.correctionMessageInfo": "(Wie hast du dir erwartet, dass die Informationen angezeigt werden? Werden derzeit falsche Informationen angezeigt?)",
  "feedback.correctionMessagePrompt": "Zusammenfassung der Berichtigung",
  "feedback.email": "E-Mail",
  "feedback.header": "Feedback-Formular",
  "feedback.iOS": "Mobile App für iOS",
  "feedback.iOSReproduceInfo": "(z. B. Anmelden, mit Barcode-Scan suchen, in den Suchergebnissen auf ein Bier tippen, Expertenbewertung aufklappen, Expertenbewertung einsenden)",
  "feedback.mediumPrompt": "Wo ist dieses Problem aufgetreten?",
  "feedback.reproducePrompt": "Mit welchen Schritten könnten wir das Problem unsererseits nachstellen?",
  "feedback.submit": "Einsenden",
  "feedback.submitted": "Vielen Dank für dein Feedback!",
  "feedback.suggestion": "Einen Vorschlag machen",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Was könnten wir auf RateBeer noch verbessern? Was wünschst du dir?",
  "feedback.url": "URL",
  "feedback.username": "Benutzername",
  "feedback.web": "Website",
  "feedback.webReproduceInfo": "(z. B. Anmelden, ratebeer.com/community besuchen, auf Link in Seitenleiste klicken)",
  "footer.FAQs": "Häufig gestellte Fragen",
  "footer.RBBest": "RateBeer Beste",
  "footer.TOS": "Nutzungsbedingungen",
  "footer.aboutRateBeer": "Über RateBeer",
  "footer.aboutUs": "Über uns",
  "footer.account": "Konto",
  "footer.addBeer": "Ein Bier hinzufügen",
  "footer.addBrewer": "Eine Brauerei hinzufügen",
  "footer.addEvent": "Eine Veranstaltung hinzufügen",
  "footer.addPlace": "Einen Ort hinzufügen",
  "footer.addPremium": "Premium hinzufügen",
  "footer.addToRB": "Zu RateBeer Hinzufügen",
  "footer.contactUs": "Kontaktiere uns",
  "footer.copyright": "Alle Rechte vorbehalten.",
  "footer.developers": "Entwickler",
  "footer.editAccount": "Konto bearbeiten",
  "footer.privacy": "Datenschutzerklärung",
  "footer.theBest": "Das Beste",
  "footer.top50Beers": "Die besten 50 Biere",
  "footer.topReviewers": "Top-Bewerter",
  "glasscopy1": "Langgezogen, schmal und mit einem kurzen Stiel. Flöten sind filigran und betonen den Körper des Bieres. Diese Gefäße sind ideal für leichte und spritzige Biere.",
  "glasscopy10": "Ein etwas obskures Glas, das einen kurzen Stiel mit Fuß hat und in Belgien für Scotch Ales verwendet wird. Es ist unten gewölbt und geht nach oben konisch gerade auseinander. Die breite Öffnung erschwert die Verwendung, ein Phänomen das auch bei Martinigläsern vorkommt, und besonders hilfreich für das Aroma ist die breite Öffnung auch nicht.",
  "glasscopy11": "Ein 91 cm hohes, langgezogenes Glas, das unten oft kugelrund geformt ist und oben auseinandergewölbt. Das Glas benötigt einen hölzernen Ständer. Das bekannteste Markenbeispiel ist Pawel Kwak (wobei Pawel Kwak kleiner ist).",
  "glasscopy12": "Dieses klassische deutsche Weizenbierglas ist schmal, hoch, und wölbt sich von einer breiter gewölbten Öffnung zu einem schmaleren Gebiet Richtung unten, wieder gefolgt von einem breiteren Boden. Die Form erlaubt eine große Schaumkrone und mehr Lichtdurchflutung, was zu einem “Leuchteffekt” führt. In solchen Gläsern wird das Bier schnell warm, also trinkt aus!",
  "glasscopy13": "Eine gedrungenere und stilbetontere Version des weniger eleganten Shakers. Ein bekanntes Beispiel ist das Hoegaarden-Glas.",
  "glasscopy14": "Ein niedriges Glas mit flachem Boden in Zylinderform, leicht kegelig oder geradwandig. Die Haupteigenschaft dieses Glases ist, viel Platz für Schaum zu lassen. Beispiele mit Fuß können einen kugelrunden Boden haben und eher wie ein Kelch aussehen.",
  "glasscopy15": "Auch Kelch genannt. Dieses wunderschöne Glas mit Fuß hat die Form einer Schüssel, die oft eine gerade oder nur leicht gekrümmte Kante daraufgesetzt hat. Die Form betont den üppigen Schaum belgischer Bierstile und sorgt für eine große Oberfläche, um Aromen freizusetzen.",
  "glasscopy16": "Ein zylindrisch geformtes Glas mit Stiel und Fuß. Bekannte Beispiele sind Celebrator und Harvey Porter.",
  "glasscopy17": "Kugelförmig mit mit Stiel, Fuß und einer schmalen Öffnung. Viele betrachten diese Glasform als die beste, um Aromen einzufangen. Oft wird dieses Glas in einer kleinen Va riante verwendet, um kleine Portionen zu servieren.",
  "glasscopy18": "Ein kleiner Becher, der in unzähligen Varianten, Farben und Formen vorkommt. Diese Becher sind typischerweise oben etwas breiter, um dem Sake zu erlauben, seine Aromen nach oben zu verströmen.",
  "glasscopy19": "Ein kleiner Becher, der am Rand oft flötenförmig ist; größer als ein Ochoko.",
  "glasscopy2": "Kleine Gläser, die etwa 330ml fassen. Meist sind sie oben etwas breiter als unten und haben leicht geneigte Seiten. Ein gutes und simples Glas, das sich gut für helle Lagerbiere eignet.",
  "glasscopy20": "Eine quadratische Zedernholzbox, die 180ml fasst und ursprünglich zum Abmessen von Reis gedacht war. Heutzutage sind Masu selten geworden, ihre Holzaromen überdecken die subtilen Geschmäcker von heutigem Premium Sake.",
  "glasscopy21": "Ein Papiersack, der oft dazu verwendet wird, Malt Liquor oder schlechtere Biere zu verstecken. Manche Biere werden heutzutage in Paper Bags verkauft.",
  "glasscopy3": "Entworfen, um in wenigen Schlucken geleert zu werden. Diese Gläser erlauben dem Schaum, sich über den Glasrand zu erheben. Beiden Gläserformen sind nur mäßig dafür geeignet, um das Aroma eines Bieres einzufangen oder Feinheiten zu unterscheiden.",
  "glasscopy4": "Der Standard unter amerikanischen Microbreweries: Ein leicht geneigtes 500ml-Glas, das für Biere, die in größeren Mengen getrunken werden können, gemacht wurde. Dieses Glas wird typischerweise verwendet, um Amber Ales oder Pale Ales zu servieren.",
  "glasscopy5": "Ähnliche Form wie der Shaker, aber oft ohne Neigung, dafür mit einer zusätzlichen Krümmung, um das Aroma einzufangen. Diese Gläser haben oft einen Eichstrich, um ein imperial Pint abzumessen (568ml).",
  "glasscopy6": "Ein Klassiker in Nordamerika: Ein großer Krug mit Henkel. Er ist konvex geformt und die Öffnung ist breiter als der Boden. Das Grübchenmuster im Glas erschwert es, das Aussehen des Bieres wertzuschätzen, dafür verbreitet die breite Öffnung das Aroma gut. Wird häufig für versiffte Lager verwendet.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "Ein kleines, nahezu geradewandiges Glas, das auf einem etwa 2-3cm langen Stiel mit Fuß. Das Standardglas dieser Form hat einen leicht gewölbten Boden und eine schmale Öffnung. Das sorgt dafür dass solche Gläser sich besser zum trinken als zum riechen eignen und das Aussehen des Bieres sehr betonen. Es hat etwas mehr Stil als so manch anderes Glas und wird am besten für Pilsner, Cream Ales und Golden Ales verwendet als für die einfachsten Lager.",
  "glasscopy9": "Das Lieblingsglas von so manchen Spitzen-Testern. Ein bekanntes Beispiel für diese Glasform ist das Duvelglas und das Tastingglas, das im Ratebeer-Logo zu finden ist.",
  "header.my2017": "Mein jahr im bier",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Die besten Biere nach Sorte",
  "home.Top Rated Beers Nearby": "Die Biere mit der besten Bewertung in der Nähe",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "App herunterladen",
  "home.downloadBlurb": "Nimm RateBeer überall hin mit! Wir bieten dir tolle Funktionen, mit denen du neue Biere finden kannst, die du lieben wirst.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Nachricht gesendet!",
  "home.porter": "Porter",
  "home.retailers": "Händler",
  "home.saison": "Saison",
  "home.searchPrompt": "Finde Biere, Brauerein oder Bars…",
  "home.shopOnline": "Online kaufen",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Text-Link",
  "home.top50Beers": "Die besten 50 Biere",
  "home.worldOfBeer": "Dein Leitfaden zur Welt des Bieres",
  "landing.TOS": "Nutzungsbedingungen",
  "landing.feedback": "Dies ist die erste Version der RateBeer-App.{br}Hilf uns, sie in Zukunft noch zu verbessern!",
  "landing.h1": "Die weltbeste Website für die Bewertung von Bieren{br}ist jetzt auch als App verfügbar!",
  "landing.h2": "Jetzt kannst du Biere auch{br}direkt auf deinem Handy bewerten",
  "landing.label": "Bewertungen",
  "landing.li1": "Durchsuche unsere Datenbank mit mehr als 500.000 Bieren",
  "landing.li2": "Bewerte und rezensiere Biere und teile deine Meinung mit der Community",
  "landing.li3": "Behalte den Überblick über alle Biere, die du jemals probiert hast",
  "landing.privacy": "DATENSCHUTZERKLÄRUNG",
  "landing.review": "RateBeer ist die zuverlässigste Informationsquelle zu Bieren und Brauereien im ganzen Internet.",
  "landing.rightsReserved": "Alle Rechte vorbehalten.",
  "landing.submit": "Feedback einsenden",
  "landing.visit": "ratebeer.com besuchen",
  "place.addBeerIds": "Bier-IDs hinzufügen",
  "place.addBeers": "Biere hinzufügen",
  "place.addEvent": "Event hinzufügen",
  "place.ambiance": "Atmosphäre",
  "place.available": "Verfügbar",
  "place.beerMenu": "Bierkarte",
  "place.close": "Schließen",
  "place.customizeShelftags": "Regaletiketten personalisieren",
  "place.editBeers": "Biere bearbeiten",
  "place.editPlace": "Ort bearbeiten",
  "place.flights": "Flüge",
  "place.food": "Essen",
  "place.foodMenu": "Speisekarte",
  "place.getDirection": "Wegbeschreibung abrufen",
  "place.hours": "Stunden",
  "place.lessInfo": "Weniger Informationen",
  "place.map": "Karte",
  "place.moreInfo": "Weitere Informationen",
  "place.overall": "Gesamt",
  "place.phone": "Telefonnummer",
  "place.printShelftags": "Regaletiketten drucken",
  "place.selection": "Auswahl",
  "place.sendToPhone": "An Handy senden",
  "place.service": "Service",
  "place.shelftags": "Regaletiketten",
  "place.showBreakdown": "Punktzahl-Aufschlüsselung anzeigen",
  "place.value": "Wert",
  "place.wifi": "WLAN kostenlos",
  "rate.BOTTLE": "Flasche",
  "rate.CAN": "Dose",
  "rate.CASK": "Cask",
  "rate.TAP": "Vom Fass",
  "rate.commentPlaceholder": "Füge Bewertungen für Geschmack, Beschreibungen, usw. hinzu",
  "rate.infoSubtitle": "Um eine hohe Qualität unserer Bierpunktzahlen zu gewährleisten, fließen in diese Punktzahlen nur ausführliche Rezensionen ein.",
  "rate.locationPlaceholder": "Standort hinzufügen",
  "rate.nearbyPlaces": "Standorte in der Nähe",
  "rate.ourRatingSystem": "Unser Bewertungssystem",
  "rate.private1": "0 – 75 Zeichen",
  "rate.private2": "Zur persönlichen Nutzung gespeichert",
  "rate.private3": "Geht nicht in die Gesamtpunktzahl ein",
  "rate.privateLabel": "PRIVAT",
  "rate.public1": "Mehr als 75 Zeichen",
  "rate.public2": "Geht in die Gesamtpunktzahl eines Biers ein",
  "rate.public3": "Für die Community sichtbar",
  "rate.publicLabel": "ÖFFENTLICH",
  "rate.save": "Speichern",
  "rate.searchResults": "Ergebnisse",
  "rate.servedIn": "Serviert in",
  "rate.totalScore": "GESAMTPUNKTZAHL",
  "search.advanced": "Erweiterte Suche",
  "search.beerCaps": "BIERE",
  "search.beerHeader": "Biere",
  "search.beerSearchFor": "Gib Suchbegriffe in die Suchleiste ein, um Biere zu finden",
  "search.brewersCaps": "BRAUER",
  "search.brewersHeader": "Brauer",
  "search.brewersSearchFor": "Gib Suchbegriffe in die Suchleiste ein, um Brauereien zu finden",
  "search.closestMatch": "Ähnlichster Suchtreffer",
  "search.for": "Suchen nach",
  "search.in": "in",
  "search.mostRatings": "Meiste Bewertungen",
  "search.myRating": "Meine Bewertung:",
  "search.noResults": "Keine Ergebnisse für ",
  "search.pCaps": "ORTE",
  "search.pHeader": "Orte",
  "search.pSearchFor": "Gib Suchbegriffe in die Suchleiste ein, um Orte zu finden",
  "search.resultFor": "Ergebnis für",
  "search.resultLabelStr": "Bewertungen",
  "search.resultsFor": "Ergebnisse für",
  "search.sortBy": "Sortieren nach",
  "search.sortByCaps": "SORTIEREN NACH",
  "search.topRated": "Beste Bewertungen",
  "search.userHeader": "Benutzer",
  "search.userSearchFor": "Gib Suchbegriffe in die Suchleiste ein, um Benutzer zu finden"
}
