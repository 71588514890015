import React, { useEffect, Fragment } from 'react'
import { noopTemplate as css } from 'lib/utils'
import { Route, Switch } from 'react-router-dom'
import { loadable } from 'lib/code-splitting'
import ScrollToTop from 'tools/scrollToTop'
import AuthCallback from 'tools/auth/AuthCallback'
import SSO from 'tools/auth/SSO'
import { captureTiming, captureEvent } from 'services/analytics'
import { config } from 'tools/config'
import Jar from 'tools/cookies'
import { sessionFromCookie, sessionFromToken } from './tools/session'
import {
  EVENT_ERROR,
  EVENT_CORRUPT_SESSION,
  EVENT_EXPIRED_JWT
} from 'tools/constants'

import { Header, Footer, PopupBanners, FacebookPixel } from 'components'

import VendorStyles from 'styles/global/vendor'
import GlobalStyles from 'styles/global/util'

import { useSetHeadMeta } from 'services/head-meta'
import { useSetErrorPage } from 'services/error-page'

const SandboxPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "sandbox" */ 'features/sandbox/Page')
})

const Home = loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ './features/homepage/Page')
})

const Favorites = loadable({
  loader: () =>
    import(/* webpackChunkName: "favourites" */ 'features/favorites/Page')
})

const BeerProfile = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "beer-profile" */ './features/beer-profile/Page'
    )
})
const BrewerProfile = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "brewer-profile" */ './features/brewer-profile/Page'
    )
})

const FestivalPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "festival" */ './features/festival/Page')
})
const PlaceProfile = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "place-profile" */ './features/place-profile/Page'
    )
})
const BeerMap = loadable({
  loader: () =>
    import(/* webpackChunkName: "beer-map" */ './features/beer-map/Page')
})
const AddBeer = loadable({
  loader: () =>
    import(/* webpackChunkName: "add-beer" */ './features/add-beer/Page')
})
const EditBeer = loadable({
  loader: () =>
    import(/* webpackChunkName: "edit-beer" */ './features/edit-beer/Page')
})
const GABS2019Page = loadable({
  loader: () =>
    import(/* webpackChunkName: "gabs" */ './features/gabs-2019/Page')
})
const FAQPage = loadable({
  loader: () => import(/* webpackChunkName: "faq" */ './features/faq/Page')
})
const SuperTastersPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "supertasters" */ './features/supertasters/Page'
    )
})
const ShopPage = loadable({
  loader: () => import(/* webpackChunkName: "shop" */ './features/shop/Page')
})
const TopBeers = loadable({
  loader: () =>
    import(/* webpackChunkName: "top-beers" */ './features/top-beers/Page')
})
const ShelfTags = loadable({
  loader: () =>
    import(/* webpackChunkName: "shelf-tags" */ 'features/shelf-tags/Page')
})
const CommunityStandards = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "community-standards" */ 'features/community-standards/Page'
    )
})
const SearchPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "search-page" */ 'features/search/Page')
})
const FeedbackPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "feedback" */ 'features/feedback/Page')
})

const PrivacyPolicyPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "privacy-policy" */ 'features/privacy-policy/Page'
    )
})

const PreviousPrivacyPolicyPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "previous-privacy-policy" */ 'features/privacy-policy/previous/Page'
    )
})

const CookiePolicyPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "cookie-policy" */ 'features/cookie-policy/Page'
    )
})

const OptOutPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "opt-out" */ 'features/opt-out/Page'
    )
})

const EditBrewerPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "edit-brewer" */ 'features/edit-brewer/Page')
})

const AddBrewerPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "add-brewer" */ 'features/add-brewer/Page')
})

const AddPlacePage = loadable({
  loader: () =>
    import(/* webpackChunkName: "add-place" */ 'features/add-place/Page')
})

const EditPlacePage = loadable({
  loader: () =>
    import(/* webpackChunkName: "edit-place" */ 'features/edit-place/Page')
})

const UserProfilePage = loadable({
  loader: () =>
    import(/* webpackChunkName: "user-profile" */ 'features/user-profile/Page')
})

const RateBeerBestPage = loadable({
  loader: () =>
    import(/* webpackChunkName: "ratebeerbest" */ 'features/ratebeerbest/Page')
})

const MyYearInBeerPage = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "my-year-in-beer" */ 'features/my-year-in-beer/Page'
    )
})

const EditProfilePage = loadable({
  loader: () =>
    import(/* webpackChunkName: "my-year-in-beer" */ 'features/profile/Page')
})

const App: React.FC = () => {
  useEffect(() => {
    if (window.performance) {
      const timeSincePageLoad = Math.round(window.performance.now())

      // For debugging purposes
      if (config.ENVIRONMENT === 'qa') {
        console.debug(`Page loaded in (${timeSincePageLoad}) ms`)
      }

      captureTiming({
        category: 'Timing',
        variable: 'load',
        value: timeSincePageLoad,
        label: document.location.href
      })
    }
  }, [])
  return (
    <Fragment>
      <PopupBanners />
      <VendorStyles />
      <GlobalStyles />
      <FacebookPixel />

      <ScrollToTop>
        <Switch>
          <Route path="/phoenix-callback" component={AuthCallback} />
          <Route exact path="/sso" render={props => <SSO {...props} />} />
          <Route exact path="/login" component={SSO} />
          <Route exact path="/signup" component={SSO} />
          {(process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_ENV === 'qa') && (
            <Route path="/sandbox" component={SandboxPage} />
          )}
          <Route
            exact
            path="/beermap"
            render={props => (
              <div className="mt-8">
                <Header />
                <BeerMap {...props} />
              </div>
            )}
          />
          <Route
            exact
            path="/:lang(\D{2})?/supertasters"
            component={SuperTastersPage}
          />

          <Route path="/:lang(\D{2})?/shelf-tags" component={ShelfTags} />

          <Route
            exact
            path="/:lang(\D{2})?/phoenix-profile"
            component={EditProfilePage}
          />

          <Route
            exact
            path="/:lang(\D{2})?/phoenix-edit-brewer/:brewerId?"
            component={EditBrewerPage}
          />

          <Route
            exact
            path="/:lang(\D{2})?/phoenix-add-brewer"
            component={AddBrewerPage}
          />

          <Route
            exact
            path="/:lang(\D{2})?/phoenix-add-place"
            component={AddPlacePage}
          />

          <Route
            exact
            path="/:lang(\D{2})?/phoenix-edit-place/:placeId"
            component={EditPlacePage}
          />

          <Route
            exact
            path="/:lang(\D{2})?/(edit-beer|editbeer.asp)/:id?"
            component={EditBeer}
          />

          <Route
            exact
            path="/:lang(\D{2})?/brewers/:part1/:part2(\d+)?(.*)?"
            // path="/:lang(\D{2})?/phoenix-brewers/:part1/:part2(\d+)?(.*)?"
            component={BrewerProfile}
          />

          <Route exact path="/:lang(\D{2})?" component={Home} />

          {/* Header + Footer Pages */}
          <Route
            render={() => (
              <div
                css={css`
                  flex-direction: column;
                  min-height: 100vh;
                  display: flex;
                `}
              >
                <Header />

                <div
                  css={css`
                    width: 100%;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    margin-top: 64px;
                  `}
                >
                  <Switch>
                    <Route
                      path="/:lang(\D{2})?/my-year-in-beer/:userId"
                      component={MyYearInBeerPage}
                    />

                    <Route
                      path="/:lang(\D{2})?/ratebeerbest"
                      component={RateBeerBestPage}
                    />

                    <Route
                      path="/:lang(\D{2})?/phoenix-user"
                      component={UserProfilePage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/privacy-policy"
                      component={PrivacyPolicyPage}
                    />

                    <Route
                      path="/:lang(\D{2})?/privacy-policy/previous"
                      component={PreviousPrivacyPolicyPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/cookie-policy"
                      component={CookiePolicyPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/opt-out"
                      component={OptOutPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/(feedback.asp|feedback)"
                      component={FeedbackPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/findbeer.asp"
                      component={SearchPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/search"
                      component={SearchPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/community-standards"
                      component={CommunityStandards}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/phoenix-shelf-tags"
                      component={ShelfTags}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/top-beers*"
                      component={TopBeers}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/favorites"
                      component={Favorites}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/shop"
                      component={ShopPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/our-scores"
                      component={FAQPage}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/gabs2019"
                      component={GABS2019Page}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/(add-beer|addbeer.asp)"
                      component={AddBeer}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/beer/:part1?/:part2/:part3(\d+)?(.*)?"
                      component={BeerProfile}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/(p|phoenixPlace)/:part1/:part2(\d+)?(.*)?"
                      component={PlaceProfile}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/Places/ShowPlace.asp"
                      component={PlaceProfile}
                    />

                    <Route
                      exact
                      path="/Place/*/:placeId.htm"
                      component={PlaceProfile}
                    />

                    <Route
                      exact
                      path="/Place/*/:placeId.html"
                      component={PlaceProfile}
                    />

                    <Route
                      exact
                      path="/:lang(\D{2})?/(festival|phoenixFestival)/:festivalName/:festivalId(\d+)?(.*)?"
                      component={FestivalPage}
                    />

                    <Route component={ErrorPage404} />
                  </Switch>
                </div>

                <Footer />
              </div>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Fragment>
  )
}

const ErrorPage404: React.FC = () => {
  const setError = useSetErrorPage()
  setError(404)
  return null
}

const ensureSessionIntegrity = async () => {
  // User would not have seen the beer rating component
  // Or the user would not have been auto-logged into the forums
  const session = sessionFromCookie()
  if (session) {
    // Log user out if they don't have expected tokens
    if (!Jar.get('token') || !Jar.get('idtoken')) {
      console.error('corrupt session detected - logging out')
      captureEvent({
        category: EVENT_ERROR,
        action: EVENT_CORRUPT_SESSION,
        label: window.location.pathname
      })

      import(/* webpackChunkName="Auth" */ 'tools/auth/Auth').then(
        async ({ default: Auth }) => {
          new Auth().logout('/SignOut.asp')
        }
      )
    } else {
      // Log user out if jwt idtoken is expired
      const jwtDecode = require('jwt-decode')
      const idtoken = Jar.get('idtoken')
      const tokenExpiration = (jwtDecode(idtoken) || {}).exp
      const nowInMilliseconds = Math.floor(Date.now() / 1000)
      if (tokenExpiration < nowInMilliseconds) {
        console.error('expired jwt detected - logging out')
        captureEvent({
          category: EVENT_ERROR,
          action: EVENT_EXPIRED_JWT,
          label: window.location.pathname
        })
        import(/* webpackChunkName="Auth" */ 'tools/auth/Auth').then(
          async ({ default: Auth }) => {
            new Auth().logout(window.location.href)
          }
        )
      }
    }
  } else {
    // Create phx session if we have the idtoken
    const userIsLoggedIn = Jar.get('idtoken')
    if (userIsLoggedIn) {
      const newSession = await sessionFromToken()
      if (newSession) {
        // Save the session to the cookie and refresh
        const expires = new Date()
        expires.setDate(expires.getDate() + 365)
        const data = btoa(JSON.stringify(newSession))
        document.cookie = `session=${data}; expires=${expires.toUTCString()}; path=/`

        // If there is a session now, refresh
        if (Jar.get('session')) {
          window.location.reload()
        }
      }
    }
  }
}

export default () => {
  useEffect(() => {
    console.warn(
      'App Mounted. Is this what you expected? Make sure you use <Links />!'
    )
  }, [])
  useSetHeadMeta(
    [
      { type: 'title', content: 'RateBeer' },
      {
        type: 'open-graph',
        attributes: { property: 'og:title', content: 'RateBeer' }
      },
      {
        type: 'open-graph',
        attributes: {
          property: 'og:url',
          content: window.location.href
        }
      }
    ],
    []
  )
  return <App />
}

ensureSessionIntegrity()
