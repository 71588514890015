export default {
  "Add description": "Dodaj opis",
  "Associated Brewer": "Powiązany producent",
  "Available here": "Dostępne tutaj",
  "Average": "Średnio",
  "Beer name": "Nazwa piwa",
  "Beers Spotted Here": "Piwa zauważone tutaj",
  "Bottles {bottlesCount}": "Butelki {bottlesCount}",
  "Cancel": "Anuluj",
  "Copied to clipboard": "Skopiowano do schowka",
  "Copy to clipboard": "Skopiuj do schowka",
  "Filter": "Filtr",
  "Follow on Social Media": "Obserwuj w mediach społecznościowych",
  "No results found for": "Nie znaleziono wyników dla {query}",
  "Read more": "czytaj więcej",
  "Review this place": "Oceń to miejsce",
  "Save": "Zapisz",
  "Search beers spotted here": "Szukaj piw zauważonych tutaj",
  "Seasonal beer": "Sezonowe piwo",
  "Taps {tapsCount}": "Krany {tapsCount}",
  "This score could not be updated": "Ten wynik nie mógł zostać zaktualizowany",
  "This score has been updated": "Ten wynik został zaktualizowany",
  "View beer menu": "Zobacz menu piwa",
  "View food menu": "Zobacz menu posiłków",
  "We apologise for the delay you are experiencing.": "Przepraszamy za opóźnienie.",
  "We are currently updating the website and expect this page to be back shortly.": "Obecnie aktualizujemy stronę i oczekujemy, że wkrótce znów będzie działać.",
  "Your review has been updated": "Twoja recenzja została zaktualizowana",
  "add.abv": "Obj. alk. %",
  "add.abvPlaceholder": "(np. 11%)",
  "add.addBeer": "Dodaj nowe piwo",
  "add.addBrewery": "Dodaj nowy browar",
  "add.autumn": "Jesień",
  "add.beerGuidelines": "Wytyczne",
  "add.beerGuidelines1": "Piwa muszą istnieć w branży i być produkowane.",
  "add.beerGuidelines2": "Receptury muszą być wyjątkowe. Niedozwolone są piwa tylko beczkowane, piwa „nitro” ani warianty receptury.",
  "add.beerGuidelines3": "Cydry owocowe muszą mieć co najmniej 50% fermentacji z jabłek i ponad 2% obj. alk.",
  "add.beerGuidelines4": "Przy nazwie piwa podaj nazwy producentów, np. {correctExample}, a nie {incorrectExample}.",
  "add.beerImage": "Dodaj zdjęcie",
  "add.beerName": "Nazwa piwa",
  "add.beerNameExplanation": "Konwencja nazewnictwa RateBeer wymaga podania nazwy browaru i nazwy piwa.",
  "add.beerNameInfo": "(Dołącz browar)",
  "add.beerNamePlaceholder": "(np. Elysian Space Dust)",
  "add.breweryName": "Nazwa browaru",
  "add.breweryNamePlaceholder": "(np. Elysian)",
  "add.contractBrewer": "Browar",
  "add.contractBrewerPlaceholder": "Wyszukaj browary…",
  "add.contractBrewerPrompt": "To piwo kontraktowe",
  "add.description": "Opis handlowy",
  "add.descriptionPlaceholder": "Co napisano na etykiecie",
  "add.duplicateFound": "Duplicate Found",
  "add.duplicateMessage": "Is this the beer you're trying to add?",
  "add.ibuPlaceholder": "(np. 57)",
  "add.imageInstructions": "* Użyj zdjęcia puszek lub butelek",
  "add.invalid": "Nieprawidłowe",
  "add.newBeer": "Dodaj nowe piwo",
  "add.noBrewery": "Nie możesz znaleźć browaru?",
  "add.optional": "Opcja",
  "add.removeBeerImage": "Usuń obraz",
  "add.seasonalPlaceholder": "Czy to piwo sezonowe?",
  "add.series": "Z serii",
  "add.signInToAddBeer": "Zaloguj się, aby dodać piwo",
  "add.special": "Specjalne wydanie",
  "add.spring": "Wiosenne",
  "add.stylePlaceholder": "Wybierz styl",
  "add.summer": "Lato",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Cyfry z kodu kreskowego)",
  "add.upcInstructions": "Wprowadź wszystkie 8, 12, 13 lub 14 cyfr",
  "add.upcPlaceholder": "Wszystkie cyfry z kodu kreskowego",
  "add.view": "View",
  "add.winter": "Zima",
  "all.adblock": "Wygląda na to, że masz zainstalowanego adblockera. Dodaj stronę RateBeer.com do listy wyjątków. Dzięki!",
  "all.ago": "{date} temu",
  "all.back": "Back",
  "all.beer": "Piwo",
  "all.beers": "Piwa",
  "all.cider": "Cydr",
  "all.ciders": "Cydry",
  "all.cookies": "Używamy plików cookie itp.",
  "all.error": "Niestety, wystąpił błąd sieci",
  "all.joinBody": "Skorzystaj ze wszystkich zalet aplikacji RateBeer. Poznaj kompleksowe recenzje, szczegółowe statystyki i opinie członków naszej społeczności.",
  "all.joinTitle": "Dołącz do naszej społeczności!",
  "all.learnMore": "Dowiedz się więcej",
  "all.loading": "Wczytywanie",
  "all.mead": "Miód pitny",
  "all.meads": "Miody pitne",
  "all.noSuchBeer": "W bazie danych nie ma takiego piwa. Spróbuj poszukać innego piwa.",
  "all.ok": "OK",
  "all.sake": "Sake",
  "all.sakes": "Sake",
  "banner.free": "Za darmo – Google Play",
  "banner.getIt": "Pobierz",
  "banner.officialApp": "Oficjalna Aplikacja",
  "beer.aboutBeer": "Informacje o tym Piwo",
  "beer.aboutCider": "Informacje o tym Cydrze",
  "beer.aboutMead": "Informacje o tym Miodzie Pitnym",
  "beer.aboutSake": "informacje o tym Sake",
  "beer.abv": "ABV",
  "beer.aka": "Znane również jako",
  "beer.aliasBeer": "Alias piwa",
  "beer.aliasCopy": "Producent sprzedaje praktycznie ten sam produkt pod wieloma nazwami. Może to być spowodowane dystrybucją tego piwa pod różnymi nazwami w różnych krajach lub zmianą nazwy nazwy w przeszłości, która nie wiązała się ze zmianą receptury.",
  "beer.allStyles": "Wszystkie rodzaje piwa dla ",
  "beer.appearance": "Wygląd",
  "beer.applyToSellWithUs": "Zarejestruj się, aby sprzedawać z nami",
  "beer.aroma": "Aromat",
  "beer.autumn": "Jesień",
  "beer.availability": "Dostępność",
  "beer.availableAt": "Dostępne w",
  "beer.averageRatings": "Średnia ocen",
  "beer.bottled": "Butelkowane",
  "beer.brewedBy": "Browar ",
  "beer.brewedFor": "Warzone dla ",
  "beer.buyFrom": "Kupić z",
  "beer.calories": "SZAC. KAL.",
  "beer.clearRating": "Wyczyść",
  "beer.copy": "Kopiuj",
  "beer.delete": "Usuń piwo/oceny",
  "beer.deleteAlias": "Usuń alias",
  "beer.deleteConfirm": "Tak, usuń",
  "beer.deleteOption": "Usuń",
  "beer.deleteRating": "Usunąć ocenę?",
  "beer.distribution": "Dystrybucja",
  "beer.edit": "Edytuj",
  "beer.editAlias": "Dodaj/edytuj alias",
  "beer.editBarcodes": "Dodaj/edytuj kody kreskowe",
  "beer.editBeer": "Edytuj to piwo",
  "beer.editPicture": "Dodaj/edytuj obraz",
  "beer.editTags": "Dodaj/Edytuj Tags",
  "beer.findFriendsText": "Znajdź znajomych do podzielenia się informacjami o tym, co piją, żeby dowiedzieć się, czego udało Ci się spróbować",
  "beer.findFriendsTitle": "Zaproś znajomych",
  "beer.findIt": "Znajdź to",
  "beer.findItNearYou": "Znajdź w pobliżu",
  "beer.fix": "Popraw wynik",
  "beer.flavor": "Smak",
  "beer.from": "z",
  "beer.highestScore": "Najwyższy wynik",
  "beer.howItWorks": "Jak to działa",
  "beer.howItWorksContent": "Ciężko pracujemy, aby znaleźć sprzedawców najwyższej jakości, o najszerszym zasięgu i doskonałych cenach, aby umożliwić Ci zakup Twojego ulubionego piwa",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Znajdź sprzedawców",
  "beer.locations": "{count} {count, plural, one {lokalizacji} other {lokalizacjach}}",
  "beer.locationsNearby": "{count} {count, plural, one {lokalizacji w pobliżu} other {lokalizacjach w pobliżu}}",
  "beer.mostLiked": "Najbardziej lubiane",
  "beer.mostRecent": "Najnowsze",
  "beer.mouthfeel": "Podniebienie",
  "beer.moveRating": "Przenieś ocenę",
  "beer.myFriends": "Moi znajomi",
  "beer.myReview": "Moja opinia",
  "beer.noDescription": "Opis jest niedostępny",
  "beer.noRating": "Brak oceny",
  "beer.noReviewsText": "Podziel się ze światem swoją opinią. Bądź pierwszą osobą, która je oceniła i dodała opinię!",
  "beer.noReviewsTitle": "Próbowałeś tego piwa?",
  "beer.onTap": "Z beczki",
  "beer.outOfProd": "NIEPRODUKOWANE",
  "beer.overall": "Ogólnie",
  "beer.per": "za",
  "beer.privacyNotice": "Klikając powyższy link do sklepu, zostaniesz przekierowany na stronę sprzedawcy, a następnie będzie obowiązywać jego Polityka prywatności.",
  "beer.private": "PRYWATNE",
  "beer.quickRate": "Szybka ocena",
  "beer.quickRateSaved": "Zapisano szybką ocenę",
  "beer.quickRated": "Wg szybkiej oceny: ",
  "beer.rateAndReview": "Oceń i napisz opinię",
  "beer.rateIt": "Próbowałeś? Oceń!",
  "beer.ratedBy": "Ocenione przez",
  "beer.ratingPrivate": "Ocena prywatna",
  "beer.ratingPublic": "Ocena publiczna",
  "beer.readMore": "czytaj więcej",
  "beer.reviews": "Opinie",
  "beer.reviewsRatings": "Opinie i oceny",
  "beer.saveRating": "Zapisz tę ocenę!",
  "beer.saveRatingText": "Utwórz konto, aby zapisywać oceny, czytać opinie ekspertów, przeglądać szczegółowe statystyki i wiele więcej.",
  "beer.seasonal": "Sezonowe",
  "beer.seasonalLabel": "SEZONOWE",
  "beer.seeAll": "Zobacz wszystko",
  "beer.seeAllXReviews": "Zobacz wszystkie opinie ({count})",
  "beer.sendCorrections": "Wyślij poprawki",
  "beer.series": "Z serii",
  "beer.serveIn": "Naczynie",
  "beer.share": "Udostępnij",
  "beer.shareTutorial": "Udostępnij, prześlij poprawki, dodaj zdjęcia",
  "beer.shelfTags": "Wyświetl etykietę na półkę",
  "beer.shopNow": "Kup teraz",
  "beer.showLess": "wyświetl mniej",
  "beer.signInToFavorite": "Zapisz to piwo w ulubionych",
  "beer.signInToFind": "Zaloguj się, aby znaleźć to piwo.",
  "beer.signInToLikeReview": "Zaloguj się, aby polubić tę opinię",
  "beer.similarBeer": "Podobne Piwa",
  "beer.similarCider": "Podobne Cydry",
  "beer.similarMead": "Podobne Miody Pitne",
  "beer.similarSake": "Podobne Sake",
  "beer.special": "Specjalne",
  "beer.spring": "Wiosna",
  "beer.style": "Rodzaj ",
  "beer.summer": "Lato",
  "beer.tags": "Etykiety",
  "beer.top50": "Top 50",
  "beer.topRaters": "Top oceniających",
  "beer.totalRetailers": "To piwo jest sprzedawane przez {count} zweryfikowanych sprzedawców zewnętrznych",
  "beer.undoRating": "Cofnij szybką ocenę",
  "beer.unrateable": "Tego piwa nie można ocenić",
  "beer.verified": "ZWERYFIKOWANY",
  "beer.winter": "Zima",
  "beer.writeAReview": "Napisz opinię",
  "beer.writeFullReview": "Napisz pełną opinię",
  "beer.yourReview": "Twoja recenzja",
  "error.refresh": "Odśwież",
  "favorites.instructions": "Kiedy znajdziesz piwo, które lubisz, dotknij przycisk Zapisz, żeby móc je później sprawdzić. Przeglądaj wszystkie zapisane piwa w jednym miejscu na wszystkich urządzeniach.",
  "favorites.noSavedBeers": "Nie zapisałeś jeszcze żadnego piwa",
  "favorites.remove": "Usuń",
  "favorites.removeConfirmation": "Tak, usuń",
  "favorites.removePrompt": "Usunąć z ulubionych?",
  "favorites.saved": "Zapisano",
  "favorites.savedConfirmation": "Zapisane w ulubionych",
  "favorites.signInToViewFavorites": "Zapisz to piwo w ulubionych",
  "favorites.viewAll": "Wyświetl wszystkie",
  "feedback.android": "Aplikacja mobilna na Androida",
  "feedback.androidReproduceInfo": "(np. zalogować się, wyszukiwanie według skanowania kodów kreskowych, dotknąć wyników dotyczących piw, rozwinąć panel ocen ekspertów, przesłać ocenę eksperta)",
  "feedback.bug": "Zgłoś błąd",
  "feedback.bugMessageInfo": "(Jakie były oczekiwane wyniki? Jakie były otrzymane wyniki?)",
  "feedback.bugMessagePrompt": "Podsumowanie błędu",
  "feedback.buildInfo": "(np. Wersja aplikacji 1.7.0. Aby znaleźć, dotknij symbolu: ? w prawym górnym rogu ekranu)",
  "feedback.buildPrompt": "Wersja aplikacji",
  "feedback.categoryPrompt": "Co chcesz dzisiaj robić?",
  "feedback.correction": "Prześlij poprawkę",
  "feedback.correctionMessageInfo": "(Jakie powinny wyglądać informacje? Czy obecnie wyświetlane są niepoprawne informacje?)",
  "feedback.correctionMessagePrompt": "Podsumowanie poprawki",
  "feedback.email": "Adres e-mail",
  "feedback.header": "Opinia od",
  "feedback.iOS": "Aplikacja mobilna na iOS",
  "feedback.iOSReproduceInfo": "(np. zalogować się, wyszukiwanie według skanowania kodów kreskowych, dotknąć wyników dotyczących piw, rozwinąć panel ocen ekspertów, przesłać ocenę eksperta)",
  "feedback.mediumPrompt": "Kiedy wystąpił ten problem?",
  "feedback.reproducePrompt": "Jakie kroki można podjąć aby odtworzyć ten problem nasz koniec?",
  "feedback.submit": "Prześlij",
  "feedback.submitted": "Dziękujemy za przesłanie opinii!",
  "feedback.suggestion": "Prześlij sugestię",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Czego oczekujesz od RateBeer?",
  "feedback.url": "ADRES URL",
  "feedback.username": "Nazwa użytkownika",
  "feedback.web": "Strona internetowa",
  "feedback.webReproduceInfo": "(np. zalogować się i przejść na stronę ratebeer.com/community, kliknąć link na pasku bocznym)",
  "footer.FAQs": "Często zadawane pytania",
  "footer.RBBest": "Najlepsze piwa wg RateBeer",
  "footer.TOS": "Warunki korzystania z usługi",
  "footer.aboutRateBeer": "Informacje o RateBeer",
  "footer.aboutUs": "O nas",
  "footer.account": "Konto",
  "footer.addBeer": "Dodaj piwo",
  "footer.addBrewer": "Dodaj producenta",
  "footer.addEvent": "Dodaj wydarzenie",
  "footer.addPlace": "Dodaj miejsce",
  "footer.addPremium": "Konto Premium",
  "footer.addToRB": "Dodaj do RateBeer",
  "footer.contactUs": "Skontaktuj się z nami",
  "footer.copyright": "Wszelkie prawa zastrzeżone.",
  "footer.developers": "Deweloperzy",
  "footer.editAccount": "Edytuj konto",
  "footer.privacy": "Polityka prywatności",
  "The most comprehensive ratings and reviews of beers from around the world": "Najobszerniejsze zestawienie ocen i opinii o piwach z całego świata",
  "footer.theBest": "Najlepsze",
  "footer.top50Beers": "Top 50 piw",
  "footer.topReviewers": "Top oceniających",
  "glasscopy1": "Tall, thin and footed with a short stem. Flutes are delicate and show off a beer’s body. These vessels are ideal for light and sparkling beers.",
  "glasscopy10": "Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.",
  "glasscopy11": "A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.",
  "glasscopy12": "This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!",
  "glasscopy13": "Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.",
  "glasscopy14": "A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.",
  "glasscopy15": "Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.",
  "glasscopy16": "A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.",
  "glasscopy17": "Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.",
  "glasscopy18": "A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.",
  "glasscopy19": "A small cup, often fluted at the edge, and larger than ochoko.",
  "glasscopy2": "Short glasses, holds around 12oz. of beer. Slightly wider at the mouth than the foot, with gradual, evenly sloping sides. Great basic glass, well-suited for pale lagers.",
  "glasscopy20": "A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.",
  "glasscopy21": "Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.",
  "glasscopy3": "Designed to be drained in a few gulps. These glasses allow for billowing heads to form. Neither are ideal for developing aroma or discerning subtleties.",
  "glasscopy4": "The American microbrewer’s standard. Gently sloped 16oz. glass made for session-type beers. Typically used to serve Ambers and English & American pales.",
  "glasscopy5": "Similar to a shaker but often straight-sided, usually with an additional flair or bulb to retain aroma. These glasses are often marked to measure an imperial pint.",
  "glasscopy6": "A classic in North America, the dimpled mug is large and has a handle. It is convex, with the mouth larger than the base. Dimples make appreciating the appearance of the beer difficult, but the wide mouth releases the aroma nicely. Commonly used for raunchy lagers.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "A small, almost straight-sided glass sits on an inch-long stem and foot. The basic footed pilsner has a slightly bulbous bottom and narrower mouth, which makes it better for drinking than for smelling, and places the most emphasis on the appearance. It has a bit more style than some other glasses, so it is best used for pilsners, and decent cream or golden ales than for the lowliest lagers.",
  "glasscopy9": "Often a favorite of top tasters. The Duvel glass is a well-known variant of the tulip style, and the tasting glass in the RateBeer logo is an almost perfect example.",
  "Account Settings": "Ustawienia konta",
  "Community": "Społeczność",
  "Events": "Wydarzenia",
  "Forums": "Fora",
  "Log In": "Zaloguj się",
  "Log Out": "Wyloguj się",
  "Manage Breweries Followed": "Zarządzaj obserwowanymi browarami",
  "Manage People Followed": "Zarządzaj obserwowanymi osobami",
  "header.my2017": "Mój rok w piwie",
  "My Beer Cellar": "Moja piwniczka z piwami",
  "My Beer Ratings": "Moje oceny piwa",
  "My Beer Styles": "Moje rodzaje piwa",
  "My Breweries": "Moje browary",
  "My Countries & States": "Moje kraje i stany",
  "My Favorites": "Moje ulubione",
  "Latest Activity": "Ostatnia aktywność",
  "My Place Ratings": "Moje oceny miejsc",
  "My Profile": "Mój profil",
  "New Releases": "Nowości",
  "Places": "Miejsca",
  "Ratings": "Oceny",
  "Sign Up": "Zarejestruj się",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "Najlepsze piwa według rodzaju",
  "home.Top Rated Beers Nearby": "Najlepiej oceniane piwa w pobliżu",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Pobierz aplikację",
  "home.downloadBlurb": "Zabierz ze sobą RateBeer, gdziekolwiek jesteś! Nasza aplikacja posiada świetne funkcje, które pomogą Ci znaleźć nowe piwa, które pokochasz.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Wiadomość wysłana!",
  "home.porter": "Porter",
  "home.retailers": "Sprzedawców",
  "home.saison": "Saison",
  "home.searchPrompt": "Znajdź piwa, browary lub bary…",
  "home.shopOnline": "Kup w sklepie internetowym",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Tekst linku",
  "home.top50Beers": "Top 50 piw",
  "home.worldOfBeer": "Twój przewodnik po świecie piwa",
  "landing.TOS": "Warunki korzystania z usługi",
  "landing.feedback": "To nasze pierwsze podejście do aplikacji RateBeer.{br}Pomóż nam ją ulepszyć!",
  "landing.h1": "Najlepsza na świecie strona z ocenami piwa{br}jest teraz aplikacją!",
  "landing.h2": "Możliwość oceny piwa masz{br}teraz zawsze pod ręką",
  "landing.label": "oceny",
  "landing.li1": "Przeszukaj bazę danych ponad 500 000 piw",
  "landing.li2": "Wystawiaj oceny, wyrażaj opinie i udostępniaj",
  "landing.li3": "Zapisuj informacje o każdym piwie, które piłeś",
  "landing.privacy": "POLITYKA PRYWATNOŚCI",
  "landing.review": "RateBeer to najbardziej wiarygodne źródło informacji o piwie i jego producentach w Internecie.",
  "landing.rightsReserved": "Wszelkie prawa zastrzeżone.",
  "landing.submit": "Prześlij opinię",
  "landing.visit": "Odwiedź ratebeer.com",
  "place.addBeerIds": "Dodaj identyfikatory piwa",
  "place.addBeers": "Dodaj piwa",
  "place.addEvent": "Dodaj wydarzenie",
  "place.ambiance": "Atmosfera",
  "place.available": "Dostępne",
  "place.beerMenu": "Menu piwa",
  "place.close": "Zamknij",
  "place.customizeShelftags": "Dostosuj etykiety",
  "place.editBeers": "Edytuj piwa",
  "place.editPlace": "Edytuj miejsce",
  "place.flights": "Loty",
  "place.food": "Jedzenie",
  "place.foodMenu": "Menu posiłków",
  "place.getDirection": "Uzyskaj instrukcje",
  "place.hours": "Godziny",
  "place.lessInfo": "Mniej informacji",
  "place.map": "Mapa",
  "place.moreInfo": "Więcej informacji",
  "place.overall": "Ogólnie",
  "place.phone": "Numer telefonu",
  "place.printShelftags": "Drukuj etykiety",
  "place.selection": "Wybór",
  "place.sendToPhone": "Prześlij na telefon",
  "place.service": "Usługa",
  "place.shelftags": "Etykiety",
  "place.showBreakdown": "Pokaż składowe oceny",
  "place.value": "Wartość",
  "place.wifi": "Bezpłatne Wi-fi",
  "rate.BOTTLE": "Butelka",
  "rate.CAN": "Puszka",
  "rate.CASK": "Beczka",
  "rate.TAP": "Kran",
  "rate.commentPlaceholder": "Dodaj uwagi na temat smaku, opisy itp.",
  "rate.infoSubtitle": "W celu zapewnienia wysokiej jakości punktacji piw bierzemy pod uwagę tylko opinie zawierające szczegółowe informacje.",
  "rate.locationPlaceholder": "Dodaj lokalizację",
  "rate.nearbyPlaces": "Pobliskie lokalizacje",
  "rate.ourRatingSystem": "Nasz system oceny",
  "rate.private1": "0–74 znaków",
  "rate.private2": "Zapisane do użytku osobistego",
  "rate.private3": "Nie ma wpływu na ogólną punktację",
  "rate.privateLabel": "PRYWATNA",
  "rate.public1": "75+ znaków ",
  "rate.public2": "Ma wpływ na ogólną punktację piwa",
  "rate.public3": "Jest widoczna dla społeczności",
  "rate.publicLabel": "PUBLICZNA",
  "rate.save": "Zapisz",
  "rate.searchResults": "Wyniki",
  "rate.servedIn": "Naczynie",
  "rate.totalScore": "ŁĄCZNY WYNIK",
  "search.advanced": "Wyszukiwanie zaawansowane",
  "search.beerCaps": "PIWA",
  "search.beerHeader": "Piwa",
  "search.beerSearchFor": "Wpisz w pasku wyszukiwania, aby znaleźć piwa",
  "search.brewersCaps": "BROWARY",
  "search.brewersHeader": "Browary",
  "search.brewersSearchFor": "Wpisz w pasku wyszukiwania, aby znaleźć producentów piwa",
  "search.closestMatch": "Najbliższe dopasowanie",
  "search.for": "Szukaj",
  "search.in": "w",
  "search.mostRatings": "Większość ocen",
  "search.myRating": "Moja ocena",
  "search.noResults": "Brak wyników dla ",
  "search.pCaps": "MIEJSC",
  "search.pHeader": "Miejsc",
  "search.pSearchFor": "Wpisz w pasku wyszukiwania, aby znaleźć miejsca",
  "Search for Beers, Breweries, or Bars...": "Szukaj piwa, browarów lub barów…",
  "search.resultFor": "wynik dla",
  "search.resultLabelStr": "Oceny",
  "search.resultsFor": "wyniki dla",
  "search.sortBy": "Sortuj według",
  "search.sortByCaps": "SORTUJ WEDŁUG",
  "search.topRated": "Najlepiej ocenione",
  "search.userHeader": "Użytkowników",
  "search.userSearchFor": "Wpisz w pasku wyszukiwania, aby znaleźć użytkowników"
}
