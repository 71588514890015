import { createStore } from 'lib/state-container'
import { ExperimentConfig } from './experiment'
import { GAConfig } from './analytics'
import { config } from 'tools/config'

// export type ExperimentName =
//   | 'forUnitTest'
//   | keyof config.AB_EXPERIMENT_CONFIG.experimentSettings
export type ExperimentName =
  | 'forUnitTest'
  | 'similarBeerWithOrWithoutPurchaseInfo'
  | 'onlineShoppingDescription'
export type ExperimentVariant = 'A' | 'B' | 'C' | 'D'
export type ResolvedExperiment =
  | {
      loaded: false
    }
  | { loaded: true; variant: ExperimentVariant }
export type AnalytcisStore = {
  optimizeConfig: ExperimentConfig
  gaConfig?: GAConfig
  experiments: { [key in ExperimentName]?: ResolvedExperiment }
  optimizeInitialised: Promise<void>
}

const { Provider: StoreProvider, useStore } = createStore<AnalytcisStore>()
export { StoreProvider, useStore }
