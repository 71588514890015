import React from 'react'
import { createStore } from 'lib/state-container'
import { Modal, Text, Button } from 'lib/components'
import { callAll } from 'lib/utils'
import { identity } from 'ramda'

type Action = {
  label: string
  handler?: () => void
}

type DialogInfo = {
  title: string
  description?: string
  actions?: Action[]
}

type State = DialogInfo & {
  show: boolean
}

const initialState: State = {
  title: '',
  description: '',
  actions: [],
  show: false
}

const { Provider: StoreProvider, useStore } = createStore<State>()

export const useOpenDialog = () => {
  const [setStore] = useStore()
  return (info: DialogInfo) => {
    setStore(state => ({ ...state, ...info, show: true }))
  }
}

const GlobalDialog: React.FunctionComponent = props => {
  const [{ title, description, actions = [], show }, setStore] = useStore(
    identity
  )

  const onClose = () => {
    setStore(state => ({ ...state, show: false }))
  }

  return (
    <Modal
      title={title}
      open={show}
      onClose={onClose}
      {...props}
      css="min-width: 400px;"
    >
      <div className="fa-c fd-c px-5 py-4">
        {description ? <Text className="w-100 mb-4">{description}</Text> : null}
        <div className="fj-sa w-100">
          {actions.map(({ label, handler = () => {} }) => (
            <Button key={label} onClick={callAll(handler, onClose)}>
              {label}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export const Provider: React.FunctionComponent = ({ children }) => {
  return (
    <StoreProvider initialState={initialState}>
      {children}
      <GlobalDialog />
    </StoreProvider>
  )
}
