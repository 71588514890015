import { pipe, tail, init, isNil, pick, pickBy } from 'ramda'
import isUrl from 'validator/lib/isURL'

import getSlug from 'speakingurl'

export const queryString = (url: string): string => {
  const regex = /\?[^#]*($|#)/
  const search = url.match(regex)
  return pipe(
    (search: RegExpMatchArray | null) => (search ? search[0] : ''),
    search => (search.startsWith('?') ? tail(search) : search),
    search => (search.endsWith('#') ? init(search) : search)
  )(search)
}

export const parseQueryString = <T extends any = any>(url: string): T => {
  return parseQueryVariables(queryString(url))
}

const parseQueryVariables = <T extends any = any>(queryString: string): T => {
  if (!queryString) {
    return {} as T
  }

  const vars = queryString.split('&')

  return vars
    .map(pair => pair.split('='))
    .filter(pair => pair.length === 2)
    .reduce((acc, [key, value]) => {
      acc[key] = decodeQueryComponent(value)
      return acc
    }, {} as T)
}

export const serializeQueryVariables = <
  T extends { [key: string]: string | number | boolean | null | undefined }
>(
  variables: T
) => {
  return Object.keys(variables).reduce((acc, key) => {
    const value = variables[key]
    if (isNil(value)) {
      return acc
    }

    const keyPairs = `${key}=${encodeQueryComponent(value)}`
    return acc ? `${acc}&${keyPairs}` : keyPairs
  }, '')
}

export const encodeQueryComponent = (query: string | number | boolean) =>
  encodeURIComponent(`${query}`).replace(/%20/g, '+')

export const decodeQueryComponent = (query: string) =>
  decodeURIComponent(query.replace(/\+/g, '%20'))

export const urlSlug = (result: string) => {
  const options = {
    custom: {
      '&': '-and-'
    }
  }
  return getSlug(result, options)
}

export const stripProtocol = (url: string) => {
  return url.replace(/https?:\/\//, '')
}

type UpdateQueryStringOptions = {
  parameters?: { [key: string]: string | number | undefined }
  allowedParameters?: string[]
}

export const updateQueryString = (
  queryString: string,
  options: UpdateQueryStringOptions
) => {
  const queryParameters = parseQueryVariables(queryString)

  const updatedQueryParameters = {
    ...(options.allowedParameters
      ? pick(options.allowedParameters, queryParameters)
      : queryParameters),
    ...(pickBy(v => v !== undefined, options.parameters || {}) as any)
  }

  return serializeQueryVariables(updatedQueryParameters)
}

export const cleanUrl = (url: string) => {
  return url.replace(/\/\//, '/')
}

export function isValidUrl(url: string) {
  return isUrl(url)
}

export function isValidUrlWithoutProtocol(url: string) {
  return isUrl(url, { require_protocol: false })
}

/**
 * Checks if the given url is valid, with or without protocol. If so, returns the url with protocol. Otherwise reutnrs null
 */
export function normalizeUrl(url: string | null | undefined) {
  if (!url) {
    return null
  } else if (isValidUrl(url)) {
    return url
  } else if (isValidUrlWithoutProtocol(url)) {
    return `https://${url}`
  } else {
    return null
  }
}
