import { createStore } from 'lib/state-container'
import { GCoordinates } from '../graphql/generated'
export type LatLong = GCoordinates

export type State = null | {
  latlng?: GCoordinates | null
}

export const initialState: State = null

export const { Provider, useStore } = createStore<State>()
