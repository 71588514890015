import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = Stylable & {
  size?: 'small' | 'medium' | 'large'
}

const Spinner: React.FunctionComponent<Props> = ({
  size = 'medium',
  ...props
}) => {
  return (
    <CircularProgress
      {...props}
      size={
        size === 'small'
          ? 40
          : size === 'medium'
          ? 80
          : size === 'large'
          ? 120
          : 40
      }
      thickness={
        size === 'small'
          ? 3.6
          : size === 'medium'
          ? 2
          : size === 'large'
          ? 1.2
          : 2
      }
    />
  )
}

export default Spinner
