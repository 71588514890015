import { iconFromMaterialUI } from 'lib/icons/tools'

import Fullscreen from '@material-ui/icons/Fullscreen'
export const FullscreenIcon = iconFromMaterialUI({ MaterialIcon: Fullscreen })

import Directions from '@material-ui/icons/Directions'
export const DirectionsIcon = iconFromMaterialUI({ MaterialIcon: Directions })

import AccessTime from '@material-ui/icons/AccessTime'
export const AccessTimeIcon = iconFromMaterialUI({ MaterialIcon: AccessTime })

import Phone from '@material-ui/icons/Phone'
export const PhoneIcon = iconFromMaterialUI({ MaterialIcon: Phone })

import Public from '@material-ui/icons/Public'
export const PublicIcon = iconFromMaterialUI({ MaterialIcon: Public })

import Restaurant from '@material-ui/icons/Restaurant'
export const RestaurantIcon = iconFromMaterialUI({ MaterialIcon: Restaurant })

import Home from '@material-ui/icons/Home'
export const HomeIcon = iconFromMaterialUI({
  MaterialIcon: Home
})

import Edit from '@material-ui/icons/Edit'
export const EditIcon = iconFromMaterialUI({
  MaterialIcon: Edit
})

import Delete from '@material-ui/icons/Delete'
export const DeleteIcon = iconFromMaterialUI({
  MaterialIcon: Delete
})

import Close from '@material-ui/icons/Close'
export const CloseIcon = iconFromMaterialUI({ MaterialIcon: Close })

import History from '@material-ui/icons/History'
export const HistoryIcon = iconFromMaterialUI({ MaterialIcon: History })

import Star from '@material-ui/icons/Star'
export const StarIcon = iconFromMaterialUI({ MaterialIcon: Star })

import StarBorder from '@material-ui/icons/StarBorder'
export const StarBorderIcon = iconFromMaterialUI({ MaterialIcon: StarBorder })

import StarHalf from '@material-ui/icons/StarHalf'
export const StarHalfIcon = iconFromMaterialUI({ MaterialIcon: StarHalf })

import Refresh from '@material-ui/icons/Refresh'
export const RefreshIcon = iconFromMaterialUI({ MaterialIcon: Refresh })

import Check from '@material-ui/icons/Check'
export const CheckIcon = iconFromMaterialUI({ MaterialIcon: Check })

import ChevronRight from '@material-ui/icons/ChevronRight'
export const ChevronRightIcon = iconFromMaterialUI({
  MaterialIcon: ChevronRight
})

import LocationOn from '@material-ui/icons/LocationOn'
export const LocationOnIcon = iconFromMaterialUI({
  MaterialIcon: LocationOn
})

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
export const ArrowDropDownIcon = iconFromMaterialUI({
  MaterialIcon: ArrowDropDown
})

import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
export const ArrowDropUpIcon = iconFromMaterialUI({
  MaterialIcon: ArrowDropUp
})

import ArrowUpward from '@material-ui/icons/ArrowUpward'
export const ArrowUpwardIcon = iconFromMaterialUI({
  MaterialIcon: ArrowUpward
})

import ArrowBack from '@material-ui/icons/ArrowBack'
export const ArrowBackIcon = iconFromMaterialUI({
  MaterialIcon: ArrowBack
})
import Menu from '@material-ui/icons/Menu'
export const MenuIcon = iconFromMaterialUI({
  MaterialIcon: Menu
})

import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
export const ShoppingBasketIcon = iconFromMaterialUI({
  MaterialIcon: ShoppingBasket
})

import Search from '@material-ui/icons/Search'
export const SearchIcon = iconFromMaterialUI({
  MaterialIcon: Search
})

import Favorite from '@material-ui/icons/Favorite'
export const FavoriteIcon = iconFromMaterialUI({
  MaterialIcon: Favorite
})

export const HeartIcon = FavoriteIcon

import HeartOutlined from '@material-ui/icons/FavoriteBorderOutlined'
export const HeartOutlinedIcon = iconFromMaterialUI({
  MaterialIcon: HeartOutlined
})

import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
export const FavoriteBorderIcon = iconFromMaterialUI({
  MaterialIcon: FavoriteBorder
})

import ExpandMore from '@material-ui/icons/ExpandMore'
export const ExpandMoreIcon = iconFromMaterialUI({
  MaterialIcon: ExpandMore
})

import ExpandLess from '@material-ui/icons/ExpandLess'
export const ExpandLessIcon = iconFromMaterialUI({
  MaterialIcon: ExpandLess
})

import MoreVert from '@material-ui/icons/MoreVert'
export const MoreVertIcon = iconFromMaterialUI({
  MaterialIcon: MoreVert
})

import Clear from '@material-ui/icons/Clear'
export const ClearIcon = iconFromMaterialUI({
  MaterialIcon: Clear
})

import Visibility from '@material-ui/icons/Visibility'
export const VisibilityIcon = iconFromMaterialUI({
  MaterialIcon: Visibility
})

import VisibilityOff from '@material-ui/icons/VisibilityOff'
export const VisibilityOffIcon = iconFromMaterialUI({
  MaterialIcon: VisibilityOff
})

import Info from '@material-ui/icons/Info'
export const InfoIcon = iconFromMaterialUI({
  MaterialIcon: Info
})

import InfoOutlined from '@material-ui/icons/InfoOutlined'
export const InfoIconOutlined = iconFromMaterialUI({
  MaterialIcon: InfoOutlined
})

import ThumbUp from '@material-ui/icons/ThumbUp'
export const ThumbUpIcon = iconFromMaterialUI({
  MaterialIcon: ThumbUp
})

import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined'
export const ThumbUpIconOutlined = iconFromMaterialUI({
  MaterialIcon: ThumbUpOutlined
})

import Map from '@material-ui/icons/Map'
export const MapIcon = iconFromMaterialUI({
  MaterialIcon: Map
})

import ShoppingCart from '@material-ui/icons/ShoppingCart'
export const ShoppingCartIcon = iconFromMaterialUI({
  MaterialIcon: ShoppingCart
})

import CheckCircle from '@material-ui/icons/CheckCircle'
export const CheckCircleIcon = iconFromMaterialUI({
  MaterialIcon: CheckCircle
})

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
export const CheckCircleOutlinedIcon = iconFromMaterialUI({
  MaterialIcon: CheckCircleOutline
})

import FilterList from '@material-ui/icons/FilterList'
export const FilterListIcon = iconFromMaterialUI({
  MaterialIcon: FilterList
})

import Share from '@material-ui/icons/Share'
export const ShareIcon = iconFromMaterialUI({
  MaterialIcon: Share
})

import SettingsOutlined from '@material-ui/icons/SettingsOutlined'
export const SettingsOutlinedIcon = iconFromMaterialUI({
  MaterialIcon: SettingsOutlined
})

import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
export const PowerSettingsNewIcon = iconFromMaterialUI({
  MaterialIcon: PowerSettingsNew
})

import Cancel from '@material-ui/icons/Cancel'
export const CancelIcon = iconFromMaterialUI({
  MaterialIcon: Cancel
})

import Add from '@material-ui/icons/Add'
export const AddIcon = iconFromMaterialUI({
  MaterialIcon: Add
})

import Remove from '@material-ui/icons/Remove'
export const RemoveIcon = iconFromMaterialUI({
  MaterialIcon: Remove
})

import InsertPhoto from '@material-ui/icons/InsertPhoto'
export const InsertPhotoIcon = iconFromMaterialUI({
  MaterialIcon: InsertPhoto
})

import ArrowDown from '@material-ui/icons/ArrowDownward'
export const ArrowDownIcon = iconFromMaterialUI({
  MaterialIcon: ArrowDown
})

import ArrowUp from '@material-ui/icons/ArrowUpward'
export const ArrowUpIcon = iconFromMaterialUI({
  MaterialIcon: ArrowUp
})

import List from '@material-ui/icons/List'
export const ListIcon = iconFromMaterialUI({
  MaterialIcon: List
})

import CalendarToday from '@material-ui/icons/CalendarToday'
export const CalendarTodayIcon = iconFromMaterialUI({
  MaterialIcon: CalendarToday
})

import Message from '@material-ui/icons/Message'
export const MessageIcon = iconFromMaterialUI({
  MaterialIcon: Message
})

import Link from '@material-ui/icons/Link'
export const LinkIcon = iconFromMaterialUI({
  MaterialIcon: Link
})

import Beenhere from '@material-ui/icons/Beenhere'
export const BeenhereIcon = iconFromMaterialUI({
  MaterialIcon: Beenhere
})
