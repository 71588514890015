import React from 'react'
import { noopTemplate as css } from 'lib/utils'
import { theme, Color } from 'theme'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import Text, { TextProps } from './Text'

export type AProps<P> = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > &
    TextProps<P>,
  'color'
> & { color?: Color }

export const A = <P extends any>(props: AProps<P>) => {
  return (
    <Text
      css={css`
        &:hover {
          color: ${theme.colors.primary};
          text-decoration: underline;
        }
      `}
      component="a"
      {...props}
      inline={props.inline === undefined || props.inline}
      color={props.color || 'primary'}
    />
  )
}

export type LinkProps<P> = TextProps<P> & RouterLinkProps

export const Link = <P extends any>(props: LinkProps<P>) => {
  return (
    <Text
      css={css`
        &:hover {
          color: ${theme.colors.primary};
          text-decoration: underline;
        }
      `}
      component={RouterLink}
      {...props}
      inline={props.inline === undefined || props.inline}
      color={props.color || 'primary'}
    />
  )
}
