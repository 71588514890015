import gql from 'graphql-tag'
import { client } from 'services/graphql'
import { User } from './store'
import { sessionFromCookie } from 'tools/session'

const meQuery = gql`
  query me {
    me {
      id
      username
      city
      email
      firstName
      festivalRatingCount
      firstName
      gender
      imageUrl
      isPremiumMember
      lastName
      placeReviewCount
      securityLevelId
      streetAddress
      zip
      place {
        id
      }
    }
  }
`

export const me = async (): Promise<User | null> => {
  const session = sessionFromCookie()

  if (!session) {
    return null
  }

  const res = (await client.query({
    query: meQuery
  })) as any
  return res.data.me || null
}
