export default {
  "Account Settings": "Configurações de conta",
  "Add description": "Adicionar descrição",
  "Add location": "Adicionar localização",
  "Appearance": "Aparência",
  "Apply to sell with us": "Inscreva-se para vender conosco",
  "Are you sure you want to delete your review?": "Tem certeza de que deseja excluir sua avaliação?",
  "Aroma": "Aroma",
  "Associated Brewer": "Cervejarias associadas",
  "Available here": "Disponível aqui",
  "Available to buy from {count} trusted retail partners": "Disponível para compra de {count} parceiros de varejo confiáveis",
  "Average": "Média",
  "Beer name": "Nome da cerveja",
  "Beer styles": "Estilos de cerveja",
  "Beers Spotted Here": "Cervejas encontradas aqui",
  "Bottles {bottlesCount}": "{bottlesCount} garrafas",
  "Buy from": "Comprar de",
  "Cancel": "Cancelar",
  "Community": "Comunidade",
  "Copied to clipboard": "Copiado para a área de transferência",
  "Copy to clipboard": "Copiar para a área de transferência",
  "Country of origin": "País de origem",
  "Events": "Eventos",
  "Filter": "Filtrar",
  "Find a beer or brewer": "Encontre uma cerveja ou cervejaria",
  "Find a country": "Encontre um país",
  "Find a style": "Encontre um estilo",
  "Follow on Social Media": "Seguir nas mídias sociais",
  "Forums": "Fóruns",
  "From {price} per {serve}": "De {price} por {serve}",
  "Latest Activity": "Última atividade",
  "Log In": "Entrar",
  "Log Out": "Efetuar logout",
  "Manage Breweries Followed": "Gerencie cervejarias seguidas",
  "Manage People Followed": "Gerenciar pessoas seguidas",
  "Mouthfeel": "Sensação na boca",
  "My Beer Cellar": "Minha adega de cervejas",
  "My Beer Ratings": "Minhas classificações de cerveja",
  "My Beer Styles": "Meus estilos de cerveja",
  "My Breweries": "Minhas cervejarias",
  "My Countries & States": "Meus países e estados",
  "My Favorites": "Minhas favoritas",
  "My Place Ratings": "Minhas classificações de local",
  "My Profile": "Meu perfil",
  "New Releases": "Novos lançamentos",
  "No results found for": "Nenhum resultado encontrado para {query}",
  "Overall": "Geral",
  "Places": "Locais",
  "Rate this beer on these attributes": "Avalie esta cerveja nesses atributos",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "A RateBeer trabalha com os maiores varejistas para oferecer aos amantes de cerveja uma variedade imbatível de cervejas com os melhores preços",
  "Ratings": "Classificações",
  "Read more": "leia mais",
  "Retailers": "Varejistas",
  "Review this place": "Avaliar este lugar",
  "Save": "Salvar",
  "Search beers spotted here": "Pesquisar as cervejas encontradas aqui",
  "Search for Beers, Breweries, or Bars...": "Pesquise por cervejas, cervejarias ou bares…",
  "Seasonal beer": "Cerveja sazonal",
  "Shop now": "Compre agora",
  "Sign Up": "Inscreva-se",
  "Similar beers": "Cervejas semelhantes",
  "Taps {tapsCount}": "{tapsCount} torneiras",
  "Taste": "Gosto",
  "The most comprehensive ratings and reviews of beers from around the world": "As classificações e avaliações de cervejas de todo mundo mais abrangente",
  "This score could not be updated": "Esta pontuação não pôde ser atualizada.",
  "This score has been updated": "Esta pontuação foi atualizada",
  "Total score": "PONTUAÇÃO TOTAL",
  "View beer menu": "Ver cardápio de cervejas",
  "View food menu": "Ver cardápio de alimentação",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Veja as ofertas de {count} {count, plural, one {retailer} other {retailers}}",
  "We apologise for the delay you are experiencing.": "Pedimos desculpas pelo atraso.",
  "We are currently updating the website and expect this page to be back shortly.": "No momento, estamos atualizando o nosso site e esperamos que a página volte em breve.",
  "Your review has been updated": "Sua avaliação foi atualizada",
  "add.abv": "% ABV",
  "add.abvPlaceholder": "(ex. 11%)",
  "add.addBeer": "Adicionar uma nova cerveja",
  "add.addBrewery": "Adicionar uma nova cervejaria",
  "add.autumn": "Outono",
  "add.beerGuidelines": "Instruções",
  "add.beerGuidelines1": "As cervejas devem existir comercialmente e estar em produção.",
  "add.beerGuidelines2": "As receitas devem ser exclusivas. Sem barril, nitrogênio, etc. ou variações de uma receita.",
  "add.beerGuidelines3": "Cidras frutadas devem ter mais de 50% de maçã fermentada e estar acima de 2% ABV.",
  "add.beerGuidelines4": "Inclua o nome dos produtores na frente do nome da cerveja, por exemplo: {correctExample} não {incorrectExample}.",
  "add.beerImage": "Adicionar imagem",
  "add.beerName": "Nome da cerveja",
  "add.beerNameExplanation": "A convenção de nomenclatura do RateBeer é incluir o nome da cervejaria + o nome da cerveja.",
  "add.beerNameInfo": "(Incluir cervejeiro)",
  "add.beerNamePlaceholder": "(ex. Elysian Space Dust)",
  "add.breweryName": "Nome da cervejaria",
  "add.breweryNamePlaceholder": "(ex. Elysian)",
  "add.contractBrewer": "Produzida por",
  "add.contractBrewerPlaceholder": "Pesquisar cervejarias...",
  "add.contractBrewerPrompt": "É fabricada fora da cervejaria",
  "add.description": "Descrição comercial",
  "add.descriptionPlaceholder": "Texto do rótulo da cerveja",
  "add.duplicateFound": "Duplicate Found",
  "add.duplicateMessage": "Is this the beer you're trying to add?",
  "add.ibuPlaceholder": "(ex. 57)",
  "add.imageInstructions": "*Use imagens de latas ou garrafas",
  "add.invalid": "Inválido",
  "add.newBeer": "Adicionar uma nova cerveja",
  "add.noBrewery": "Não consegue encontrar uma cervejaria?",
  "add.optional": "Opcional",
  "add.removeBeerImage": "Remover imagem",
  "add.seasonalPlaceholder": "É uma cerveja sazonal?",
  "add.series": "Série",
  "add.signInToAddBeer": "Faça login para adicionar uma cerveja",
  "add.special": "Lançamento especial",
  "add.spring": "Primavera",
  "add.stylePlaceholder": "Selecionar estilo",
  "add.summer": "Verão",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Número do código de barras)",
  "add.upcInstructions": "Inclua todos os números 8, 12, 13 ou 14",
  "add.upcPlaceholder": "Todos os números do código de barras",
  "add.view": "View",
  "add.winter": "Inverno",
  "all.adblock": "Parece que há um bloqueador de anúncios instalado. Considere a possibilidade de adicionar o domínio RateBeer.com como uma exceção. Saúde!",
  "all.ago": "{date} atrás",
  "all.back": "Back",
  "all.beer": "Cerveja",
  "all.beers": "Cervejas",
  "all.cider": "Cidra",
  "all.ciders": "Cidras",
  "all.cookies": "Nós usamos cookies e afins",
  "all.error": "Opa, ocorreu um erro de rede",
  "all.joinBody": "Aproveite tudo o que a RateBeer tem a oferecer. Leia avaliações completas, veja as estatísticas detalhadas e confira o que a nossa community tem a dizer.",
  "all.joinTitle": "Junte-se à nossa comunidade!",
  "all.learnMore": "Saiba mais",
  "all.loading": "Carregando",
  "all.mead": "Hidromel",
  "all.meads": "Hidromel",
  "all.noSuchBeer": "Esta cerveja não se encontra na base de dados. Tente buscar por outra cerveja.",
  "all.ok": "Ok",
  "all.sake": "Saquê",
  "all.sakes": "Saquês",
  "banner.free": "Grátis – Google Play",
  "banner.getIt": "Obter",
  "banner.officialApp": "Aplicativo oficial",
  "beer.aboutBeer": "Sobre esta Cerveja",
  "beer.aboutCider": "Sobre esta Cidra",
  "beer.aboutMead": "Sobre esta Hidromel",
  "beer.aboutSake": "Sobre esta Saquês",
  "beer.abv": "ABV",
  "beer.aka": "Também conhecido como",
  "beer.aliasBeer": "Cerveja Apelido",
  "beer.aliasCopy": "A cervejeira comercializa esse mesmo produto (ou praticamente o mesmo produto) com mais de um nome. Isso pode ser o resultado de uma cervejaria distribuir a mesma cerveja com nomes diferentes, em diferentes países, ou à cervejeira simplesmente alterar o nome, mas não a receita, de tempos em tempos.",
  "beer.allStyles": "Todos os estilos de cerveja para ",
  "beer.appearance": "Aparência",
  "beer.applyToSellWithUs": "Comece a vender conosco",
  "beer.aroma": "Aroma",
  "beer.autumn": "Outono",
  "beer.availability": "Disponibilidade",
  "beer.availableAt": "Disponível em",
  "beer.averageRatings": "Classificações médias",
  "beer.bottled": "Engarrafado",
  "beer.brewedBy": "Produzida por ",
  "beer.brewedFor": "Produzida para ",
  "beer.buyFrom": "Comprar de",
  "beer.calories": "EST. CAL.",
  "beer.clearRating": "Limpar",
  "beer.copy": "Copiar",
  "beer.delete": "Excluir cerveja / classificações",
  "beer.deleteAlias": "Excluir apelido",
  "beer.deleteConfirm": "Sim, excluir",
  "beer.deleteOption": "Excluir",
  "beer.deleteRating": "Excluir classificação?",
  "beer.distribution": "Distribuição",
  "beer.edit": "Editar",
  "beer.editAlias": "Adicionar/editar apelido",
  "beer.editBarcodes": "Adicionar/editar códigos de barra",
  "beer.editBeer": "Editar esta cerveja",
  "beer.editPicture": "Adicionar/editar imagem",
  "beer.editTags": "Adicionar/Editar Tags",
  "beer.findFriendsText": "Encontre amigos para compartilhar o que você está bebendo e veja o que eles têm experimentado",
  "beer.findFriendsTitle": "Encontrar amigos",
  "beer.findIt": "Encontrar",
  "beer.findItNearYou": "Encontre perto de você",
  "beer.fix": "Corrigir pontuação",
  "beer.flavor": "Gosto",
  "beer.from": "de ",
  "beer.highestScore": "Maior pontuação",
  "beer.howItWorks": "Como funciona",
  "beer.howItWorksContent": "Nosso trabalho é encontrar os varejistas da mais alta qualidade, com a maior variedade e os melhores preços para que você possa comprar sua cerveja favorita",
  "beer.ibu": "IBU",
  "beer.locateSellers": "Localizar vendedores",
  "beer.locations": "{count} {count, plural, one {local} other {locais}}",
  "beer.locationsNearby": "{count} {count, plural, one {local próximo} other {locais próximos}}",
  "beer.mostLiked": "As mais curtidas",
  "beer.mostRecent": "Mais recente",
  "beer.mouthfeel": "Paladar",
  "beer.moveRating": "Mover classificação",
  "beer.myFriends": "Meus amigos",
  "beer.myReview": "Minha avaliação",
  "beer.noDescription": "Nenhuma descrição disponível.",
  "beer.noRating": "Sem classificação",
  "beer.noReviewsText": "Diga ao mundo o que você achou. Seja o primeiro a classificar e avaliar.",
  "beer.noReviewsTitle": "Experimentou esta cerveja?",
  "beer.onTap": "De torneira",
  "beer.outOfProd": "PRODUÇÃO EM FALTA",
  "beer.overall": "Geral",
  "beer.per": "por",
  "beer.privacyNotice": "Ao clicar no (s) link (s) da loja acima, você será encaminhado para o site do varejista e, a partir daí, a Política de Privacidade será aplicada posteriormente.",
  "beer.private": "PRIVADO",
  "beer.quickRate": "Classificação rápida",
  "beer.quickRateSaved": "Classificação rápida salva",
  "beer.quickRated": "Classificação rápida: ",
  "beer.rateAndReview": "Classifique e avalie",
  "beer.rateIt": "Experimentou? Classifique!",
  "beer.ratedBy": "Classificado por",
  "beer.ratingPrivate": "Classificação privada",
  "beer.ratingPublic": "Avaliação pública",
  "beer.readMore": "leia mais",
  "beer.reviews": "Avaliações",
  "beer.reviewsRatings": "Avaliações e classificações",
  "beer.saveRating": "Salve essa classificação!",
  "beer.saveRatingText": "Crie uma conta para salvar classificações, ler avaliações de especialistas, ver classificações detalhadas, avaliar estatísticas e muito mais.",
  "beer.seasonal": "Sazonal",
  "beer.seasonalLabel": "SAZONAL",
  "beer.seeAll": "Ver tudo",
  "beer.seeAllXReviews": "Ver todas as {count} avaliações",
  "beer.sendCorrections": "Enviar correções",
  "beer.series": "Série",
  "beer.serveIn": "Servido em",
  "beer.share": "Compartilhar",
  "beer.shareTutorial": "Compartilhe, envie correções, adicione fotos",
  "beer.shelfTags": "Ver marcação de prateleira",
  "beer.shopNow": "Compre agora",
  "beer.showLess": "mostrar menos",
  "beer.signInToFavorite": "Faça login para marcar essa cerveja como favorita",
  "beer.signInToFind": "Efetue login para encontrar esta cerveja.",
  "beer.signInToLikeReview": "Faça login para curtir essa avaliação",
  "beer.similarBeer": "Cervejas Semelhantes",
  "beer.similarCider": "Cidra Semelhante",
  "beer.similarMead": "Hidromel Semelhante",
  "beer.similarSake": "Saquês Semelhante",
  "beer.special": "Especial",
  "beer.spring": "Primavera",
  "beer.style": "Estilo ",
  "beer.summer": "Verão",
  "beer.tags": "Marcações",
  "beer.top50": "As 50 melhores",
  "beer.topRaters": "Principais classificadores",
  "beer.totalRetailers": "Esta cerveja é comercializada por {count} vendedores externos certificados",
  "beer.undoRating": "Desfazer classificação",
  "beer.unrateable": "Esta cerveja não pode ser classificada",
  "beer.verified": "VERIFICADO",
  "beer.winter": "Inverno",
  "beer.writeAReview": "Escrever uma avaliação",
  "beer.writeFullReview": "Escrever avaliação completa",
  "beer.yourReview": "Sua avaliação",
  "error.refresh": "Atualizar",
  "favorites.instructions": "Quando encontrar uma cerveja que goste, toque no botão salvar para salvá-la para mais tarde. Visualize suas cervejas salvas em um só lugar em todos os seus dispositivos.",
  "favorites.noSavedBeers": "Você não salvou nenhuma cerveja",
  "favorites.remove": "Remover",
  "favorites.removeConfirmation": "Sim, remover",
  "favorites.removePrompt": "Remover de Favoritas?",
  "favorites.saved": "Salvo",
  "favorites.savedConfirmation": "Salvo em favoritas",
  "favorites.signInToViewFavorites": "Faça login para marcar essa cerveja como favorita",
  "favorites.viewAll": "Ver tudo",
  "feedback.android": "Aplicativo móvel para Android",
  "feedback.androidReproduceInfo": "(por exemplo: efetue login, procure pelo leitor de códigos de barras, toque em resultado de cerveja, expanda o painel de classificação de especialistas e submeta a classificação de especialista)",
  "feedback.bug": "Reportar um erro",
  "feedback.bugMessageInfo": "(Que resultados você esperava? Que resultados você vivenciou?)",
  "feedback.bugMessagePrompt": "Resumo do erro",
  "feedback.buildInfo": "(por exemplo: versão do aplicativo 1.7.0 Para encontrar, toque o botão? no canto superior direito da tela inicial)",
  "feedback.buildPrompt": "Versão do aplicativo",
  "feedback.categoryPrompt": "O que você gostaria de fazer hoje?",
  "feedback.correction": "Submeter uma correção",
  "feedback.correctionMessageInfo": "(Como você esperava que as informações aparecessem? Há alguma informação incorreta sendo exibida no momento?)",
  "feedback.correctionMessagePrompt": "Resumo da correção",
  "feedback.email": "E-mail",
  "feedback.header": "Fórum de feedback",
  "feedback.iOS": "Aplicativo móvel para iOS",
  "feedback.iOSReproduceInfo": "(por exemplo: efetue login, procure pelo leitor de códigos de barras, toque em resultado de cerveja, expanda o painel de classificação de especialistas e submeta a classificação de especialista)",
  "feedback.mediumPrompt": "Onde você vivenciou este problema?",
  "feedback.reproducePrompt": "Que medidas podemos tomar para reproduzir este problema em nosso ambiente?",
  "feedback.submit": "Enviar",
  "feedback.submitted": "Obrigado por enviar seu feedback!",
  "feedback.suggestion": "Dar uma sugestão",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "O que você gostaria de ver do RateBeer?",
  "feedback.url": "URL",
  "feedback.username": "Nome de usuário",
  "feedback.web": "Web site",
  "feedback.webReproduceInfo": "(por exemplo: efetue login, acesse ratebeer.com/community, clique no link da barra lateral)",
  "footer.FAQs": "Perguntas frequentes",
  "footer.RBBest": "As melhores do RateBeer",
  "footer.TOS": "Termos de serviço",
  "footer.aboutRateBeer": "Sobre o RateBeer",
  "footer.aboutUs": "Sobre nós",
  "footer.account": "Conta",
  "footer.addBeer": "Adicionar uma cerveja",
  "footer.addBrewer": "Adicionar uma cervejaria",
  "footer.addEvent": "Adicionar um evento",
  "footer.addPlace": "Adicionar um local",
  "footer.addPremium": "Adicionar Premium",
  "footer.addToRB": "Adicionar ao RateBeer",
  "footer.contactUs": "Fale conosco",
  "footer.copyright": "Todos os direitos reservados.",
  "footer.developers": "Desenvolvedores",
  "footer.editAccount": "Editar conta",
  "footer.privacy": "Política de privacidade",
  "footer.theBest": "A melhor",
  "footer.top50Beers": "As 50 melhores cervejas",
  "footer.topReviewers": "Principais avaliadores",
  "glasscopy1": "Tall, thin and footed with a short stem. Flutes are delicate and show off a beer’s body. These vessels are ideal for light and sparkling beers.",
  "glasscopy10": "Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.",
  "glasscopy11": "A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.",
  "glasscopy12": "This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!",
  "glasscopy13": "Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.",
  "glasscopy14": "A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.",
  "glasscopy15": "Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.",
  "glasscopy16": "A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.",
  "glasscopy17": "Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.",
  "glasscopy18": "A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.",
  "glasscopy19": "A small cup, often fluted at the edge, and larger than ochoko.",
  "glasscopy2": "Short glasses, holds around 12oz. of beer. Slightly wider at the mouth than the foot, with gradual, evenly sloping sides. Great basic glass, well-suited for pale lagers.",
  "glasscopy20": "A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.",
  "glasscopy21": "Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.",
  "glasscopy3": "Designed to be drained in a few gulps. These glasses allow for billowing heads to form. Neither are ideal for developing aroma or discerning subtleties.",
  "glasscopy4": "The American microbrewer’s standard. Gently sloped 16oz. glass made for session-type beers. Typically used to serve Ambers and English & American pales.",
  "glasscopy5": "Similar to a shaker but often straight-sided, usually with an additional flair or bulb to retain aroma. These glasses are often marked to measure an imperial pint.",
  "glasscopy6": "A classic in North America, the dimpled mug is large and has a handle. It is convex, with the mouth larger than the base. Dimples make appreciating the appearance of the beer difficult, but the wide mouth releases the aroma nicely. Commonly used for raunchy lagers.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "A small, almost straight-sided glass sits on an inch-long stem and foot. The basic footed pilsner has a slightly bulbous bottom and narrower mouth, which makes it better for drinking than for smelling, and places the most emphasis on the appearance. It has a bit more style than some other glasses, so it is best used for pilsners, and decent cream or golden ales than for the lowliest lagers.",
  "glasscopy9": "Often a favorite of top tasters. The Duvel glass is a well-known variant of the tulip style, and the tasting glass in the RateBeer logo is an almost perfect example.",
  "header.my2017": "Meu ano na cerveja",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "As melhores cervejas por estilo",
  "home.Top Rated Beers Nearby": "As cervejas mais bem classificadas em locais próximos",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Baixe o aplicativo",
  "home.downloadBlurb": "Leve o RateBeer com você para onde for! Oferecemos ótimos recursos para ajudá-lo a encontrar novas cervejas que você adorará.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Mensagem enviada!",
  "home.porter": "Porter",
  "home.retailers": "Varejistas",
  "home.saison": "Saison",
  "home.searchPrompt": "Encontre cervejas, cervejarias e bares…",
  "home.shopOnline": "Loja online",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Text Link",
  "home.top50Beers": "50 melhores cervejas",
  "home.worldOfBeer": "Seu guia para o mundo da cerveja",
  "landing.TOS": "Termos de serviço",
  "landing.feedback": "Esta é a nossa primeira tentativa de criar um aplicativo para o RateBeer.{br}Ajude-nos a torná-lo ainda melhor.",
  "landing.h1": "O site de classificação de cerveja nº 1 do mundo{br}agora é um aplicativo!",
  "landing.h2": "Agora você pode classificar{br}a partir da palma da sua mão",
  "landing.label": "classificações",
  "landing.li1": "Pesquise em nossa base de dados com mais de 500 mil cervejas",
  "landing.li2": "Classifique, avalie e compartilhe",
  "landing.li3": "Mantenha o registro de todas as cervejas que já experimentou",
  "landing.privacy": "POLÍTICA DE PRIVACIDADE",
  "landing.review": "O RateBeer é a fonte mais confiável de informações sobre cervejas e cervejarias disponível na internet.",
  "landing.rightsReserved": "Todos os direitos reservados.",
  "landing.submit": "Envie um feedback",
  "landing.visit": "Visite ratebeer.com",
  "place.addBeerIds": "Adicionar IDs de cervejas",
  "place.addBeers": "Adicionar cervejas",
  "place.addEvent": "Adicionar evento",
  "place.ambiance": "Ambiente",
  "place.available": "Disponível",
  "place.beerMenu": "Cardápio de cervejas",
  "place.close": "Fechar",
  "place.customizeShelftags": "Personalizar Shelftags",
  "place.editBeers": "Editar cervejas",
  "place.editPlace": "Editar local",
  "place.flights": "Voos",
  "place.food": "Comida",
  "place.foodMenu": "Cardápio",
  "place.getDirection": "Obter instruções",
  "place.hours": "Horas",
  "place.lessInfo": "Menos informação",
  "place.map": "Mapa",
  "place.moreInfo": "Mais informação",
  "place.overall": "Geral",
  "place.phone": "Telefone",
  "place.printShelftags": "Imprimir Shelftags",
  "place.selection": "Seleção",
  "place.sendToPhone": "Enviar para o telefone",
  "place.service": "Serviço",
  "place.shelftags": "Shelftags",
  "place.showBreakdown": "Mostrar divisão de pontuação",
  "place.value": "Valor",
  "place.wifi": "Wi-Fi grátis",
  "rate.BOTTLE": "Garrafa",
  "rate.CAN": "Lata",
  "rate.CASK": "Barril",
  "rate.TAP": "Torneira",
  "rate.commentPlaceholder": "Adicionar comentários sobre paladar, descrições, etc...",
  "rate.infoSubtitle": "Para certificar a qualidade de nossas pontuações de cerveja, incluímos apenas as avaliações que tenham análises detalhadas.",
  "rate.locationPlaceholder": "Adicionar localização",
  "rate.nearbyPlaces": "Locais próximos",
  "rate.ourRatingSystem": "Nosso sistema de classificação",
  "rate.private1": "0 - 74 caracteres",
  "rate.private2": "Salvo para uso pessoal",
  "rate.private3": "Não contribui para a pontuação geral",
  "rate.privateLabel": "PRIVADA",
  "rate.public1": "75 caracteres ou mais ",
  "rate.public2": "Contribui para a pontuação geral da cerveja",
  "rate.public3": "Visível para a comunidade",
  "rate.publicLabel": "PÚBLICA",
  "rate.save": "Salvar",
  "rate.searchResults": "Resultados",
  "rate.servedIn": "Servido em",
  "rate.totalScore": "PONTUAÇÃO TOTAL",
  "search.advanced": "Busca avançada",
  "search.beerCaps": "CERVEJAS",
  "search.beerHeader": "Cervejas",
  "search.beerSearchFor": "Digite na barra de pesquisa para encontrar cervejas",
  "search.brewersCaps": "CERVEJARIAS",
  "search.brewersHeader": "Cervejarias",
  "search.brewersSearchFor": "Digite na barra de pesquisa para encontrar cervejarias",
  "search.closestMatch": "Correspondência mais próxima",
  "search.for": "Busque por",
  "search.in": "em",
  "search.mostRatings": "A maioria das classificações",
  "search.myRating": "Minha classificação",
  "search.noResults": "Nenhum resultado para ",
  "search.pCaps": "LOCAIS",
  "search.pHeader": "Locais",
  "search.pSearchFor": "Digite na barra de pesquisa para encontrar locais",
  "search.resultFor": "resultado para",
  "search.resultLabelStr": "Classificações",
  "search.resultsFor": "resultados para",
  "search.sortBy": "Ordenar por",
  "search.sortByCaps": "ORDENAR POR",
  "search.topRated": "Melhor classificado",
  "search.userHeader": "Usuários",
  "search.userSearchFor": "Digite na barra de pesquisa para encontrar usuários"
}
