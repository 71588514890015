import React, { Component } from 'react'
import { Spinner } from 'lib/components'
import store from 'store'
import {
  STORAGE_KEY_URL_AT_AUTH_FLOW_START,
  STORAGE_KEY_PENDING_NAVIGATION
} from '../constants'
import { captureError } from '../utils'

export class AuthCallback extends Component {
  componentDidMount() {
    // the lock.on('authenticated' method should handle post-auth nav
    // should something go wrong, this will ensure the user isn't stuck
    // on the call back page
    setTimeout(() => {
      // Extract the original url at the time auth was triggered
      const redirectURL = store.get(STORAGE_KEY_URL_AT_AUTH_FLOW_START)
      store.remove(STORAGE_KEY_URL_AT_AUTH_FLOW_START)

      // Extract the gated destination if it exists
      const pendingNav = store.get(STORAGE_KEY_PENDING_NAVIGATION)
      store.remove(STORAGE_KEY_PENDING_NAVIGATION)

      captureError('auto-nav did not fire.', 'AuthCallback page')
      window.location.assign(pendingNav || redirectURL || '/')
    }, 5000)
  }

  render() {
    return <Spinner />
  }
}

export default AuthCallback
