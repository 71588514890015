// ***********************
// DO NOT ADD TO THIS FILE
// PUT CONSTANTS IN utils/constants
// ***********************

export const STATE_AT_REST = 0
export const STATE_SENDING = 2
export const STATE_SUCCEEDED = 3
export const STATE_FAILED = 4

export const CATEGORY_USERS = 'user'
export const CATEGORY_PLACES = 'p'
export const CATEGORY_FAVORITES = 'favorites'
export const CATEGORY_PLACE_PREVIEW = 'place'

export const NUMBER_OF_CHARACTERS_FOR_PUBLIC_REVIEW = 75

export const AVAILABILITY_MAP_VERTICAL_OFFSET = 0.0015

export const SOURCE_HOMEPAGE = 'Homepage'

export const LABEL_TOP_50 = 'top50style'

export const CAROUSEL_TYPE_STYLE = 'STYLE_CAROUSEL'
export const CAROUSEL_TYPE_BEER = 'BEER_CAROUSEL'

export const CAROUSEL_TOP_BY_STYLE = 'Top Beers by Style'
export const CAROUSEL_NEARBY_BEERS = 'Top Rated Beers Nearby'
export const CAROUSEL_STYLE_RECOMMENDATIONS = 'Recommended for You'
export const CAROUSEL_TOP_LIST = 'top50Beers'

// Link and image identifiers for Homepage Top Beers by Style Carousel
export const styleCarouselCardArr = [
  'india-pale-ale',
  'american-pale-ale',
  'saison',
  'imperial-double-ipa',
  'sour-wild-ale',
  'golden-ale-blond-ale',
  'imperial-stout',
  'stout',
  'porter',
  'amber-ale'
]

export const ARROW_DIRECTION_NEXT = 'next'
export const ARROW_DIRECTION_PREV = 'previous'

// Place analytics
export const EVENT_CATEGORY_PLACE = 'Places'
export const EVENT_ACTION_VIEW_BEER_MENU = 'View Beer Menu'
export const EVENT_ACTION_ADD_BEER_TO_MENU = 'Add beer to menu'
export const EVENT_ACTION_RATE_PLACE = 'Rate place'

// HOMEPAGE ANALYTICS
export const EVENT_CATEGORY_HOMEPAGE_CAROUSEL = 'Homepage Carousel'
export const EVENT_CAROUSEL_CLICK_NEW_PAGE =
  'User clicked to scroll to new page'
export const EVENT_CAROUSEL_CLICK_PREV_PAGE =
  'User clicked to scroll to previous page'
export const EVENT_CARD_CLICK = 'User clicked card at position'

// REVIEWS
export const EVENT_CATEGORY_REVIEWS = 'Reviews'

// LOCATE SELLERS
export const EVENT_CATEGORY_LOCATE_SELLERS = 'Locate Sellers'
export const EVENT_LOCATE_SELLERS_CLICKED = 'User clicked on locate sellers'

// EDIT REVIEWS
export const EVENT_CATEGORY_EDIT_REVIEWS = 'Edit Review'

export const EVENT_REVIEW_EDIT_MODAL_LINK_CLICKED =
  'Review Edit Modal link clicked'

// DOWNLOAD APP CTAS
export const EVENT_CATEGORY_DOWNLOAD_APP_CTA = 'Download App CTA'
export const EVENT_USER_DISMISSED_APP_BANNER =
  'User dismissed Android Download App Banner'
export const EVENT_USER_CLICKED_THROUGH_APP_BANNER =
  'User clicked through banner to get Android App'

// MISC EVENTS
export const EVENT_COOKIE_TERMS_CONSENT_CAPTURED =
  'User consented to cookie policy'

// AUTH FLOW EVENTS
export const EVENT_USER_TRIGGERED_AUTHENTICATION =
  'User triggered authentication flow'
export const EVENT_USER_CLICKED_SIGN_UP = 'User clicked sign up'
export const EVENT_USER_CLICKED_SIGN_IN = 'User clicked sign in'
export const EVENT_USER_COMPLETED_AUTHENTICATION_FLOW =
  'User authenticated successfully'
export const EVENT_COMPLETED_SIGN_UP_FLOW =
  'User created an account successfully'
export const EVENT_USER_CANCELLED_AUTHENTICATION =
  'User cancelled authentication flow'
export const EVENT_USER_LOGGED_OUT = 'User logged out'

export const STORAGE_KEY_URL_AT_AUTH_FLOW_START =
  'The URL the user initiated auth from'
export const STORAGE_KEY_PENDING_NAVIGATION = 'Pending navigation URL'
export const STORAGE_KEY_FUNNEL_ORIGIN = 'Auth flow origin'
export const STORAGE_KEY_AUTH_USER_TYPE = 'Auth flow user type'

// ERROR EVENTS
export const EVENT_ERROR = 'Errors'
export const EVENT_CORRUPT_SESSION = 'Corrupt session detected'
export const EVENT_EXPIRED_JWT = 'Expired JWT detected'

// ADSENSE
export const EVENT_ADBLOCK_DETECTED = 'Ad blocker detected'

export const EVENT_APP_STORE_LINK_SMS = 'User triggered an SMS for app download'

export const SERVED_IN_CASK = 'CASK'
export const SERVED_IN_TAP = 'TAP'
export const SERVED_IN_CAN = 'CAN'
export const SERVED_IN_BOTTLE = 'BOTTLE'
export const SERVED_IN_UNKNOWN = 'UNKNOWN'

import React from 'react'
import TapIcon from 'images/tap.svg'
import BottleIcon from 'images/bottle.svg'
import CanIcon from 'images/can.svg'
import CaskIcon from 'images/cask.svg'

export const servedInIcons = {
  CASK: <CaskIcon />,
  TAP: <TapIcon />,
  CAN: <CanIcon />,
  BOTTLE: <BottleIcon />,
  UNKNOWN: <div />
}

// ADD A BEER FORM CONSTANTS
export const brewerStringsToStrip = [
  ' Ltd.',
  ' Beer Company',
  ' Ale Companie',
  ' Beer Companie',
  ' Ale Works',
  ' Ale Company',
  ' Ltd',
  ' Limited',
  ' Cider Company',
  ' Cider Co.',
  ' Co.',
  ' AG',
  ' OOD',
  ' GmBH',
  'Brasserie ',
  'Brouwerij ',
  'Browar ',
  'Privat Brauerie',
  'Brauerie',
  'Browary ',
  'Pivovarna ',
  'Pivovar ',
  'Pivara ',
  'Pivzavod ',
  ' Brewpub',
  ' Restaurant &',
  ' & Restaurant',
  ' and Restaurant',
  ' & Pizzeria',
  ' Pizzeria &',
  ' and Pizzeria',
  ' Pizzeria and',
  ' Pub and',
  ' Pub &',
  ' & Pub',
  ' and Pub',
  ' & Grill',
  ' and Grill ',
  ' Smokehouse & Brewery',
  ' Brewery',
  ' Company',
  ' Brewing',
  ' Brewhouse',
  ' Microbrasserie',
  ' - Microbrasserie',
  ' Brasserie Rurale',
  ' - Brasserie Rurale',
  ' Brasseur Itinérant',
  ' - Brasseur Itinérant',
  ' Artisan Brasseur',
  ' - Artisan Brasseur',
  ' Artisans Brasseurs',
  ' - Artisans Brasseurs',
  ' Brasserie Artisanale',
  ' - Brasserie Artisanale',
  ' - Bar à Bière Maison',
  ' Bistro-Brasserie',
  ' - Bistro-Brasserie',
  ' - Microbrasserie Universitaire',
  ' Broue Pub',
  ' Brouepub',
  ' - Brouepub',
  ' Brasseur Artisanal',
  ' - Brasseur Artisanal',
  ' Brasserie Éducative',
  ' - Brasserie Éducative',
  ' Brasserie',
  ' - Brasserie ',
  ' & Distillerie',
  ' - Microbrasserie Pirate',
  ' Ferme Brassicole',
  ' - Ferme Brassicole',
  ' Domaine',
  ' Ferme Brasserie',
  ' - Ferme Brasserie',
  ' Ferme-Brasserie',
  ' - Ferme Brasserie',
  ' Bistro Brasserie',
  ' - Bistro Brasserie',
  ' Bière Artisanale',
  ' - Bière Artisanale',
  ' Bières Artisanales',
  ' - Bières Artisanales',
  ' - Brasseur Nomade',
  ' Bièrerie',
  ' - Bièrerie',
  ' - Brasseurs de Quartier',
  ' La Brasserie',
  ' - Brasseurs',
  ' Resto-Brasserie',
  ' - Resto-Brasserie',
  ' - Spécialités Microbrasseries',
  ' Brasserie Coop',
  ' - Brasserie Coop',
  ' Coopérative Brassicole',
  ' - Coopérative Brassicole',
  ' Distillerie &',
  ' & Charcuteries',
  ' - Brasseur'
]

export const seasonOptions = [
  'WINTER',
  'SPRING',
  'SUMMER',
  'AUTUMN',
  'SPECIAL',
  'SERIES'
]