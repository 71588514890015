import React from 'react'
import MPaper, { PaperProps as MPaperProps } from '@material-ui/core/Paper'
import { noopTemplate as css } from 'lib/utils'
import { BORDER_RADIUS_LARGE } from 'styles/constants'

export type PaperProps = MPaperProps

export const Paper = ({ ...props }: PaperProps) => {
  return (
    <MPaper
      css={css`
        border-radius: ${BORDER_RADIUS_LARGE}px;
      `}
      {...props}
    />
  )
}
