export default {
  "Account Settings": "Kontoinställningar",
  "Add description": "Lägg till beskrivning",
  "Add location": "Lägg till plats",
  "Appearance": "Utseende",
  "Apply to sell with us": "Ansök om att sälja hos oss",
  "Are you sure you want to delete your review?": "Är du säker på att du vill ta bort recensionen?",
  "Aroma": "Arom",
  "Associated Brewer": "Associerat bryggeri",
  "Available here": "Tillgängligt här",
  "Available to buy from {count} trusted retail partners": "Finns att köpa från {count} betrodda partneråterförsäljare",
  "Average": "Genomsnitt",
  "Beer name": "Ölets namn",
  "Beer styles": "Ölstilar",
  "Beers Spotted Here": "Öl som setts här",
  "Bottles {bottlesCount}": "Flaskor {bottlesCount}",
  "Buy from": "Köp från",
  "Cancel": "Avbryt",
  "Community": "Community",
  "Copied to clipboard": "Kopierat till Urklipp",
  "Copy to clipboard": "Kopiera till Urklipp",
  "Country of origin": "Ursprungsland",
  "Events": "Händelser",
  "Filter": "Filter",
  "Find a beer or brewer": "Hitta en öl eller ett bryggeri",
  "Find a country": "Hitta ett land",
  "Find a style": "Hitta en stil",
  "Follow on Social Media": "Följ på sociala medier",
  "Forums": "Forum",
  "From {price} per {serve}": "Från {price} per {serve}",
  "Latest Activity": "Senaste aktivitet",
  "Log In": "Logga in",
  "Log Out": "Logga ut",
  "Manage Breweries Followed": "Hantera bryggerier du följer",
  "Manage People Followed": "Hantera människor du följer",
  "Mouthfeel": "Smakupplevelse",
  "My Beer Cellar": "Min ölkällare",
  "My Beer Ratings": "Mina betyg för ölsorter",
  "My Beer Styles": "Mina ölstilar",
  "My Breweries": "Mina bryggerier",
  "My Countries & States": "Mina länder och städer",
  "My Favorites": "Mina favoriter",
  "My Place Ratings": "Mina betyg för platser",
  "My Profile": "Min profil",
  "New Releases": "Nya releaser",
  "No results found for": "Inga resultat hittades för {query}",
  "Overall": "Övergripande",
  "Places": "Platser",
  "Rate this beer on these attributes": "Betygsätt den här ölen baserat på dessa egenskaper",
  "RateBeer works with the biggest retailers to give beer lovers an unbeatable range of beers at the best prices": "RateBeer samarbetar med de största återförsäljarna för att ge ölälskare ett oslagbart utbud av öl till bästa pris",
  "Ratings": "Betyg",
  "Read more": "läs mer",
  "Retailers": "Återförsäljare",
  "Review this place": "Recensera den här platsen",
  "Save": "Spara",
  "Search beers spotted here": "Sök efter öl som setts här",
  "Search for Beers, Breweries, or Bars...": "Sök efter öl, bryggerier eller barer …",
  "Seasonal beer": "Säsongsbetonade öl",
  "Shop now": "Handla nu",
  "Sign Up": "Registrera dig",
  "Similar beers": "Liknande ölsorter",
  "Taps {tapsCount}": "Kranar {tapsCount}",
  "Taste": "Smak",
  "The most comprehensive ratings and reviews of beers from around the world": "De mest omfattande betygen och recensionerna för öl från hela världen",
  "This score could not be updated": "De här poängen kunde inte uppdateras",
  "This score has been updated": "De här poängen har uppdaterats",
  "Total score": "TOTALPOÄNG",
  "View beer menu": "Visa ölmeny",
  "View food menu": "Visa matmeny",
  "View offers from {count} {count, plural, one {retailer} other {retailers}}": "Visa erbjudanden från {count} {count, plural, one {retailer} other {retailers}}",
  "We apologise for the delay you are experiencing.": "Vi ber om ursäkt för fördröjningen",
  "We are currently updating the website and expect this page to be back shortly.": "Vi uppdaterar för närvarande webbplatsen och vi förväntar att den är tillgänglig igen inom kort.",
  "Your review has been updated": "Din recension har uppdaterats",
  "add.abv": "Alkoholhalt %",
  "add.abvPlaceholder": "(t.ex. 11 %)",
  "add.addBeer": "Lägg till nytt öl",
  "add.addBrewery": "Lägg till nytt bryggeri",
  "add.autumn": "Höst",
  "add.beerGuidelines": "Riktlinjer",
  "add.beerGuidelines1": "Öl måste existera kommersiellt och vara i produktion.",
  "add.beerGuidelines2": "Recept måste vara unika. Inga cask-, nitro- etc. variationer på ett recept.",
  "add.beerGuidelines3": "Fruktcider måste bestå av mer än 50 % fermenterbart äpple och över 2 % alkoholhalt.",
  "add.beerGuidelines4": "Lägg till tillverkarens namn framför namnet på ölet, t.ex. {correctExample} inte {incorrectExample}.",
  "add.beerImage": "Lägg till bild",
  "add.beerName": "Ölets namn",
  "add.beerNameExplanation": "Namngivningskonventionen för RateBeer är att inkludera bryggerinamn + ölnamn.",
  "add.beerNameInfo": "(Inkludera bryggeri)",
  "add.beerNamePlaceholder": "(t.ex. Elysian Space Dust)",
  "add.breweryName": "Bryggeriets namn",
  "add.breweryNamePlaceholder": "(t.ex. Elysian)",
  "add.contractBrewer": "Bryggt av",
  "add.contractBrewerPlaceholder": "Sök efter bryggeri ...",
  "add.contractBrewerPrompt": "Det här är ett kontraktsöl",
  "add.description": "Handelsbeteckning",
  "add.descriptionPlaceholder": "Det här står på produktetiketten",
  "add.duplicateFound": "Hittade dubblett",
  "add.duplicateMessage": "Är det här den öl du försöker lägga till?",
  "add.ibuPlaceholder": "(t.ex. 57)",
  "add.imageInstructions": "* Använd bilder på burkar och flaskor",
  "add.invalid": "Ogiltig",
  "add.newBeer": "Lägg till en ny öl",
  "add.noBrewery": "Hittar du inte ditt bryggeri?",
  "add.optional": "Frivilligt",
  "add.removeBeerImage": "Ta bort bild",
  "add.seasonalPlaceholder": "Är det här ett säsongsbetonat öl?",
  "add.series": "Serie",
  "add.signInToAddBeer": "Logga in för att lägga till en öl",
  "add.special": "Specialreleaser",
  "add.spring": "Vår",
  "add.stylePlaceholder": "Välj stil",
  "add.summer": "Sommar",
  "add.upc": "UPC/GTIN",
  "add.upcInfo": "(Streckkodsnummer)",
  "add.upcInstructions": "Inkludera alla 8, 12, 13 eller 14 nummer",
  "add.upcPlaceholder": "Alla nummer på streckkoden",
  "add.view": "Visa",
  "add.winter": "Vinter",
  "all.adblock": "Det ser ut som om du har en adblocker installerad. Kanske du kunde överväga att lägga till RateBeer.com till undantagen. Tack!",
  "all.ago": "{date} sedan",
  "all.back": "Back",
  "all.beer": "Öl",
  "all.beers": "Öl",
  "all.cider": "Cider",
  "all.ciders": "Ciderdrycker",
  "all.cookies": "Vi använder cookies och sånt",
  "all.error": "Ojdå, det var visst ett fel i nätverket.",
  "all.joinBody": "Dra nytta av allt RateBeer har att erbjuda. Läsa hela recensioner, se detaljerad statistik och kolla vad vårt community har att säga.",
  "all.joinTitle": "Gå med i vår community!",
  "all.learnMore": "Läs mer",
  "all.loading": "Läser in",
  "all.mead": "Mjöd",
  "all.meads": "Mjöddrycker",
  "all.noSuchBeer": "Det finns ingen sådan öl i databasen. Prova att leta efter en annan ölsort.",
  "all.ok": "OK",
  "all.sake": "Sake",
  "all.sakes": "Sake-drycker",
  "banner.free": "Gratis – Google Play",
  "banner.getIt": "Ladda ned",
  "banner.officialApp": "Officiell app",
  "beer.aboutBeer": "Om denna Öl",
  "beer.aboutCider": "Om denna Cider",
  "beer.aboutMead": "Om denna Mjöd",
  "beer.aboutSake": "Om denna Sake",
  "beer.abv": "Alkoholhalt",
  "beer.aka": "Kallas även",
  "beer.aliasBeer": "Öl med pseudonym",
  "beer.aliasCopy": "Bryggeriet marknadsför den här liknande eller nästan liknande produkten under fler än ett namn. Det här kan vara ett resultat av att bryggeriet distribuerar produkten under olika namn i olika länder, eller så har bryggeriet helt enkelt ändrat på namnet, men inte på receptet, en eller flera gånger.",
  "beer.allStyles": "Alla ölsorter för ",
  "beer.appearance": "Utseende",
  "beer.applyToSellWithUs": "Ansök om att sälja via oss",
  "beer.aroma": "Arom",
  "beer.autumn": "Höst",
  "beer.availability": "Tillgänglighet",
  "beer.availableAt": "Tillgänglig på",
  "beer.averageRatings": "Genomsnittsbetyg",
  "beer.bottled": "På flaska",
  "beer.brewedBy": "Bryggt av ",
  "beer.brewedFor": "Bryggt för ",
  "beer.buyFrom": "Köp från",
  "beer.calories": "Uppskattad kalorimängd",
  "beer.clearRating": "Ta bort",
  "beer.copy": "Kopiera",
  "beer.delete": "Ta bort öl/betyg",
  "beer.deleteAlias": "Ta bort pseudonym",
  "beer.deleteConfirm": "Ja, ta bort",
  "beer.deleteOption": "Ta bort",
  "beer.deleteRating": "Vill du ta bort betyget?",
  "beer.distribution": "Distribution",
  "beer.edit": "Redigera",
  "beer.editAlias": "Lägg till/redigera pseudonym",
  "beer.editBarcodes": "Lägg till/redigera streckkoder",
  "beer.editBeer": "Redigera ölet",
  "beer.editPicture": "Lägg till/redigera bild",
  "beer.editTags": "Lägg till/redigera Tags",
  "beer.findFriendsText": "Hitta dina vänner – dela vad du dricker och se vad de har provat",
  "beer.findFriendsTitle": "Hitta vänner",
  "beer.findIt": "Hitta den",
  "beer.findItNearYou": "Hitta det nära dig",
  "beer.fix": "Fixa poäng",
  "beer.flavor": "Smak",
  "beer.from": "från",
  "beer.highestScore": "Högsta poäng",
  "beer.howItWorks": "Så här fungerar det",
  "beer.howItWorksContent": "Vi jobbar hårt med att hitta återförsäljare åt dig av den allra högsta kvaliteten med det bredast urvalet och de bästa priserna, så att du kan köpa öl du älskar",
  "beer.ibu": "IBU-värde",
  "beer.locateSellers": "Hitta återförsäljare",
  "beer.locations": "{count} {count, plural, one {plats} other {platser}}",
  "beer.locationsNearby": "{count} {count, plural, one {plats i närheten} other {platser i närheten}}",
  "beer.mostLiked": "Mest omtyckta",
  "beer.mostRecent": "Den senaste",
  "beer.mouthfeel": "Munkänsla",
  "beer.moveRating": "Flytta betyg",
  "beer.myFriends": "Mina vänner",
  "beer.myReview": "Min recension",
  "beer.noDescription": "Ingen tillgänglig beskrivning",
  "beer.noRating": "Inget betyg",
  "beer.noReviewsText": "Tala om för världen vad du tycker. Bli den första att betygsätta och recensera!",
  "beer.noReviewsTitle": "Har du provat detta öl?",
  "beer.onTap": "På fat",
  "beer.outOfProd": "UR PRODUKTION",
  "beer.overall": "Övergripande",
  "beer.per": "per",
  "beer.privacyNotice": "Genom att klicka på butikslänkarna ovan skickas du till återförsäljarens webbplats och därifrån kommer deras integritetspolicy att gälla.",
  "beer.private": "PRIVAT",
  "beer.quickRate": "Snabbetygsätt",
  "beer.quickRateSaved": "Snabbetyg sparat",
  "beer.quickRated": "Snabbetygsatt: ",
  "beer.rateAndReview": "Betygsätt och recensera",
  "beer.rateIt": "Har du provat det här? Betygsätt det!",
  "beer.ratedBy": "Betygsatt av",
  "beer.ratingPrivate": "Privat betyg",
  "beer.ratingPublic": "Offentligt betyg",
  "beer.readMore": "läs mer",
  "beer.reviews": "Recensioner",
  "beer.reviewsRatings": "Recensioner och betyg",
  "beer.saveRating": "Spara betyget!",
  "beer.saveRatingText": "Skapa ett konto för att spara betyg, läsa expertrecensioner, se detaljerad statistik om betyg och recensioner och mer därtill.",
  "beer.seasonal": "Säsongsbetonade",
  "beer.seasonalLabel": "SÄSONGSBETONADE",
  "beer.seeAll": "Visa alla",
  "beer.seeAllXReviews": "Visa alla {count} recensioner",
  "beer.sendCorrections": "Skicka in rättelser",
  "beer.series": "Serie",
  "beer.serveIn": "Servera i",
  "beer.share": "Dela",
  "beer.shareTutorial": "Dela, skicka korrigeringar, lägg till bilder",
  "beer.shelfTags": "Visa hylltagg",
  "beer.shopNow": "Shoppa nu",
  "beer.showLess": "visa mindre",
  "beer.signInToFavorite": "Logga in för att spara den här ölen i dina favoriter",
  "beer.signInToFind": "Logga in för att hitta den här ölsorten.",
  "beer.signInToLikeReview": "Logga in för att gilla den här recensionen",
  "beer.similarBeer": "Liknande Öl",
  "beer.similarCider": "Liknande Ciderdrycker",
  "beer.similarMead": "Liknande Mjöddrycker",
  "beer.similarSake": "Liknande Sake-drycker",
  "beer.special": "Special",
  "beer.spring": "Vår",
  "beer.style": "Stil ",
  "beer.summer": "Sommar",
  "beer.tags": "Taggar",
  "beer.top50": "Topp 50",
  "beer.topRaters": "Expertrecensenter",
  "beer.totalRetailers": "Denna öl säljs av {count} verifierade tredjepartsåterförsäljare",
  "beer.undoRating": "Ångra betyg",
  "beer.unrateable": "Detta öl kan inte betygsättas",
  "beer.verified": "VERIFIERAD",
  "beer.winter": "Vinter",
  "beer.writeAReview": "Skriv en recension",
  "beer.writeFullReview": "Skriv en hel recension",
  "beer.yourReview": "Din recension",
  "error.refresh": "Uppdatera",
  "favorites.instructions": "När du hittar en öl som du gillar kan du trycka på knappen Spara för att spara den till senare. Visa dina sparade öl på ett ställe på alla dina enheter.",
  "favorites.noSavedBeers": "Du har inte sparat någon öl",
  "favorites.remove": "Ta bort",
  "favorites.removeConfirmation": "Ja, ta bort",
  "favorites.removePrompt": "Vill du ta bort från favoriter?",
  "favorites.saved": "Sparad",
  "favorites.savedConfirmation": "Sparad i favoriter",
  "favorites.signInToViewFavorites": "Logga in för att spara den här ölen i dina favoriter",
  "favorites.viewAll": "Visa alla",
  "feedback.android": "Android-mobilapp",
  "feedback.androidReproduceInfo": "(t.ex. logga in, leta efter streckkod, trycka på ölresultat, utvidga panelen för expertbetyg, skicka in ett expertbetyg)",
  "feedback.bug": "Rapportera en bugg",
  "feedback.bugMessageInfo": "(Vilket resultat förväntade du dig Vad resulterade det i?)",
  "feedback.bugMessagePrompt": "Sammanfattning av bugg",
  "feedback.buildInfo": "(t.ex. appversion 1.7.0. För att hitta ska du trycka på ?-ikonen på startsidans övre högre hörn)",
  "feedback.buildPrompt": "Appversion",
  "feedback.categoryPrompt": "Vad vill du göra i dag?",
  "feedback.correction": "Skicka in en rättelse",
  "feedback.correctionMessageInfo": "(Hur förväntade du dig att informationen skulle visas? Visas det för närvarande felaktig information?)",
  "feedback.correctionMessagePrompt": "Sammanfattning av rättelse",
  "feedback.email": "E-postadress",
  "feedback.header": "Feedback-formulär",
  "feedback.iOS": "iOS-mobilapp",
  "feedback.iOSReproduceInfo": "(t.ex. logga in, leta efter streckkod, trycka på ölresultat, utvidga panelen för expertbetyg, skicka in ett expertbetyg)",
  "feedback.mediumPrompt": "Var hittade du det här problemet?",
  "feedback.reproducePrompt": "Vilka åtgärder kan vi vidta för att återskapa problemet?",
  "feedback.submit": "Skicka in",
  "feedback.submitted": "Tack för att du skickade in din feedback!",
  "feedback.suggestion": "Lämna ett förslag",
  "feedback.suggestionMessageInfo": " ",
  "feedback.suggestionMessagePrompt": "Vad vill du att RateBeer ska göra härnäst?",
  "feedback.url": "Webbadress",
  "feedback.username": "Användarnamn",
  "feedback.web": "Webbplats",
  "feedback.webReproduceInfo": "(t.ex. logga in, gå till ratebeer.com/community, klicka på en länk i sidofältet)",
  "footer.FAQs": "Vanliga frågor och svar",
  "footer.RBBest": "De bästa på RateBeer",
  "footer.TOS": "Tjänstevillkor",
  "footer.aboutRateBeer": "Om RateBeer",
  "footer.aboutUs": "Om oss",
  "footer.account": "Konto",
  "footer.addBeer": "Lägg till en öl",
  "footer.addBrewer": "Lägg till ett bryggeri",
  "footer.addEvent": "Lägg till en händelse",
  "footer.addPlace": "Lägg till en plats",
  "footer.addPremium": "Lägg till Premium",
  "footer.addToRB": "Lägg till i RateBeer",
  "footer.contactUs": "Kontakta oss",
  "footer.copyright": "Alla rättigheter förbehålls.",
  "footer.developers": "Utvecklare",
  "footer.editAccount": "Redigera konto",
  "footer.privacy": "Sekretesspolicy",
  "footer.theBest": "De bästa",
  "footer.top50Beers": "De bästa 50 ölen",
  "footer.topReviewers": "Expertrecensenter",
  "glasscopy1": "Högt, smalt och kupat glas på fot med en kort stjälk. Flutes (flöjtglas) är ömtåliga och visar upp en öls kropp. Dessa kärl är ideala för lätta och mousserande öl.",
  "glasscopy10": "Somewhat obscure glass, this short-stemmed footed glass is used by the Belgians for scotch ales. The thistle shape is bulbous at the bottom with flaring straight edge above the bulb that ends wider at the top. It’s wide mouth creates usability issues seen with martini glasses -- a dribble or spill -- and does little for aroma containment.",
  "glasscopy11": "A stretched, three foot (.91 m) tall, curving glass often bulbous at the top and spherical at the bottom. The glass requires a wooden stand. The best-known branded example is the Belgian brand, Pauwel Kwak.",
  "glasscopy12": "This classic German wheat beer glass is tall, curving from a larger bulbous area at the top to a narrow gripping area above the base to a slightly wider base. The shape supports a larger head shape and allows more light to pass through cloudy areas of wheat beers to create a glowing effect. Dissipates cold quickly, so drink up!",
  "glasscopy13": "Normally a squatter, more stylized version of the less elegant, more utilitarian shaker. Hoegaarden is the most common example.",
  "glasscopy14": "A squat, flat-bottom cylinder shape, straight edged or slightly tapered, which has the primary feature of a broad space to contain an ample, rocky foam head. Footed examples may have a spherical bottom and are nearer the shape of a chalice.",
  "glasscopy15": "Also known as a chalice, this beautiful footed glass vessel is bowl-shaped often with a straight or slightly tapered edge above the bowl. The shape showcases the rocky heads of Belgian styles and provides a large surface to release aromas.",
  "glasscopy16": "A footed tapering cylindrical shape exemplified by branded glasses for Celebrator and Harvey Porter.",
  "glasscopy17": "Stemmed and footed, spherical vessels that narrow to the top. Counted by many as a best shape for unparalleled containment of aroma, they are often used in miniature sizes for smaller samples.",
  "glasscopy18": "A small cup that is common in countless varieties, colors and shapes. Cups typically broaden at the neck to allow the fragrance of the sake to waft gently upward.",
  "glasscopy19": "A small cup, often fluted at the edge, and larger than ochoko.",
  "glasscopy2": "Korta glas som rymmer ungefär 33 cl öl. Något bredare i toppen än botten, med succesivt jämnt sluttande sidor. Utmärkt grundglas vilket passar bra för ljusa lager.",
  "glasscopy20": "A square cedar box holding 180ml originally designed as a rice measure. No longer popular, the masu’s wood aromas overpower the delicate flavors of today’s premium sake.",
  "glasscopy21": "Most commonly used to hold and disguise malt liquor or low-end beers. Paper bags are occasionally used to market and package some beer styles.",
  "glasscopy3": "Designade för att tömmas i ett par munfullar. Dessa glas tillåter bildandet av böljande skumkronor. Inget av glasen passar för utvecklande av aromer eller uppfatta subtil karaktär.",
  "glasscopy4": "Standard hos amerikanska mikrobryggerier. Mjukt sluttande glas vilket rymmer ca 47 cl och är framtaget för öl av typ session. Används typiskt för att servera öl som Amber och Pale Ale.",
  "glasscopy5": "Liknar glastypen shaker, dock ofta med raka sidor. Vanligen med en ytterligare utbuktning för att hålla kvar aromer. Glasen är ofta markerade för att kunna mäta upp en imperial (engelsk) pint.",
  "glasscopy6": "Sejdeln är en klassiker i Nordamerika, den är stor och har handtag. Glaset är konvext, där toppen är större än basen. Groparna i glaset gör att det är svårt att uppskatta ölets utseende, men den vida toppen frigör enkelt aromer. Används ofta för lättdruckna lageröl.",
  "glasscopy7": "Bavarian steins are the most ornate beer vessels. These are usually ceramic, earthenware or stoneware, and are intricately decorated with scenes of nature, castles, and villages. Steins do little for the appearance of the beer, although they are beautiful pieces of folk art. The aromatic aspect is admittedly not as strong from these materials as from glass, but the taste is unencumbered. Use for any traditional lager.",
  "glasscopy8": "Ett glas med nästan helt raka sidor vilket avslutas med en kort stjälk och fot. De vanligaste glasen i typen har en något utbuktande topp och smalare botten, detta gör det lättare att dricka än lukta på ölen, istället läggs mest betoning på utseendet. Glaset har mer elegans än vissa andra typer och används med fördel till pilsner samt bra cream och golden ale än för lågt stående lager.",
  "glasscopy9": "Tulpanglas, eller kupor, är ofta en favorit bland de som provar öl. Glaset från Duvel är en välkänd variant av stilen, även provningsglaset i logon för RateBeer är ett närmast perfekt exempel.",
  "header.my2017": "Mitt år i öl",
  "home.Recommended for You": " Recommended for You",
  "home.Top Beers by Style": "De bästa ölen efter typ",
  "home.Top Rated Beers Nearby": "Högst rankade öl i närheten",
  "home.amber-ale": "Amber Ale",
  "home.american-pale-ale": "American Pale Ale",
  "home.downloadApp": "Ladda ner appen",
  "home.downloadBlurb": "Ta med dig RateBeer var du än är! Vi erbjuder funktioner som hjälper dig hitta öl du kommer att gilla.",
  "home.golden-ale-blond-ale": "Golden Ale/Blond Ale",
  "home.imperial-double-ipa": "Imperial IPA",
  "home.imperial-stout": "Imperial Stout",
  "home.india-pale-ale": "India Pale Ale (IPA)",
  "home.messageSent": "Meddelandet har skickats!",
  "home.porter": "Porter",
  "home.retailers": "Återförsäljare",
  "home.saison": "Saison",
  "home.searchPrompt": "Hitta öl, bryggerier eller barer…",
  "home.shopOnline": "Shoppa online",
  "home.sour-wild-ale": "Sour/Wild Ale",
  "home.stout": "Stout",
  "home.textLink": "Textlänk",
  "home.top50Beers": "De bästa 50 ölen",
  "home.worldOfBeer": "Din guide till världens öl",
  "landing.TOS": "Tjänstevillkor",
  "landing.feedback": "Det här är vårt första försök till en RateBeer-app.{br}Hjälp oss att göra den ännu bättre!",
  "landing.h1": "Världen populäraste webbplats{br}för betygsättande av öl är nu en app!",
  "landing.h2": "Nu kan du betygsätta{br}var du än är",
  "landing.label": "BETYG",
  "landing.li1": "Sök i vår databas som består av över 500 000 ölsorter",
  "landing.li2": "Betygsätt, skriv recensioner och dela",
  "landing.li3": "Håll koll på varje ölsort du har provat",
  "landing.privacy": "SEKRETESSPOLICY",
  "landing.review": "RateBeer är den mest tillförlitliga källan till information om öl och bryggerier på internet.",
  "landing.rightsReserved": "Alla rättigheter förbehålls.",
  "landing.submit": "Skicka in feedback",
  "landing.visit": "Gå till ratebeer.com",
  "place.addBeerIds": "Lägg till öl-ID",
  "place.addBeers": "Lägg till öl",
  "place.addEvent": "Lägg till händelse",
  "place.ambiance": "Atmosfär",
  "place.available": "Tillgänglig",
  "place.beerMenu": "Ölmeny",
  "place.close": "Stäng",
  "place.customizeShelftags": "Anpassa hylletiketter",
  "place.editBeers": "Redigera öl",
  "place.editPlace": "Redigera plats",
  "place.flights": "Flyg",
  "place.food": "Mat",
  "place.foodMenu": "Matmeny",
  "place.getDirection": "Få vägbeskrivning",
  "place.hours": "Timmar",
  "place.lessInfo": "Mindre information",
  "place.map": "Karta",
  "place.moreInfo": "Mer information",
  "place.overall": "Övergripande",
  "place.phone": "Telefonnummer",
  "place.printShelftags": "Skriv ut hylletiketter",
  "place.selection": "Urval",
  "place.sendToPhone": "Skicka till telefon",
  "place.service": "Service",
  "place.shelftags": "Hylletiketter",
  "place.showBreakdown": "Visa uppdelning av poäng",
  "place.value": "Värde",
  "place.wifi": "Gratis wifi",
  "rate.BOTTLE": "Flaska",
  "rate.CAN": "Burk",
  "rate.CASK": "Tunna",
  "rate.TAP": "Fat",
  "rate.commentPlaceholder": "Lägg till smakanteckningar, beskrivningar osv.",
  "rate.infoSubtitle": "För att säkerställa kvaliteten på våra ölpoäng inkluderar vi bara detaljerade recensioner",
  "rate.locationPlaceholder": "Lägg till plats",
  "rate.nearbyPlaces": "Platser i närheten",
  "rate.ourRatingSystem": "Vårt betygssystem",
  "rate.private1": "0–74 tecken",
  "rate.private2": "Sparad för privat bruk",
  "rate.private3": "Bidrar inte till övergripande poäng",
  "rate.privateLabel": "PRIVAT",
  "rate.public1": "Fler än 75 tecken ",
  "rate.public2": "Bidrar till övergripande ölpoäng",
  "rate.public3": "Synlig för community",
  "rate.publicLabel": "OFFENTLIG",
  "rate.save": "Spara",
  "rate.searchResults": "Resultat",
  "rate.servedIn": "Serveras i",
  "rate.totalScore": "TOTALPOÄNG",
  "search.advanced": "Avancerad sökning",
  "search.beerCaps": "ÖL",
  "search.beerHeader": "Öl",
  "search.beerSearchFor": "Skriv i sökfältet för att hitta ölsorter",
  "search.brewersCaps": "BRYGGERIER",
  "search.brewersHeader": "Bryggerier",
  "search.brewersSearchFor": "Skriv i sökfältet för att hitta bryggerier",
  "search.closestMatch": "Närmaste träffarna",
  "search.for": "Sök efter",
  "search.in": "i",
  "search.mostRatings": "Flest betyg",
  "search.myRating": "Mitt betyg:",
  "search.noResults": "Inga resultat för ",
  "search.pCaps": "PLATSER",
  "search.pHeader": "Platser",
  "search.pSearchFor": "Skriv i sökfältet för att hitta platser",
  "search.resultFor": "resultat för",
  "search.resultLabelStr": "BETYG",
  "search.resultsFor": "resultat för",
  "search.sortBy": "Sortera efter",
  "search.sortByCaps": "SORTERA EFTER",
  "search.topRated": "Högst rankade",
  "search.userHeader": "Användare",
  "search.userSearchFor": "Skriv i sökfältet för att hitta användare"
}
