import { ApolloClient } from 'apollo-client'
import { ApolloLink, GraphQLRequest } from 'apollo-link'
import DebounceLink from 'apollo-link-debounce'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import Jar from 'tools/cookies'
import makeConfig from 'tools/config'
import { getBestLocaleAndMessages } from 'tools/intl'
import { createHttpLink } from 'apollo-link-http'
import { MockLink } from 'apollo-link-mock'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'

const DEFAULT_DEBOUNCE_TIMEOUT = 100

const config = makeConfig()

export const createHeaders = (
  operation?: GraphQLRequest,
  previousContext?: any
) => {
  // Get the auth token from its cookie
  const idToken = Jar.get('idtoken')

  // Provide the language to the backend
  const [locale] = getBestLocaleAndMessages()
  const headers = { locale } as any
  // Flag to backend to use phx-specific upload package if request has upload
  if (previousContext && previousContext.hasUpload) {
    headers['x-api-upload-v2'] = 'true'
  }
  if (idToken) {
    headers.authorization = `Bearer ${idToken}`
  }
  return { headers }
}

const middlewareLink = setContext(createHeaders)

type GetClientInput = {
  mocks?: any
}

export const getClient = ({ mocks }: GetClientInput = { mocks: undefined }) => {
  // FIXME: Temporarily removing the persisted query link, look at removing
  //        and making sure this implementation works going forward
  // const httpLink = createPersistedQueryLink({
  //   useGETForHashedQueries: true
  // }).concat(createHttpLink({ uri: config.GRAPHQL_URI }))
  const httpLink = createHttpLink({ uri: config.GRAPHQL_URI })
  const uploader = createUploadLink({ uri: config.GRAPHQL_URI })

  const links = mocks ? [new MockLink(mocks)] : []

  return new ApolloClient({
    // If query or mutation passes in a context of hasUpload
    // use uploader link instead of batcher link
    link: ApolloLink.from([
      ...links,
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      ApolloLink.split(
        (operation) => operation.getContext().hasUpload,
        middlewareLink.concat(uploader),
        middlewareLink.concat(httpLink)
      ),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (object: any) => {
        switch (object.__typename) {
          case 'BeerPurchaseInfo':
            return `BeerPurchaseInfo:${object.productUrl}:${object.price}`
          default:
            return defaultDataIdFromObject(object)
        }
      },
    }),

    queryDeduplication: true,
  })
}

export const client = getClient()
