import { useStore } from './store'
import { me } from './api'
import { User } from './store'
import { isAdmin, isPlaceAssociate } from './utils'

export const useAuthStore = useStore

export const useInitialFetchComplete = () => {
  const [initialFetchComplete] = useStore(state => state.initialFetchComplete)
  return initialFetchComplete
}

export const useGetUser = () => {
  const [setState] = useStore()

  return async () => {
    const user = await me()
    // saveSessionToCooke(user)
    setState(state => ({ ...state, user, initialFetchComplete: true }))
    return user
  }
}

export const useUser = (): User | null => {
  const [user] = useStore(state => state.user)
  return user
}

export const useIsPlaceAssociate = (placeId?: ID | null): boolean => {
  const user = useUser()
  return isPlaceAssociate(user, placeId)
}

export const useIsAdmin = (): boolean => {
  const user = useUser()
  return isAdmin(user)
}

export const useIsPremiumMember = (): boolean => {
  const user = useUser()
  return !!(user && user.isPremiumMember)
}

type AuthPayload = {
  origin?: string
  message?: string
  customRedirect?: string
}

const dynamicAuthImport = () =>
  import(
    /* webpackChunkName: "Auth" */ /* webpackPrefetch: true */ /* webpackMode: "lazy" */ 'tools/auth/Auth'
  ).then(({ default: Auth }) => {
    return new Auth()
  })

export const useSignup = () => ({
  origin,
  message,
  customRedirect
}: AuthPayload = {}) =>
  dynamicAuthImport().then(auth => auth.signup(origin, message, customRedirect))

export const useSignin = () => ({
  origin,
  message,
  customRedirect
}: AuthPayload = {}) =>
  dynamicAuthImport().then(a => a.login(origin, message, customRedirect))

export const useLogout = () => (redirectUrl?: string) =>
  dynamicAuthImport().then(a => a.logout(redirectUrl || document.location.href))
