import React from 'react'
import { noopTemplate as css } from 'lib/utils'

import { Button, A, Link } from 'lib/components'

type HeaderLinkButtonProps = Stylable & {
  external?: boolean
  to: string
  color?: 'yellow'
  variant?: 'contained'
  children: React.ReactNode
}

const HeaderLinkButton = ({
  external,
  to,
  color,
  variant,
  children,
  className,
  style
}: HeaderLinkButtonProps) => {
  return external ? (
    <A href={to}>
      <Button
        size="small"
        css={css`
          text-transform: none;
          font-size: 14px;
        `}
        color={color}
        variant={variant}
        className={className}
        style={style}
      >
        {children}
      </Button>
    </A>
  ) : (
    <Link to={to}>
      <Button
        size="small"
        css={css`
          text-transform: none;
          font-size: 14px;
        `}
        color={color}
        variant={variant}
        className={className}
        style={style}
      >
        {children}
      </Button>
    </Link>
  )
}

export default HeaderLinkButton
