import { useRef, useState } from 'react'

type VerifyKindaPartial<T, KP> = Partial<T> &
  { [K in keyof KP]-?: K extends keyof T ? T[K] : never }

export const useInstance = <T extends {}>(initialValue: T): T => {
  const { current } = useRef<T>(initialValue)
  return current
}

type SetState<S> = <KP extends any>(
  input:
    | (KP & VerifyKindaPartial<S, KP>)
    | ((state: S) => KP & VerifyKindaPartial<S, KP>)
) => void

export const useComponentState = <S extends any>(
  initialState: S
): { state: S; setState: SetState<S> } => {
  const [state, setState] = useState<S>(initialState)

  const instance = useInstance<{ state: S; setState: SetState<S> }>({
    state,
    setState: input => {
      setState(
        typeof input === 'function'
          ? (input as any)(instance.state)
          : { ...instance.state, ...input }
      )
    }
  })

  instance.state = state

  return instance
}
