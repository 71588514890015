import React from 'react'
import { theme } from 'theme'
import { noopTemplate as css } from 'lib/utils'

import CookieConsent from './CookieConsent'
import AppBanner from './AppBanner'

const PopupBanners = () => {
  const isAndroid = /(android)/i.test(navigator.userAgent)

  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: ${theme.zIndex.aboveBase};
      `}
    >
      {isAndroid && <AppBanner />}
      {/* <CookieConsent /> */}
    </div>
  )
}

export default PopupBanners
