import { InjectedIntl, FormattedMessage, MessageValue } from 'react-intl'

import german from 'tools/locales/de'
import spanish from 'tools/locales/es'
import english from 'tools/locales/default'
import french from 'tools/locales/fr'
import italian from 'tools/locales/it'
import dutch from 'tools/locales/nl'
import polish from 'tools/locales/pl'
import portugese from 'tools/locales/pt'
import swedish from 'tools/locales/sv'

import DefaultTranslations from 'tools/locales/placeholder'
type AllMessages = typeof DefaultTranslations
export type TranslationKey = {
  [K in keyof AllMessages]: AllMessages[K] extends { status: 'obsolete' }
    ? never
    : K
}[keyof AllMessages]
export type Messages = { [key in TranslationKey]: string }

export const messages = {
  de: german,
  es: spanish,
  en: english,
  fr: french,
  it: italian,
  nl: dutch,
  pl: polish,
  pt: portugese,
  sv: swedish
}

export type LanguageCode = keyof typeof messages

export type IntlService = Omit<InjectedIntl, 'formatMessage'> & {
  translate: (translation: Translation) => string
  formatMessage: (
    messageDescriptor:
      | TranslationKey
      | Omit<FormattedMessage.MessageDescriptor, 'id'> & {
          id: TranslationKey
        },
    values?: { [key: string]: MessageValue }
  ) => string
  formatDistance: (meters: number, precision?: number) => string
}

export type Translation = {
  msgId: TranslationKey
  values?: Record<string, any>
  defaultMessage?: string
}
