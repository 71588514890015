/*
  This hook is to replace the HOC `withRouter` from 'react-router-dom'
 */
import React, { createContext, useContext, useMemo } from 'react'
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom'

type Context = Omit<RouteComponentProps<any>, 'match'>

const RouterContext = createContext((null as unknown) as Context)

type ProviderProps = RouteComponentProps<any>

const _Provider: React.FunctionComponent<ProviderProps> = ({
  children,
  history,
  location
}) => {
  return (
    <RouterContext.Provider
      value={useMemo(() => ({ history, location }), [history, location])}
    >
      {children}
    </RouterContext.Provider>
  )
}

export const Provider = withRouter(_Provider)

type UseRouterInput =
  | string
  | {
      path: string
      strict?: boolean
      exact?: boolean
    }

type UseRouterOutput<T> = Pick<
  RouteComponentProps<T>,
  'history' | 'location'
> & {
  match: RouteComponentProps<T>['match'] | null
}

export const useRouter = <T extends any>(
  input?: UseRouterInput
): UseRouterOutput<T> => {
  const router = useContext(RouterContext)
  return {
    ...router,
    match: input ? matchPath<T>(router.location.pathname, input) : null
  }
}
