import React, { useEffect } from 'react'
import { v4 } from 'uuid'
import { targetingCookiesAllowed } from 'services/gdpr'

export const FacebookPixel: React.FunctionComponent = () => {
  useEffect(() => {
    if (!targetingCookiesAllowed()) {
      return
    }

    const id = v4()
    const script = document.createElement('script')
    script.id = id

    script.innerHTML = `
 !function(f,b,e,v,n,t,s)
 {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
 n.callMethod.apply(n,arguments):n.queue.push(arguments)};
 if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
 n.queue=[];t=b.createElement(e);t.async=!0;
 t.src=v;s=b.getElementsByTagName(e)[0];
 s.parentNode.insertBefore(t,s)}(window, document,'script',
 'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '2664255776959865');
 fbq('track', 'PageView');
`
    document.head.appendChild(script)

    return () => {
      const node = document.getElementById(id)

      if (node) {
        node.remove()
      }
    }
  }, [])

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=2664255776959865&ev=PageView&noscript=1https://www.facebook.com/tr?id=2664255776959865&ev=PageView&noscript=1
"
      />
    </noscript>
  )
}
