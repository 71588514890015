import React, { useState, useContext, createContext } from 'react'
import { noopTemplate as css } from 'lib/utils'

import store from 'store'

import { Paper, Button, Text, Collapse, Link } from 'lib/components'

const ChristmasHolidaysKey = 'show-christmasholidays-banner'

const Context = createContext({
  showChristmasHolidaysBanner: false,
  toggleShowChristmasHolidaysBanner: () => {},
})

export const ChristmasHolidaysBannerProvider: React.FC = ({ children }) => {
  const storageVal = store.get(ChristmasHolidaysKey)
  // const [show, setShow] = useState(
  //   storageVal === undefined ? true : Boolean(storageVal)
  // )
  // Temporarily stop banner showing
  const [show, setShow] = useState(false)

  const toggle = () => {
    setShow((val) => {
      const newVal = !val
      store.set(ChristmasHolidaysKey, newVal)
      return newVal
    })
  }

  return (
    <Context.Provider
      value={{
        showChristmasHolidaysBanner: show,
        toggleShowChristmasHolidaysBanner: toggle,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default () => {
  const {
    showChristmasHolidaysBanner,
    toggleShowChristmasHolidaysBanner,
  } = useContext(Context)

  return (
    <Collapse in={showChristmasHolidaysBanner}>
      <Paper
        className="fj-c"
        css={css`
          border-radius: 0;
        `}
      >
        <div
          className="fa-c fj-sb f-wrap py-4 px-4"
          css={css`
            max-width: 1000px;
            width: 100%;
          `}
        >
          <div>
            <Text inline variant="body1">
              The team at RateBeer just want to wish you all a Merry Christmas
              and a Happy New Year. Thanks for all your support and keep rating
              those beers.
            </Text>
          </div>

          <div className="fa-c">
            <Button
              css={css`
                text-transform: uppercase;
              `}
              onClick={toggleShowChristmasHolidaysBanner}
            >
              Dismiss
            </Button>
          </div>
        </div>
      </Paper>
    </Collapse>
  )
}
