import { css } from 'styled-components'

export default css`
  select,
  option {
    -webkit-appearance: none; /* Chrome, Opera, Safari */
    -moz-appearance: none; /* Firefox */
  }
  /* Internet Explorer 10–11 and Edge */
  select::-ms-expand {
    display: none;
  }
  select {
    border: none;
    background-color: #ffffff;
    height: 46px;
  }
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    color: #7f8c8d;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7f8c8d;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #7f8c8d;
  }
`
