import ReactGA from 'react-ga'
// import * as Sentry from '@sentry/browser'
import Jar from 'tools/cookies'
import { sessionFromCookie } from 'tools/session'

import { performanceCookiesAllowed } from 'services/gdpr'

export type GAEvent = {
  category: string
  action: string
  label?: string
}

export function captureTiming(args: any) {
  if (!performanceCookiesAllowed()) {
    return
  }
  ReactGA.timing(args)
}

export function capturePageView(page: string) {
  if (!performanceCookiesAllowed()) {
    return
  }
  const session = sessionFromCookie()
  const customDimensions = {
    dimension2: session ? 'LoggedInUser' : ''
  }
  ReactGA.set(customDimensions)
  ReactGA.pageview(page)
}

// https://github.com/react-ga/react-ga/pull/152
function getCustomDimensions() {
  const session = sessionFromCookie()
  return session ? { dimension2: 'LoggedInUser' } : {}
}

export const captureEvent = (ev: GAEvent) => {
  if (!performanceCookiesAllowed()) {
    return
  }
  const analyticsEvent = Object.assign({}, ev, getCustomDimensions())
  ReactGA.event(analyticsEvent)
}

export const captureOnClick = (ev?: GAEvent) => {
  const capture = () => {
    if (ev) {
      captureOnClick(ev)
    }
  }

  return {
    onMouseDown: capture,
    onTouchStart: capture
  }
}

type ReactEventHandler = (event: React.SyntheticEvent) => void
export const fireOnClick = (fn: ReactEventHandler) => {
  return {
    onMouseDown: fn,
    onTouchStart: fn
  }
}

export const captureError = (e: Error | string = '', action = '') => {
  const error = typeof e === 'string' ? new Error(e) : e

  console.error(e)

  const errorsToIgnore = [
    // user has connectivity problems
    'Network error: Failed to fetch',
    'Network error: The Internet connection appears to be offline',
    // adblock related issues
    'adsbygoogle',
    'googleads.g.doubleclick.net'
  ]

  const ignore = errorsToIgnore.some(err => error.toString().includes(err))
  if (!ignore) {
    const session = Jar.get('session') || ''
    // Sentry.setTags({ session, action })
    // Sentry.captureException(error)
  }
}
export type GAConfig = { clientId: string }

export function initAnalytics(clientId: string) {
  if (!performanceCookiesAllowed()) {
    return
  }

  ReactGA.initialize(clientId, {
    gaOptions: {
      siteSpeedSampleRate: 10
    }
  })
}
