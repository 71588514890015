import { captureEvent, capturePageView } from 'services/analytics'

type Tab = 'beer' | 'brewer' | 'place' | 'user'

type CaptureGAEventInput =
  | {
      category: 'Search Page Result Selected at Position'
      action: Tab
      label: string
    }
  | {
      category: 'Search in Header initiated'
      action: string
    }
  | {
      category: 'Search Term Not Found'
      action: Tab
      label: string
    }
  | {
      category: 'Header Search Bar Result Selected at Position'
      action: Tab
      label: string
    }
  | {
      category: 'User Clicked Into Search Tab'
      action: Tab
    }
  | {
      category: 'Search Sort Order Changed'
      action: Tab
      label: string
    }
  | {
      category: 'Search Preview Affordance Clicked'
      action: string
    }

const captureGAEvent = (input: CaptureGAEventInput) => captureEvent(input)

type CaptureSearchPageResultSelectedInput = {
  tab: Tab
  position: number
}

export const captureSearchPageResultSelected = ({
  tab,
  position
}: CaptureSearchPageResultSelectedInput) => {
  captureGAEvent({
    category: 'Search Page Result Selected at Position',
    action: tab,
    label: `${position}`
  })
}

type CaptureSearchTermNotFoundInput = {
  tab: Tab
  searchText: string
}

export const captureSearchTermNotFound = ({
  tab,
  searchText
}: CaptureSearchTermNotFoundInput) => {
  captureGAEvent({
    category: 'Search Term Not Found',
    action: tab,
    label: searchText
  })
}

export const captureClickedIntoSearchTab = (tab: Tab) => {
  captureGAEvent({
    category: 'User Clicked Into Search Tab',
    action: tab
  })
}

type CaptureSearchOrderChangeInput = {
  tab: Tab
  order: string
}

export const captureSearchOrderChange = ({
  tab,
  order
}: CaptureSearchOrderChangeInput) => {
  captureGAEvent({
    category: 'Search Sort Order Changed',
    action: tab,
    label: order
  })
}

export const captureSearchInitiated = (searchText: string) => {
  captureGAEvent({
    category: 'Search in Header initiated',
    action: searchText
  })
}

export const captureFollowSeeMoreResults = (searchText: string) => {
  captureGAEvent({
    category: 'Search Preview Affordance Clicked',
    action: searchText
  })
}

type CaptureSearchPreviewResultsSelectedAtPositionInput = {
  type: Tab
  position: number
}

export const captureSearchPreviewResultSelectedAtPosition = ({
  position,
  type
}: CaptureSearchPreviewResultsSelectedAtPositionInput) => {
  captureGAEvent({
    category: 'Header Search Bar Result Selected at Position',
    action: type,
    label: `${position}`
  })
}

export const captureSearchPageView = () => {
  capturePageView('/search')
}
