import React, { Component } from 'react'
import PropTypes from 'prop-types'
import makeConfig from '../config'
import { Spinner } from 'lib/components'
import Jar from '../../tools/cookies'
const config = makeConfig()

export default class SSO extends Component {
  componentDidMount() {
    const queryString = require('query-string')

    // These tell the service things needed to auto log-in a user to Discourse
    const { location } = this.props
    const { sig, sso } = queryString.parse(location.search)
    if (sig) Jar.set('sig', sig)
    if (sso) Jar.set('sso', sso)

    const initiateAutoLogin = Jar.get('token')
    if (initiateAutoLogin) {
      // Users who navigate to the forums from the ratebeer site
      // will leave a cookie trail behind which signals to that
      // we should automatically log them into discourse
      this.goTo(config.AUTO_SSO_URI + location.search)
    } else if (location.hash !== '') {
      // The user has completed authentication and we now need to
      // send them to our auto-sso service to finish the rest of the
      // sso job on the server
      const hasDiscourseCookies = Boolean(Jar.get('sig') && Jar.get('sso'))
      if (hasDiscourseCookies) {
        // This sends the user to the /auto route in our SSO service
        // which is specifically written for Discourse
        const sig = Jar.get('sig')
        Jar.remove('sig')
        const sso = Jar.get('sso')
        Jar.remove('sso')
        const queryArgs =
          location.hash.replace('#', '') +
          '&sig=' +
          encodeURI(sig) +
          '&sso=' +
          encodeURI(sso)
        this.goTo(config.AUTO_SSO_URI + '?' + queryArgs)
      } else {
        // This sends the user to the /session route in our SSO service
        // which logs the user in by adding all necessary details to document.cookie
        const redirectUrl = Jar.get('referrer')
        Jar.remove('referrer')
        const queryArgs = location.hash.replace('#', '')
        const { access_token, id_token } = queryString.parse(queryArgs)
        this.goTo(
          `${
            config.SSO_URI
          }/session?accessToken=${access_token}&idToken=${id_token}&redirectUrl=${redirectUrl}`
        )
      }
    } else {
      const { redirectUrl } = queryString.parse(location.search)
      // the SSO component can be called with /login and /signup
      const authMethod =
        window.location.pathname.toLocaleLowerCase() === '/signup'
          ? this.showAuth0SignUp
          : this.showAuth0Login
      authMethod(redirectUrl || document.referrer)
    }
  }

  showAuth0Login = redirectUrl => {
    return import(/* webpackChunkName: "Auth" */ './Auth').then(
      async ({ default: Auth }) => {
        const auth = new Auth()
        auth.login('login page', null, redirectUrl)
      }
    )
  }

  showAuth0SignUp = redirectUrl => {
    return import(/* webpackChunkName: "Auth" */ './Auth').then(
      async ({ default: Auth }) => {
        const auth = new Auth()
        auth.signup('sign up page', null, redirectUrl)
      }
    )
  }

  // separate method to help with unit tests
  goTo = url => {
    document.location.assign(url)
  }

  render() {
    return <Spinner />
  }
}

SSO.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string
  })
}
