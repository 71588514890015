import React, { createContext, useContext } from 'react'
import { injectIntl, InjectedIntl, IntlProvider } from 'react-intl'

import { config } from './config'
import { TranslationKey, IntlService } from './constants'
import { createIntlService } from './format'

const Context = createContext((null as unknown) as IntlService)

type ProviderProps = { intl: InjectedIntl }

const _Provider: React.FunctionComponent<ProviderProps> = ({
  children,
  intl
}) => {
  return (
    <Context.Provider value={createIntlService(intl)}>
      {children}
    </Context.Provider>
  )
}

const HooksProvider = injectIntl(_Provider)

export const Provider: React.FunctionComponent = ({ children }) => {
  return (
    <IntlProvider {...config}>
      <HooksProvider>{children}</HooksProvider>
    </IntlProvider>
  )
}

export type Translation = {
  msgId: TranslationKey
  values?: Record<string, any>
  defaultMessage?: string
}
