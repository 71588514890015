import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { createStore } from 'lib/state-container'
import { identity } from 'ramda'
import {
  useStore,
  ExperimentName,
  ExperimentVariant,
  ResolvedExperiment
} from './store'

export type ExperimentConfig = ServerExperimentConfig | DevExperimentConfig

export interface ServerExperimentConfig {
  kind: 'server'
  gtmId: string
  experimentSettings: {
    [key in ExperimentName]?: { id: string; event: string }
  }
}
export interface DevExperimentConfig {
  kind: 'dev'
  experimentSettings: {
    [key in ExperimentName]?: { simulation: ExperimentVariant }
  }
}
type VariantOption = {
  [key in ExperimentVariant]?: () => React.ReactElement<any> | null
}
type ExperimentType = {
  name: ExperimentName
  variants: VariantOption
}
export const Experiment: React.FC<ExperimentType> = ({ name, variants }) => {
  const experiment = useExperiment(name)
  if (!experiment.loaded) return null
  const Variant = variants[experiment.variant]
  if (!Variant) return null
  return Variant()
}

export const initOptimizer = (gtmId: string) => {
  ReactGA.ga('require', gtmId)
}

export const useExperiment = (name: ExperimentName): ResolvedExperiment => {
  const [
    {
      experiments: { [name]: experiment },
      optimizeInitialised,
      optimizeConfig
    },
    setState
  ] = useStore(identity)
  useEffect(() => {
    if (experiment && experiment.loaded) return
    optimizeInitialised.then(() => {
      startExperiment(optimizeConfig, name)
      const variant = getVariant(optimizeConfig, name)
      setState(state => ({
        ...state,
        experiments: { ...state.experiments, [name]: { loaded: true, variant } }
      }))
    })
  }, [])
  return experiment || { loaded: false }
}

const getVariant = (
  config: ExperimentConfig,
  name: ExperimentName
): ExperimentVariant => {
  if (config.kind === 'dev') {
    const ex = config.experimentSettings[name]
    return ex ? ex.simulation : 'A'
  }
  const ex = config.experimentSettings[name]
  if (!ex) {
    console.warn(
      `virant value is undefined, there is no settings for this experiment ${name}, set to A thou`
    )
    return 'A'
  }
  const experimentId = ex.id
  const value =
    window.google_optimize && window.google_optimize.get(experimentId)
  value
    ? console.log('get virant value:', experimentId, value)
    : console.warn(
        'virant value is undefined, either the user is not targetted or some other issues, set to A thou'
      )
  return getVariantLetterByDigi(value)
}

const startedEvents: string[] = []
const startExperiment = (config: ExperimentConfig, name: ExperimentName) => {
  if (config.kind === 'dev') return
  const ex = config.experimentSettings[name]
  if (ex && ex.event && !startedEvents.includes(ex.event)) {
    startedEvents.push(ex.event)
    if (window.dataLayer) window.dataLayer.push({ event: ex.event })
  }
}

type OptimizeVariantValue = '0' | '1' | '2' | '3'
declare global {
  interface Window {
    google_optimize?: { get: (x: string) => OptimizeVariantValue }
    dataLayer?: { hide?: { end?: () => void }; push: (x: any) => void }
  }
}
const getVariantLetterByDigi = (
  v?: OptimizeVariantValue
): ExperimentVariant => {
  const str = String.fromCharCode(
    'A'.charCodeAt(0) + ((v || '0').charCodeAt(0) - '0'.charCodeAt(0))
  )
  return str as ExperimentVariant
}
