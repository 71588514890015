import React from 'react'

import MModal from '@material-ui/core/Modal'
import Divider from 'lib/components/material/Divider'
import { IconButton } from 'lib/components/material/IconButton'
import { Paper } from './Paper'
import Text from './Text'

import Clear from '@material-ui/icons/Clear'

export type ModalProps = Stylable & {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  transparentHeader?: boolean
  transparent?: boolean
  showDefaultCloseButton?: boolean
}

const Modal: React.FunctionComponent<ModalProps> = ({
  className,
  style,
  open,
  onClose,
  title,
  children,
  transparentHeader,
  transparent,
  showDefaultCloseButton = true
}) => {
  const Container = transparent ? 'div' : Paper
  const TitleContainer = typeof title === 'string' ? Text : 'div'

  const Title =
    typeof title === 'string' ? (
      <div className="fa-c pl-4" style={{ minHeight: 48, paddingRight: 50 }}>
        <Text variant="h6">{title}</Text>
      </div>
    ) : (
      <div style={{ minHeight: 48 }}>{title}</div>
    )

  return (
    <MModal
      className="fj-c fa-c"
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
    >
      <div className="position-relative">
        {showDefaultCloseButton && (
          <IconButton
            css={`
              position: absolute;
              top: 0;
              right: 0;
            `}
            onClick={onClose}
          >
            <Clear />
          </IconButton>
        )}

        {transparentHeader && Title}

        <Container>
          {!transparentHeader && Title}

          {!transparent && !transparentHeader ? <Divider /> : null}

          <div style={style} className={className}>
            {children}
          </div>
        </Container>
      </div>
    </MModal>
  )
}

export default Modal
