import { useStore } from './store'
import gql from 'graphql-tag'
import {
  useGetGeoLocationLazyQuery,
  GCoordinates,
  GGetGeoLocationQuery,
} from 'services/graphql/generated'
import { useEffect } from 'react'
const geoLocationKey = 'userGeoLocation'

export const useGetGeoLocation = (): boolean => {
  const [
    getGeoLocation,
    { data, loading, error, called },
  ] = useGetGeoLocationLazyQuery()
  const [setState] = useStore()
  const cachedLocation = getCachedLocation()
  const stillWaitingForData = () =>
    cachedLocation ? !cachedLocation : !called || loading

  const retrieveData = () => {
    if (cachedLocation) {
      setState((state) => ({
        ...state,
        latlng: cachedLocation,
      }))
    } else if (!called) {
      getGeoLocation()
    }
  }

  useEffect(() => {
    retrieveData()

    if (data) {
      const position = parsePosition(data)
      setState((state) => ({
        ...state,
        latlng: position,
      }))

      setCacheLocation(position)
    } else if (error) {
      setState((state) => ({
        ...state,
        latlng: null,
      }))
    }
  }, [data, error, cachedLocation, called])

  return stillWaitingForData()
}

const parsePosition = (data: GGetGeoLocationQuery) => {
  return data?.geoLocation?.latitude && data?.geoLocation?.longitude
    ? {
        latitude: data?.geoLocation?.latitude,
        longitude: data?.geoLocation?.longitude,
      }
    : null
}

type StoredLocationObj = GCoordinates & {
  timestamp: number
}

const getCachedLocation = (): StoredLocationObj | null => {
  const val =
    localStorage && geoLocationKey && localStorage.getItem(geoLocationKey)
  const parsedVal = val ? (JSON.parse(val) as StoredLocationObj) : null
  if (!parsedVal) return null
  const overOneHour = Date.now() - parsedVal.timestamp > 60 * 60 * 1000
  return process.env.NODE_ENV !== 'development' && overOneHour
    ? null
    : parsedVal
}

const setCacheLocation = (val: GCoordinates | null) => {
  if (!val) return

  const store: StoredLocationObj = {
    ...val,
    timestamp: Date.now(),
  }

  localStorage.setItem(geoLocationKey, JSON.stringify(store))
}

// Instead of defaulting to null, lets default to the center of the US
export const useGeoLocation = (): Vector2D | null => {
  const [geoLocation] = useStore((state) => (state ? state.latlng : null))
  if (!geoLocation || !geoLocation.longitude || !geoLocation.latitude)
    return [39.8333333, -98.585522]
  return [geoLocation?.latitude, geoLocation?.longitude]
}

gql`
  query GetGeoLocation {
    geoLocation {
      latitude
      longitude
    }
  }
`
