import { addLocaleData } from 'react-intl'

// When adding a support for a new locale you'll want to pull
// locale data here from react-intl. Note that regional locales
// such as en-UK are included as part of the greater set of
// locale data associated with the country code.
import de from 'react-intl/locale-data/de'
import es from 'react-intl/locale-data/es'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'
import nl from 'react-intl/locale-data/nl'
import pl from 'react-intl/locale-data/pl'
import pt from 'react-intl/locale-data/pt'
import sv from 'react-intl/locale-data/sv'

// Import the actual translated messages keyed by id.
import german from './locales/de'
import spanish from './locales/es'
import english from './locales/default'
import french from './locales/fr'
import italian from './locales/it'
import dutch from './locales/nl'
import polish from './locales/pl'
import portugese from './locales/pt'
import swedish from './locales/sv'

// Check to see if Javascript version has global Intl.
import areIntlLocalesSupported from 'intl-locales-supported'

// We also need to add the locale here to see if we need to Polyfill on Android.
const supportedLocales = ['de', 'es', 'en', 'fr', 'it', 'nl', 'pl', 'pt', 'sv']

import {
  de as deLocale,
  es as esLocale,
  en as enLocale,
  fr as frLocale,
  it as itLocale,
  nl as nlLocale,
  pl as plLocale,
  pt as ptLocale,
  sv as svLocale
} from 'date-fns/locale'

// https://date-fns.org/v1.29.0/docs/I18n
export const dateLocales = {
  de: deLocale,
  es: esLocale,
  en: enLocale,
  fr: frLocale,
  it: itLocale,
  nl: nlLocale,
  pl: plLocale,
  pt: ptLocale,
  sv: svLocale
}

// Android Polyfill
if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.
  if (!areIntlLocalesSupported(supportedLocales)) {
    const IntlPolyfill = require('intl')
    global.Intl.NumberFormat = IntlPolyfill.NumberFormat
    global.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    // Needed for use of <FormattedDate/>
    require('intl/locale-data/jsonp/de')
    require('intl/locale-data/jsonp/es')
    require('intl/locale-data/jsonp/en')
    require('intl/locale-data/jsonp/fr')
    require('intl/locale-data/jsonp/it')
    require('intl/locale-data/jsonp/nl')
    require('intl/locale-data/jsonp/pl')
    require('intl/locale-data/jsonp/pt')
    require('intl/locale-data/jsonp/sv')
  }
} else {
  global.Intl = require('intl')
}

// You then destructure the import and add the locale data here.
addLocaleData([...de, ...es, ...en, ...fr, ...it, ...nl, ...pl, ...pt, ...sv])

// Create messages object/dictionary. Later put it in redux store initial state
export const messages = {
  de: german,
  es: spanish,
  en: english,
  fr: french,
  it: italian,
  nl: dutch,
  pl: polish,
  pt: portugese,
  sv: swedish
}

export const getLocaleFromURL = (url = window.location.href) => {
  const pattern = /^http(s:\/\/www.ratebeer\.com|s:\/\/www\.r8\.beer|:\/\/localhost:3000)\/(\D{2})\//
  const matches = pattern.exec(url)
  return matches && matches.length > 2 ? matches[2] : null
}

// Check the browser for preferred language--default set to 'en'
export const getBestLocaleAndMessages = (nav = navigator) => {
  const defaultLocale = 'en'
  const { languages } = nav
  const topLang = languages && languages.length ? languages[0] : undefined
  const locale =
    getLocaleFromURL() ||
    topLang ||
    nav.userLanguage ||
    nav.browserLanguage ||
    nav.language ||
    nav.systemLanguage
  const localeWithoutRegion = locale
    ? locale.toLowerCase().split(/[_-]+/)[0]
    : ''
  // Try to find messages for locale, fallback to without region, fallback to default
  let bestLocale
  let localeMessages
  if (messages[locale]) {
    bestLocale = locale
    localeMessages = messages[locale]
  } else if (messages[localeWithoutRegion]) {
    bestLocale = localeWithoutRegion
    localeMessages = messages[localeWithoutRegion]
  } else {
    bestLocale = defaultLocale
    localeMessages = messages[defaultLocale]
  }
  return [bestLocale, { ...messages.en, ...localeMessages }]
}
