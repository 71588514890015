import React from 'react'
import { theme } from 'theme'
import { createStore } from 'lib/state-container'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

type Action = {
  label: string
  handler: () => void
}

type SnackbarInfo = {
  message?: string
  actions?: Action[]
}

type State = SnackbarInfo & {
  open: boolean
  queue: SnackbarInfo[]
}

const initialState: State = {
  open: false,
  message: '',
  actions: [],
  queue: []
}

const { Provider: StoreProvider, useStore } = createStore<State>()

type OpenSnackbarInput = {
  message?: string
  actions?: Action[]
}

export const useOpenSnackbar = () => {
  const [setState, getState] = useStore()

  return (info: OpenSnackbarInput) => {
    const { open } = getState()

    if (open) {
      setState(state => ({
        ...state,
        open: false,
        queue: [...state.queue, info]
      }))
    } else {
      setState(state => ({
        ...state,
        message: info.message,
        actions: info.actions,
        open: true
      }))
    }
  }
}

const GlobalSnackbar: React.FunctionComponent = () => {
  const [{ open, message, actions = [], queue }, setState] = useStore(
    state => state
  )

  const closeSnackbar = () => setState(state => ({ ...state, open: false }))

  const processQueue = () => {
    if (queue.length) {
      setState(state => ({
        ...state,
        ...queue[0],
        queue: queue.slice(1),
        open: true
      }))
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={(e, reason) => {
        if (reason !== 'clickaway') {
          closeSnackbar()
        }
      }}
      onExited={processQueue}
      message={message}
      action={[
        ...actions.map((action, index) => (
          <Button
            key={index}
            onClick={action.handler}
            size="small"
            style={{ color: theme.colors.green }}
          >
            {action.label}
          </Button>
        )),
        <IconButton key="close" color="inherit" onClick={closeSnackbar}>
          <CloseIcon />
        </IconButton>
      ]}
    />
  )
}

export const Provider: React.FunctionComponent = ({ children }) => {
  return (
    <StoreProvider initialState={initialState}>
      {children}
      <GlobalSnackbar />
    </StoreProvider>
  )
}
