import React, { useState, useEffect } from 'react'
import {
  noopTemplate as css,
  urlSlug,
  classNames,
  noop,
  useDebouncedValue
} from 'lib/utils'
import { theme } from 'theme'
import { isEmpty } from 'ramda'

import {
  Text,
  InputBase,
  Paper,
  LinearProgress,
  ClickAwayListener,
  IconButton,
  A,
  Link,
  Button
} from 'lib/components'
import { SearchIcon, ClearIcon, ChevronRightIcon } from 'lib/icons'

import gql from 'graphql-tag'

import { useHeaderSearchQuery } from 'services/graphql/generated'

import { useRouter } from 'services/router'

import { searchPageUrl } from 'features/search/routing'

import { beerProfileUrl } from 'features/beer-profile/routing'
import { placeProfileURL } from 'features/place-profile/routing'

import { intl } from 'services/intl'

import {
  captureSearchInitiated,
  captureFollowSeeMoreResults,
  captureSearchPreviewResultSelectedAtPosition
} from 'services/analytics/events/search'

type SearchInputProps = Stylable & {
  searchText: string
  onSearchTextChange: (searchText: string) => void
  onClear?: () => void
  autoFocus?: boolean
  numberOfBeerResults?: number
  includeButton?: boolean
}

const SearchInput = ({
  className,
  style,
  searchText,
  autoFocus,
  onSearchTextChange,
  onClear = noop,
  includeButton = false,
  numberOfBeerResults = 4
}: SearchInputProps) => {
  const [showSearchResults, setShowSearchResults] = useState(false)

  const openSearchResults = () => setShowSearchResults(true)
  const closeSearchResults = () => setShowSearchResults(false)

  const router = useRouter()

  const goToSearchPage = () => {
    closeSearchResults()
    router.history.push(searchPageUrl({ searchTerm: searchText }))
  }

  const debouncedSearchText = useDebouncedValue(searchText, 500)

  useEffect(() => {
    if (debouncedSearchText) {
      captureSearchInitiated(debouncedSearchText)
    }
  }, [debouncedSearchText])

  const res = useHeaderSearchQuery({
    skip: !debouncedSearchText,
    variables: {
      firstNBeers: numberOfBeerResults,
      query: debouncedSearchText
    }
  })

  return (
    <ClickAwayListener onClickAway={closeSearchResults}>
      <Paper
        className={classNames(className, 'fg-1 flex')}
        style={style}
        css={css`
          position: relative;
          height: 40px;
          border-radius: 20px;
        `}
        onClick={() => console.log('click paper parent')}
      >
        <InputBase
          onKeyPress={e => {
            if (e.nativeEvent.code === 'Enter') {
              goToSearchPage()
            }
          }}
          autoFocus={autoFocus}
          className="fg-1"
          css={css`
            font-size: 14px;
          `}
          placeholder={intl.formatMessage(
            'Search for Beers, Breweries, or Bars...'
          )}
          startAdornment={
            <IconButton
              className="cursor-pointer"
              onClick={() => goToSearchPage()}
            >
              <SearchIcon />
            </IconButton>
          }
          endAdornment={
            <>
              {searchText ? (
                <IconButton
                  className="cursor-pointer"
                  onClick={() => {
                    onSearchTextChange('')
                    onClear()
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : null}
              {includeButton ? (
                <Button
                  centerRipple
                  variant="contained"
                  color="textPrimary"
                  onClick={() => goToSearchPage()}
                  css={css`
                    height: 100%;
                    border-radius: 0 16px 16px 0;
                    background-color: ${theme.colors.yellow};
                  `}
                >
                  <Text
                    css={css`
                      margin: 0 1em;
                      word-break: keep-all;
                    `}
                    color="textPrimary"
                  >
                    {intl.formatMessage('Search')}
                  </Text>
                </Button>
              ) : null}
            </>
          }
          value={searchText}
          onChange={e => {
            if (!showSearchResults) {
              openSearchResults()
            }
            onSearchTextChange(e.target.value)
          }}
          onFocus={openSearchResults}
        />

        {showSearchResults && searchText && res.data ? (
          <Paper
            css={css`
              position: absolute;
              min-width: 100%;
              top: 100%;
              left: 0;
              transform: translateY(-4px);
              border-radius: 0;
              z-index: ${theme.zIndex.interactiveMenu};
            `}
          >
            {res.loading ? (
              <LinearProgress
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                `}
              />
            ) : null}

            <SearchHeader>{intl.formatMessage('Beers')}</SearchHeader>
            {!isEmpty(res.data) && res.data.beers.items.length ? (
              res.data.beers.items.map((item, index) => (
                <Link
                  color="textPrimary"
                  key={item.beer.id}
                  to={beerProfileUrl({
                    id: item.beer.id,
                    name: item.beer.name
                  })}
                  onClick={() => {
                    closeSearchResults()
                    captureSearchPreviewResultSelectedAtPosition({
                      type: 'beer',
                      position: index + 1
                    })
                  }}
                >
                  <SearchItem {...item.beer} />
                </Link>
              ))
            ) : res.loading ? null : (
              <NoItems />
            )}

            <SearchHeader>{intl.formatMessage('Brewers')}</SearchHeader>
            {!isEmpty(res.data) && res.data.brewers.items.length ? (
              res.data.brewers.items.map((item, index) => (
                <A
                  color="textPrimary"
                  key={item.id}
                  href={`/brewers/${urlSlug(item.name)}/${item.id}/`}
                  onClick={() => {
                    closeSearchResults()
                    captureSearchPreviewResultSelectedAtPosition({
                      type: 'brewer',
                      position: index + 1
                    })
                  }}
                >
                  <SearchItem {...item} />
                </A>
              ))
            ) : res.loading ? null : (
              <NoItems />
            )}

            <SearchHeader>{intl.formatMessage('Places')}</SearchHeader>
            {!isEmpty(res.data) && res.data.places.items.length ? (
              res.data.places.items.map((item, index) => (
                <Link
                  color="textPrimary"
                  key={item.id}
                  to={placeProfileURL({
                    id: item.id,
                    name: item.name
                  })}
                  onClick={() => {
                    closeSearchResults()
                    captureSearchPreviewResultSelectedAtPosition({
                      type: 'place',
                      position: index + 1
                    })
                  }}
                >
                  <SearchItem {...item} />
                </Link>
              ))
            ) : res.loading ? null : (
              <NoItems />
            )}

            <Link
              bold
              to={searchPageUrl({ searchTerm: debouncedSearchText })}
              onClick={() => {
                closeSearchResults()
                captureFollowSeeMoreResults(debouncedSearchText)
              }}
            >
              <div
                className="px-4 py-3 fj-sb"
                css={css`
                  background: ${theme.colors.greyTint};
                `}
              >
                <span>
                  {intl.formatMessage('See more results for')} "
                  {debouncedSearchText}"
                </span>

                <ChevronRightIcon color="primary" />
              </div>
            </Link>
          </Paper>
        ) : null}
      </Paper>
    </ClickAwayListener>
  )
}

export default SearchInput

const SearchHeader: React.FC = ({ children }) => {
  return (
    <div
      className="px-4 py-3"
      css={css`
        background: ${theme.colors.greyTint};
      `}
    >
      <Text bold variant="subtitle1">
        {children}
      </Text>
    </div>
  )
}

type SearchItemProps = {
  name: string
  imageUrl?: string | null
}

const SearchItem = ({ name, imageUrl }: SearchItemProps) => {
  return (
    <div className="px-4 py-3 fa-c">
      {imageUrl ? (
        <div
          className="mr-4 fa-c fj-c fs-0"
          css={css`
            width: 40px;
            height: 40px;
          `}
        >
          <img
            className="rounded"
            css={css`
              object-fit: contain;
              object-position: center;
              max-width: 40px;
              max-height: 40px;
            `}
            src={imageUrl}
          />
        </div>
      ) : null}
      <Text variant="body1">{name}</Text>
    </div>
  )
}

const NoItems = () => {
  return (
    <div className="px-4 py-3 fa-c">
      <Text variant="caption" color="textSecondary">
        No matches found
      </Text>
    </div>
  )
}

gql`
  query HeaderSearch($query: String!, $firstNBeers: Int) {
    beers: beerSearch(query: $query, first: $firstNBeers) {
      items {
        beer {
          id
          name
          imageUrl
        }
      }
    }

    brewers: brewerSearch(query: $query, first: 1) {
      items {
        id
        name
        imageUrl
      }
    }

    places: placeSearch(query: $query, first: 1) {
      items {
        id
        name
        imageUrl
      }
    }
  }
`
